//BonusHistory.js
import React, {useEffect, useState} from 'react'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import tablesCommonStyles from "src/components/pages/Transactions/common/TablesCommonStyles.module.scss"
import {useTranslation} from "react-i18next";

const BonusHistory = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // Пример. Потом ошибки и загрузку выводим из хранилища.
  // const loading = useSelector(selectLoading);
  // const error = useSelector(selectError);

  //Пример. Локальное состояние. loading и error
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const {t} = useTranslation()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 200);
    
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className={stylesCommon.profileRoundedTabNoPadding}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : <div>
        <h1 className={tablesCommonStyles.title}>{t('Bonus history')}</h1>
        {/* <GameGroups />
        <Providers /> */}
      </div>
      }
    </div>
  );
}

// export default withAuth(BonusHistory);
export default BonusHistory;