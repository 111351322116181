import React, { useEffect, useRef, useState } from 'react';
import Alert, { AlertProps } from '../../../layouts/ui/alert/Alert';
import styles from './Wrapper.module.scss';
import clsx from 'clsx';

interface WrapperProps {
    title?: string | null;
    description?: string | null;
    children: React.ReactNode;
    alertProps?: AlertProps | null; // Используем интерфейс AlertProps
    alertPosition?: 'top' | 'bottom';
}

const Wrapper: React.FC<WrapperProps> = ({
    title = null,
    description = null,
    children,
    alertProps = null,
    alertPosition = "bottom"
}) => {
    const alertRef = useRef<HTMLDivElement>(null);

    const [isScrolled, setIsScrolled] = useState<boolean>(false)

    useEffect(() => {
        if (alertProps && alertPosition === 'bottom') {
          if(isScrolled === false){
            alertRef.current?.scrollIntoView({ behavior: 'smooth' });
            alertRef.current?.focus();
            setIsScrolled(true)
          }
        }else{
          setIsScrolled(false)
        }
    }, [alertProps, alertPosition, isScrolled]);

    return (
        <div className={clsx("container", styles.container)}>
            <div className="row">
                <div className={clsx("col", styles.col)}>
                    {title && <h1>{title}</h1>}
                    {description && <p>{description}</p>}

                    {/* Отображение Alert сверху, если alertPosition === "top" */}
                    {alertProps && alertPosition === "top" && (
                        <div className="mt-2 mb-2">
                            <Alert {...alertProps} />
                        </div>
                    )}

                    {children}

                    {/* Отображение Alert снизу, если alertPosition === "bottom" */}
                    {alertProps && alertPosition === "bottom" && (
                        <div ref={alertRef} tabIndex={-1} className="mt-2 mb-2" style={{ outline: 'none' }}>
                            <Alert {...alertProps} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Wrapper;
