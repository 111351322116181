import styles from './CurrencyComingSoon.module.scss'
import {FinancialProcessesNavigation} from "../../../../elems/FinancialProcessesNavigation/FinancialProcessesNavigation"
import React from "react"

export const CurrencyComingSoon = () => {
  return (
    <div className={styles.currencyComingSoon}>
      <FinancialProcessesNavigation />
      <div className={styles.soon}>Coming soon...</div>
    </div>
  )
}