// src/redux/reducers.js
import { combineReducers } from 'redux';
import balanceReducer from './reducers/balanceReducer';
import balancesReducer from './reducers/balancesReducer';
import providerReducer from './reducers/providerReducer';
import gameListReducer from './reducers/game/gameListReducer';
import gameGroupsReducer from './reducers/game/gameGroupsReducer';
import bonusListReducer from './reducers/bonus/bonusListReducer';
import vipClubInfoReducer from './reducers/bonus/vipClubInfoReducer';
import listFavoriteGamesReducer from './reducers/player/listFavoriteGamesReducer';
import myProgressReducer from './reducers/player/myProgressReducer';
import listBalancesReducer from './reducers/player/listBalancesReducer';
import updatePersonalInfoReducer from './reducers/player/updatePersonalInfoReducer';
import getPersonalInfoReducer from './reducers/player/getPersonalInfoReducer';
import getListDocumentsReducer from './reducers/document/getListDocumentsReducer';
import getDocumentSettingTypesReducer from './reducers/document/getDocumentSettingTypesReducer';
import { transactionsReducer } from "./slices/transactionsSlice";
import getPlayerProfileSettingsReducer from './reducers/setting/getPlayerProfileSettingsReducer';
import { api, apiProxy } from "../api/api";
import { gameReducer } from "./slices/gameSlice";
import { InterfaceReducer } from "./slices/interfaceSlice";
import { favoriteGameReducer } from "./slices/addFavoriteGame";
import { getGameWidgetsReducer } from "./slices/getGameWidgets";
import { updatePasswordReducer } from "./slices/updatePasswordSlice";
import { switchAccountReducer } from "./slices/switchAccountSlice";
import playerBalancesReducer from "./reducers/playerDeposit/playerBalancesReducer.js";
import getPaymentMethodsReducer from "./reducers/payment/getPaymentMethodsReducer.js";
import bonusDepositListReducer from "./reducers/playerDeposit/bonusDepositListReducer.js";
import viewPlayerDepositReducer from "./reducers/playerDeposit/viewReducer";
import { projectSettingsReducer } from "./slices/getProjectSettingsSlice";
import { setDepositRequestReducer } from "./slices/setDepositRequestSlice";
import { GetDepositResponsibleGamingReducer } from "./slices/getDepositResponsibleGamingSlice";
import { ListAvailableBalancesReducer } from "./slices/listAvailableBalancesSlice"
import { CreateBalanceReducer } from "./slices/createBalanceSlice"
import menuFinancialProcessesReducer from "./slices/menuFinancialProcessesSlice"
import { GetPaymentMethodsWithdrawalReducer } from "./slices/Withdrawal/getPaymentMethodsWithdrawalSlice"
import { PaymentMethodsWithdrawalViewsReducer } from "./slices/Withdrawal/paymentMethodsWithdrawalViewsSlice"
import { SetWithdrawalRequestReducer } from "./slices/Withdrawal/setWithdrawalRequestSlice"
import ipReducer from "./slices/ipSlice"
import { ModalPlayerDepositReducer } from "./slices/modals/modalPlayerDepositSlice"
import { ListBalancesSliceReducer } from "./slices/Account/listBalancesSlice"
import { MyProgressSliceReducer } from "./slices/Account/myProgressSlice"
import { BalancesSliceReducer } from "./slices/Account/balancesSlice"
import { GetBonusInfoReducer } from "./slices/Bonus/getBonusInfoSlice"
import { BonusSubscribeReducer } from "./slices/Bonus/bonusSubscribeSlice"
import { menuAccountReducer } from "./slices/menus/menuAccountSlice";
import { menuProfileReducer } from "./slices/menus/menuProfileSlice";
import { UserIpBlacklistReducer } from "./slices/blacklist/userIpBlacklistSlice";
import { ModalBonusInfoReducer } from "./slices/modals/modalBonusInfoSlice"
import { LogoutSliceReducer } from "./slices/auth/logoutSlice"
import { LoginSliceReducer } from "./slices/auth/loginSlice"
import { IsAuthenticatedSliceReducer } from "./slices/auth/isAuthenticatedSlice"
import { RegisterSliceReducer } from "./slices/auth/registerSlice"
import { ListResponsibleGamingsReducer } from "./slices/Limits/listResponsibleGamingsSlice"
import { AvailableLimitsReducer } from "./slices/Limits/getAvailableLimitsSlice"
import { CreateResponsibleGamingReducer } from "./slices/Limits/createResponsibleGamingSlice"
import { UpdateResponsibleGamingReducer } from "./slices/Limits/updateResponsibleGamingSlice"
import { DeleteResponsibleGamingReducer } from "./slices/Limits/deleteResponsibleGamingSlice"
import { SendPhoneConfirmationReducer } from "./slices/player/sendPhoneConfirmationSlice"
import { SendEmailConfirmationReducer } from "./slices/player/sendEmailConfirmationSlice"
import { ConfirmPhoneReducer } from "./slices/site/confirmPhone"
import { GetInfoReducer } from "./slices/player/getInfoSlice"
import { ConfirmEmailReducer } from "./slices/site/confirmEmail"
import { GetPlayerConfirmationReducer } from "./slices/player/getPlayerConfirmationSlice"
import { AuthFormReducer } from "./slices/auth/authFormSlice"
import { SendCodeConfirmationReducer } from "./slices/auth/sendCodeConfirmationSlice"
import { GetRegistrationFormReducer } from "./slices/auth/getRegistrationFormSlice"
import { RegisterValidationFirstScreenReducer } from "./slices/auth/registerValidationFirstScreenSlice"
import { CheckPopupReducer } from "./slices/popup/CheckPopupSlice"
import { ChangeQueueReducer } from "./slices/popup/changeQueueSlice"
import { CheckQueueReducer } from "./slices/popup/checkQueueSlice"
import { RequestPasswordResetReducer } from "./slices/site/requestPasswordReset"
import { PromocodeReducer } from "./slices/Bonus/promocodeSlice"

const rootReducer = combineReducers({
    balanceReducer,
    providerReducer,
    balancesReducer,
    gameListReducer,
    gameGroupsReducer,
    listFavoriteGamesReducer,
    bonusListReducer,
    getListDocumentsReducer,
    getDocumentSettingTypesReducer,
    myProgressReducer,
    listBalancesReducer,
    vipClubInfoReducer,
    updatePersonalInfoReducer,
    transactions: transactionsReducer,
    getPersonalInfoReducer,
    getPlayerProfileSettingsReducer,
    [api.reducerPath]: api.reducer,
    game: gameReducer,
    interface: InterfaceReducer,
    favoriteGame: favoriteGameReducer,
    getGameWidgets: getGameWidgetsReducer,
    switchAccount: switchAccountReducer,
    playerBalancesReducer,
    getPaymentMethodsReducer,
    [apiProxy.reducerPath]: apiProxy.reducer,
    bonusDepositListReducer,
    viewPlayerDepositReducer,
    updatePasswordReducer,
    projectSettingsReducer,
    setDepositRequestReducer,
    GetDepositResponsibleGamingReducer,
    ListAvailableBalancesReducer,
    CreateBalanceReducer,
    ipReducer,
    menuFinancialProcessesReducer,
    GetPaymentMethodsWithdrawalReducer,
    PaymentMethodsWithdrawalViewsReducer,
    SetWithdrawalRequestReducer,
    ModalPlayerDepositReducer,
    ListBalancesSliceReducer,
    BalancesSliceReducer,
    MyProgressSliceReducer,
    GetBonusInfoReducer,
    BonusSubscribeReducer,
    menuAccountReducer,
    menuProfileReducer,
    UserIpBlacklistReducer,
    ModalBonusInfoReducer,
    LogoutSliceReducer,
    LoginSliceReducer,
    IsAuthenticatedSliceReducer,
    RegisterSliceReducer,
    ListResponsibleGamingsReducer,
    AvailableLimitsReducer,
    CreateResponsibleGamingReducer,
    UpdateResponsibleGamingReducer,
    DeleteResponsibleGamingReducer,
    SendPhoneConfirmationReducer,
    ConfirmPhoneReducer,
    GetInfoReducer,
    SendEmailConfirmationReducer,
    ConfirmEmailReducer,
    GetPlayerConfirmationReducer,
    AuthFormReducer,
    SendCodeConfirmationReducer,
    GetRegistrationFormReducer,
    RegisterValidationFirstScreenReducer,
    CheckPopupReducer,
    PromocodeReducer,
    ChangeQueueReducer,
    CheckQueueReducer,
    RequestPasswordResetReducer
});

export default rootReducer;
