import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import shield_2 from 'src/assets/images/shield_2.png'
import React from "react"
import clsx from 'clsx'
import styles from './VerifyProfile.module.scss'
import {ButtonStyled} from "../../../../../common/ButtonStyled/ButtonStyled"
import {useVerifyClick} from "../../../../../common/hooks/useVerifyClick"
import {useTranslation} from "react-i18next";

export const VerifyProfile = () => {

  const { onVerifyClick } = useVerifyClick()
  const {t} = useTranslation()

  return (
    <div className={clsx(stylesCommon.accountElement, styles.verifyProfile)}>
      <div className={styles.topContainer}>
        <img src={shield_2} alt="shield_2"/>
        <div className={stylesCommon.header}>{t('Verify your profile')}</div>
        <div className={styles.text}>{t('We recommend you to set a two-factor authentication and verify your phone number')}</div>
      </div>

      <ButtonStyled paddingVariant={'14px 18px'} height={'48px'} className={styles.button} variant={'accent'} onClick={onVerifyClick}>{t('Verify')}</ButtonStyled>
    </div>
  )
}