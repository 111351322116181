import React from "react";
import { UserIconProps } from "../../../../common/types";

export const Settings04Icon = ({ stroke = "white", strokeOpacity = "0.6", className }: UserIconProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="settings-04">
      <path
        id="Icon"
        d="M2.7002 5.33398L10.7002 5.33399M10.7002 5.33399C10.7002 6.43855 11.5956 7.33398 12.7002 7.33398C13.8048 7.33398 14.7002 6.43855 14.7002 5.33398C14.7002 4.22941 13.8048 3.33398 12.7002 3.33398C11.5956 3.33398 10.7002 4.22942 10.7002 5.33399ZM6.7002 10.6673L14.7002 10.6673M6.7002 10.6673C6.7002 11.7719 5.80476 12.6673 4.7002 12.6673C3.59563 12.6673 2.7002 11.7719 2.7002 10.6673C2.7002 9.56275 3.59563 8.66732 4.7002 8.66732C5.80476 8.66732 6.7002 9.56275 6.7002 10.6673Z"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
