// getPlayerConfirmationSlice
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type GetPlayerConfirmationResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type GetPlayerConfirmationError = {
  error: any;
  message: string;
};

interface GetPlayerConfirmationState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: any[]; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: GetPlayerConfirmationState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchGetPlayerConfirmation
interface GetPlayerConfirmationParams {
  token: string;
//   params?: {};
}

export const fetchGetPlayerConfirmation = createAsyncThunk<
  GetPlayerConfirmationResponse,
  GetPlayerConfirmationParams,
  { rejectValue: GetPlayerConfirmationError }
>(
  'GetPlayerConfirmation/fetchGetPlayerConfirmation',
  async ({ token }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchGetPlayerConfirmation:', `${config.serverUrl}/player/get-player-confirmation`, { token });

      const response = await axios.get<GetPlayerConfirmationResponse>(
        `${config.serverUrl}/player/get-player-confirmation`,{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        }
      );
      console.log('Attempting to fetchGetPlayerConfirmation response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error Get Info:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to Get Info .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);



const GetPlayerConfirmation = createSlice({
  name: 'Profile/GetPlayerConfirmation',
  initialState,
  reducers: {
    resetStateGetPlayerConfirmation: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetPlayerConfirmation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchGetPlayerConfirmation.fulfilled, (state, action: PayloadAction<GetPlayerConfirmationResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchGetPlayerConfirmation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateGetPlayerConfirmation } = GetPlayerConfirmation.actions;

export const GetPlayerConfirmationReducer = GetPlayerConfirmation.reducer;



// ---------
// Selectors
// ---------
export const selectGetPlayerConfirmationLoading = (state: RootState) => state.GetPlayerConfirmationReducer.loading;
export const selectGetPlayerConfirmationSuccess = (state: RootState) => state.GetPlayerConfirmationReducer.success;
export const selectGetPlayerConfirmationError = (state: RootState) => state.GetPlayerConfirmationReducer.error;
export const selectGetPlayerConfirmationMessages = (state: RootState) => state.GetPlayerConfirmationReducer.messages;
export const selectGetPlayerConfirmationData = (state: RootState) => state.GetPlayerConfirmationReducer.data;

export const selectGetPlayerConfirmationDataMainBalance = (state: RootState) => state.GetPlayerConfirmationReducer.data?.main_balance;

export const selectGetPlayerConfirmationErrorString = (state: RootState): string | null => {
  const error = state.GetPlayerConfirmationReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectGetPlayerConfirmationErrorArray = (state: RootState): string[] | null => {
  const error = state.GetPlayerConfirmationReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
