import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from "../../../../hooks"
import TextInput from '../formComponents/TextInput'
import DataInput from '../formComponents/DataInput/DataInput'
import PasswordInput from '../formComponents/PasswordInput'
import SelectInput from '../formComponents/SelectInput/SelectInput'
import CheckboxInput from '../formComponents/CheckboxInput'
import Alert from '../../../layouts/ui/alert/Alert'
import styles from './Signup.module.scss'
import Grid from '@mui/material/Grid2'

import {
  fetchRegister,
  resetStateRegister,
  selectRegisterSliceLoading,
  selectRegisterSliceSuccess,
  selectRegisterSliceError,
  selectRegisterSliceMessages,
  selectRegisterSliceData,
  selectRegisterSliceErrorArray,
  FetchRegisterParam

} from '../../../../redux/slices/auth/registerSlice'

import {
  RegistrationFormElementsType,
  RegistrationFormElementType,
} from '../../../../redux/slices/auth/getRegistrationFormSlice'

import { SubmitButton } from "../formComponents/SubmitButton/SubmitButton"
import mail_01_passive from 'src/assets/images/icons/mail_01_passive.svg'
import mail_01 from 'src/assets/images/icons/mail_01.svg'
import { SelectChangeEvent } from '@mui/material'
import { interfaceSelector } from "../../../../redux/slices/interfaceSlice"
import { prepareFormStateForServer, Currencies, FormSettings, DataFirstStepState } from "./Signup"

// Интерфейсы для состояний формы и ошибок клиента
interface FormStateRegostration {
  [key: string]: string | boolean;
}

interface ClientErrors {
  required: { [key: string]: string };
}


interface Country {
  country_id: string;
  title_ru: string;
  title_ua: string;
  title_be: string;
  title_en: string;
  title_es: string;
  title_pt: string;
  title_de: string;
  title_fr: string;
  title_it: string;
  title_pl: string;
  title_ja: string;
  title_lt: string;
  title_lv: string;
  title_cz: string;
  iso: string;
}


interface SignupRegistrationFormProps {
  onRegistr: () => void;
  dataFirstStep: DataFirstStepState;
  currencies: Currencies[];
  formSettings: FormSettings;
  formElements: RegistrationFormElementsType;
}



const SignupRegistrationForm: React.FC<SignupRegistrationFormProps> = ({ onRegistr, dataFirstStep, currencies, formSettings, formElements }) => {
  // const dispatch = useDispatch();
  const dispatch = useAppDispatch()

  // Рефы
  const formRef = useRef<HTMLFormElement>(null)

  // Результаты запроса на регистрацию игрока
  const registrationAccauntData = useSelector(selectRegisterSliceData)
  const registrationAccauntLoading = useSelector(selectRegisterSliceLoading)
  const registrationAccauntError = useSelector(selectRegisterSliceError)
  const registrationAccauntErrorArray = useSelector(selectRegisterSliceErrorArray)
  const registrationAccauntMessages = useSelector(selectRegisterSliceMessages)
  const registrationAccauntCreatedSuccess = useSelector(selectRegisterSliceSuccess)
  

  // Вспомогательные локальные стэйты
  const [formState, setFormState] = useState<FormStateRegostration>({})
  const [initialState, setInitialState] = useState<FormStateRegostration>({})


  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState<boolean>(false)
  const [clientErrors, setClientErrors] = useState<ClientErrors>({ required: {} })

  const [showErrors, setShowErrors] = useState(false) //это чтобы алерт с ошибками не появлялся после закрытия

  // Результат регистрации на втором экране
  useEffect(() => {
    if (registrationAccauntCreatedSuccess) {
      setIsRegistrationSuccess(true)
      setFormState(initialState) // Очистка формы после успешной регистрации
      onRegistr()
    }
    if (registrationAccauntError) {
      setShowErrors(true) // раз ошибки есть то разблокируем показ окна алерта
    }
  }, [registrationAccauntCreatedSuccess, registrationAccauntError, initialState])



  const clearDataAndErrorsStepTwo = () => {
    dispatch(resetStateRegister())
    setIsRegistrationSuccess(false)
    setShowErrors(false)
  }

  // Очищаем данные регистрации после регистрации
  useEffect(() => {
    clearDataAndErrorsStepTwo()

    // Очистка ошибок при размонтировании
    return () => {
      clearDataAndErrorsStepTwo()
    }
  }, [])


  // Обработка нажатия кнопки регистрации
  const handleButtonCreateAccauntClick = () => {
    clientValidation()
  }


  // Обработка отправки формы на регистрацию на втором экране
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (clientValidation()) {
      const preparedFormState = prepareFormStateForServer({
        ...dataFirstStep,
        ...formState
      })
      console.log("handleSubmit preparedFormState", preparedFormState);
      dispatch(fetchRegister(preparedFormState as FetchRegisterParam))

    } else {
      console.error('Validation failed')
    }

  }

  // Функция валидации полей формы
  const clientValidation = (): boolean => {
    const formElements = formRef.current?.elements
    const newErrors: ClientErrors = { required: {} }
    let formIsValid = true

    if (formElements) {
      Array.from(formElements).forEach(field => {
        const input = field as HTMLInputElement
        // Определяем, является ли поле пустым
        const isFieldEmpty =
          input.type === 'checkbox'
            ? !input.checked
            : typeof input.value === 'string' && input.value.trim() === ''

        if (input.required && isFieldEmpty) {
          formIsValid = false
          const errorMessage = input.getAttribute('data-error-required') || 'This field is required.'
          newErrors.required[input.name] = errorMessage
        }
      })
    }

    setClientErrors(newErrors)
    return formIsValid
  }

  const handleInputChange = (
    code: string, e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | SelectChangeEvent<string>
  ) => {

    // Используем `checked` для чекбоксов, а `value` для остальных полей
    const target = e.target as (HTMLInputElement | HTMLSelectElement)

    const value: string | boolean =
      target && target.type === 'checkbox' && 'checked' in target
        ? (target as HTMLInputElement).checked
        : String(target.value)

    console.log(code, value)
    const updatedFormState = {
      ...formState,
      [code]: value,  // Используем `code` как ключ, чтобы обновить соответствующее поле
    }
    // console.log(updatedFormState);
    setFormState(updatedFormState)

    // Проверяем, изменились ли данные по сравнению с начальным состоянием
    // setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))

    // Если поле заполнено (или отмечено), удаляем его из ошибок валидации
    setClientErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }

      // Проверка для строки перед использованием `trim()`
      const target = e.target as HTMLInputElement | HTMLSelectElement // Type casting
      const isFieldFilled =
        target.type === 'checkbox'
          ? value
          : typeof value === 'string' && value.trim() !== ''

      if (isFieldFilled) {
        delete updatedErrors['required'][code]
      } else {
        const target = e.target as HTMLInputElement | HTMLSelectElement // Type casting
        const errorMessage = target.getAttribute('data-error-required') || 'This field is required.'
        updatedErrors['required'][code] = errorMessage
      }

      return updatedErrors
    })
  }

  const handleErrorClose = () => {
    setShowErrors(false) // Скрываем ошибки при нажатии на крестик
  }



  const { isMobile } = useAppSelector(interfaceSelector)

  // Рендеринг полей формы в зависимости от типа
  const renderFormField = (item: RegistrationFormElementType, isRegistration?: boolean) => {

    const isAutoComplete = isRegistration ? "off" : "on"

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | SelectChangeEvent<string>) =>
      handleInputChange(item.code, e)

    let serverValidationErrors = null

    if (registrationAccauntError?.[item.code]) {
      serverValidationErrors = registrationAccauntError[item.code]
    }


    const commonProps = {
      id: item.code,
      name: item.code,
      value: String(formState[item.code] || ''),
      onChange: handleChange, // Обработчик для input и select
      required: item.flag_required === "YES",
      placeholder: item.name,
      error: clientErrors.required[item.code] || serverValidationErrors,
    }

    switch (item.code) {
      case 'currency':
        const currencyOptions = currencies

        return <SelectInput {...commonProps} options={currencyOptions
          ? Object.entries(currencyOptions).map(([id, code]) => ({
            id,
            name: String(code)
          }))
          : []
        } variant={'currency'} />

      case 'country':
        const countries: Country[] = formSettings.countries
        
        return (
          <>
            <SelectInput {...commonProps} options={countries.map((country: Country) => ({
              id: String(country.country_id),
              name: country.title_en
            }))}
              variant={'country'}
            />
          </>

        )

      case 'terms':
        return (
          <CheckboxInput
            {...commonProps}
            checked={!!formState[item.code]} // Булевое значение для контролируемого чекбокса
            hint="I agree to the Terms & Conditions and Privacy Policy"
          />
        )

      case 'birth':
        return <DataInput {...commonProps} />

      case 'password':
        return (
          <>
            <PasswordInput {...commonProps} id="new_password" name="new_password"
              placeholder="Enter new password" autoComplete={isAutoComplete} />
            {(
              formSettings?.is_password_confirmation_required
              &&
              <PasswordInput {...commonProps} id="confirm_password" name="confirm_password"
                placeholder="Confirm password" autoComplete={isAutoComplete} />
            )}

          </>
        )

      case 'email':
        return (
          <TextInput {...commonProps}
            activeIcon={mail_01}
            passiveIcon={mail_01_passive}
            autoComplete={isAutoComplete}
          />
        )

      default:
        return <TextInput {...commonProps} />
    }
  }

  const stylesBodyCommon = getComputedStyle(document.body)
  const formColumnSpacing = stylesBodyCommon.getPropertyValue('--form-column-spacing')

  return (
    <>
      {/* Блок с формой */}
      <form onSubmit={handleSubmit} ref={formRef} className={styles.signupFormInputs}>
        <Grid container columns={2} columnSpacing={formColumnSpacing} rowSpacing={'16px'}>
          {formElements && formElements.map((item: RegistrationFormElementType, index: number) => {
            if (item.flag_displayed !== "YES") return null

            if (item.code === "email" || item.code === "password" || item.code === "currency" || item.code === "terms"
            ) {
              return null
            }

            return (
              <Grid size={1} key={item.id || index}>
                <React.Fragment>
                  {renderFormField(item)}
                </React.Fragment>
              </Grid>
            )
          })}

        </Grid>

        <div key="gender-radio">
          {formSettings.flag_gender === "YES" && (
            <div className="mb-3">
              <label className="form-label"><strong>Gender</strong></label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="genderMale"
                  value="male"
                />
                <label className="form-check-label" htmlFor="genderMale">
                  Male
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="genderFemale"
                  value="female"
                />
                <label className="form-check-label" htmlFor="genderFemale">
                  Female
                </label>
              </div>
            </div>
          )}
        </div>

        {/* Блок с кнопкой и ссылкой */}
        <SubmitButton onClick={handleButtonCreateAccauntClick} isLoading={registrationAccauntLoading}
          text={'Register'} />
      </form>

      {showErrors && registrationAccauntErrorArray && registrationAccauntErrorArray.length > 0 && (
        <Alert onClose={handleErrorClose} type="error">
          {registrationAccauntErrorArray.map((error, index) => {
            return (<p key={index}>{error}</p>)
            // Проверяем, существует ли ошибка в локальном состоянии
            // if (!secondScreenServerValidationErrors.hasOwnProperty(index)) {
            //     return (<p key={index}>{error}</p>);
            // }
            // return null; // Не выводим ошибку, если она есть в локальном состоянии
          })}
        </Alert>
      )}

      {isRegistrationSuccess && (
        <Alert><strong>Success!</strong> Player account has been created! Now you can log
          in!</Alert>
      )}


    </>
  )

}

export default SignupRegistrationForm
