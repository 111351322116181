// import React from 'react';
import styles from './TabbarElement.module.scss';
import React from "react"

export type TabbarElementProps = {
  label: string
  icon: string
  onClick?: () => void
}

const TabbarElement: React.FC<TabbarElementProps> = ({ label, icon, onClick }) => {
  return (
    <div className={styles.tabbarElement} onClick={onClick}>
      <img src={icon} alt={label} className={styles.icon} />
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default TabbarElement;