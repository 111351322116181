import axios from 'axios';
import config from '../../config';
import {DISABLE_MISC_LOGS} from "../../common/constants";

export const FETCH_BALANCE_REQUEST = 'FETCH_BALANCE_REQUEST';
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS';
export const FETCH_BALANCE_FAILURE = 'FETCH_BALANCE_FAILURE';

// Экшены для получения баланса
export const fetchBalanceRequest = () => ({
  type: FETCH_BALANCE_REQUEST,
});

export const fetchBalanceSuccess = (balance) => ({
  type: FETCH_BALANCE_SUCCESS,
  payload: balance,
});

export const fetchBalanceFailure = (error) => ({
  type: FETCH_BALANCE_FAILURE,
  payload: error,
});

export const fetchBalance = (token) => async (dispatch) => {
  dispatch(fetchBalanceRequest());
  try {
    const response = await axios.get(`${config.apiBaseUrl}/player/balance`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      }
    });
    dispatch(fetchBalanceSuccess(response.data.balance));
  } catch (error) {
    dispatch(fetchBalanceFailure(error.message));
  }
};

// Экшены для WebSocket
export const connectBalanceWebSocket = (token) => {
  return (dispatch) => {
    const ws = new WebSocket(`${config.serverWebsocketUrl}`);

    ws.onopen = () => {
      !DISABLE_MISC_LOGS && console.log('Connected to WebSocket server');
      ws.send(JSON.stringify({ type: 'getBalance', token }));
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log('Parsed event.data getBalance:', data.balance.data);
        if (data.type === 'getBalance') {
          dispatch(fetchBalanceSuccess(data.balance.data));
        } else if (data.type === 'error') {
          dispatch(fetchBalanceFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error.message);
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
