import React from "react"
import {useTranslation} from "react-i18next"
import styles from './PromoCode.module.scss'

type Props = {
  marginTop?: string
}

export const PromoCode = ({marginTop = '16px'}: Props) => {
  const {t} = useTranslation()

  const style: React.CSSProperties = {
    marginTop: marginTop,
  }

  return (
    <div className={styles.promoCode} style={style}>
      <div className={styles.promo}>{t('I have promo code')}</div>
    </div>
  )
}