//getDocumentSettingTypesReducer.js
import { FETCH_DOCUMENTSETTINGTYPES_REQUEST, FETCH_DOCUMENTSETTINGTYPES_SUCCESS, FETCH_DOCUMENTSETTINGTYPES_FAILURE, DOCUMENTSETTINGTYPES_CLEAR_STATE } from '../../actions/document/getDocumentSettingTypesActions';

const initialState = {
    documentSettingTypes: [],
    loading: false,
    error: null,
    messages: null,
  };
  
  const getDocumentSettingTypesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DOCUMENTSETTINGTYPES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_DOCUMENTSETTINGTYPES_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          documentSettingTypes: action.payload,
        };
      case FETCH_DOCUMENTSETTINGTYPES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case DOCUMENTSETTINGTYPES_CLEAR_STATE:
        return initialState;  

      default:
        return state;
    }
  };
  
  export default getDocumentSettingTypesReducer;