import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch, useAppSelector} from "../../../../hooks"
import {
  clearErrorsLogin,
  fetchLogin,
  selectLoginData,
  selectLoginError,
  selectLoginErrorArray,
  selectLoginLoading,
  selectLoginSuccess
} from '../../../../redux/slices/auth/loginSlice'

import {interfaceSelector, setAuthActiveTab} from "../../../../redux/slices/interfaceSlice"

import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import PasswordInput from '../formComponents/PasswordInput'
import TextInput from '../formComponents/TextInput'
import stylesCommon from "../AuthCommon.module.scss"
import styles from './Login.module.scss'
import {NavigationButtonsContainer} from "../../../../common/NavigationButtonsContainer/NavigationButtonsContainer"
import login_background from 'src/assets/images/login_background.jpg'
import login_background_mobile from 'src/assets/images/login_background_mobile.jpg'
import {LoginNavigationButtons} from "../formComponents/LoginNavigationButtons/LoginNavigationButtons"
import {SocialAuth} from "../formComponents/SocialAuth/SocialAuth"
import {SubmitButton} from "../formComponents/SubmitButton/SubmitButton"
import mail_01_passive from 'src/assets/images/icons/mail_01_passive.svg'
import mail_01 from 'src/assets/images/icons/mail_01.svg'
import {browser, deviceType, os} from '../../../../helpers/deviceDetectorHelper'
import clsx from 'clsx'
import {useTranslation} from "react-i18next"
import { MobileStatus } from 'src/common/MobileStatus/MobileStatus'


// Определение интерфейсов для состояний
interface FormState {
  email?: string;
  password?: string;
}

// interface ClientErrors {
//   required: { [key: string]: string };
// }

interface ClientErrors {
  required: {
    [key: string]: {
      hasError: boolean;
      message: string;
    };
  };
}

interface LoginProps {
  handleButtonActiveTabClick: (tab: 'login' | 'signup') => void;
}

const Login: React.FC<LoginProps> = ({handleButtonActiveTabClick}) => {
  const dispatch = useAppDispatch()
  // Навигация
  const navigate = useNavigate()

  const loginSuccess: boolean = useSelector(selectLoginSuccess)
  const loginErrors: any = useSelector(selectLoginError)
  const loginData: any = useSelector(selectLoginData)
  const loginErrorsArray: string[] | null = useSelector(selectLoginErrorArray)
  const isLoadingLogin: boolean = useSelector(selectLoginLoading)
  const {isMobile} = useAppSelector(interfaceSelector)
  const {t} = useTranslation()

  const [ip, setIp] = useState<string>('')

  const [clientErrors, setClientErrors] = useState<ClientErrors>({
    required: {
      email: {
        hasError: false,
        message: 'This field is required.'
      },
      password: {
        hasError: false,
        message: 'This field is required.'
      }
    }
  })

  const [emailFieldErrors, setEmailFieldErrors] = useState<string[] | null>(null)
  const [passwordFieldErrors, setPasswordFieldErrors] = useState<string[] | null>(null)
  const [formState, setFormState] = useState<FormState>({})
  const [initialState, setInitialState] = useState<FormState>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    const initialFormValues: FormState = {
      email: '',
      password: ''
    }
    // const deviceDetect = deviceDetector()
    setFormState(initialFormValues)
    setInitialState(initialFormValues)
  }, [])

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json')
        setIp(response.data.ip)
      } catch (error) {
        console.error('Error fetching IP:', error)
      }
    }
    fetchIp()
  }, [])

  // Set validation errors
  useEffect(() => {
    if (loginSuccess) {
      console.log('Login loginData', loginData)
      // debugger;
      navigate('/profile')
    }

    if (loginErrors) {
      if (loginErrors.email) {
        setEmailFieldErrors(loginErrors.email)
      } else {
        setEmailFieldErrors(null)
      }

      if (loginErrors.password) {
        setPasswordFieldErrors(loginErrors.password)
      } else {
        setPasswordFieldErrors(null)
      }
    }
  }, [loginErrors, loginSuccess, loginData])

  useEffect(() => {
    // Очистка ошибок при каждом монтировании компонента
    dispatch(clearErrorsLogin())
    setEmailFieldErrors(null)
    setPasswordFieldErrors(null)

    // Очистка ошибок при размонтировании
    return () => {
      dispatch(clearErrorsLogin())
      setEmailFieldErrors(null)
      setPasswordFieldErrors(null)
    }
  }, [])


  // Login request
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // dispatch(clearErrorsLogin())
    const email = formState.email
    const password = formState.password

    if (clientValidation() && email && password) {
      const loginParams = {
        ip: ip,
        email: email,
        password: password,
        device: deviceType(),
        browser: browser(),
        os: os(),
      }
      dispatch(fetchLogin(loginParams))
    } else {
      console.log('Validation failed')
    }
  }

  const updateFieldError = (fieldName: string, value: string | boolean) => {
    setClientErrors((prevErrors) => {
      const updatedErrors = {...prevErrors}

      // Проверяем, есть ли ошибка для поля
      if (updatedErrors.required.hasOwnProperty(fieldName)) {
        const isFieldFilled = typeof value === 'boolean' ? value : (typeof value === 'string' && value.trim() !== '')

        // Обновляем ошибку в зависимости от того, заполнено ли поле
        updatedErrors.required[fieldName].hasError = !isFieldFilled
      }

      return updatedErrors
    })
  }

  const clientValidation = (): boolean => {
    const formElements = formRef.current?.elements
    let formIsValid = true

    const isFieldRequired = (fieldName: string) => {
      return clientErrors.required.hasOwnProperty(fieldName)
    }

    if (formElements) {
      Array.from(formElements).forEach(field => {
        const input = field as HTMLInputElement
        const inputName = input.name
        const inputValue = input.value.trim()

        updateFieldError(inputName, inputValue)
        if (!inputValue && clientErrors.required.hasOwnProperty(inputName)) {
          formIsValid = false
        }

      })
    }


    return formIsValid
  }

  const handleButtonLoginAccauntClick = () => {
    const formIsValid =  clientValidation()
    if (formIsValid) formRef.current?.requestSubmit()
  }

  const handleInputChange = (code: string, e: ChangeEvent<HTMLInputElement>) => {
    // const value = e.target.value
    const value = e.target.type === 'checkbox' ? (e.target as HTMLInputElement).checked : e.target.value

    const updatedFormState = {
      ...formState,
      [code]: value,
    }
    setFormState(updatedFormState)
    setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))

    // Обновляем ошибки
    updateFieldError(code, value)
  }

  // Кнопка закрытия на первом экране
  /* const handleClickCloseButtonInFirstScreen = () => {
     if (location.key !== 'default') {
       navigate(-1) // Переход на предыдущую страницу, если история есть
     } else {
       navigate('/') // Переход на главную, если истории нет
     }
   }*/

  const NavigationButtons = () => (
    <NavigationButtonsContainer>
      <LoginNavigationButtons
        onLoginClick={() => handleButtonActiveTabClick('login')}
        onRegisterClick={() => handleButtonActiveTabClick('signup')}
        activeButton={'login'}
      />
    </NavigationButtonsContainer>
  )

  const LoginFormComponents = () => (
    <div className={styles.loginFormComponents}>
      <SubmitButton onClick={handleButtonLoginAccauntClick} isLoading={isLoadingLogin} text={t('Login')}/>
      <div className={styles.forgotPasswordContainer}>
        <div className={styles.forgetText}>
          <a onClick={() => dispatch(setAuthActiveTab('password_recovery'))}>{t('Forgot password?')}</a>
        </div>
      </div>

      {loginErrorsArray && (!emailFieldErrors && !passwordFieldErrors) && (
        <div className="alert alert-danger" role="alert">
          {loginErrorsArray.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}
      <SocialAuth variant={'login'}/>
    </div>
  )


  // Render View
  const renderView = (): JSX.Element => (
    <>
      {isMobile && <MobileStatus/>}
    <div className={styles.login}>
      {isMobile && <NavigationButtons/>} {/*move top isMobile*/}

      <div className={styles.gridContainer}>
        <>
          {/*{isMobile && <LoginFormComponents/> }*/}
          
          <div className={clsx(stylesCommon.leftContent, styles.leftContent)} style={{
            "--background": `url(${!isMobile ? login_background : login_background_mobile})`,
          } as React.CSSProperties}>
            <div className={stylesCommon.text}>
              {t('Play and win big with Democasino!')}
            </div>
          </div>

          {/*{isMobile && <LoginFormComponents/> }*/}
          
        </>


        <div className={clsx(stylesCommon.rightContent, styles.rightContent)}>
          {/* Блок с кнопками Логин и Регистрация */}
          {!isMobile && <NavigationButtons/>}
          {/* Блок с формой */}
          <form onSubmit={handleSubmit} ref={formRef}>

            <div className={styles.formInputs}>
              <TextInput
                id="email"
                name="email"
                value={formState.email || ''}
                onChange={(e) => handleInputChange('email', e)}
                // required
                placeholder={t('Enter your email')}
                passiveIcon={mail_01_passive}
                activeIcon={mail_01}
                error={
                  (clientErrors.required["email"].hasError ? clientErrors.required["email"].message : null) ||
                  (emailFieldErrors)
                }
              />

              <PasswordInput
                id="password"
                name="password"
                value={formState.password || ''}
                onChange={(e) => handleInputChange('password', e)}
                // required
                placeholder={t('Enter your password')}
                // error={clientErrors.required.password || passwordFieldErrors}
                error={
                  (clientErrors.required["password"].hasError ? clientErrors.required["password"].message : null) ||
                  (passwordFieldErrors)
                }
              />
            </div>


            {/* Блок с кнопкой и ссылкой */}
            {!isMobile && <LoginFormComponents/> }

          </form>
        </div>
      </div>
      {isMobile && <LoginFormComponents/> }
      
      {/* тут будет внутренний контент */}
    </div>
    </>
  )

  return (
    renderView()
  )
}

export default Login
