//getDocumentSettingTypesActions.js
import axios from 'axios';
import config from '../../../config';

export const FETCH_DOCUMENTSETTINGTYPES_REQUEST = 'FETCH_DOCUMENTSETTINGTYPES_REQUEST';
export const FETCH_DOCUMENTSETTINGTYPES_SUCCESS = 'FETCH_DOCUMENTSETTINGTYPES_SUCCESS';
export const FETCH_DOCUMENTSETTINGTYPES_FAILURE = 'FETCH_DOCUMENTSETTINGTYPES_FAILURE';
export const DOCUMENTSETTINGTYPES_CLEAR_STATE   = 'DOCUMENTSETTINGTYPES_CLEAR_STATE';

// Экшены для получения данных
export const fetchDocumentSettingTypesActionsRequest = () => ({
  type: FETCH_DOCUMENTSETTINGTYPES_REQUEST,
});

export const fetchDocumentSettingTypesActionsSuccess = (documentSettingTypes) => ({
  type: FETCH_DOCUMENTSETTINGTYPES_SUCCESS,
  payload: documentSettingTypes,
});

export const fetchDocumentSettingTypesActionsFailure = (error) => ({
  type: FETCH_DOCUMENTSETTINGTYPES_FAILURE,
  payload: error,
});

export const documentSettingTypesActionsClearState = () => ({
  type: DOCUMENTSETTINGTYPES_CLEAR_STATE,
});

export const fetchDocumentSettingTypesActions = (token) => async (dispatch) => {
  dispatch(fetchDocumentSettingTypesActionsRequest());
  try {
    const response = await axios.get(`${config.serverUrl}/document/setting-types`, {
      headers: {
        'Authorization': `${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
    
    dispatch(fetchDocumentSettingTypesActionsSuccess(response.data.data));
  } catch (error) {
    console.log('Documents documentSettingTypes selectors fetchDocumentSettingTypesActions error', error)
    dispatch(fetchDocumentSettingTypesActionsFailure(error.message));
  }
};


export const connectWebSocketGetDocumentSettingTypes = (token, language = null) => {
  return (dispatch) => {
    const ws = new WebSocket(config.serverWebsocketUrl);

    ws.onopen = () => {
      dispatch(fetchDocumentSettingTypesActionsRequest());
      console.log('Connected to WebSocket server in action file getDocumentSettingTypesActions.js');
      ws.send(JSON.stringify({ type: 'getDocumentSettingTypes', token }));
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received getDocumentSettingTypes:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log('Parsed event.data:', data.documentSettingTypes.data);

        if (data.type === 'getDocumentSettingTypes') {
          const documentSettingTypes = data.documentSettingTypes.data;
          
          // Преобразуем данные в массив
          const documentSettingTypesArray = Array.isArray(documentSettingTypes) ? documentSettingTypes : [documentSettingTypes];

          console.log('file getDocumentSettingTypesActions.js received (array):', documentSettingTypesArray);
          dispatch(fetchDocumentSettingTypesActionsSuccess(documentSettingTypesArray));
        } else if (data.type === 'error') {
          console.log('Documents documentSettingTypes selectors ws error')
          dispatch(fetchDocumentSettingTypesActionsFailure(data.message));
        }
      } catch (error) {
        dispatch(fetchDocumentSettingTypesActionsFailure(error.message));
        console.error('Error parsing WebSocket message:', error);
        console.log('Documents documentSettingTypes selectors ws error', error)
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server file getDocumentSettingTypesActions.js');
    };

    return () => {
      ws.close();
    };
  };
};
