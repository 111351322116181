// confirmEmail.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type ConfirmEmailResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type ConfirmEmailError = {
  error: any;
  message: string;
};

interface ConfirmEmailState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: any[]; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: ConfirmEmailState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchConfirmEmail
interface ConfirmEmailParams {
  token: string;// это токен не для авторизации
}

export const fetchConfirmEmail = createAsyncThunk<
  ConfirmEmailResponse,
  ConfirmEmailParams,
  { rejectValue: ConfirmEmailError }
>(
  'ConfirmEmail/fetchConfirmEmail',
  async ({ token }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchConfirmEmail:', `${config.serverUrl}/site/confirm-email`, { token });

      const response = await axios.get<ConfirmEmailResponse>(
        `${config.serverUrl}/site/confirm-email`,
        {
          params: {
            token,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Attempting to fetchConfirmEmail response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error Confirm Email', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data.errors || ['Failed to Confirm Email .'],
          message: error.response.data.messages || ['Failed to Confirm Email .'],
        });
      }

      return rejectWithValue({
        error: ['An unknown error occurred.'],
        message: 'An unknown error occurred.',
      });
    }
  }
);



const ConfirmEmail = createSlice({
  name: 'Profile/ConfirmEmail',
  initialState,
  reducers: {
    resetStateConfirmEmail: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfirmEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchConfirmEmail.fulfilled, (state, action: PayloadAction<ConfirmEmailResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchConfirmEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateConfirmEmail } = ConfirmEmail.actions;

export const ConfirmEmailReducer = ConfirmEmail.reducer;



// ---------
// Selectors
// ---------
export const selectConfirmEmailLoading = (state: RootState) => state.ConfirmEmailReducer.loading;
export const selectConfirmEmailSuccess = (state: RootState) => state.ConfirmEmailReducer.success;
export const selectConfirmEmailError = (state: RootState) => state.ConfirmEmailReducer.error;
export const selectConfirmEmailMessages = (state: RootState) => state.ConfirmEmailReducer.messages;
export const selectConfirmEmailData = (state: RootState) => state.ConfirmEmailReducer.data;


export const selectConfirmEmailErrorString = (state: RootState): string | null => {
  const error = state.ConfirmEmailReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectConfirmEmailErrorArray = (state: RootState): string[] | null => {
  const error = state.ConfirmEmailReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
