import styles from "./ListBalancesDropdown.module.scss"
import {
  selectToken
} from "../../../redux/slices/auth/loginSlice"
import {useGetListBalancesQuery} from "../../../api/api"
import {MoneyBlock} from "../../../common/MoneyBlock/MoneyBlock"
import React, {useEffect, useMemo, useState} from "react"
import clsx from "clsx"
import Box from "@mui/material/Box"
import {CurrencyData} from "../../../api/types"
import Tippy_up_border from "src/assets/images/icons/Tippy_up_border.svg"
import {useAppSelector} from "../../../hooks"
import check_account_dropdown from "src/assets/images/icons/check_green.svg"
import {interfaceSelector} from "../../../redux/slices/interfaceSlice"
import {BrightTabs} from "../../../common/BrightTabs/BrightTabs"
import {CurrencySelector} from "../../../common/CurrencySelectors"
import {useSwitchAccount} from "../../../common/hooks/useSwitchAccount"

type Props = {
  balance: {
    currency: string
    amount: string
    currency_symbol: string
  }
}

type TabPanelProps = {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = (props: TabPanelProps) => {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`listbalancesdropdown-tabpanel-${index}`}
      aria-labelledby={`listbalancesdropdown-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

export const ListBalancesDropdown = ({
                                       balance,
                                     }: Props) => {
  const token = useAppSelector(selectToken)
  const {isListBalancesDropdownShown} = useAppSelector(interfaceSelector)
  const {isMobile} = useAppSelector(interfaceSelector)

  const {
    data: listBalancesData,
    isLoading,
    isFetching,
    isError,
    error
  } = useGetListBalancesQuery({token: token ? token : ''})

  const [activeTab, setActiveTab] = useState(0)

  // Search flag_main in the listBalancesData object, to set active tab
  const flagMainResult = useMemo(() => {
    if (!listBalancesData || !listBalancesData.data) {
      return null // return null if no data is available
    }
    if (listBalancesData.data.fiat && listBalancesData.data.fiat.some((item) => item.flag_main === "YES")) {
      return "fiat"
    }
    if (listBalancesData.data.crypto && listBalancesData.data.crypto.some((item) => item.flag_main === "YES")) {
      return "crypto"
    }
    return null // return null if flag_main is not found
  }, [listBalancesData])

  useEffect(() => {
    console.log({
      token,
      listBalancesData,
      isLoading,
      isFetching,
      isError,
      error,
    })
  }, [token, listBalancesData, isLoading, isFetching, isError, error])

  useEffect(() => {
    if (!listBalancesData || !listBalancesData.data) {
      setActiveTab(0) // if no data, first tab
      return
    }
    if (flagMainResult === "crypto") {
      setActiveTab(0)
    } else if (flagMainResult === "fiat") {
      setActiveTab(1)
    } else {
      setActiveTab(0) // if no flag found, first tab
    }
  }, [flagMainResult, listBalancesData])

  const {onSwitchAccountClick} = useSwitchAccount()

  const CurrencyContent = ({items}: { items: CurrencyData[] }) => {

    useEffect(() => {
      console.log('listBalancesData CurrencyContent items', items)

    }, [items])

    const onSwitchAccount = async (currencyId: number) => {
      void onSwitchAccountClick(currencyId)
    }

    return (
      <div className={styles.currencyContent}>
        {items.map((item: CurrencyData) => (
          <div key={item.currency_code} className={styles.currencyElement}
               onClick={() => onSwitchAccount(item.currency_id)}>
            <div className={styles.currencyCode}>
              <CurrencySelector currencyCode={item.currency_code}/>
              {item.currency_code}
            </div>
            <div className={styles.amount}>
              {item.flag_main === 'YES' &&
                  <img src={check_account_dropdown} alt="check_account_dropdown" className={styles.checkedAccountImg}/>}
              {Number(item.amount)}
            </div>
          </div>
        ))}
      </div>
    )
  }

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const tabs = [
    {label: "Crypto"},
    {label: "Fiat"},
  ]

  return (
    <>
      <MoneyBlock
        type={"Real"}
        currency={balance.currency}
        amount={balance.amount}
      >
        <div className={styles.listBalancesDropdown}>
          <div
            className={clsx(
              "dropdown-menu",
              isListBalancesDropdownShown && "show",
              styles.menu
            )}
          >
            {isListBalancesDropdownShown && !isMobile && <img src={Tippy_up_border} alt="Tippy_up" className={styles.tippy}/>}

            <BrightTabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={handleChange}
              className={styles.tabs}
            />
            <div className={styles.scrollableContent}>
              <Box>
                <CustomTabPanel value={activeTab} index={0}>
                  {listBalancesData?.data?.crypto && <CurrencyContent items={listBalancesData?.data?.crypto}/>}
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={1}>
                  {listBalancesData?.data?.fiat && <CurrencyContent items={listBalancesData?.data?.fiat}/>}
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </div>
      </MoneyBlock>
    </>
  )
}