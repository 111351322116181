import React from "react";
import { UserIconProps } from "../../../../common/types";

export const GamingPad01Icon = ({
                               stroke = "#F6F6F6",
                               strokeOpacity = "0.6",
                               className,
                             }: UserIconProps) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g id="gaming-pad-01" clip-path="url(#clip0_4207_115135)">
      <path id="Icon"
            d="M4.87493 7.33398H7.54159M6.20826 6.00065V8.66732M10.8749 8.00065H10.8816M12.8749 6.66732H12.8816M7.8409 3.33398H9.90896C11.6589 3.33398 12.5338 3.33398 13.2206 3.66561C13.8253 3.95758 14.3277 4.42538 14.662 5.00771C15.0417 5.66912 15.1041 6.54186 15.2287 8.28733L15.3928 10.5837C15.4732 11.7096 14.5814 12.6673 13.4526 12.6673C12.8754 12.6673 12.3279 12.4109 11.9584 11.9674L11.7083 11.6673C11.4795 11.3928 11.3651 11.2555 11.2349 11.1446C10.9618 10.9121 10.6313 10.7573 10.2778 10.6964C10.1092 10.6673 9.93057 10.6673 9.57322 10.6673H8.17664C7.81928 10.6673 7.64061 10.6673 7.47202 10.6964C7.11859 10.7573 6.78805 10.9121 6.515 11.1446C6.38475 11.2555 6.27037 11.3928 6.04159 11.6673L5.79149 11.9674C5.42193 12.4109 4.87449 12.6673 4.29723 12.6673C3.16842 12.6673 2.27667 11.7096 2.35709 10.5837L2.52112 8.28733C2.64579 6.54185 2.70813 5.66912 3.08784 5.00771C3.42216 4.42538 3.92456 3.95758 4.52923 3.66561C5.21601 3.33398 6.09097 3.33398 7.8409 3.33398Z"
            stroke={stroke} strokeOpacity={strokeOpacity} stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_4207_115135">
        <rect width="16" height="16" fill="white" transform="translate(0.875)"/>
      </clipPath>
    </defs>
  </svg>
);
