import styles from "./BrightInput.module.scss"
import clsx from "clsx"
import React, {forwardRef, useRef, useState} from "react"
import eye from 'src/assets/images/icons/eye.svg'
import eye_off from 'src/assets/images/icons/eye_off.svg'
import eye_passive from 'src/assets/images/icons/eye_passive.svg'
import info_circle from 'src/assets/images/icons/info_circle.svg'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import {useTranslation} from "react-i18next";

type PropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  onKeyUp?: () => void
  variation?: 'search' | 'auth' | 'profile' | 'searchAddWallet'
  icon?: string
  activeIcon?: string
  iconPosition?: 'left' | 'right';
  label?: string
  error?: string | string[] | null
  isNoEyePositionShift?: boolean
  autoComplete?: 'on' | 'off'
}

export const BrightInput = forwardRef<HTMLDivElement, PropsType>( ({
                                                     className,
                                                     onKeyUp,
                                                     variation = 'search',
                                                     icon,
                                                     activeIcon = icon,
                                                     iconPosition = 'left',
                                                     label,
                                                     error,
                                                     isNoEyePositionShift,
                                                     autoComplete,
                                                     ...rest
                                                   }, ref) => {

  const inputRef = useRef<HTMLInputElement>(null);
  const [isPasswordVisible, setPasswordVisibility] = useState(false)
  const [hasText, setHasText] = useState(false); // Tracks if input has text
  const {t} = useTranslation()

  const handleInput = () => { /* for autofill icon */
    if (inputRef.current) {
      setHasText(!!inputRef.current.value.trim()); // Check if input has text
    }
  };

  const togglePasswordVisibility = () => {
    if (!hasText) return;
    setPasswordVisibility((prev) => !prev); // Change the state to the opposite
  };

    return (
      <div>
        {label && <label className={stylesCommon.inputSelectLabel} htmlFor={rest.id}>{t(label)}</label>}
        <div ref={ref} className={clsx(styles.search,
          className,
          iconPosition === 'right' && styles.iconRight
        ) } style={{
          "--icon": `url(${hasText  ? activeIcon : icon})`, // Pass the icon through a CSS variable
        } as React.CSSProperties}>
          <input
            className={clsx(styles.input,
              icon && styles.inputWithIcon,
              variation === 'auth' ? styles.inputAuth : styles.inputSearch,
              {
                [styles.inputAuth]: variation === 'auth',
                [styles.inputSearch]: variation === 'search',
                [styles.inputProfile]: variation === 'profile',
                [styles.inputSearchAddWallet]: variation === 'searchAddWallet',

              },
              iconPosition === 'right' && styles.inputWithIconRight
            )}
            onInput={handleInput}
            onKeyUp={onKeyUp}
            ref={inputRef}
            {...rest}
            type={
              rest.type === 'password'
                ? (isPasswordVisible ? 'text' : 'password')
                : 'text'
            }
            autoComplete={autoComplete === 'off' ? 'new-password' : ''}
          />
          {rest.type === 'password' && (
            <img
              src={hasText ? (isPasswordVisible ? eye_off : eye) : eye_passive}
              alt=""
              className={clsx(styles.eye, !hasText && styles.eyePassive, !isNoEyePositionShift && styles.eyePositionShift)}
              onClick={togglePasswordVisibility} // Just toggle visibility on click
            />
          )}
          {error && (
            <div className={styles.errorWrapper}>
              <img src={info_circle} alt="info_circle"/>
              <div className={styles.error}>
                {Array.isArray(error)
                  ? error.map((errMsg, index) => <div key={index}>{errMsg}</div>)
                  : <div>{error}</div>
                }
              </div>
            </div>

          )}
        </div>
      </div>

    );
})

