import styles from './TitleAndFilterButton.module.scss'
import React from "react"
import {ButtonStyled} from "../../../../../common/ButtonStyled/ButtonStyled"

type Props = {
  title: string
  filterShown: boolean
  onFilterShown: React.Dispatch<React.SetStateAction<boolean>>
}

export const TitleAndFilterButton = ({title, filterShown, onFilterShown}: Props) => {
  return (
    <div className={styles.titleAndFilterButton} onClick={() => onFilterShown(!filterShown)}>
      <h1 className={styles.title}>{title}</h1>
      <ButtonStyled variant={'glass'}>{!filterShown ? 'Show' : 'Hide'} Filters</ButtonStyled>
    </div>
  )
}