//ModalPlayerDeposit.js
import React from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from "../../../hooks"
import AddWallet from "../../pages/AddWallet/AddWallet"
import Modal from "../ui/modal/Modal"
import {selectPreviousView} from '../../../redux/selectors/playerDeposit/viewSelectors'
import {setPreviousView} from '../../../redux/actions/playerDeposit/viewActions'

interface ModalAddWalletProps {
    isShowModal: boolean;
    onCloseModal: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
}


const ModalAddWallet: React.FC<ModalAddWalletProps> = ({isShowModal, onCloseModal})  => {

    const dispatch = useAppDispatch()
    const previousView = useSelector(selectPreviousView)

    const handleModalBackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        dispatch(setPreviousView())
    }
    
    return (

        <>
            <Modal
                show={isShowModal}
                onClose={onCloseModal}
                title="Add Wallet"
                body={<AddWallet />}
                variant="addWallet"
            />
        </>
    );
};

export default ModalAddWallet;
