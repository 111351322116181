import React, { ChangeEvent } from 'react';
import styles from './TextInput.module.scss';
import clsx from 'clsx'
import './TextInputBootstrap.scss'
import { useTranslation } from "react-i18next"; /*non module class to style bootstrap error position*/


interface TextInputProps {
  id: string;
  name: string;
  value: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  label?: string;
  extraName?: string,
  placeholder: string;
  error?: string | null;
  hint?: string;
  currency_code?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  name,
  value,
  onChange,
  required = false,
  placeholder,
  error,
  label,
  extraName,
  hint,
  currency_code
}) => {

  const { t } = useTranslation()

  return (
    <div className={styles.TextInput}>
      {label && <label htmlFor={id} className="form-label">
        <div className={styles.title}>
          {`${t(label)} ${extraName !== undefined ? `(${t(extraName)})` : ''}`}
        </div>
      </label>}
      <div className={styles.inputWrap}>
        <input
          type="text"
          id={id}
          name={name}
          className={clsx(`form-control ${error ? 'is-invalid' : ''}`, styles.input)}
          value={value}
          onChange={onChange}
          required={required}
          placeholder={`${placeholder} here ...`}
          data-error-required={`${placeholder} is required`}
        />
        {/* {extraName && <span className={styles.extraname}>{extraName}</span>} */}
      </div>
      {error && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
      {hint && (
        <div className={styles.hint}>
          {hint}
        </div>
      )}
      <div className={styles.currency}>
        {currency_code}
      </div>
    </div>)
};

export default TextInput;

// todo: need to add currency name to the end of the placeholder
