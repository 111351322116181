// loginSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';
import { ActionSetAuthenticated } from './isAuthenticatedSlice';
import {DISABLE_MISC_LOGS} from "../../../common/constants"


type LoginSliceResponse = {
  success: boolean;
  data: boolean; // Уточните тип данных
  messages: string | null;
};

type LoginSliceError = {
  error: any;
  message: string;
};

interface LoginSliceState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: boolean; // Уточните тип массива, если известно
  messages: string | null;
}


// Типизация параметров для fetchLogin
type FetchLoginParam = {
  email: string;
  password: string;
  ip: string;
  device: string;
  browser: string;
  os: string;
};

const savedState = localStorage.getItem('loginState');

const clearState: LoginSliceState = {
  success: false,
  loading: false,
  error: null,
  data: false,
  messages: null,
};

const initialState: LoginSliceState = savedState
  ? JSON.parse(savedState)
  : clearState;

export const fetchLogin = createAsyncThunk<
  LoginSliceResponse,
  FetchLoginParam,
  { rejectValue: LoginSliceError }
>(
  'LoginSlice/fetchLogin',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { email, password, ip, device, browser, os } = params;
      console.log('Attempting to fetchLogin:', { email, password, ip, device, browser, os });

      const response = await axios.post<LoginSliceResponse>(
        `${config.serverUrl}/site/login`,
        { email, password, ip, device, browser, os },
        { headers: { 'Content-Type': 'application/json' } } // Добавляем заголовки
      );

      console.log('fetchLogin response.data:', response.data);

      // При успешном логине автоматически аутенифицируем. 
      if (response.data?.success) {
        dispatch(ActionSetAuthenticated());
      }
      return response.data;
    } catch (error) {
      console.error('Error in fetchLogin:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data.errors || ["Login failed"],
          message: error.response.data.messages || ['Failed to get Login.'],
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);


const LoginSlice = createSlice({
  name: 'Profile/LoginSlice',
  initialState,
  reducers: {
    resetStateLogin: (state) => {
      !DISABLE_MISC_LOGS && console.log('App auth: resetStateLogin start');

      const newState = {
        ...state,
        success: clearState.success,
        loading: clearState.loading,
        error: clearState.error,
        data: clearState.data,
        messages: clearState.messages,
      }
      localStorage.removeItem('loginState');

      !DISABLE_MISC_LOGS && console.log('App auth: resetStateLogin end', JSON.parse(JSON.stringify(newState)));
      return newState;
    },
    clearErrorsLogin: (state) => {
      return {
        ...state,
        loading: clearState.loading,
        error: clearState.error,
        success: clearState.success,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchLogin.fulfilled, (state, action: PayloadAction<LoginSliceResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;

        // Сохраняем состояние в localStorage
        localStorage.setItem('loginState', JSON.stringify({
          success: state.success,
          data: state.data,
          messages: state.messages,
        }));
      })
      .addCase(fetchLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateLogin, clearErrorsLogin } = LoginSlice.actions;
export const LoginSliceReducer = LoginSlice.reducer;



// ---------
// Selectors
// ---------
export const selectLoginLoading = (state: RootState) => state.LoginSliceReducer.loading;
export const selectLoginSuccess = (state: RootState) => state.LoginSliceReducer.success;
export const selectLoginError = (state: RootState) => state.LoginSliceReducer.error;
export const selectLoginMessages = (state: RootState) => state.LoginSliceReducer.messages;
export const selectLoginData = (state: RootState) => state.LoginSliceReducer.data;
export const selectToken = (state: RootState) => state.LoginSliceReducer.data.token;


export const selectLoginErrorString = (state: RootState): string | null => {
  const error = state.LoginSliceReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectLoginErrorArray = (state: RootState): string[] | null => {
  const error = state.LoginSliceReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
