import React from "react";
import { UserIconProps } from "../../../../common/types";

export const Wallet01Icon = ({
                             stroke = "#F6F6F6",
                             strokeOpacity = "0.6",
                             className,
                           }: UserIconProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_4207_76366)">
      <path
        d="M14.7669 5.66602H1.43359M1.43359 8.33268H3.79805C4.15817 8.33268 4.33824 8.33268 4.51235 8.36372C4.6669 8.39127 4.81766 8.43691 4.96153 8.49972C5.12362 8.57048 5.27344 8.67036 5.57309 8.87012L5.96077 9.12858C6.26041 9.32834 6.41023 9.42822 6.57232 9.49898C6.71619 9.56178 6.86696 9.60743 7.0215 9.63498C7.19562 9.66602 7.37568 9.66602 7.73581 9.66602H8.46471C8.82484 9.66602 9.0049 9.66602 9.17902 9.63498C9.33356 9.60743 9.48433 9.56178 9.6282 9.49898C9.79029 9.42822 9.94011 9.32834 10.2398 9.12858L10.6274 8.87012C10.9271 8.67036 11.0769 8.57048 11.239 8.49972C11.3829 8.43691 11.5336 8.39127 11.6882 8.36372C11.8623 8.33268 12.0423 8.33268 12.4025 8.33268H14.7669M1.43359 4.79935L1.43359 11.1993C1.43359 11.9461 1.43359 12.3195 1.57892 12.6047C1.70675 12.8556 1.91072 13.0595 2.16161 13.1874C2.44682 13.3327 2.82019 13.3327 3.56693 13.3327L12.6336 13.3327C13.3803 13.3327 13.7537 13.3327 14.0389 13.1874C14.2898 13.0595 14.4938 12.8556 14.6216 12.6047C14.7669 12.3195 14.7669 11.9461 14.7669 11.1993V4.79935C14.7669 4.05261 14.7669 3.67925 14.6216 3.39403C14.4938 3.14315 14.2898 2.93917 14.0389 2.81134C13.7537 2.66602 13.3803 2.66602 12.6336 2.66602L3.56693 2.66602C2.82019 2.66602 2.44682 2.66602 2.16161 2.81134C1.91072 2.93917 1.70675 3.14315 1.57892 3.39403C1.43359 3.67924 1.43359 4.05261 1.43359 4.79935Z"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4207_76366">
        <rect
          width="16"
          height="16"
          fill={stroke}
          strokeOpacity={strokeOpacity}
          transform="translate(0.100098)"
        />
      </clipPath>
    </defs>
  </svg>
);
