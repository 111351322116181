// getBonusInfoSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type GetBonusInfoResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type GetBonusInfoError = {
  error: any;
  message: string;
};

interface GetBonusInfoState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: any[]; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: GetBonusInfoState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchGetBonusInfo
interface GetBonusInfoParams {
  token: string;
  params?: {};
}

export const fetchGetBonusInfo = createAsyncThunk<
  GetBonusInfoResponse,
  GetBonusInfoParams,
  { rejectValue: GetBonusInfoError }
>(
  'GetBonusInfo/fetchGetBonusInfo',
  async ({ token, params = {} }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchGetBonusInfo:', `${config.serverUrl}/payment/get-payment-methods`, { token, params });

      const response = await axios.get<GetBonusInfoResponse>(
        `${config.serverUrl}/bonus/get-info`,
        {
          params: {
            ...params, // Ваши дополнительные параметры
            token,     // Токен авторизации как часть query-параметров
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Attempting to fetchGetBonusInfo response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error get bonus info:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to get bonus info .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);



const GetBonusInfoSlice = createSlice({
  name: 'GetBonusInfo',
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetBonusInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchGetBonusInfo.fulfilled, (state, action: PayloadAction<GetBonusInfoResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchGetBonusInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetState } = GetBonusInfoSlice.actions;

export const GetBonusInfoReducer = GetBonusInfoSlice.reducer;



// ---------
// Selectors
// ---------
export const selectGetBonusInfoLoading = (state: RootState) => state.GetBonusInfoReducer.loading;
export const selectGetBonusInfoSuccess = (state: RootState) => state.GetBonusInfoReducer.success;
export const selectGetBonusInfoError = (state: RootState) => state.GetBonusInfoReducer.error;
export const selectGetBonusInfoMessages = (state: RootState) => state.GetBonusInfoReducer.messages;
export const selectGetBonusInfoData = (state: RootState) => state.GetBonusInfoReducer.data;

export const selectGetBonusInfoErrorString = (state: RootState): string | null => {
  const error = state.GetBonusInfoReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectGetBonusInfoErrorArray = (state: RootState): string[] | null => {
  const error = state.GetBonusInfoReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
