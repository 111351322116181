// listFavoriteGamesActions.js
import axios from 'axios';
import config from '../../../config';
import {DISABLE_MISC_LOGS} from "../../../common/constants";

export const FETCH_LISTFAVORITEGAMES_REQUEST = 'FETCH_LISTFAVORITEGAMES_REQUEST';
export const FETCH_LISTFAVORITEGAMES_SUCCESS = 'FETCH_LISTFAVORITEGAMES_SUCCESS';
export const FETCH_LISTFAVORITEGAMES_FAILURE = 'FETCH_LISTFAVORITEGAMES_FAILURE';

// Экшены для получения данных
export const actionListFavoriteGamesRequest = () => ({
  type: FETCH_LISTFAVORITEGAMES_REQUEST,
});

export const actionListFavoriteGamesSuccess = (result, resultMessages) => ({
  type: FETCH_LISTFAVORITEGAMES_SUCCESS,
  result,
  resultMessages
});

export const actionListFavoriteGamesFailure = (error, errorMessage) => ({
  type: FETCH_LISTFAVORITEGAMES_FAILURE,
  error: error,
  errorMessage: errorMessage
});

//For Express
export const fetchToListFavoriteGames = (token) => async (dispatch) => {

  // Инициализируем запрос
  dispatch(actionListFavoriteGamesRequest());

  try {
    !DISABLE_MISC_LOGS && console.log('✅Attempting to fetchListFavoriteGames:' + `${config.serverUrl}/player/list-favorite-games`, { token });

    const response = await axios.post(`${config.serverUrl}/player/list-favorite-games`,
      { token },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      });

    const data = response.data;
    !DISABLE_MISC_LOGS && console.log('✅Full response from API:', data, 'data.data.length ', Object.keys(data.data).length > 0); // Выводим весь ответ от API
    
    if (response.status === 200 && data.success && Object.keys(data.data).length > 0) {
      !DISABLE_MISC_LOGS && console.log('✅fetchListFavoriteGames data.data: ', data.data);
      !DISABLE_MISC_LOGS && console.log('✅fetchListFavoriteGames data.messages: ',  data.messages);
      dispatch(actionListFavoriteGamesSuccess(data.data, data.messages));
    } else {
      console.error('✅fetchListFavoriteGames error:', data.errors);
      dispatch(actionListFavoriteGamesFailure(data.errors, data.message || 'Error fetch to List Favorite Games .'));
    }
  } catch (error) {
    if (error.response) {
      console.error(`✅Error: ${error.response.status}`, error.response.data);

      if (error.response.status === 404) {
        dispatch(actionListFavoriteGamesFailure(null, 'API endpoint not found.'));
      } else {
        dispatch(actionListFavoriteGamesFailure(error.response.data.error, error.response.data.message || 'Server error.'));
      }
    } else {
      console.error('✅fetchListFavoriteGames network error:', error.message);
      dispatch(actionListFavoriteGamesFailure(null, 'Network error: Unable to connect to the server.'));
    }
  }
};


// export const connectWebSocket = (token, language = null) => {
//   return (dispatch) => {
//     const ws = new WebSocket(config.serverWebsocketUrl);

//     ws.onopen = () => {
//       console.log('Connected to WebSocket server getListFavoriteGames');
//       ws.send(JSON.stringify({ type: 'getListFavoriteGames', token }));
//     };

//     ws.onmessage = (event) => {
//       console.log('WebSocket message received getListFavoriteGames:', event.data);
//       try {
//         const data = JSON.parse(event.data);
//         console.log('Parsed event.data:', data.listFavoriteGames.data.game);

//         if (data.type === 'getListFavoriteGames') {
//           const favoriteGamesData = data.listFavoriteGames.data.game;
          
//           // Преобразуем данные в массив
//           // Добавил тля итерации
//           const favoriteGamesArray = Array.isArray(favoriteGamesData) ? favoriteGamesData : [favoriteGamesData];

//           console.log('ListFavoriteGames received (array):', favoriteGamesArray);
//           dispatch(fetchListFavoriteGamesSuccess(favoriteGamesArray));
//         } else if (data.type === 'error') {
//           dispatch(fetchListFavoriteGamesFailure(data.message));
//         }
//       } catch (error) {
//         console.error('Error parsing WebSocket message:', error.message);
//       }
//     };

//     ws.onclose = () => {
//       console.log('Disconnected from WebSocket server');
//     };

//     return () => {
//       ws.close();
//     };
//   };
// };
