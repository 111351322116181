import React from 'react'
import clsx from "clsx"
import styles from './ChildrenMenu.module.scss'
import stilesSidebar from "../Sidebar/Sidebar.module.scss"
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {
  childrenMenuSelector,
  interfaceSelector,
  setIsChildrenMenuOpened,
  setSelectedMenu
} from "../../../redux/slices/interfaceSlice"
import {NavWithChildrenMenuClose} from "../NavWithChildrenMenuClose/NavWithChildrenMenuClose"
import trophy_2_1 from "../../../assets/images/trophy_2_1.png"
import Tippy from '../../../assets/images/Tippy.svg'
import {ColorBlock} from "../Sidebar/ColorBlock/ColorBlock"
import {useTranslation} from "react-i18next"
import {QuestionBlock} from "../Sidebar/QuestionBlock/QuestionBlock"
import chevron_left from 'src/assets/images/icons/chevron_left.svg'
import config from "../../../config"

export const ChildrenMenu = () => {
  const {selectedMenu, menuPosition, isChildrenMenuOpened} = useAppSelector(childrenMenuSelector)
  const dispatch = useAppDispatch()
  const {t} = useTranslation()

  const {isMobile} = useAppSelector(interfaceSelector)

  const handleSidebarMouseLeave = () => {
    !isMobile && dispatch(setIsChildrenMenuOpened(false))
  }

  if (!selectedMenu) return <></>

  const onBackClick = () => {
    dispatch(setSelectedMenu(null))
    dispatch(setIsChildrenMenuOpened(false))
  }

  return (
    <div
      className={clsx(!isMobile ? styles.childrenMenu : stilesSidebar.sidebar, {
        [styles.open]: (isChildrenMenuOpened && !isMobile),
        [styles.closed]: (!isChildrenMenuOpened && !isMobile),
      })}
      style={!isMobile ? { top: `${menuPosition}px` } : undefined}  /*disable menu shift if mobile mode*/
      onMouseLeave={handleSidebarMouseLeave}
    >
      {isMobile && <>
          <ColorBlock
              variant={'Wheel of Fortune'}
              className={stilesSidebar.wheelOfFortune}
              title={t('Wheel of Fortune')}
              subtitle={t('Spin and win!')}
          />
          <ColorBlock
              variant={'Free Bonuses'}
              className={stilesSidebar.freeBonuses}
              title={t('Free Bonuses')}
              subtitle={t('Find your treasure')}

          />
          <div className={stilesSidebar.backContainer} onClick={onBackClick}>
              <img src={chevron_left} alt="chevron_left"/>
              <div>Back</div>
          </div>

      </>}

      {selectedMenu && (
        <div className={clsx(stylesCommon.itemsContainer, styles.childrenItemsContainer)}>
          {!isMobile && <img src={Tippy} alt={'Tippy'} className={styles.tippy}/> }
          {selectedMenu.map((item) => (
            <div key={item.id} className={stylesCommon.item}>
              <img src={item.logo_path ? `${config.fileUrls.rootFolder}${item.logo_path}` : trophy_2_1} alt="trophy_2_1" className={stylesCommon.trophy}/>
              <NavWithChildrenMenuClose item={item}/>
            </div>
          ))}
        </div>
      )}
      {isMobile && <>
          <QuestionBlock/>
          <ColorBlock
              variant={'Vip Club'}
              className={stilesSidebar.vipClub}
              title={t('Vip Club')}
              subtitle={t('Cash back and rewards!')}
          />
      </>}
    </div>
  )
}

// todo: fix right border of Tippy

