import React from "react";
import { UserIconProps } from "../../../../common/types";

export const Gift01Icon = ({
                                      stroke = "white",
                                      strokeOpacity = "0.6",
                                      className,
                                    }: UserIconProps) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g id="gift-01" clip-path="url(#clip0_4207_71839)">
      <path id="Icon"
            d="M8.12516 4.00065V14.6673M8.12516 4.00065H5.76802C5.42071 4.00065 5.08763 3.86018 4.84205 3.61013C4.59646 3.36008 4.4585 3.02094 4.4585 2.66732C4.4585 2.3137 4.59646 1.97456 4.84205 1.72451C5.08763 1.47446 5.42071 1.33398 5.76802 1.33398C7.60135 1.33398 8.12516 4.00065 8.12516 4.00065ZM8.12516 4.00065H10.4823C10.8296 4.00065 11.1627 3.86018 11.4083 3.61013C11.6539 3.36008 11.7918 3.02094 11.7918 2.66732C11.7918 2.3137 11.6539 1.97456 11.4083 1.72451C11.1627 1.47446 10.8296 1.33398 10.4823 1.33398C8.64897 1.33398 8.12516 4.00065 8.12516 4.00065ZM13.4585 7.33398V12.534C13.4585 13.2807 13.4585 13.6541 13.3132 13.9393C13.1853 14.1902 12.9814 14.3942 12.7305 14.522C12.4453 14.6673 12.0719 14.6673 11.3252 14.6673L4.92516 14.6673C4.17843 14.6673 3.80506 14.6673 3.51984 14.522C3.26896 14.3942 3.06499 14.1902 2.93715 13.9393C2.79183 13.6541 2.79183 13.2807 2.79183 12.534V7.33398M1.4585 5.06732L1.4585 6.26732C1.4585 6.64069 1.4585 6.82737 1.53116 6.96998C1.59507 7.09542 1.69706 7.19741 1.8225 7.26132C1.96511 7.33398 2.15179 7.33398 2.52516 7.33398L13.7252 7.33398C14.0985 7.33398 14.2852 7.33398 14.4278 7.26132C14.5533 7.19741 14.6553 7.09542 14.7192 6.96998C14.7918 6.82737 14.7918 6.64069 14.7918 6.26732V5.06732C14.7918 4.69395 14.7918 4.50727 14.7192 4.36466C14.6553 4.23922 14.5533 4.13723 14.4278 4.07331C14.2852 4.00065 14.0985 4.00065 13.7252 4.00065L2.52516 4.00065C2.15179 4.00065 1.96511 4.00065 1.8225 4.07331C1.69706 4.13723 1.59507 4.23922 1.53116 4.36466C1.4585 4.50727 1.4585 4.69395 1.4585 5.06732Z"
            stroke={stroke} stroke-opacity={strokeOpacity} stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_4207_71839">
        <rect width="16" height="16" fill="white" transform="translate(0.125)"/>
      </clipPath>
    </defs>
  </svg>

);
