import {useNavigate} from 'react-router-dom'
import React from 'react'
import styles from "./GameGroups.module.scss"
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import BrightCarousel from "../../../common/BrightCarousel/BrightCarousel"
import game_icon from 'src/assets/images/game_icon.png'
import clsx from 'clsx'
import config from "../../../config"
import {useAppSelector} from "../../../hooks"
import {interfaceSelector} from "../../../redux/slices/interfaceSlice"
import {useGetGameGroupsQuery} from "../../../api/api"
import {GetGroupsData} from "../../../api/types"

// in Figma: Categories
const GameGroups: React.FC = () => {
  const navigate = useNavigate()
  const {selectedLanguage} = useAppSelector(interfaceSelector)
  const {data, isSuccess, isLoading} = useGetGameGroupsQuery({language: selectedLanguage.id})
  const {isMobile} = useAppSelector(interfaceSelector)

  const handleGroupClick = (group: GetGroupsData) => {
    if (group.id && group.slug) {
      console.log('Group ID:', group.id)
      console.log('Group Slug:', group.slug)
      navigate(`/game-group/${group.slug}`)
    } else {
      console.error('Group ID or Slug is missing', group)
    }
  }

  if (!data || !isSuccess) return <></>
  if (isLoading) return <div>Loading GameGroups...</div>

  return (
    <div className={clsx(styles.gameGroups, !isMobile ? stylesCommon.group : stylesCommon.groupMobileMobileMarginBottom16)}>
      <div className={styles.groupsContainer}>
        <BrightCarousel
          elements={
            data.data?.map((group, index) => (
              <div onClick={() => handleGroupClick(group)} className={clsx(stylesCommon.link, styles.element)}
                   key={index}>
                <img src={group.logo_path ? `${config.fileUrls.gameGroup}${group.logo_path}` : game_icon}
                     alt="game_icon"
                     className={styles.gameIcon}/>
                <div className={styles.groupName}>{group.name}</div>
              </div>
            ))}
          className={styles.carousel}
          isControlHidden={isMobile}
          {...(isMobile && { smooth: { active: true, direction: 'right' } })}
        />
      </div>
    </div>
  )
}

export default GameGroups


