import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import styles from './Balance.module.scss'
import React, {useState, useEffect} from "react"
import SelectInput, {SelectOption} from "../../../auth/formComponents/SelectInput/SelectInput"
import {SelectChangeEvent} from "@mui/material"
import {BalanceElement} from "./BalanceElement/BalanceElement"
import {ButtonStyled} from "../../../../../common/ButtonStyled/ButtonStyled"
import {useTranslation} from "react-i18next"
import clsx from 'clsx'
import { openModal, closeModal, setCurrencyId, selectIsShowModal } from "src/redux/slices/modals/modalPlayerDepositSlice"

import {
  selectIsAuthenticated
} from "../../../../../redux/slices/auth/isAuthenticatedSlice"
import {
  selectToken
} from "../../../../../redux/slices/auth/loginSlice"

import {
  fetchListBalances,
  selectListBalancesSliceData,
  selectListBalancesSliceMessages,
  selectListBalancesSliceError,
  selectListBalancesSliceSuccess,
  selectListBalancesSliceLoading,
  selectListBalancesSliceErrorArray
} from "src/redux/slices/Account/listBalancesSlice"

import {
  fetchBalances,
  selectBalancesSliceData,
  selectBalancesSliceMessages,
  selectBalancesSliceError,
  selectBalancesSliceSuccess,
  selectBalancesSliceLoading,
  selectBalancesSliceErrorArray,
  BalanceItem,
  Balances,
  BalancesList
} from "src/redux/slices/Account/balancesSlice"

import { useAppDispatch, useAppSelector } from "src/hooks"
import { useSelector } from 'react-redux'
import {DISABLE_MISC_LOGS} from "../../../../../common/constants"


export const Balance = () => {

  const dispatch = useAppDispatch()
  const isShowModal = useSelector(selectIsShowModal)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

  const listBalancesSliceData = useSelector(selectListBalancesSliceData)
  const listBalancesSliceError = useSelector(selectListBalancesSliceError)
  const listBalancesSliceSuccess = useSelector(selectListBalancesSliceSuccess)
  const listBalancesSliceLoading = useSelector(selectListBalancesSliceLoading)
  const listBalancesSliceErrorArray = useSelector(selectListBalancesSliceErrorArray)

  const balancesSliceData = useSelector(selectBalancesSliceData)
  const balancesSliceError = useSelector(selectBalancesSliceError)
  const balancesSliceSuccess = useSelector(selectBalancesSliceSuccess)
  const balancesSliceLoading = useSelector(selectBalancesSliceLoading)
  const balancesSliceErrorArray = useSelector(selectBalancesSliceErrorArray)

  const [balanceOptions, setBalanceOptions] = useState<SelectOption[] | null>(null);
  // const [realAmount, setRealAmount] = useState<string>('0');
  // const [bonusAmount, setBonusAmount] = useState<string>('0');
  const [selectedCurrency, setSelectedCurrency] = useState<SelectOption | null>(null);

  // const balanceOptions: SelectOption[] = [
  //   { id: '1', name: 'BTC' },
  //   { id: '2', name: 'EUR' },
  // ]

  // const realAmount = '0.00000000012345'
  // const bonusAmount = '0.00000000012345'


  const [value, setValue] = useState('1')
  const { t } = useTranslation()

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(fetchListBalances({ token }));
      dispatch(fetchBalances({ token }));
    }

    !DISABLE_MISC_LOGS && console.log('Balance fetchListBalances');
  }, [dispatch, token])

  useEffect(() => {
    if (listBalancesSliceSuccess && listBalancesSliceData.length > 0) {
      !DISABLE_MISC_LOGS && console.log('Balance listBalancesSliceData', listBalancesSliceData);
      const options: SelectOption[] = listBalancesSliceData.map((item: any) => {
        if(item.flag_main === "YES"){
          setValue(item.currency_id)
        }

        return {
          id: item.currency_id,
          name: item.currency_code,
          amount: item.amount,
          amount_bonus: item.amount_bonus,
        }
    });
      setBalanceOptions(options);
    } else {
      setBalanceOptions(null);
    }

    !DISABLE_MISC_LOGS && console.log('Balance fetchListBalances');
  }, [listBalancesSliceSuccess, listBalancesSliceData]);

  useEffect(() => {
    let currentCurrency = findCurrencyById(value);
    if (currentCurrency) {
      setSelectedCurrency(currentCurrency)
    } else {
      setSelectedCurrency(null)
    }
  }, [value, balanceOptions]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    setValue(e.target.value)
  }


  const findCurrencyById = (id: string): SelectOption | null | undefined => {
    return balanceOptions ? balanceOptions.find((option: SelectOption) => option.id === id) : null;
  };

  return (
    <div className={clsx(stylesCommon.accountElement, styles.balance)}>
      <div className={stylesCommon.header}>{t('Balance')}</div>
      <SelectInput
        id={'balance'}
        name={'balance'}
        value={value}
        placeholder={t('Select currency')}
        onChange={handleChange}
        options={balanceOptions}
        variant={'currency'}
      />
      <div>
        <div className={styles.balances}>
          {selectedCurrency ? (
            <>
              <BalanceElement variant={"Real"} amount={selectedCurrency.amount || "0"} currency={selectedCurrency.name} />
              <BalanceElement variant={"Bonus"} amount={selectedCurrency.amount_bonus || "0"} currency={selectedCurrency.name} />
            </>
          ) : (
            <p>{t('Available currency not found')}</p>
          )}


        </div>
        <ButtonStyled
          isWidth100={true}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            // console.log
            selectedCurrency && dispatch(setCurrencyId(+selectedCurrency.id));
            dispatch(openModal());
          }}
          className={styles.button}
          height={'48px'}
        >
          {t('Deposit')}
        </ButtonStyled>
      </div>
    </div>
  )
}