import React from "react"
import styles from './ButtonStyledProfile.module.scss'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import clsx from 'clsx'

type ButtonStyledProfileProps = {
  variant: 'selected' | 'passive'
  children: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
  className?: string
}

export const ButtonStyledProfile = ({onClick, children, variant, className}: ButtonStyledProfileProps) => {
  return (
    <button onClick={onClick} className={clsx(
      stylesCommon.button, className, styles.buttonStyledProfile, {
       [styles.selected]: variant === 'selected'
      })}>
      {children}
    </button>
  )
}