import React, { useEffect } from 'react';
import { useStore } from 'react-redux';
import {DISABLE_STATE_LOGGER_LOG} from "../../common/constants";

const StateLogger = () => {
    const store = useStore();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            const currentState = store.getState();
            
            // Группируем и форматируем вывод в консоли
            !DISABLE_STATE_LOGGER_LOG && console.group('%cCurrent Redux state', 'color: white; background-color: #007bff; padding: 2px 6px; border-radius: 4px;');
            !DISABLE_STATE_LOGGER_LOG && console.log('%cState:', 'color: #28a745; font-weight: bold;', currentState);
            console.groupEnd();
        });

        return () => {
            unsubscribe();
        };
    }, [store]);

    return null;
};

export default StateLogger;

