// modalPlayerDepositSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface ModalPlayerDepositState {
  isShowModal: boolean;
  currencyId?: number | null;
}

const initialState: ModalPlayerDepositState = {
  isShowModal: false,
  currencyId: 2,
};

const modalPlayerDepositSlice = createSlice({
  name: 'modalPlayerDepositSlice',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isShowModal = true;
    },
    closeModal: (state) => {
      state.isShowModal = false;
      state.currencyId = null;
    },
    setCurrencyId: (state, action: PayloadAction<number | null>) => {
      state.currencyId = action.payload;
    },
    setModalPlayerDepositState: (state, action: PayloadAction<{isShowModal: boolean; currencyId: number | null }>) => {
      state.isShowModal = action.payload.isShowModal;
      state.currencyId = action.payload.currencyId;
    },
  },
});

export const { openModal, closeModal, setCurrencyId, setModalPlayerDepositState } = modalPlayerDepositSlice.actions;
export const ModalPlayerDepositReducer = modalPlayerDepositSlice.reducer;
// export default modalPlayerDepositSlice.reducer;

export const selectIsShowModal = (state: RootState) => state.ModalPlayerDepositReducer.isShowModal;
export const selectCurrencyIdModal = (state: RootState) => state.ModalPlayerDepositReducer.currencyId;

