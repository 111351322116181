import React, {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useGetPageBySlugQuery} from '../../../api/api'
import {FetchBaseQueryError} from '@reduxjs/toolkit/query'
import {useAppSelector} from "../../../hooks"
import {interfaceSelector} from "../../../redux/slices/interfaceSlice"
import styles from './Page.module.scss'
import {ScrollButton} from "../../../common/ScrollButton/ScrollButton"
import {BrightBreadcrumbs} from "../../../common/BrightBreadcrumbs/BrightBreadcrumbs"
import config from "../../../config"
import {MobileStatus} from "../../../common/MobileStatus/MobileStatus"
import {useTranslation} from "react-i18next"

export const Page = () => {
  const {slug} = useParams()
  const {selectedLanguage} = useAppSelector(interfaceSelector)

  const {data: response, error, isLoading} = useGetPageBySlugQuery({slug: slug || '', language: selectedLanguage.id})
  console.log('Page data response', response)

  const pageData = response?.data?.length ? response.data[0] : null
  const errorText = response?.errors?.Page || 'Error loading page'

  const navigate = useNavigate()
  const {isMobile} = useAppSelector(interfaceSelector)

  const breadcrumbItems = [
    {label: 'Home page', path: '/', isLink: true},
    {label: 'Articles', path: '/pagelist', isLink: true},
    {label: slug || '', isLink: false}
  ]

  useEffect(() => {
    if (!error && response?.success && pageData) {
      document.title = pageData.meta_title || 'Loading...'
      const metaDescription = document.querySelector('meta[name="description"]')
      if (metaDescription) {
        metaDescription.setAttribute('content', pageData.meta_description || '')
      }
    } else {
      document.title = errorText
    }

    // Handle 404 error by navigating to the not found page
    if (error && 'status' in error) {
      const fetchError = error as FetchBaseQueryError
      if (fetchError.status === 404) {
        navigate('/404')
      }
    }
  }, [error, response, pageData, navigate])

  const {t} = useTranslation()

  if (isLoading) return <p>Loading...</p>

  // If there are errors other than 404
  if (error || !response?.success || !pageData) return <p>{errorText}</p>

  return (
    <>
      {isMobile && <MobileStatus variant={'game'} title={pageData.h1}/>}
      <div className={styles.page}>
        {!isMobile && <BrightBreadcrumbs items={breadcrumbItems}/>}
        <div className={styles.pageContainer}>
          {!isMobile && <div className={styles.nameContainer}>
            <ScrollButton onClick={() => {
              navigate(-1)
            }} direction={"left"}/>
            <h1 className={styles.articleTitle}>{pageData.h1}</h1>
          </div> }

          {pageData.subtitle && <h2>{pageData.subtitle}</h2>}
          <div
            dangerouslySetInnerHTML={{__html: pageData.body}
            }
            className={styles.body}
          />
          {
            pageData.image_path !== null &&
              <div className={styles.imageContainer}>
                  <img src={`${config.fileUrls.page}${pageData.image_path}`} alt={pageData.h1}/>
              </div>
          }
        </div>
      </div>
    </>
  )
}
