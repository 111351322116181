import styles from './MobileStatus.module.scss'
import {SiteLogo} from "../SiteLogo/SiteLogo"
import x_close from 'src/assets/images/icons/x_close.svg'
import chevron_left from 'src/assets/images/icons/chevron_left.svg'
import {useAppDispatch} from "../../hooks"
import {setAuthActiveTab} from "../../redux/slices/interfaceSlice"
import {useNavigate} from "react-router-dom"

type Props = {
  onClose?: () => void
  variant?: 'loginAuth' | 'PasswordRecovery'  | 'gameGroup' | 'game'
  title?: string
}

export const MobileStatus = ({onClose, variant = 'loginAuth', title}: Props) => {
  const navigate = useNavigate();

  const handleClose = () => {
    if (onClose) {
    } else if (variant === 'game') {
      navigate('/')
    } else {
      window.history.back()
    }
  }

  const onBackClick = () => {
    if (variant === 'loginAuth') {
      dispatch(setAuthActiveTab('login'))
    } else if (variant === 'gameGroup' || variant === 'PasswordRecovery' || variant === 'game') {
      window.history.back()
    }
  }

  const dispatch = useAppDispatch()

  return (
    <div className={styles.mobileStatus}>
      {variant === 'loginAuth' && <SiteLogo variant={'143x20'}/>}
      {(variant === 'PasswordRecovery' || variant === 'gameGroup' || variant === 'game') && <>
          <div className={styles.backContainer} onClick={onBackClick}>
              <img src={chevron_left} alt="chevron_left"/>
              <div className={styles.back}>Back</div>
          </div>
          <div className={styles.header}>
            {variant === 'PasswordRecovery' && 'Restore password'}
            {(variant === 'gameGroup' || 'game') && title}
          </div>
      </>}
      <img src={x_close} alt={'x_close'} onClick={handleClose}/>
    </div>
  )
}