import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import {useAppDispatch, useAppSelector} from "../../../../hooks"
import Inactivity from "./Inactivity"
import { INACTIVITY_POPUP_TIMEOUT_MS } from '../../../../common/constants'

import {
    fetchCheckPopup,
    selectCheckPopupData,
    selectCheckPopupMessages,
    selectCheckPopupError,
    selectCheckPopupSuccess,
    selectCheckPopupLoading,
    fetchCheckPopupParams

} from '../../../../redux/slices/popup/CheckPopupSlice'
import Modal from "../../../layouts/ui/modal/Modal"

import {
    interfaceSelector,
    setIsShowPopup
  } from "../../../../redux/slices/interfaceSlice"

const InactivityPopup = () => {
    
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const dispatch = useAppDispatch()
    
    const {isShowPopup} = useAppSelector(interfaceSelector)
    const [isInactive, setIsInactive] = useState(false);

    const checkPopupData = useSelector(selectCheckPopupData)
    const checkPopupError = useSelector(selectCheckPopupError)
    const checkPopupMessages = useSelector(selectCheckPopupMessages)
    const checkPopupSuccess = useSelector(selectCheckPopupSuccess)
    const checkPopupLoading = useSelector(selectCheckPopupLoading)

    useEffect(() => {
        const params: fetchCheckPopupParams = {
            type: 'inactivity'
        };
        dispatch(fetchCheckPopup(params))
    }, [dispatch, isInactive]);

    useEffect(() => {
        if (checkPopupError) {
            console.error(checkPopupError)
            return;
        }

        if (checkPopupSuccess && checkPopupData && checkPopupData.flag_active === 'YES') {
            const resetTimer = () => {
                if (timerRef.current) clearTimeout(timerRef.current);
                startTimer();
            };

            const startTimer = () => {
                timerRef.current = setTimeout(() => setIsInactive(true), INACTIVITY_POPUP_TIMEOUT_MS);
            };

            const events = ['mousemove', 'keypress', 'scroll', 'click', 'mousedown', 'touchstart'];
            events.forEach(event => window.addEventListener(event, resetTimer));

            startTimer();

            return () => {
                if (timerRef.current) clearTimeout(timerRef.current);
                events.forEach(event => window.removeEventListener(event, resetTimer));
            };
        }


    }, [checkPopupError, checkPopupSuccess, checkPopupData]);

    const handleClosePopup = () => {
        setIsInactive(false);
        if (isShowPopup) dispatch(setIsShowPopup(false));
    };

    return (
        <>
            {
                checkPopupSuccess &&
                checkPopupData &&
                checkPopupData.flag_active === 'YES' &&
                <Modal
                    show={isInactive || isShowPopup}
                    onClose={handleClosePopup}
                    body={(isInactive || isShowPopup) && <Inactivity data={checkPopupData} onClose={handleClosePopup} />}
                    showHeader={false}
                    width="343px" // Указываем ширину
                    height="343px"
                />
            }
        </>
    );
};

export default InactivityPopup;
