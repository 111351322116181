// getPaymentMethodsWithdrawalSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type GetPaymentMethodsWithdrawalResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type GetPaymentMethodsWithdrawalError = {
  error: any;
  message: string;
};

interface GetPaymentMethodsWithdrawalState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: any[]; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: GetPaymentMethodsWithdrawalState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchGetPaymentMethodsWithdrawal
interface GetPaymentMethodsWithdrawalParams {
  token: string;
  params?: {};
}

export const fetchGetPaymentMethodsWithdrawal = createAsyncThunk<
  GetPaymentMethodsWithdrawalResponse,
  GetPaymentMethodsWithdrawalParams,
  { rejectValue: GetPaymentMethodsWithdrawalError }
>(
  'GetPaymentMethodsWithdrawal/fetchGetPaymentMethodsWithdrawal',
  async ({ token, params = {} }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchGetPaymentMethodsWithdrawal:', `${config.serverUrl}/payment/get-payment-methods`, { token, params });

      const response = await axios.get<GetPaymentMethodsWithdrawalResponse>(
        `${config.serverUrl}/payment/get-payment-methods`,
        {
          params: {
            ...params, // Ваши дополнительные параметры
            token,     // Токен авторизации как часть query-параметров
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Attempting to fetchGetPaymentMethodsWithdrawal response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error get payment methods withdrawal:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to get payment methods withdrawal .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);



const GetPaymentMethodsWithdrawalSlice = createSlice({
  name: 'GetPaymentMethodsWithdrawal',
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetPaymentMethodsWithdrawal.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchGetPaymentMethodsWithdrawal.fulfilled, (state, action: PayloadAction<GetPaymentMethodsWithdrawalResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchGetPaymentMethodsWithdrawal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetState } = GetPaymentMethodsWithdrawalSlice.actions;

export const GetPaymentMethodsWithdrawalReducer = GetPaymentMethodsWithdrawalSlice.reducer;



// ---------
// Selectors
// ---------
export const selectGetPaymentMethodsWithdrawalLoading = (state: RootState) => state.GetPaymentMethodsWithdrawalReducer.loading;
export const selectGetPaymentMethodsWithdrawalSuccess = (state: RootState) => state.GetPaymentMethodsWithdrawalReducer.success;
export const selectGetPaymentMethodsWithdrawalError = (state: RootState) => state.GetPaymentMethodsWithdrawalReducer.error;
export const selectGetPaymentMethodsWithdrawalMessages = (state: RootState) => state.GetPaymentMethodsWithdrawalReducer.messages;
export const selectGetPaymentMethodsWithdrawalData = (state: RootState) => state.GetPaymentMethodsWithdrawalReducer.data;

export const selectGetPaymentMethodsWithdrawalErrorString = (state: RootState): string | null => {
  const error = state.GetPaymentMethodsWithdrawalReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectGetPaymentMethodsWithdrawalErrorArray = (state: RootState): string[] | null => {
  const error = state.GetPaymentMethodsWithdrawalReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
