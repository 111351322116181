import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import {useAppDispatch, useAppSelector} from "../../../../hooks"
import Success from './Success'

import Modal from "../../../layouts/ui/modal/Modal"

import {
    interfaceSelector,
    setSuccessPopup
  } from "../../../../redux/slices/interfaceSlice"

const SuccessPopup = () => {
    const dispatch = useAppDispatch()
    
    const {successPopup} = useAppSelector(interfaceSelector)

    useEffect(() => {
        console.log('SuccessPopup successPopup: ', successPopup)
      }, [successPopup]);


    const handleClosePopup = () => {
        if (successPopup.show) dispatch(setSuccessPopup({show: false, text: ''}));
    };

    return (
        <>
            {
                successPopup.show &&
                <Modal
                    show={successPopup.show}
                    onClose={handleClosePopup}
                    body={successPopup.show && <Success message={successPopup.text} />}
                    showHeader={false}
                    width="343px"
                    height="343px"
                />
            }
        </>
    );
};

export default SuccessPopup;
