import styles from './StartTask.module.scss'
import check_green from "src/assets/images/icons/check_green.svg"
import arrow_right from 'src/assets/images/icons/arrow_right.svg'
import {Link} from "react-router-dom"
import React from "react"

export type StartTaskProps = {
  text: string
  isCompleted: boolean,
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  link?: string
}

export const StartTask = ({text, isCompleted, onClick, link}: StartTaskProps) => {
  const icon = isCompleted ? check_green : arrow_right

  return (
    <div className={styles.startTask}>
      <div className={styles.text}>{text}</div>
      {link ? (
        <Link to={link}>
          <img src={icon} alt="completed"/>
        </Link>
      ) : (
        <img src={icon} alt="completed" onClick={onClick}/>
      )}
    </div>
  )
}