import styles from './PageHeader.module.scss'
import clsx from 'clsx'

type Props = {
  title: string
  className?: string
}

export const PageHeader = ({title, className}: Props) => {
  return (
    <h1 className={clsx(styles.pageHeader, className)}>
      {title}
    </h1>
  )
}