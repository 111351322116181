import React, { ReactNode } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import styles from './Modal.module.scss'
import x_close from 'src/assets/images/icons/x_close.svg'
import clsx from 'clsx'

interface ModalProps {
  show: boolean;
  onClose: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  title?: string | null;
  header?: ReactNode;
  body?: ReactNode;
  children?: ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  variant?: 'security' | 'success' | 'addWallet';
  width?: string; // Новый пропс: ширина модального окна
  height?: string; // Новый пропс: высота модального окна
}

const Modal: React.FC<ModalProps> = ({
  show,
  onClose,
  title = null,
  header,
  body,
  children,
  showHeader = true,
  showFooter = false,
  variant,
  width, // Получаем новый пропс для ширины
  height // Получаем новый пропс для высоты
}) => {

  if (!show) return null // Если show === false, не рендерим модальное окно

  return (
    // Контент модального окна
    <Dialog
      open={show}
      onClose={onClose}
      classes={{
        root: styles.overlay,
        paper: clsx(styles.modalDialog,
          {
            [styles.modalDialogSecurity]: variant === 'security',
            [styles.modalSuccess]: variant === 'success',
            [styles.modalAddWallet]: variant === 'addWallet'
          }
        )
      }}
      PaperProps={{
        style: { ...(width && { width }), ...(height && { height }) } // Применяем ширину и высоту, если они заданы
      }}
    >
      <button className={styles.btnClose} onClick={onClose}>
        <img src={x_close} alt="x_close" />
      </button>
      {/* Кнопка закрытия */}
      {(showHeader && (header || title)) && (

        <DialogTitle className={clsx(styles.modalHeader,
        variant === 'addWallet' && styles.modalHeaderAddWallet,
        )}>
          {header || title} {/* Отображаем заголовок */}
        </DialogTitle>
      )}

      <DialogContent className={clsx(styles.modalBody, variant === 'addWallet' && styles.modalBodyAddWallet)}>
        {body || children} {/* Если body отсутствует, будет показан children */}
      </DialogContent>

      {showFooter && (
        <DialogActions className={styles.modalFooter}>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onClose}
          >
            Close
          </button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default Modal
