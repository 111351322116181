import styles from './BrightCopyToClipboard.module.scss'
import copy_06 from "../../assets/images/icons/copy_06.svg"
import copy_06_primary from "../../assets/images/icons/copy_06_primary.svg"
import CopyToClipboard from "react-copy-to-clipboard"
import React from "react"

type Props = {
   text: string
   variant?: 'history'
}

export const BrightCopyToClipboard = ({text, variant}: Props) => {
  return (
    <CopyToClipboard text={text}>
      <img src={variant === 'history' ? copy_06_primary : copy_06} alt="copy_06" className={styles.copy}/>
    </CopyToClipboard>
  )
}