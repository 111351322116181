import styles from './MyBonuses.module.scss'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import clsx from "clsx"
import React from "react"
import {ProfileShowAllLink} from "../../common/ProfileShowAllLink/ProfileShowAllLink"
import {MyBonusesElement} from "./MyBonusesElement/MyBonusesElement"
import {useTranslation} from "react-i18next";

export const MyBonuses = () => {
    const { t } = useTranslation()
  return (
    <div className={clsx(stylesCommon.accountElement, styles.myBonuses)}>
      <div className={styles.headerContainer}>
        <div className={stylesCommon.header}>{t('My bonuses')}</div>
        <ProfileShowAllLink to={'/bonuses'}/>
      </div>
      <div className={styles.bonusElements}>
        <MyBonusesElement bonusHeader={'Hello Win'} bonusText={'Deposit 20 EUR once to activate the bonus'}
                          isActive={true}
                          countdown={{days: 10, hours: 10, minutes: 10}}/>
        <MyBonusesElement bonusHeader={'Hello Win'} bonusText={'Deposit 20 EUR once to activate the bonus'}/>
      </div>
    </div>
  )
}