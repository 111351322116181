import { useState } from "react"

export const useFilters = <T extends Record<string, string>>(initialFilters: T) => {
  const [filters, setFilters] = useState<T>(initialFilters)
  const [draftFilters, setDraftFilters] = useState<T>(initialFilters)

  // Update draft filters
  const handleDraftFilterChange = (field: keyof T, value: string) => {
    setDraftFilters((prev) => ({ ...prev, [field]: value }))
  }

  // Apply draft filters
  const handleApplyFilters = () => {
    setFilters(draftFilters)
  }

  // Reset all filters
  const handleClearFilters = () => {
    setDraftFilters(initialFilters)
    setFilters(initialFilters)
  }

  return {
    filters,
    draftFilters,
    handleDraftFilterChange,
    handleApplyFilters,
    handleClearFilters,
  }
}
