import styles from './BannerRight.module.scss'
import {ButtonStyled} from "../../../../common/ButtonStyled/ButtonStyled"
import {PromoCode} from "../../../../common/PromoCode/PromoCode"
import SelectInput from "../../../pages/auth/formComponents/SelectInput/SelectInput"
import {SelectChangeEvent} from "@mui/material"
import {useGetDepositBannerQuery} from "../../../../api/api"
import {DepositBannerCurrency} from "../../../../api/types"
import {DepositMethods} from "../DepositMethods/DepositMethods"
import {BrightInput} from "../../../../common/BrightInput/BrightInput"
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useScreenWidth} from "../../../../common/hooks/useScreenWidth"
import {isMobileScreen} from "../../../../common/helpers"

interface BannerRightProps {
  onPlay: (data: { currencyId: string; amount: string }) => void;
}

export const BannerRight: React.FC<BannerRightProps> = ({ onPlay }) => {
  const defaultInputValue = ''

  // Default selected currency ID
  const [value, setValue] = useState('')
  const [inputValue, setInputValue] = useState(defaultInputValue)

  const [isInputTouched, setIsInputTouched] = useState(false)

  const {data} = useGetDepositBannerQuery({language: 1, id: 1})

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value
    setValue(selectedId)

    const selectedCurrency = values.find(item => item.id === selectedId)

    if (selectedCurrency) {
      const {min_deposit} = selectedCurrency
      if (min_deposit !== null && min_deposit !== undefined) {
        setIsInputTouched(false)
        setInputValue(min_deposit.toString())
      } else {
        setIsInputTouched(false)
        setInputValue(defaultInputValue)
      }
    }
  }

  // Currency array fetched from server
  const values = data?.data?.currencies
    ? data.data.currencies.map((item: DepositBannerCurrency) => ({
      id: item.id.toString(),
      name: item.code,
      min_deposit: item.min_deposit,
    }))
    : []

  // Set initial input value based on default currency when data is loaded
  useEffect(() => {
    if (!isInputTouched) {
      const selectedCurrency = values.find(item => item.id === value)
      if (selectedCurrency?.min_deposit != null) {
        setInputValue(selectedCurrency.min_deposit.toString())
      } else {
        setInputValue(defaultInputValue)
      }
    }
  }, [values, value])

  // set first value from values as initial value for select and input
  useEffect(() => {
    if (!value && values[0]) {
      const {id, min_deposit} = values[0]
      setValue(id)
      setInputValue(min_deposit != null ? min_deposit.toString() : defaultInputValue)
    }
  }, [values, value])

  const {t} = useTranslation()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9.]/g, '') // Allow only numbers and dots
    setIsInputTouched(true)
    setInputValue(value)
  }

  const handlePlayClick = () => {
    if (value && inputValue) {
      onPlay({
        currencyId: value,
        amount: inputValue
      })
    }
  }

  const screenWidth = useScreenWidth()  // Detect screen width for responsive behavior
  const isMobile = isMobileScreen(screenWidth)

  return (
    <div className={styles.bannerRight}>
      <div className={styles.mainContent}>
        {isMobile && <DepositMethods/>}
        <div className={styles.title}>{t('Deposit and win!')}</div>
        <div className={styles.selectInputContainer}>
          <BrightInput value={inputValue}
                       onChange={handleInputChange}
                       className={styles.input}
                       variation={'auth'}
          />
          <SelectInput
            value={value}
            onChange={handleChange}
            id="doctype-select"
            name="doctype"
            placeholder={''}
            options={values}
            variant="currencyFilter"
            className={styles.select}
          />
        </div>
        <ButtonStyled 
          variant={'primary'} 
          paddingVariant={'14px 18px'} 
          className={styles.button} 
          isWidth100={true}
          height={'48px'}
          onClick={handlePlayClick}
        >
          {t("Let's Play!")}
        </ButtonStyled>
        <PromoCode marginTop={'24px'}/>
      </div>
      {!isMobile && <DepositMethods/>}
    </div>
  )
}