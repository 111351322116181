import React from 'react'
import clsx from 'clsx'
import stylesCommon from '../../../common/styles/commonStyles.module.scss'
import {
  childrenMenuSelector,
  interfaceSelector,
  setIsChildrenMenuOpened,
  setIsMobileMenuOpened
} from '../../../redux/slices/interfaceSlice'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import {useNavigate} from 'react-router-dom'
import {MenuItem} from '../../../api/types'
import styles from './NavWithChildrenMenuClose.module.scss'

type Props = {
  item: MenuItem | string
  onClick?: () => void | ((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void)
}

// this is replacing for Link or Div with onClick, that support closing children menu
export const NavWithChildrenMenuClose = ({item, onClick}: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {isChildrenMenuOpened} = useAppSelector(childrenMenuSelector)
  const {isMobileMenuOpened} = useAppSelector(interfaceSelector)

  const onLinkClick = (e: React.MouseEvent<HTMLDivElement>, href: string = '') => {
    e.stopPropagation(); // Prevents the click from bubbling up to the parent
    if (isChildrenMenuOpened) dispatch(setIsChildrenMenuOpened(false))
    if (isMobileMenuOpened) dispatch(setIsMobileMenuOpened(false))
    navigate(href)
  }

  const onClickAlternative = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // Prevents the click from bubbling up to the parent
    if (isChildrenMenuOpened) dispatch(setIsChildrenMenuOpened(false))
    if (isMobileMenuOpened) dispatch(setIsMobileMenuOpened(false))
    if (onClick) onClick();
  }

  return (
    <div
      className={clsx('nav-link', stylesCommon.link)}
      onClick={(e) => (typeof item === 'string' ? onClickAlternative(e) : onLinkClick(e, item.link_href))}
    >
      {<div className={styles.item}>
        {typeof item === 'string'
          ? item
          : item.title
        }
      </div>}
    </div>
  )
}

// todo: no need to close ChildrenMenu on link click, because menu is closed by hover