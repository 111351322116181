import React from 'react'
import {Box, TableCell, TableSortLabel} from "@mui/material"
import {visuallyHidden} from '@mui/utils'

type SortableFilterableTableCellProps = {
  label: string 
  orderBy: string 
  currentOrderBy: string 
  order: 'asc' | 'desc' 
  onSort: () => void
}

export const SortableTableCell: React.FC<SortableFilterableTableCellProps> = ({
                                                                                          label,
                                                                                          orderBy,
                                                                                          currentOrderBy,
                                                                                          order,
                                                                                          onSort
                                                                                        }) => {
  return (
    <TableCell>
      <TableSortLabel
        active={currentOrderBy === orderBy}
        direction={currentOrderBy === orderBy ? order : 'asc'}
        onClick={onSort}
      >
        {label}
        {currentOrderBy === orderBy ? (
          <Box component="span" sx={visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  ) 
} 
