import React from "react"
import styles from './DocumentsElement.module.scss'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import clsx from 'clsx'
import {useTranslation} from "react-i18next"
import {formatFilename} from "../../../../common/helpers"

export type Document = {
  id: number
  type: string
  docfile_path?: string
  datetime_client: string
  status: string
}

type Props = {
  doc: Document
  imageLoadStatus: Record<number, boolean>
  handleImageLoad: (id: number) => void
  handleImageError: (id: number) => void
  minHeight?: number
}

export const DocumentsElement = ({doc, imageLoadStatus, handleImageLoad, handleImageError, minHeight}: Props) => {
  const [date, time] = doc.datetime_client.split(" ")
  const filename = doc.docfile_path ? doc.docfile_path.slice(doc.docfile_path.lastIndexOf('/') + 1) : '';
  const {t} = useTranslation()

  return (
    <div className={styles.documentElement} style={{
      minHeight: `${minHeight}px`
    }}>
      <div className={styles.document}>
        <div className={clsx(styles.status,
          {
            [stylesCommon.statusApproved]: doc.status === 'APPROVED',
            [stylesCommon.statusRejected]: doc.status === 'DECLINED',
            [stylesCommon.statusPending]: doc.status === 'UPLOADED',
          }
        )}>{t(doc.status.toLowerCase())}</div>
        <div className={styles.doctype}>{t(doc.type)}</div>
        {
          filename && (
            <div className={styles.filename}>
              {formatFilename(filename)}
            </div>
          )
        }
        {/*<div>{doc.id}</div>*/}
      </div>

      <div className={styles.dateTimeContainer}>
        <div className={styles.dateTime}>{date}</div>
        <div className={styles.dateTime}>{time}</div>
      </div>

    </div>

  )
}