import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch, useAppSelector} from "../../../../hooks"
import {interfaceSelector, setAuthActiveTab, authTabsType} from "../../../../redux/slices/interfaceSlice"

import {
  clearErrorsRequestPasswordReset,
  resetStateRequestPasswordReset,
  fetchRequestPasswordReset,
  selectRequestPasswordResetSuccess,
  selectRequestPasswordResetLoading,
  selectRequestPasswordResetError,
  selectRequestPasswordResetErrorArray,
  selectRequestPasswordResetData,
  selectRequestPasswordResetMessages,

} from "../../../../redux/slices/site/requestPasswordReset"

import arrow_left from 'src/assets/images/icons/arrow_left.svg'
import {useNavigate} from 'react-router-dom'
import TextInput from '../formComponents/TextInput'
import stylesCommon from "../AuthCommon.module.scss"
import styles from './PasswordRecovery.module.scss'
import login_background from 'src/assets/images/login_background.jpg'
import {SubmitButton} from "../formComponents/SubmitButton/SubmitButton"
import mail_01_passive from 'src/assets/images/icons/mail_01_passive.svg'
import mail_01 from 'src/assets/images/icons/mail_01.svg'
import clsx from 'clsx'
import {useTranslation} from "react-i18next"
import {MobileStatus} from "../../../../common/MobileStatus/MobileStatus"


// Определение интерфейсов для состояний
interface FormState {
  email?: string;
}

// interface ClientErrors {
//   required: { [key: string]: string };
// }

type ClientError = {
  [groupKey: string]: { // Общий индекс, который допускает любые верхнеуровневые ключи
    [fieldName: string]: { // Динамические ключи внутри групп
      hasError: boolean;
      message: string;
    };
  };
}

type ClientErrors = ClientError | null;


interface PasswordRecoveryProps {

}

const PasswordRecovery: React.FC<PasswordRecoveryProps> = () => {
  const dispatch = useAppDispatch()
  // Навигация
  const navigate = useNavigate()

  const requestPasswordResetSuccess = useSelector(selectRequestPasswordResetSuccess)
  const requestPasswordResetLoading = useSelector(selectRequestPasswordResetLoading)
  const requestPasswordResetError = useSelector(selectRequestPasswordResetError)
  const requestPasswordResetErrorArray = useSelector(selectRequestPasswordResetErrorArray)
  const requestPasswordResetData = useSelector(selectRequestPasswordResetData)
  const requestPasswordResetMessages = useSelector(selectRequestPasswordResetMessages)


  const {t} = useTranslation()


  const [clientErrors, setClientErrors] = useState<ClientErrors>({
    required: {
      'email': {
        hasError: false,
        message: 'This field is required.'
      },
    },
    email: {
      'email': {
        hasError: false,
        message: 'This field most be valid email.'
      },
    }
  })

  const [emailFieldErrors, setEmailFieldErrors] = useState<string[] | null>(null)
  const [formState, setFormState] = useState<FormState>({})
  const [initialState, setInitialState] = useState<FormState>({})

  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    const initialFormValues: FormState = {
      email: '',
    }
    // const deviceDetect = deviceDetector()
    setFormState(initialFormValues)
    setInitialState(initialFormValues)
  }, [])

  // Set validation errors
  useEffect(() => {
    if (requestPasswordResetSuccess) {
      console.error('requestPasswordResetSuccess', requestPasswordResetSuccess)
    }

    if (requestPasswordResetError) {
      console.error('requestPasswordResetError', requestPasswordResetError)
    }
  }, [requestPasswordResetError, requestPasswordResetSuccess, requestPasswordResetData])

  useEffect(() => {
    // Очистка ошибок при размонтировании
    return () => {
      // dispatch(clearErrorsRequestPasswordReset())
      dispatch(resetStateRequestPasswordReset())
      setClientErrors(null)

    }
  }, [])


  // Login request
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // dispatch(clearErrorsLogin())
    const email = formState.email

    if (clientValidation() && email) {
      dispatch(fetchRequestPasswordReset({email: email}))
    } else {
      console.error('Validation failed')
    }
  }

  const updateFieldError = (fieldName: string, value: string | boolean) => {
    setClientErrors((prevErrors) => {
      const updatedErrors = {...prevErrors}

      // Проверка required
      if (updatedErrors.required?.hasOwnProperty(fieldName)) {
        const isFieldFilled = typeof value === 'boolean' ? value : (typeof value === 'string' && value.trim() !== '')
        updatedErrors.required[fieldName].hasError = !isFieldFilled
      }

      // email equal
      if (updatedErrors.email?.hasOwnProperty(fieldName)) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const isValidEmail = typeof value === 'string' && emailRegex.test(value)
        updatedErrors.email[fieldName].hasError = !isValidEmail
      }

      return updatedErrors
    })
  }


  const checkErrors = (errors: ClientErrors): boolean => {
    let hasErrors = false

    // Перебираем ключи верхнего уровня объекта (например, "required", "email")
    if (errors) {
      Object.keys(errors).forEach((key) => {
        const fieldGroup = errors[key] // Получаем вложенный объект (например, { email: { hasError, message } })

        // Перебираем поля вложенного объекта (например, "email")
        Object.keys(fieldGroup).forEach((fieldKey) => {
          const field = fieldGroup[fieldKey] // Получаем объект поля (например, { hasError, message })
          if (field.hasError) {
            hasErrors = true // Если есть ошибка, устанавливаем флаг
            console.log(`Error found in field: ${fieldKey}, message: ${field.message}`)
          }
        })
      })
    }
    return hasErrors
  }


  const getFieldClientErrorMessages = (field: string) => {
    for (const key in clientErrors) {
      if (clientErrors[key][field]?.hasError) {
        return clientErrors[key][field].message
      }
    }
    return null
  }

  const getFieldServerErrorMessages = (field: string) => {
    if (requestPasswordResetError && requestPasswordResetError[field]) {
      return requestPasswordResetError[field]
    }
  }

  const getCommonServerErrors = () => {
    let inputs: string[] = [];
    let commonErrors: string[] = [];
    let serverErrors = requestPasswordResetError; // Пример исходных ошибок

    if (serverErrors) {
      const formElements = formRef.current?.elements

      if (formElements) {
        // Перебираем элементы формы
        Array.from(formElements).forEach(field => {
          const input = field as HTMLInputElement
          const inputName = input.name
          inputs.push(inputName) // Добавляем имя инпута в массив
        })
      }

      // Теперь добавляем в массив ошибки, которые не привязаны к инпутам
      Object.keys(serverErrors).forEach(key => {
        if (!inputs.includes(key)) {
          commonErrors.push(serverErrors[key]) // Добавляем ошибку в массив, если она не относится к инпуту
        }
      })
    }

    // Возвращаем массив ошибок или false, если ошибок нет
    return commonErrors.length > 0 ? commonErrors : false
  }


  const clientValidation = (): boolean => {
    const formElements = formRef.current?.elements
    let formIsValid = true

    if (formElements) {
      Array.from(formElements).forEach(field => {
        const input = field as HTMLInputElement
        const inputName = input.name
        const inputValue = input.value.trim()

        updateFieldError(inputName, inputValue)

        if (checkErrors(clientErrors)) {
          formIsValid = false
        }

      })
    }

    return formIsValid
  }

  const handleInputChange = (code: string, e: ChangeEvent<HTMLInputElement>) => {

    const value = e.target.type === 'checkbox' ? (e.target as HTMLInputElement).checked : e.target.value

    const updatedFormState = {
      ...formState,
      [code]: value,
    }
    setFormState(updatedFormState)
    // setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))

    // Обновляем ошибки
    updateFieldError(code, value)
  }


  const commonErrors = getCommonServerErrors();
  const {isMobile} = useAppSelector(interfaceSelector);

  return (
    <>
      {isMobile && <MobileStatus variant={'PasswordRecovery'}/>}
      <div className={styles.passwordRecovery}>

        <button className={styles.btnPrev} onClick={() => dispatch(setAuthActiveTab('login'))}>
          <img src={arrow_left} alt="arrow_left"/>
        </button>

        <div className={styles.gridContainer}>
          {!isMobile && <div className={clsx(stylesCommon.leftContent, styles.leftContent)} style={{
            "--background": `url(${login_background})`,
          } as React.CSSProperties}>
              <div className={stylesCommon.text}>
                {t('Play and win')}
              </div>
          </div>}

          <div className={clsx(stylesCommon.rightContent, styles.rightContent)}>

            {requestPasswordResetSuccess ?

              <>
                <div className={styles.header}>
                  {(requestPasswordResetMessages && t(requestPasswordResetMessages)) || t('Password reset link has been successfully sent to your email address.')}
                </div>
              </>
              :
              <>
                <div className={styles.header}>
                  {t('Forward an email to unlock')}
                </div>

                <div className={styles.text}>
                  {t('If your account has been blocked, enter your email address, and we will send you a special email to unlock it. If something doesn\'t work out, contact the support service.')}
                </div>
                {/* Блок с формой */}
                <form onSubmit={handleSubmit} ref={formRef}>

                  <div className={styles.formInputs}>
                    <TextInput
                      id="email"
                      name="email"
                      value={formState.email || ''}
                      onChange={(e) => handleInputChange('email', e)}
                      placeholder={t('Enter your email')}
                      passiveIcon={mail_01_passive}
                      activeIcon={mail_01}
                      error={getFieldClientErrorMessages("email") || getFieldServerErrorMessages("email")}
                    />
                  </div>

                  <SubmitButton isLoading={requestPasswordResetLoading} text={t('Send')}/>

                  {commonErrors && (
                    <div className="alert alert-danger" role="alert">
                      {Array.isArray(commonErrors) &&
                        commonErrors.map((error, index) => <p key={index}>{error}</p>)}
                    </div>
                  )}

                </form>
              </>

            }


          </div>
        </div>
      </div>
    </>
  )

}

export default PasswordRecovery
