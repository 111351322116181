import checkmark_1 from 'src/assets/images/checkmark_1.png'
import styles from './SecurityPasswordSuccess.module.scss'
import {useAppSelector} from "../../../../hooks"
import {interfaceSelector} from "../../../../redux/slices/interfaceSlice"

export const SecuritySuccess = () => {
  const {successModal} = useAppSelector(interfaceSelector)
  const variant = successModal.variant

  return (
    <div className={styles.securityPasswordSuccess}>
      <img src={checkmark_1} alt="checkmark_1"/>
      <div className={styles.title}>Your {variant} has been changed</div>
    </div>
  )
}