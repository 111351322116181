import styles from './FaqTermsTemplate.module.scss'
import {FaqItem, TermsItem} from "../../api/types"
import {PageSubHeader} from "../PageSubHeader/PageSubHeader"
import React from "react"
import download_01 from 'src/assets/images/icons/download_01.svg'
import {ButtonStyled} from "../ButtonStyled/ButtonStyled"
import {useAppSelector} from "../../hooks"
import {interfaceSelector} from "../../redux/slices/interfaceSlice"
import {getNumberFromPx} from "../helpers"
import {BrightAccordion} from '../BrightAccordion/BrightAccordion'

type Item = FaqItem | TermsItem;

type Props = {
  items: Item[]
  selectedItem: Item | null
  setSelectedItem: (item: Item | null) => void;
  variant?: 'faq' | 'terms'
}

export const FaqTermsTemplate = ({items, setSelectedItem, selectedItem, variant = 'faq'}: Props) => {
  const {termTopHeight, sidebarHeight, faqPageHeaderContainerHeight} = useAppSelector(interfaceSelector)
  const rootStyles = getComputedStyle(document.body)
  const contentGridPadding = getNumberFromPx(rootStyles.getPropertyValue('--content-grid-padding'))
  const offset = sidebarHeight - (variant === 'terms' ? termTopHeight : faqPageHeaderContainerHeight ) - contentGridPadding * 2

  const {isMobile} = useAppSelector(interfaceSelector)


  return isMobile 
    ? (<BrightAccordion.Wrapper>
        {items.map((item) => (
          <BrightAccordion.Root
            key={item.id}
            summary={
              variant === 'faq'
                ? (item as FaqItem).name
                : `${(item as TermsItem).section_number}. ${(item as TermsItem).title}`
            }
            details={
              <div className={styles.faqAnswerText}>
                {item.description}
              </div>
            }
            isExpandMoreIconHidden={true}
            variant={'termsFaq'}
          />
        ))}
      </BrightAccordion.Wrapper>
    )

    : (
    <div className={styles.itemContent} style={{
      minHeight: `${offset}px`,
    }}>
      {/* Left column: list of names or titles */}
      <div className={styles.itemNameTitles}>
        {items.length > 0 ? (
          items.map((item) => (
            <button
              key={item.id}
              className={`${styles.nameTitle} ${
                selectedItem?.id === item.id ? styles.active : ''
              }`}
              onClick={() => setSelectedItem(item)}
            >
              {/* Check the type via variant */}
              {variant === 'faq'
                ? (item as FaqItem).name
                : `${(item as TermsItem).section_number}. ${(item as TermsItem).title}`}
            </button>
          ))
        ) : (
          <div>No items available.</div>
        )}
      </div>

      {/* Right column: selected item */}
      <div className={styles.itemDescription}>
        <div className={styles.subHeaderContainer}>
          <PageSubHeader
            title={
              variant === 'faq'
                ? (selectedItem as FaqItem)?.name || ''
                : `${(selectedItem as TermsItem)?.section_number || ''}. ${(selectedItem as TermsItem)?.title || ''}`
            }
          />
          {
            variant === 'terms' &&
            <ButtonStyled variant={'glass'} className={styles.button}>Download <img src={download_01} alt={'download_01'}/> </ButtonStyled>
          }
        </div>
        <div className={styles.faqAnswerText}>
         {selectedItem?.description || ''}
        </div>
      </div>
    </div>
  )
}