import styles from './DepositRightSidebar.module.scss'
import {DepositRightSidebarElement} from "./DepositRightSidebarElement/DepositRightSidebarElement"
import flash from 'src/assets/images/DepositRightSidebar/flash.png'
import question from 'src/assets/images/DepositRightSidebar/question.png'
import money from 'src/assets/images/DepositRightSidebar/money.png'
import trophy_2_1_2 from 'src/assets/images/bonuses/trophy_2_1_2.png'
import {useTranslation} from "react-i18next";

export type DepositSidebarElement = {
    text: string
    img: string
    amount: string
    type?: 'amount' | 'star'
}

const sidebarElements: Array<DepositSidebarElement> = [
  { text: 'Total amount of slots', img: flash, amount: '10 000+'},
  { text: 'Support 24/7', img: question, amount: '5', type: 'star'},
  { text: 'Latest big Win', img: money, amount: '120 000 €'},
  { text: 'Max jackpot winning', img: trophy_2_1_2, amount: '325 000 €'}
]

export const DepositRightSidebar = () => {
  const {t} = useTranslation()
  return (
    <div className={styles.depositRightSidebar}>
      {sidebarElements.map((element, index) => (
        <DepositRightSidebarElement key={index} text={t(element.text)} img={element.img} amount={element.amount} type={element.type}/>
      ))}
    </div>
  )
}