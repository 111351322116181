import styles from './SocialAuth.module.scss'
import {ButtonStyled} from "../../../../../common/ButtonStyled/ButtonStyled"
import google from "../../../../../assets/images/icons/social/google.svg"
import facebook from "../../../../../assets/images/icons/social/facebook.svg"
import apple from "../../../../../assets/images/icons/social/apple.svg"
import React from "react"
import {useTranslation} from "react-i18next";

type Props = {
  variant: 'login' | 'sign up'
}

export const SocialAuth = ({variant}: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.dividerContainer}>
        <div className={styles.line}></div>
        <div className={styles.dividerText}>{t(`or ${variant} with`)}</div>
        <div className={styles.line}></div>
      </div>
      {/* Блок с соцсетями */}
      <div className={styles.socialButtons}>
        <ButtonStyled variant={'glass'} className={styles.button}>
          <img src={google} alt="Google"/>
        </ButtonStyled>
        <ButtonStyled variant={'glass'} className={styles.button}>
          <img src={facebook} alt="Facebook"/>
        </ButtonStyled>
        <ButtonStyled variant={'glass'} className={styles.button}>
          <img src={apple} alt="apple"/>
        </ButtonStyled>
      </div>
    </>
  )
}