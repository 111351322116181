import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Personal from './Personal/Personal'


const PersonalInformation = () => {
  const [currentView, setCurrentView] = useState<'Personal' | 'Location' | 'Contacts' | 'Security'>('Personal');


  const renderView = () => {
    switch (currentView) {
      case 'Personal':
        return (
          <Personal />
        );

      default:
        return null;
    }
  };

  return (
    <div className="mt-2 mb-2">
      <div className="card col-8 offset-2">
        <h1 className="p-2">Profile Information</h1>
        <div className="container mt-5">
          <ul className="nav nav-pills justify-content-between border p-2">
            <li className="nav-item">
              <Link onClick={() => setCurrentView('Personal')} className={currentView === 'Personal' ? "btn btn-success text-white" : "nav-link font-weight-bold"} to="#">Personal</Link>
            </li>
          </ul>
        </div>

        <div className="card-body">
          {renderView()} {/* Вызов функции для рендеринга текущего вида */}
        </div>

      </div>
    </div>
  );
};

export default PersonalInformation;
