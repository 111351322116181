export const TRANSACTIONS_PAGINATION_VALUES = [2, 5, 10, 20, 50]
export const PAGE_PAGINATION_VALUES = [1, 2, 3, 10]
export const DEFAULT_PAGINATION_VALUE = 50
export const SIBLING_COUNT = 2
export const GAME_GROUP_GAMES_PER_PAGE = 10
export const ARTICLES_PER_PAGE = 10
export const DISABLE_MISC_LOGS = true
export const DISABLE_STATE_LOGGER_LOG = true
export const DISABLE_I18N_LOGS = true /*todo: need enable again and fix later */
export const DISABLE_INACTIVITY_POPUP = false

export const INACTIVITY_POPUP_TIMEOUT_MS = 180000; // 3 minutes in milliseconds