import React, { useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsAuthenticated
  } from "../../redux/slices/auth/isAuthenticatedSlice"
  import {
    selectToken
  } from "../../redux/slices/auth/loginSlice"
import {connectBalanceWebSocket} from "src/redux/actions/balanceActions"

type BasePageProps = {
    children: ReactNode;
};

const BasePage: React.FC<BasePageProps> = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated) as boolean;
    const token = useSelector(selectToken) as string | null;

    useEffect(() => {
        if (!isAuthenticated) {
            // Здесь можно добавить логику для выхода или редиректа
            // navigate('/auth');
        } else {
            const closeBalanceWebSocket = dispatch(connectBalanceWebSocket(token || ''));

            return () => {
                closeBalanceWebSocket();
            };
        }
    }, [isAuthenticated, token, navigate, dispatch]);

    // if (!token) {
    //     return null; // Или можете вернуть какой-то компонент загрузки
    // }

    return <>{children}</>;
};

export default BasePage;
