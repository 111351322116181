import React from 'react'
import {Grid2} from "@mui/material"
import styles from "./CarouselBlock.module.scss"
import {Carousel} from "./Carousel/Carousel"
import clsx from "clsx"
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import {useAppSelector} from "../../../hooks"
import {interfaceSelector} from "../../../redux/slices/interfaceSlice"

export const CarouselBlock = () => {

  const {isMobile} = useAppSelector(interfaceSelector)


  return (
    <div className={clsx(styles.carouselBlock, stylesCommon.group)}>
      <Grid2 spacing={isMobile ? '8px' : '24px'} container direction={'column'}>
        <div className={styles.carouselContainer}><Carousel id={1}/></div>
        <div className={styles.carouselContainer}><Carousel id={2}/></div>
      </Grid2>
    </div>

  )
}