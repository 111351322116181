import React, { useEffect, useState, useRef } from 'react';
import styles from "./InactivityPopup.module.scss";
import { useSelector } from 'react-redux'
import { useAppDispatch } from "../../../../hooks"
import config from '../../../../config'
import { useTranslation } from "react-i18next";
import {DISABLE_MISC_LOGS} from "../../../../common/constants"

interface InactivityProps {
    data: any
    onClose: () => void
}

const Inactivity: React.FC<InactivityProps> = ({ data, onClose }) => {
    const dispatch = useAppDispatch()
    const totalTime = data.timer_seconds;
    const imgFolder = config.fileUrls.popup;

    const [remainingTime, setRemainingTime] = useState(totalTime);

    const progressBarWidth = (remainingTime / totalTime) * 100;
    const { t } = useTranslation()

    useEffect(() => {
        !DISABLE_MISC_LOGS && console.log('Inactivity data', data)
        !DISABLE_MISC_LOGS && console.log('Inactivity remainingTime', remainingTime)
    }, [data, remainingTime]);

    useEffect(() => {
        if (remainingTime > 0) {
            const timerId = setInterval(() => {
                setRemainingTime((prevTime: any) => prevTime - 1);
            }, 1000);
            return () => clearInterval(timerId); // Очистка интервала при размонтировании компонента
        } else {
            onClose(); // Закрытие окна по истечению времени
        }
    }, [remainingTime, onClose]);

    const onClick = (e: React.MouseEvent<HTMLButtonElement>, link: string) => {
        e.preventDefault()
        console.log('onClick', link)
    }

    return (
        <>
            <div className={styles.popupContainer}>
                <div className={styles.popupContainerTop}>
                    {/* title */}
                    <div className={`${styles.title} ${styles[data.title_class]}`}>
                        <div
                            style={{
                                fontSize: `${data.title_fontsize}px`,
                                fontWeight: data.title_fontstyle,
                                textAlign: data.title_alignment
                            }}
                        >
                            {data.popupLangs[0].title_text}
                        </div>
                    </div>

                    {/* description */}
                    <div className={`${styles.description} ${styles[data.description_class]}`}>
                        <div
                            style={{
                                fontSize: `${data.description_fontsize}px`,
                                fontWeight: data.description_fontstyle,
                                textAlign: data.description_alignment
                            }}
                        >
                            {data.popupLangs[0].description_text}
                        </div>
                    </div>
                </div>
                <div className={styles.popupContainerBottom}>
                    <div className={styles.timer}>
                        <div className={styles.timerText}>{t('The offer expires in')} {remainingTime}</div>
                        <div className={styles.progress}>
                            <div
                                className={styles.progressBar}
                                style={{ width: `${progressBarWidth}%` }}
                            ></div>
                        </div>
                    </div>

                    {/* button */}
                    <button
                        className={`${styles.button} ${styles[data.button_class]}`}
                        style={{ fontSize: `${data.button_fontsize}px` }}
                        onClick={(e) => onClick(e, data.button_link)}
                    >
                        {data.popupLangs[0].button_text}
                    </button>
                </div>
            </div>
            <div className={styles.popupBackground} style={{ backgroundImage: `url(${imgFolder}/${data.image_path})` }}></div>
        </>
    );
};

export default Inactivity;
