import styles from './PageSubHeader.module.scss'

type Props = {
  title: string
}

export const PageSubHeader = ({title}: Props) => {
  return (
    <div className={styles.pageSubHeader}>
      {title}
    </div>
  )
}