import React from 'react'
import user_01 from 'src/assets/images/icons/user_01.svg'
import user_01_white from 'src/assets/images/icons/user_01_white.svg'
import styles from "./User01Icon.module.scss"
import clsx from 'clsx'

type Props = {
  className?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  style?: React.CSSProperties
  isNewDesign?: boolean
}

export const Avatar = ({className, onClick, style, isNewDesign}: Props) => {
  return (
    <div className={clsx(!isNewDesign ? styles.icon : styles.iconNewDesign, className)} onClick={onClick} style={style}>
      <img src={!isNewDesign ? user_01 : user_01_white} alt="user_01"/>
    </div>
  )
}