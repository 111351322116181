// VipClub.js
import config from '../../config';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import withAuth from '../hoc/withAuth';  // Import the HOC
import { connectWebSocketVipClubInfo } from '../../redux/actions/bonus/vipClubInfoActions';
import { selectVipClubInfo, selectVipClubInfoLoading, selectVipClubInfoError } from '../../redux/selectors/bonus/vipClubInfoSelectors';
import {
    selectIsAuthenticated
} from "../../redux/slices/auth/isAuthenticatedSlice.ts"
import {
    selectToken
} from "../../redux/slices/auth/loginSlice.ts"

const VipClub = () => {
    //   let { slug } = useParams();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);
    const vipClubInfo = useSelector(selectVipClubInfo);
    const loading = useSelector(selectVipClubInfoLoading);
    const error = useSelector(selectVipClubInfoError);
    const sortedData = useMemo(() => {
        return vipClubInfo.slice().sort((a, b) => a.number - b.number);
    }, [vipClubInfo]);

    useEffect(() => {
        if (isAuthenticated) {
            const closeWebSocketVipClubInfo = dispatch(connectWebSocketVipClubInfo(token));

            return () => {
                if (closeWebSocketVipClubInfo && typeof closeWebSocketVipClubInfo === 'function') {
                    closeWebSocketVipClubInfo();
                }
            };
        }
    }, [dispatch, token, isAuthenticated]);


    useEffect(() => {
        console.log('vipClubInfo:', vipClubInfo);

    }, [vipClubInfo]);

    const handleClickBackToHome = async (e) => {
        e.preventDefault();
        console.log('BACK TO HOMEPAGE');
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <div className="card mt-2">
                    <div className="card-header d-flex justify-content-center">
                        <h1>Vip Club</h1>
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">VIP Level</th>
                                    <th scope="col">Points</th>
                                    <th scope="col">Cashback</th>
                                    <th scope="col">Prize</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map((item) => (
                                    <tr key={item.number}>
                                        <th scope="row">{item.number}</th>
                                        <td>{item.vip_level}</td>
                                        <td>{item.points}</td>
                                        <td>{item.cashback}</td>
                                        <td>{item.prize}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div>
                            <p className="">
                                <strong>Are you ready to join us and take on the challenge of the casino world? Our VIP club is based on a story of adventure, come with us into the world of rewards!</strong>
                            </p>
                        </div>

                        <div>
                            <button onClick={handleClickBackToHome} type="button" className="btn btn-primary">BACK TO HOMEPAGE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// export default withAuth(GameGroup);
export default VipClub;