// RequestPasswordReset.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type RequestPasswordResetResponse = {
    success: boolean;
    data: boolean; // Уточните тип данных
    messages: string | null;
};

type RequestPasswordResetError = {
    error: any;
    message: string;
};

interface RequestPasswordResetState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: boolean; // Уточните тип массива, если известно
    messages: string | null;
}


// Типизация параметров для fetchRequestPasswordReset
type FetchRequestPasswordResetParam = {
    email: string;
};



const initialState: RequestPasswordResetState = {
    success: false,
    loading: false,
    error: null,
    data: false,
    messages: null,
};


export const fetchRequestPasswordReset = createAsyncThunk<
    RequestPasswordResetResponse,
    FetchRequestPasswordResetParam,
    { rejectValue: RequestPasswordResetError }
>(
    'RequestPasswordReset/fetchRequestPasswordReset',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { email } = params;
            console.log('Attempting to fetchRequestPasswordReset:', { email });

            const response = await axios.post<RequestPasswordResetResponse>(
                `${config.serverUrl}/site/request-password-reset`,
                { email },
                { headers: { 'Content-Type': 'application/json' } } // Добавляем заголовки
            );

            console.log('fetchRequestPasswordReset response.data:', response.data);

            return response.data;
        } catch (error) {
            console.error('Error in fetchRequestPasswordReset:', error);

            if (axios.isAxiosError(error) && error.response) {
                return rejectWithValue({
                    error: error.response.data.errors || ["RequestPasswordReset failed"],
                    message: error.response.data.messages || ['Failed to get RequestPasswordReset.'],
                });
            }

            return rejectWithValue({
                error: null,
                message: 'An unknown error occurred.',
            });
        }
    }
);


const RequestPasswordReset = createSlice({
    name: 'Site/request-password-resetSlice',
    initialState,
    reducers: {
        resetStateRequestPasswordReset: (state) => {
            return {
                ...state,
                success: initialState.success,
                loading: initialState.loading,
                error: initialState.error,
                data: initialState.data,
                messages: initialState.messages,
            }

        },
        clearErrorsRequestPasswordReset: (state) => {
            return {
                ...state,
                loading: initialState.loading,
                error: initialState.error,
                success: initialState.success,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRequestPasswordReset.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.messages = null;
                state.success = false;
            })
            .addCase(fetchRequestPasswordReset.fulfilled, (state, action: PayloadAction<RequestPasswordResetResponse>) => {
                state.loading = false;
                state.success = action.payload.success;
                state.data = action.payload.data;
                state.messages = action.payload.messages;

                // Сохраняем состояние в localStorage
                localStorage.setItem('loginState', JSON.stringify({
                    success: state.success,
                    data: state.data,
                    messages: state.messages,
                }));
            })
            .addCase(fetchRequestPasswordReset.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error || null;
                state.messages = action.payload?.message || 'Unknown error occurred.';
                state.success = false;
            });
    },
});

export const { resetStateRequestPasswordReset, clearErrorsRequestPasswordReset } = RequestPasswordReset.actions;
export const RequestPasswordResetReducer = RequestPasswordReset.reducer;



// ---------
// Selectors
// ---------
export const selectRequestPasswordResetLoading = (state: RootState) => state.RequestPasswordResetReducer.loading;
export const selectRequestPasswordResetSuccess = (state: RootState) => state.RequestPasswordResetReducer.success;
export const selectRequestPasswordResetError = (state: RootState) => state.RequestPasswordResetReducer.error;
export const selectRequestPasswordResetMessages = (state: RootState) => state.RequestPasswordResetReducer.messages;
export const selectRequestPasswordResetData = (state: RootState) => state.RequestPasswordResetReducer.data;
export const selectToken = (state: RootState) => state.RequestPasswordResetReducer.data.token;


export const selectRequestPasswordResetErrorString = (state: RootState): string | null => {
    const error = state.RequestPasswordResetReducer.error;

    if (Array.isArray(error)) {
        return error.join('\n');
    }

    if (typeof error === 'object' && error !== null) {
        return Object.entries(error)
            .map(([key, value]) => `${key}: ${value}`)
            .join('\n');
    }

    return error;
};

export const selectRequestPasswordResetErrorArray = (state: RootState): string[] | null => {
    const error = state.RequestPasswordResetReducer.error;
    let errorsArray: string[] = [];

    if (Array.isArray(error)) {
        errorsArray = error.map((err) => `${err}`);
    } else if (typeof error === 'object' && error !== null) {
        errorsArray = Object.entries(error)
            .flatMap(([key, value]) =>
                Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
            );
    } else if (error) {
        errorsArray = [error];
    }

    return errorsArray.length > 0 ? errorsArray : null;
};
