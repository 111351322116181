import {ButtonId, NavigationButtonData} from "../../../../common/types"
import React, {useEffect, useState} from "react"
import {NavigationButtonsGroup} from "../../../../common/NavigationButtonsGroup/NavigationButtonsGroup"
import {GamingPad01Icon} from "../../../../assets/images/icons/withColorChange/GamingPad01Icon"
import {CreditDeposit01Icon} from "../../../../assets/images/icons/withColorChange/CreditDeposit01Icon"
import {CreditWithdraw01Icon} from "../../../../assets/images/icons/withColorChange/CreditWithdraw01Icon"
import {Gift01Icon} from "../../../../assets/images/icons/withColorChange/Gift01Icon"
import {SessionsInfo} from "../../Transactions/GameActivity/SessionsInfo/SessionsInfo"
import Deposits from "../../Transactions/Deposits"
import {Withdrawals} from "../../Transactions/Withdrawals"
import BonusHistory from "../../BonusHistory"

export type HistoryTabs = 'Game activity' | 'Deposits' | 'Withdrawals' | 'Bonus history'
type HistoryButtonData = Omit<NavigationButtonData, 'id'> & { id: HistoryTabs }

export const History = () => {
  const buttonData: Array<HistoryButtonData> = [
    {id: 'Game activity', label: 'Game activity', icon: GamingPad01Icon},
    {id: 'Deposits', label: 'Deposits', icon: CreditDeposit01Icon},
    {id: 'Withdrawals', label: 'Withdrawals', icon: CreditWithdraw01Icon},
    {id: 'Bonus history', label: 'Bonus history', icon: Gift01Icon},
  ]

  const [activeButton, setActiveButton] = useState<HistoryTabs>('Game activity')

  useEffect(() => {
    document.title = `Account - History - ${activeButton}`
  }, [activeButton])

  const handleButtonClick = (id: ButtonId) => {
    setActiveButton(id as HistoryTabs)
  }

  return (
    <div>
      <NavigationButtonsGroup buttonData={buttonData} activeButton={activeButton}
                              handleButtonClick={handleButtonClick} variant={'profileMain'}/>
      {activeButton === 'Game activity' && <SessionsInfo />}
      {activeButton === 'Deposits' && <Deposits />}
      {activeButton === 'Withdrawals' &&  <Withdrawals />}
      {activeButton === 'Bonus history' && <BonusHistory />}
    </div>


  )
}