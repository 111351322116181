// bonusSubscribeSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type BonusSubscribeResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type BonusSubscribeError = {
  error: any;
  message: string;
};

interface BonusSubscribeState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: any[]; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: BonusSubscribeState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchBonusSubscribe
interface BonusSubscribeParams {
  token: string;
  bonusId: string;
  currencyId: number;
}

export const fetchBonusSubscribe = createAsyncThunk<
  BonusSubscribeResponse,
  BonusSubscribeParams,
  { rejectValue: BonusSubscribeError }
>(
  'BonusSubscribe/fetchBonusSubscribe',
  async ({ token, bonusId, currencyId }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchBonusSubscribe:', `${config.serverUrl}/player/bonus-subscribe`, { token, bonusId, currencyId });

      const response = await axios.post<BonusSubscribeResponse>(
        `${config.serverUrl}/player/bonus-subscribe`,
        { token, bonusId, currencyId },
        { headers: { 'Content-Type': 'application/json' } }
      );
      console.log('Attempting to fetchBonusSubscribe response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error bonus subscribe:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to bonus subscribe .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);



const BonusSubscribeSlice = createSlice({
  name: 'BonusSubscribe',
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBonusSubscribe.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchBonusSubscribe.fulfilled, (state, action: PayloadAction<BonusSubscribeResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchBonusSubscribe.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetState } = BonusSubscribeSlice.actions;

export const BonusSubscribeReducer = BonusSubscribeSlice.reducer;



// ---------
// Selectors
// ---------
export const selectBonusSubscribeLoading = (state: RootState) => state.BonusSubscribeReducer.loading;
export const selectBonusSubscribeSuccess = (state: RootState) => state.BonusSubscribeReducer.success;
export const selectBonusSubscribeError = (state: RootState) => state.BonusSubscribeReducer.error;
export const selectBonusSubscribeMessages = (state: RootState) => state.BonusSubscribeReducer.messages;
export const selectBonusSubscribeData = (state: RootState) => state.BonusSubscribeReducer.data;

export const selectBonusSubscribeErrorString = (state: RootState): string | null => {
  const error = state.BonusSubscribeReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectBonusSubscribeErrorArray = (state: RootState): string[] | null => {
  const error = state.BonusSubscribeReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
