import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import styles from './Security.module.scss'
import clsx from 'clsx'
import { SecurityElement, SecurityElementType } from "./SecurityElement/SecurityElement"
import Modal from "../../../layouts/ui/modal/Modal"
import React, { useEffect } from "react"
import SecurityPassword from "../SecurityPassword/SecurityPassword"
import SecurityPhoneVerification from "../SecurityPhoneVerification/SecurityPhoneVerification"
import SecurityEmailVerification from "../SecurityEmailVerification/SecurityEmailVerification"
import { SecuritySuccess } from "../SecurityPasswordSuccess/SecuritySuccess"
import { useAppDispatch, useAppSelector } from "../../../../hooks"

import {
  interfaceSelector,
  setIsShowPasswordModal,
  setPasswordSuccessModalShow,
  setIsShowPhoneNumberVerificationModal,
  setIsShowEmailVerificationModal
} from "../../../../redux/slices/interfaceSlice"

import {
  selectGetPlayerConfirmationData,
  selectGetPlayerConfirmationSuccess,
  selectGetPlayerConfirmationLoading,
  selectGetPlayerConfirmationError,
  fetchGetPlayerConfirmation
} from "../../../../redux/slices/player/getPlayerConfirmationSlice"

import {
  selectIsAuthenticated
} from "../../../../redux/slices/auth/isAuthenticatedSlice"
import {
  selectToken
} from "../../../../redux/slices/auth/loginSlice"

import { useSelector } from 'react-redux'

export const Security = () => {

  const dispatch = useAppDispatch()

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

  const {
    successModal,
    isShowPasswordModal,
    isShowPhoneNumberVerificationModal,
    isShowEmailVerificationModal
  } = useAppSelector(interfaceSelector)

  const getPlayerConfirmationData = useSelector(selectGetPlayerConfirmationData)
  const getPlayerConfirmationSuccess = useSelector(selectGetPlayerConfirmationSuccess)
  const getPlayerConfirmationLoading = useSelector(selectGetPlayerConfirmationLoading)
  const getPlayerConfirmationError = useSelector(selectGetPlayerConfirmationError)

  const passwordSuccessModalShow = successModal.show

  const elements: Array<SecurityElementType> = [
    {
      title: 'Change password',
      description: 'If you want to change your password, just simply click the button below',
      buttonText: 'Change password',
      onClick: () => dispatch(setIsShowPasswordModal(true))
    },
    {
      title: 'Two-factor authentication',
      description: 'Improve the security of your account by activating 2FA',
      buttonText: 'Setup 2FA',
      onClick: () => console.log('Setup 2FA')
    },
    {
      title: 'Phone number verification',
      description: 'You will receive an SMS on your phone',
      buttonText: 'Verify your phone number',
      onClick: () => dispatch(setIsShowPhoneNumberVerificationModal(true)),
      verified: getPlayerConfirmationSuccess && getPlayerConfirmationData?.flag_phone === "YES"
    },
    {
      title: 'Email verification',
      description: 'You will receive an message in email',
      buttonText: 'Verify your email',
      onClick: () => dispatch(setIsShowEmailVerificationModal(true)),
      verified: getPlayerConfirmationSuccess && getPlayerConfirmationData?.flag_email === "YES"
    }
  ]

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(fetchGetPlayerConfirmation({ token }));
    }

  }, [dispatch, token, isAuthenticated, isShowPhoneNumberVerificationModal, isShowEmailVerificationModal])

  useEffect(() => {
    if (getPlayerConfirmationError) {
      console.error('getPlayerConfirmationError', getPlayerConfirmationError)
    }

     if (getPlayerConfirmationData) {
      console.log('getPlayerConfirmationData', getPlayerConfirmationData)
    }

  }, [getPlayerConfirmationError, getPlayerConfirmationData])

  return (
    <div className={clsx(stylesCommon.profileRoundedTab, styles.security)}>
      {elements.map((element, index) => (
        <SecurityElement key={index} {...element} />
      ))}
      <Modal
        show={isShowPasswordModal}
        onClose={() => dispatch(setIsShowPasswordModal(false))}
        body={<SecurityPassword />}
        variant={'security'}
      />
      <Modal
        show={passwordSuccessModalShow}
        onClose={() => dispatch(setPasswordSuccessModalShow({ show: false, variant: 'password' }))}
        body={<SecuritySuccess />}
        variant={'success'}
      />

      <Modal
        show={isShowPhoneNumberVerificationModal}
        onClose={() => dispatch(setIsShowPhoneNumberVerificationModal(false))}
        body={<SecurityPhoneVerification onSuccess={() => dispatch(setIsShowPhoneNumberVerificationModal(false))} />}
        variant={'security'}
      />

      <Modal
        show={isShowEmailVerificationModal}
        onClose={() => dispatch(setIsShowEmailVerificationModal(false))}
        body={<SecurityEmailVerification onSuccess={() => dispatch(setIsShowEmailVerificationModal(false))} />}
        variant={'security'}
      />

    </div>
  )
}