//Documents.js
import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from "../../../hooks"
import {useNavigate} from 'react-router-dom'
import {connectWebSocketListDocuments, fetchListDocuments} from 'src/redux/actions/document/getListDocumentsActions'
import {
  selectListDocuments,
  selectListDocumentsError,
  selectListDocumentsLoading
} from 'src/redux/selectors/document/getListDocumentsSelectors'

import {
  connectWebSocketGetDocumentSettingTypes,
  documentSettingTypesActionsClearState,
  fetchDocumentSettingTypesActions
} from 'src/redux/actions/document/getDocumentSettingTypesActions'

import {
  selecttDocumentSettingTypes,
  selecttDocumentSettingTypesError,
  selecttDocumentSettingTypesLoading
} from 'src/redux/selectors/document/getDocumentSettingTypesSelectors'
import axios from 'axios'
import config from '../../../config'
import {selectIsAuthenticated} from "../../../redux/slices/auth/isAuthenticatedSlice"
import {selectToken} from "../../../redux/slices/auth/loginSlice"
import clsx from 'clsx'
import stylesCommon from "../../../common/styles/commonStyles.module.scss"
import styles from './Documents.module.scss'
import {ButtonStyled} from "../../../common/ButtonStyled/ButtonStyled"
import SelectInput from '../auth/formComponents/SelectInput/SelectInput'
import {SelectChangeEvent} from "@mui/material"
import {Document, DocumentsElement} from "./DocumentsElement/DocumentsElement"
import {useTranslation} from "react-i18next"
import {FileUpload} from "./FileUpload/FileUpload"
import {getNumberFromPx} from "../../../common/helpers"

const Documents = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const fileRef = useRef(null);

  const listDocuments = useSelector(selectListDocuments)
  const listDocumentsLoading = useSelector(selectListDocumentsLoading)
  const listDocumentsError = useSelector(selectListDocumentsError)

  const documentSettingTypes = useSelector(selecttDocumentSettingTypes)
  const documentSettingTypesLoading = useSelector(selecttDocumentSettingTypesLoading)
  const documentSettingTypesError = useSelector(selecttDocumentSettingTypesError)

  const { t } = useTranslation()

  // Пример. Потом ошибки и загрузку выводим из хранилища.
  // const loading = useSelector(selectLoading);
  // const error = useSelector(selectError);

  const loading = false

  const [file, setFile] = useState<File | null>(null)
  const [doctype, setDoctype] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [imageLoadStatus, setImageLoadStatus] = useState<Record<string | number, boolean>>({})
  const [uploadHeight, setUploadHeight] = useState(0)
  const [yourDocumentsHeight, setYourDocumentsHeight] = useState(0)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

useEffect(() => {
    console.log('Documents documentSettingTypes selectors error', documentSettingTypesError)
    console.log('Documents documentSettingTypes selectors loADING', documentSettingTypesLoading)
  }, [documentSettingTypesError, documentSettingTypesLoading])

  useEffect(() => {
    if (isAuthenticated) {
      getData(token)
      // dispatch(fetchListDocuments(token))
      const closeWebSocketListDocuments = dispatch(connectWebSocketListDocuments(token))

      // dispatch(fetchDocumentSettingTypesActions(token));
      const closeWebSocketGetDocumentSettingType = dispatch(connectWebSocketGetDocumentSettingTypes(token))

      return () => {
        closeWebSocketListDocuments()
        closeWebSocketGetDocumentSettingType()
      }
    }
  }, [dispatch, token, isAuthenticated])

  const getData = (token: string): void => {
    dispatch(fetchListDocuments(token));
    dispatch(fetchDocumentSettingTypesActions(token));
  }

  // Measure upload & other heights
  const uploadRef = useRef<HTMLDivElement>(null)
  const yourDocumentsTitleRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (uploadRef.current) {
      setUploadHeight(uploadRef.current.clientHeight)
    }

    if (yourDocumentsTitleRef.current) {
      setYourDocumentsHeight(yourDocumentsTitleRef.current.clientHeight)
    }
  }, [uploadRef, yourDocumentsTitleRef, listDocuments])

  // also getting small padding to calculate the height to minus
  const rootStyles = getComputedStyle(document.body)
  const standardSmallPadding = getNumberFromPx(rootStyles.getPropertyValue('--standard-small-padding'))
  const paddingRecalculation = getNumberFromPx(rootStyles.getPropertyValue('--standart-block-padding')) - getNumberFromPx(rootStyles.getPropertyValue('--standard-small-padding'))

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null
    setSuccess(null)
    console.log('handleFileChange file', file)
    if (file) {
      setFile(file)
    }
  }

  const handleDoctypeChange = (event: SelectChangeEvent<string>) => {
    setSuccess(null)
    setDoctype(String(event.target.value))
    // console.log(event.target.value)
  }

  const handleSubmit = async () => {
    if (!file || !doctype) {
      setError('Please select a file and document type.')
      return
    }

    setError(null)
    setSuccess(null)

    const formData = new FormData()
    formData.append('document', file)
    formData.append('doctype', doctype)

    try {
      const response = await axios.post(`${config.serverUrl}/document/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `${token}`,
        },
      })
      console.log(response.data.success)
      console.log(response.data.errors)
      if (response.data.success) {
        setFile(null) // Сброс инпут файла
        setDoctype('') // Сброс инпут типа документа
        if (fileRef.current) {
          (fileRef.current as HTMLInputElement).value = '';
          // event.target.files?.[0]
        }
        console.log('Documents documentSettingTypes selectors CLEAR START')
        dispatch(documentSettingTypesActionsClearState())
        console.log('Documents documentSettingTypes selectors CLEAR END')

        // window.location.reload()
        getData(token)
        setSuccess('File uploaded successfully!')
      } else {
        setError(response.data.errors ? JSON.stringify(response.data.errors) : 'Unknown error occurred.')
      }
    } catch (error) {
      setError('An error occurred during the upload.')
      console.log('An error occurred during the upload.', error)
    }
  }

  // Это для проверки того, чтобы если урл картинки есть, а в папке ее не существует, выводить сообщение.
  const handleImageLoad = (docId: string | number) => {
    setImageLoadStatus(prevStatus => ({ ...prevStatus, [docId]: true }))
  }

  const handleImageError = (docId: string | number) => {
    setImageLoadStatus(prevStatus => ({ ...prevStatus, [docId]: false }))
  }


  return (
    <>
      <div className={clsx(stylesCommon.profileRoundedTab, styles.documents)}>
        {/* download file block */}
        <div className={styles.upload} ref={uploadRef}>
          <h1 className={styles.title}>{t('Upload')}</h1>
          <FileUpload ref={fileRef} handleFileChange={handleFileChange} clear={success !== null} />
          <div className={styles.selectContainer}>
            <SelectInput
              value={doctype}
              onChange={handleDoctypeChange}
              id="doctype-select"
              name="doctype"
                placeholder={t('Select document type')}
                options={documentSettingTypes.map((type) => ({
                id: type.id,
                name: t(type.name),
              }))}
              variant="verification"
            />
            {documentSettingTypesLoading && <div>{t('Loading...')}</div>}
            {documentSettingTypesError && <div>{t('Error')}: {documentSettingTypesError}</div>}
            {documentSettingTypes.length === 0 && !documentSettingTypesLoading && !documentSettingTypesError && (
              <div>{t('No data')}</div>
            )}
          </div>

          <ButtonStyled onClick={handleSubmit} variant={'primary'} isWidth100={true} paddingVariant={'14px 18px'}
            height={'48px'} className={styles.button}>{t('Upload')}</ButtonStyled>
          {error && <div style={{ color: 'red' }}>{error}</div>}
          {success && <div style={{ color: 'green' }}>{success}</div>}
          <p></p>

          <div>
            {t('Only JPG, PNG files up to 10 MB can be uploaded.')}
            <br />
            <br />
            {t('Must be a Passport, Driving License or other official Government issued ID Card.')} {t('Both Front and Back of ID must be received.')}
          </div>
        </div>


        <div className={styles.yourDocuments}>
          <div className={styles.yourDocumentsTitle} ref={yourDocumentsTitleRef}>
            {t('Your documents')}
          </div>
          <div className={styles.documentsContainer}>
            {listDocumentsLoading ? (
              <p>{t('Loading...')}</p>
            ) : listDocumentsError ? (
              <p>{t('Error')}: {listDocumentsError}</p>
            ) : listDocuments.length > 0 ? (
              listDocuments.map((doc: Document, i) => (
                <DocumentsElement
                  key={doc.id}
                  doc={doc}
                  imageLoadStatus={imageLoadStatus}
                  handleImageLoad={handleImageLoad}
                  handleImageError={handleImageError}
                  minHeight={uploadHeight - yourDocumentsHeight - standardSmallPadding - paddingRecalculation}
                />
              ))
            ) : (
              <div className="col-4">
                <p>{t('No documents')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

// export default withAuth(Documents);
export default Documents