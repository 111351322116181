// paymentMethodsWithdrawalViewsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface PaymentMethodsWithdrawalViewsState {
  views: string[];
  currentView: string;
  previousView: string | null;
}

const initialState: PaymentMethodsWithdrawalViewsState = {
  views: ['CurrencyAndPayment'],
  currentView: 'CurrencyAndPayment',
  previousView: null,
};

const getPreviousView = (currentView: string, views: string[]): string | null => {
  const currentIndex = views.indexOf(currentView);
  return currentIndex > 0 ? views[currentIndex - 1] : null;
};

const getNextView = (currentView: string, views: string[]): string => {
  const currentIndex = views.indexOf(currentView);
  return currentIndex >= 0 && currentIndex < views.length - 1 ? views[currentIndex + 1] : currentView;
};

const paymentMethodsWithdrawalViewsSlice = createSlice({
  name: 'paymentMethodsWithdrawalViewsSlice',
  initialState,
  reducers: {
    setCurrentView(state, action: PayloadAction<string>) {
      state.previousView = state.currentView;
      state.currentView = action.payload;
    },
    addView(state, action: PayloadAction<string>) {
      if (!state.views.includes(action.payload)) {
        state.views.push(action.payload);
      }
    },
    navigateToPreviousView(state) {
      const previousView = getPreviousView(state.currentView, state.views);
      if (previousView) {
        state.previousView = state.currentView;
        state.currentView = previousView;
      }
    },
    navigateToNextView(state) {
      const nextView = getNextView(state.currentView, state.views);
      if (nextView !== state.currentView) {
        state.previousView = state.currentView;
        state.currentView = nextView;
      }
    },
  },
});

export const {
  setCurrentView,
  addView,
  navigateToPreviousView,
  navigateToNextView,
} = paymentMethodsWithdrawalViewsSlice.actions;

export const selectCurrentView = (state: RootState) => state.PaymentMethodsWithdrawalViewsReducer.currentView;
export const selectPreviousView = (state: RootState) => state.PaymentMethodsWithdrawalViewsReducer.previousView;
export const selectViews = (state: RootState) => state.PaymentMethodsWithdrawalViewsReducer.views;

export const PaymentMethodsWithdrawalViewsReducer = paymentMethodsWithdrawalViewsSlice.reducer;
