// setWithdrawalRequestSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type SetWithdrawalRequestResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type SetWithdrawalRequestError = {
  error: any;
  message: string;
};

interface SetWithdrawalRequestState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: any[]; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: SetWithdrawalRequestState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchSetWithdrawalRequest
interface SetWithdrawalRequestParams {
  token: string;
  params?: {};
}

export const fetchSetWithdrawalRequest = createAsyncThunk<
  SetWithdrawalRequestResponse,
  SetWithdrawalRequestParams,
  { rejectValue: SetWithdrawalRequestError }
>(
  'SetWithdrawalRequest/fetchSetWithdrawalRequest',
  async ({ token, params }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchSetWithdrawalRequest:', `${config.serverUrl}/payment/set-withdrawal-request`, { token, params });

      const response = await axios.post<SetWithdrawalRequestResponse>(
        `${config.serverUrl}/payment/set-withdrawal-request`,
        { token, params },
        { headers: { 'Content-Type': 'application/json' } }
      );
      console.log('Attempting to fetchSetWithdrawalRequest response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error set withdrawal request:', error);

      if (axios.isAxiosError(error) && error.response) {
        // Извлечение нужных данных из ошибки
        const { errors, message, success } = error.response.data;

        return rejectWithValue({
          error: errors || null, // Извлекаем только ошибки
          message: message || 'Failed to set withdrawal request.', // Сообщение
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);


const SetWithdrawalRequestSlice = createSlice({
  name: 'SetWithdrawalRequest',
  initialState,
  reducers: {
    resetStateSetWithdrawalRequest: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSetWithdrawalRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchSetWithdrawalRequest.fulfilled, (state, action: PayloadAction<SetWithdrawalRequestResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchSetWithdrawalRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateSetWithdrawalRequest } = SetWithdrawalRequestSlice.actions;

export const SetWithdrawalRequestReducer = SetWithdrawalRequestSlice.reducer;



// ---------
// Selectors
// ---------
export const selectSetWithdrawalRequestLoading = (state: RootState) => state.SetWithdrawalRequestReducer.loading;
export const selectSetWithdrawalRequestSuccess = (state: RootState) => state.SetWithdrawalRequestReducer.success;
export const selectSetWithdrawalRequestError = (state: RootState) => state.SetWithdrawalRequestReducer.error;
export const selectSetWithdrawalRequestMessages = (state: RootState) => state.SetWithdrawalRequestReducer.messages;
export const selectSetWithdrawalRequestData = (state: RootState) => state.SetWithdrawalRequestReducer.data;

export const selectSetWithdrawalRequestErrorString = (state: RootState): string | null => {
  const error = state.SetWithdrawalRequestReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectSetWithdrawalRequestErrorArray = (state: RootState): string[] | null => {
  const error = state.SetWithdrawalRequestReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
