// balancesSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';
import {DISABLE_MISC_LOGS} from "../../../common/constants"


export interface BalanceItem {
  amount: number;
  currency: string;
  currency_symbol: string | null;
}

export interface Balances {
  main_balance: BalanceItem;
  bonus_balance: BalanceItem;
}

export type BalancesList = Balances[] | [];

type BalancesSliceResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type BalancesSliceError = {
  error: any;
  message: string;
};

interface BalancesSliceState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: any[]; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: BalancesSliceState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchBalances
interface BalancesSliceParams {
  token: string;
  params?: {};
}

export const fetchBalances = createAsyncThunk<
  BalancesSliceResponse,
  BalancesSliceParams,
  { rejectValue: BalancesSliceError }
>(
  'BalancesSlice/fetchBalances',
  async ({ token, params = {} }, { rejectWithValue }) => {
    try {
      !DISABLE_MISC_LOGS && console.log('Attempting to fetchBalances:', `${config.serverUrl}/player/balances`, { token, params });

      const response = await axios.get<BalancesSliceResponse>(
        `${config.serverUrl}/player/balances`,
        {
          params: {
            ...params, // Ваши дополнительные параметры
            token,     // Токен авторизации как часть query-параметров
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      !DISABLE_MISC_LOGS && console.log('Attempting to fetchBalances response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error get list balances:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to get BalancesSlice .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);



const BalancesSliceSlice = createSlice({
  name: 'Profile/BalancesSlice',
  initialState,
  reducers: {
    resetStateListBalances: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalances.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchBalances.fulfilled, (state, action: PayloadAction<BalancesSliceResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchBalances.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateListBalances } = BalancesSliceSlice.actions;

export const BalancesSliceReducer = BalancesSliceSlice.reducer;



// ---------
// Selectors
// ---------
export const selectBalancesSliceLoading = (state: RootState) => state.BalancesSliceReducer.loading;
export const selectBalancesSliceSuccess = (state: RootState) => state.BalancesSliceReducer.success;
export const selectBalancesSliceError = (state: RootState) => state.BalancesSliceReducer.error;
export const selectBalancesSliceMessages = (state: RootState) => state.BalancesSliceReducer.messages;
export const selectBalancesSliceData = (state: RootState) => state.BalancesSliceReducer.data;

export const selectBalancesSliceErrorString = (state: RootState): string | null => {
  const error = state.BalancesSliceReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectBalancesSliceErrorArray = (state: RootState): string[] | null => {
  const error = state.BalancesSliceReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
