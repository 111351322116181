import React, {useEffect, useRef, useState} from 'react'
import {useGetFaqQuery} from '../../../api/api'
import {FaqItem} from '../../../api/types'
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {interfaceSelector, setFaqPageHeaderContainerHeight} from "../../../redux/slices/interfaceSlice"
import styles from './Faq.module.scss'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import clsx from 'clsx'

import {PageHeader} from "../../../common/PageHeader/PageHeader"
import {FaqTermsTemplate} from "../../../common/FaqTermsTemplate/FaqTermsTemplate"
import {MobileStatus} from "../../../common/MobileStatus/MobileStatus"
import {useTranslation} from "react-i18next"

export const Faq = () => {
  const {selectedLanguage} = useAppSelector(interfaceSelector) // Current selected language
  const {data, error, isLoading} = useGetFaqQuery(selectedLanguage.id) // Fetch FAQs based on language
  const [selectedFaq, setSelectedFaq] = useState<FaqItem | null>(null) // Track selected FAQ
  const {isMobile} = useAppSelector(interfaceSelector)

  const faqs = data?.data || [] // Extract FAQ data or set empty array

  const dispatch = useAppDispatch()

  // Measure Height
  const elementRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (elementRef.current) {
      dispatch(setFaqPageHeaderContainerHeight(elementRef.current.clientHeight)) // setting element height
    }
  }, [isLoading])

  useEffect(() => {
    if (faqs.length > 0 && !selectedFaq) {
      setSelectedFaq(faqs[0]) // Set the first FAQ as selected
    }
  }, [faqs, selectedFaq])

  const {t} = useTranslation()

  if (isLoading) return <p>Loading FAQs, please wait...</p>
  if (error) return <p>Error fetching FAQs: {error.toString()}</p>


  return (
    <>
      {isMobile && <MobileStatus variant={'game'} title={t('FAQ')}/>}
      <div className={clsx(styles.faqContainer, stylesCommon.pageContainer)}>
        {!isMobile && <div className={styles.faqPageHeaderContainer}
                           ref={elementRef}> {/* this container for more simple height measure*/}
            <PageHeader title={'FAQ'} className={styles.pageHeader}/>
        </div>}
        <FaqTermsTemplate items={faqs} selectedItem={selectedFaq}
                          setSelectedItem={(item) => setSelectedFaq(item as FaqItem)}
        />
      </div>
    </>
  )
}
