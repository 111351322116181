import React, {ReactNode} from "react"
import styles from './NavigationButtonsContainer.module.scss'
import clsx from 'clsx'

export type NavigationButtonsVariant = 'dropdown' | 'profile' | 'profileMain'

type Props = {
  children: ReactNode
  variant?: NavigationButtonsVariant
}

export const NavigationButtonsContainer = ({children, variant}: Props) => {
  return (
    <div className={clsx(
      {
        [styles.navigationButtonsContainer]: variant === 'dropdown' || variant === 'profile',
        [styles.navigationButtonsContainerProfileMain]: variant === 'profileMain',

      },

      {
        [styles.profileVariant]: variant === 'profile',
        [styles.navigationButtonsContainerProfileMain]: variant === 'profileMain',
      }

      )}>
      {children}
    </div>
  )
}