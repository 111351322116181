import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LayoutWrapper from '../components/layouts/LayoutWrapper/LayoutWrapper.tsx';

import {
    AuthPage,
    BasePage,
    Bonuses,
    BonusHistory,
    Documents,
    FavoriteGames,
    Home,
    Notifications,
    PersonalInformation,
    Provider,
    ResponsibleGaming,
    VipClub
} from '../components/pages'
import { Home as HomeGuest } from '../components/pages/guest'
import { useSelector } from 'react-redux'
import { useAppDispatch } from "../hooks"
// import { AppDispatch } from '../redux/store';
import StateLogger from '../helpers/components/StateLogger'
import { Faq } from "../components/pages/Faq/Faq.tsx"
import { NotFound } from "../components/pages/NotFound/NotFound"
import { Page } from "../components/pages/Page/Page"
import { Game } from "../components/pages/Game/Game"
import { SeoUpdater } from "../common/SeoUpdater"
import { SeoRedirecter } from "../common/SeoRedirecter"
import { PageList } from "../components/pages/PageList/PageList"
import { createTheme, ThemeProvider } from "@mui/material"
import { SessionTransaction } from "../components/pages/Transactions/GameActivity/SessionTransaction/SessionTransaction"
import 'src/common/styles/index.scss'
import styles from './App.module.scss'
import { Games } from "../components/pages/Games/Games";
import { GameGroup } from "../components/pages/GameGroup/GameGroup";
import { fetchIp } from '../redux/slices/ipSlice.ts';
import { Account } from "../components/pages/Account/Account";
import { Terms } from "../components/pages/Terms/Terms";
import { Sheepy } from "../components/pages/Redirects/sheepy/Sheepy.tsx";
import { RestorePasswordSuccess } from "../components/pages/Redirects/restorePasswordSuccess/RestorePasswordSuccess.tsx";

import {
    webSocketGetIsAuthenticated,
    selectIsAuthenticated,
    resetStateAuthenticated,
    fetchIsAuthenticated
} from "../redux/slices/auth/isAuthenticatedSlice.ts"

import {
    selectToken,
    resetStateLogin
} from "../redux/slices/auth/loginSlice.ts"

import {
    clearAuthState
} from "../redux/slices/auth/logoutSlice.ts"
import {DISABLE_MISC_LOGS} from "../common/constants";

function App() {

    const dispatch = useAppDispatch()
    const token = useSelector(selectToken);
    const isAuthenticated = useSelector(selectIsAuthenticated);

    // state for MUI theme
    const [theme, setTheme] = useState(createTheme({
        typography: {
            button: {
                textTransform: 'none'
            }
        }
    }))


    useEffect(() => {
        !DISABLE_MISC_LOGS && console.log('Before dispatch(fetchIp)');
        dispatch(fetchIp());
        !DISABLE_MISC_LOGS && console.log('After dispatch(fetchIp)');
    }, [dispatch]);

    useEffect(() => {
        if (token) {
            !DISABLE_MISC_LOGS && console.log('App auth: token', token);

            dispatch(fetchIsAuthenticated(token)).then((res) => {
                !DISABLE_MISC_LOGS && console.log('App auth: App fetchIsAuthenticated res', res);

                // Если данные в ответе true, то пользователь есть
                if (res.payload.success && res.payload.data === true) {
                    !DISABLE_MISC_LOGS && console.log('App auth: User is authenticated');
                } else {
                    console.log('App auth: User is not authenticated');
                    clearAuthState(dispatch);
                }
            });

            const closeWebSocketGetIsAuthenticated = dispatch(
                webSocketGetIsAuthenticated(token, (isAuthenticated) => {
                    !DISABLE_MISC_LOGS && console.log('App auth: WebSocket isAuthenticated', isAuthenticated);
                    if (!isAuthenticated) {
                        console.log('App auth: WebSocket auth check failed, clearing auth state...');
                        clearAuthState(dispatch);
                    }
                })
            );

            return () => {
                closeWebSocketGetIsAuthenticated();
            };
        } else {
            // Если токен отсутствует, сразу сбрасываем стейт
            clearAuthState(dispatch);
        }
    }, [token]);


    //инициализация селекторов
    //const bonusList = useSelector(selectBonusList)
    // const listFavoriteGames = useSelector(selectListFavoriteGames)

    useEffect(() => {
        // В момент загрузки страницы
        // Токен сохраняется при удачном логине в локальном хранилище и в редаксе. Если страницу обновить,
        // то в редаксе теряется токен, поэтому он снова тут устанавливается, если он есть в локалсторе.
        // Таким образом при обновлении страницы пользователь не разлогинивается

        // if (storedToken) {
        //   dispatch(fetchLoginSuccess(storedToken))
        // }

        localStorage.setItem('groupId', '9')

        // add dark theme always
        // TODO: Implement theme toggle using a button in the future
        document.body.classList.add('dark-theme');
        document.body.classList.remove('light-theme');

        const styles = getComputedStyle(document.body);
        const primaryTextColor = styles.getPropertyValue('--text-base-primary');
        const secondaryTextColor = styles.getPropertyValue('--text-base-secondary');

        const updatedTheme = createTheme({
            palette: {
                text: {
                    primary: primaryTextColor,
                    secondary: secondaryTextColor,
                },
            },
            typography: {
                button: {
                    textTransform: 'none',
                },
            },
        });

        setTheme(updatedTheme);

    }, [])


    // const BasePageRoute = ({registered: RegisteredComponent, guest: GuestComponent}) => (
    //     <BasePage>
    //         {isAuthenticated ? <RegisteredComponent/> : <GuestComponent/>}
    //     </BasePage>
    // );

    const BasePageRoute = ({ registered: RegisteredComponent, guest: GuestComponent }) => {
        if (isAuthenticated) {
            return (
                <BasePage>
                    <RegisteredComponent />
                </BasePage>
            );
        }

        // Если `GuestComponent` — это `<Navigate />`, рендерим его для редиректа,
        // иначе — рендерим как обычный компонент для гостя.
        return React.isValidElement(GuestComponent) && GuestComponent.type === Navigate ? GuestComponent : (
            <BasePage>
                <GuestComponent />
            </BasePage>
        );
    };

    return (
        <div className={styles.app}>
            <StateLogger />
            <BrowserRouter>
                <Routes>
                    {/* Маршрут для страницы 404 */}
                    <Route path="/404" element={<NotFound />} />

                    {/* Основные маршруты с LayoutWrapper */}
                    <Route
                        path="*"
                        element={
                            <LayoutWrapper>
                                <SeoRedirecter>
                                    <SeoUpdater>
                                        <ThemeProvider theme={theme}>
                                            <Routes>
                                                {/* Все твои маршруты */}
                                                <Route path="/" element={<BasePageRoute registered={Home} guest={HomeGuest} />} />
                                                <Route path="/auth" element={<AuthPage />} />
                                                <Route path="/auth/:param?" element={<AuthPage />} />
                                                <Route path="/notifications" element={<BasePageRoute registered={Notifications} guest={<Navigate to="/auth" />} />} />
                                                <Route path="/favorite-games" element={<BasePageRoute registered={FavoriteGames} guest={<Navigate to="/auth" />} />} />
                                                <Route path="/personal-information" element={<BasePageRoute registered={PersonalInformation} guest={<Navigate to="/auth" />} />} />
                                                <Route path="/documents" element={<BasePageRoute registered={Documents} guest={<Navigate to="/auth" />} />} />
                                                <Route path="/transactions/:transactionId" element={<BasePageRoute registered={SessionTransaction} guest={<Navigate to="/auth" />} />} />
                                                <Route path="/bonus-history" element={<BasePageRoute registered={BonusHistory} guest={<Navigate to="/auth" />} />} />
                                                <Route path="/responsible-gaming" element={<BasePageRoute registered={ResponsibleGaming} guest={<Navigate to="/auth" />} />} />
                                                <Route path="/bonuses" element={<Bonuses />} />
                                                <Route path="/vip" element={<BasePageRoute registered={VipClub} guest={<Navigate to="/auth" />} />} />
                                                <Route path={'/games'} element={<Games />} />
                                                <Route path="/provider/:id" element={<BasePageRoute registered={Provider} guest={<Navigate to="/auth" state={{ fromRedirect: true }} />} />} />
                                                <Route path="/game-group/:gameGroupSlug" element={<GameGroup />} />
                                                <Route path="/faq" element={<Faq />} />
                                                <Route path="/page/:slug" element={<Page />} />
                                                {/* <Route path="/sheepy/success/:reference" element={<Sheepy />} /> */}
                                                <Route path="/sheepy/success" element={<Sheepy />} />{/* /sheepy/success?reference=fc508e73562d9d0f9dced4827f7fc357 */}
                                                <Route path="/restore-password-success" element={<RestorePasswordSuccess />} />
                                                <Route path="/games/:slug" element={<Game />} />
                                                <Route path="/pagelist" element={<PageList />} />
                                                <Route path="/profile" element={<BasePageRoute registered={Account} guest={<Navigate to="/auth" state={{ fromRedirect: true }} />} />} />
                                                {/* <Route path="/profile" element={<Account />} /> */}

                                                <Route path="/terms" element={<Terms />} />
                                                {/* If route not found, redirect to 404 */}
                                                <Route path="*" element={<Navigate to="/404" />} />
                                            </Routes>
                                        </ThemeProvider>
                                    </SeoUpdater>
                                </SeoRedirecter>
                            </LayoutWrapper>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App 
