import styles from './PromoInput.module.scss'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from "../../../../../../hooks"
import info_circle from 'src/assets/images/icons/info_circle.svg'
import check from "src/assets/images/icons/check_green.svg"
import {
  interfaceSelector,
  setSuccessPopup
} from "../../../../../../redux/slices/interfaceSlice"

import {
  selectToken
} from "../../../../../../redux/slices/auth/loginSlice"

import {
  selectPromocodeData,
  selectPromocodeSuccess,
  selectPromocodeError,
  selectPromocodeMessages,
  fetchPromocode,
  resetStatePromocode
} from '../../../../../../redux/slices/Bonus/promocodeSlice'
import { SassString } from 'sass';

type ClientError = {
  [groupKey: string]: { // Общий индекс, который допускает любые верхнеуровневые ключи
    [fieldName: string]: { // Динамические ключи внутри групп
      hasError: boolean;
      message: string;
    };
  };
}

type ClientErrors = ClientError | null;

type FormState = {
  code: string;
}

export const PromoInput = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { successPopup } = useAppSelector(interfaceSelector)

  const promocodeData = useSelector(selectPromocodeData)
  const promocodeSuccess = useSelector(selectPromocodeSuccess)
  const promocodeError = useSelector(selectPromocodeError)

  const token = useSelector(selectToken)

  const initFormState = {
    code: ''
  }

  const [formState, setFormState] = useState<FormState>(initFormState)

  const initClientErrors = {
    required: {
      'code': {
        hasError: false,
        message: 'This field is required.'
      },
    },
    // email: {
    //   'email': {
    //     hasError: false,
    //     message: 'This field most be valid email.'
    //   },
    // }
  };

  const [clientErrors, setClientErrors] = useState<ClientErrors>(initClientErrors);
  const [isActiveButton, setIsActiveButton] = useState<boolean>(false);

  // useEffect(() => {
  //   dispatch(setSuccessPopup({ show: true, text: 'Your promocode has been activated' }));
  // }, [dispatch]);


  useEffect(() => {

    if (checkErrors(clientErrors) || !formState.code.trim()) {
      setIsActiveButton(false);
    } else {
      setIsActiveButton(true);
    }
  }, [clientErrors, formState]);

  useEffect(() => {
    if (promocodeSuccess) {
      console.info('PromoInput promocodeSuccess', promocodeSuccess)
      dispatch(setSuccessPopup({ show: true, text: 'Your promocode has been activated' }));
    }

    if (promocodeError) {
      console.error('PromoInput promocodeError', promocodeError)
    }
  }, [promocodeSuccess, promocodeError])

  const handleInputChange = (code: string, e: ChangeEvent<HTMLInputElement>) => {

    const value = e.target.type === 'checkbox' ? (e.target as HTMLInputElement).checked : e.target.value;

    const updatedFormState = {
      ...formState,
      [code]: value,
    }
    setFormState(updatedFormState)
    // setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))

    // Обновляем ошибки
    updateFieldError(code, value);
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // dispatch(clearErrorsLogin())
    const code = formState.code

    if (clientValidation() && token) {
      dispatch(fetchPromocode({ token, code }))
    } else {
      console.error('PromoInput Validation failed')
    }
  }


  const clientValidation = (): boolean => {
    const formElements = formRef.current?.elements
    let formIsValid = true

    if (formElements) {
      Array.from(formElements).forEach(field => {
        const input = field as HTMLInputElement
        const inputName = input.name;
        const inputValue = input.value.trim();

        updateFieldError(inputName, inputValue);

        if (checkErrors(clientErrors)) {
          formIsValid = false;
        }

      })
    }

    // formIsValid ? setIsActiveButton(true) : setIsActiveButton(false);

    return formIsValid
  }

  const updateFieldError = (fieldName: string, value: string | boolean) => {
    setClientErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      // Проверка required
      if (updatedErrors.required?.hasOwnProperty(fieldName)) {
        const isFieldFilled = typeof value === 'boolean' ? value : (typeof value === 'string' && value.trim() !== '');
        updatedErrors.required[fieldName].hasError = !isFieldFilled;
      }

      // // email equal
      // if (updatedErrors.email?.hasOwnProperty(fieldName)) {
      //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      //   const isValidEmail = typeof value === 'string' && emailRegex.test(value);
      //   updatedErrors.email[fieldName].hasError = !isValidEmail;
      // }

      return updatedErrors;
    });
  };


  const checkErrors = (errors: ClientErrors): boolean => {
    let hasErrors = false;

    if (errors) {
      Object.keys(errors).forEach((key) => {
        const fieldGroup = errors[key];

        Object.keys(fieldGroup).forEach((fieldKey) => {
          const field = fieldGroup[fieldKey]; // Получаем объект поля (например, { hasError, message })
          if (field.hasError) {
            hasErrors = true; // Если есть ошибка, устанавливаем флаг
            console.log(`Error found in field: ${fieldKey}, message: ${field.message}`);
          }
        });
      });
    }
    return hasErrors;
  };

  const getFieldClientErrorMessages = (field: string) => {
    for (const key in clientErrors) {
      if (clientErrors[key][field]?.hasError) {
        return clientErrors[key][field].message;
      }
    }
    return null;
  };

  const getFieldServerErrorMessages = (field: string) => {
    if (promocodeError && promocodeError[field]) {
      return promocodeError[field];
    }
    return null;
  };

  const getCommonServerErrors = () => {
    let inputs: string[] = [];
    let commonErrors: string[] = [];
    let serverErrors = promocodeError;

    if (serverErrors) {
      const formElements = formRef.current?.elements;

      if (formElements) {
        Array.from(formElements).forEach(field => {
          const input = field as HTMLInputElement;
          const inputName = input.name;
          inputs.push(inputName);
        });
      }

      Object.keys(serverErrors).forEach(key => {
        if (!inputs.includes(key)) {
          commonErrors.push(serverErrors[key]);
        }
      });
    }

    return commonErrors.length > 0 ? commonErrors : false;
  }


  const error: (string | string[] | null) = getFieldClientErrorMessages("code") || getFieldServerErrorMessages("code");
  const commonErrors = getCommonServerErrors();


  return (
    <div>
      <form onSubmit={handleSubmit} ref={formRef}>
        <div
          className={`${styles.promoInput} 
          ${promocodeSuccess ? styles.promoInputSuccess : isActiveButton ? styles.promoInputActive : ''}`}
        >

          <div className={styles.inputWrap}>
            <input
              name="code"
              value={formState.code}
              onChange={(e) => handleInputChange('code', e)}
              className={styles.input}
              placeholder={t('Enter a promo code')
              } />
            <img src={check} alt="check_code" className={styles.checkedImg} />
          </div>
          <button className={`${styles.button}`}>
            {t('Apply')}
          </button>

        </div>
        {error
          && (
            <div className={styles.errorWrapper}>
              <img src={info_circle} alt="info_circle" />
              <div className={styles.error}>
                {Array.isArray(error)
                  ? error.map((errMsg, index) => <div key={index}>{errMsg}</div>)
                  : <div>{error}</div>
                }
              </div>
            </div>

          )}

        {commonErrors && (
          <div className={styles.errorWrapper}>
            <img src={info_circle} alt="info_circle" />
            <div className={styles.error}>
              {Array.isArray(commonErrors)
                ? commonErrors.map((errMsg, index) => <div key={index}>{errMsg}</div>)
                : <div>{commonErrors}</div>
              }
            </div>
          </div>
        )}
      </form>
    </div>

  )
}