import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import styles from './GettingStarted.module.scss'
import React from "react"
import {StartTask, StartTaskProps} from "./StartTask/StartTask"
import {openModal, selectIsShowModal} from "src/redux/slices/modals/modalPlayerDepositSlice"
import {useAppDispatch} from "src/hooks"
import {useSelector} from 'react-redux'
import {useVerifyClick} from "../../../../../common/hooks/useVerifyClick"
import {useTranslation} from "react-i18next";



export const GettingStarted = () => {
  const dispatch = useAppDispatch()
  const isShowModal = useSelector(selectIsShowModal)

  const { onVerifyClick } = useVerifyClick()
  const {t} = useTranslation()

  const tasks: Array<StartTaskProps> = [
    {text: 'Open an account', isCompleted: true},
    {text: 'Verify email', isCompleted: false},
    {text: 'Make a first deposit', isCompleted: false},
    {text: 'Play with real money', isCompleted: false, link: '/games'},
    {text: 'Verify profile', isCompleted: false, onClick: onVerifyClick},
  ]

  return (
    <div className={stylesCommon.accountElement}>
      <div className={stylesCommon.header}>{t('Getting started')}</div>
      <div className={styles.tasksGroup}>
        {tasks.map((task: StartTaskProps, index: number) => {
          return task.text === 'Make a first deposit' ? 
            (<StartTask
              key={index}
              text={t(task.text)}
              isCompleted={task.isCompleted}
              onClick={() => dispatch(openModal())} 
            />)
            :
            (<StartTask
              key={index}
              text={t(task.text)}
              isCompleted={task.isCompleted}
              link={task.link}
              onClick={task.onClick}
            />)
          })}
      </div>
    </div>
  )
}