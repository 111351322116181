import {useNavigate} from "react-router-dom"
import {useAppDispatch} from "../../hooks"
import {useSelector} from "react-redux"
import {selectToken} from "src/redux/slices/auth/loginSlice"
import {clearChildrenMenuData} from "src/redux/slices/interfaceSlice"
import {setShowModalMyProgress} from "src/redux/actions/player/myProgressActions"
import {fetchLogout} from "../../redux/slices/auth/logoutSlice"

export const useLogout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const token = useSelector(selectToken)

  return async () => {
    dispatch(clearChildrenMenuData())
    dispatch(setShowModalMyProgress(false))
    await dispatch(fetchLogout(token as string))
    navigate('/auth')
  }
}