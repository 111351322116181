import React, {useEffect, useState} from 'react'
import {format} from 'date-fns'
import {DayPicker, DayPickerVariant} from './DayPicker'
import styles from './DataInput.module.scss'
import stylesCommon from "src/common/styles/commonStyles.module.scss"

type TextInputProps = {
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  placeholder: string;
  error?: string | string[] | null;
  variant?: DayPickerVariant
  label?: string
  isOpen?: boolean // Controls whether the calendar is opened from the outside
  onToggle?: (isOpen: boolean) => void // Callback for switching state
  onClear?: () => void; // Callback to clear date
};

const otherFormat = 'yyyy-MM-dd';
const filtersFormat = 'M/d/yyyy';

const DataInput: React.FC<TextInputProps> = ({
  id,
  name,
  value = '',
  onChange,
  required = false,
  placeholder,
  error = '',
  variant = 'other',
  label,
  isOpen = undefined,
  onToggle,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(value ? new Date(value) : null);

  useEffect(() => {
    if (!value) {
      setSelectedDate(null);
    }
  }, [value]);

  const onChangeLocal = (date: string | undefined) => {
    if (date) {
      // Обновляем состояние родителя (или текущего компонента)
      setSelectedDate(new Date(date)); // Преобразуем строку в Date

      // Форматируем дату для передачи дальше
      const formattedDate = format(new Date(date), variant === 'filters' ? filtersFormat : otherFormat);

      // Вызываем onChange родительского компонента
      onChange({
        target: {
          name: name, // Имя поля (важно для формы)
          value: formattedDate, // Передаём строку в формате 'yyyy-MM-dd'
        },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      setSelectedDate(null); // Сбрасываем состояние, если нет даты
    }
  }

  return (
    <div className={styles.dateInput}>
      {label && <label className={stylesCommon.inputSelectLabel}>{label}</label>}
      <DayPicker
        errorMessage={'error message'}
        // label={'day picker'}
        onChange={onChangeLocal}
        selected={selectedDate ? format(selectedDate, variant === 'filters' ? filtersFormat : otherFormat) : undefined}
        variant={variant}
        isOpen={isOpen}
        onToggle={onToggle}
      />
      <div className="invalid-feedback">
        {Array.isArray(error) ? (
          error.map((errMsg, index) => (
            <p key={index}>{errMsg}</p>
          ))
        ) : (
          error || ''
        )}
      </div>
    </div>
  );
};

export default DataInput;
