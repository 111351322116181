import React, {useEffect} from 'react'
import styles from './ProfileDropdown.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import clsx from 'clsx'
import {Avatar} from "../../../common/Icons/User01Icon/Avatar"
import Tippy_up from "src/assets/images/icons/Tippy_up.svg"
import {useSelector} from "react-redux"
import {useGetPersonalInfoQuery} from "../../../api/api"
import {selectIsAuthenticated} from "../../../redux/slices/auth/isAuthenticatedSlice"

import {selectToken} from "../../../redux/slices/auth/loginSlice"
import {Link, useNavigate} from "react-router-dom"
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {PlayerContainer} from "../../../common/PlayerContainer/PlayerContainer"
import {setAccountActiveButton} from "../../../redux/slices/menus/menuAccountSlice"
import {useTranslation} from "react-i18next"
import {useLogout} from "../../../common/hooks/useLogout"
import {interfaceSelector} from "../../../redux/slices/interfaceSlice"


type Props = {
  showDropdown: boolean
  setShowDropdown: (value: boolean) => void
}


export const ProfileDropdown = ({
  showDropdown,
  setShowDropdown,
}: Props) => {


  const token = useSelector(selectToken)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const { t } = useTranslation()


  const { data: getPersonalInfoData } = useGetPersonalInfoQuery({ token: token ? token : '' })

  const playerId = getPersonalInfoData?.data?.player_id

  const {isMobile} = useAppSelector(interfaceSelector)

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth')
    }
  }, [isAuthenticated])

  const handleLogout = useLogout()

  type ProfileDropdownLink = {
    to?: string
    name: string
    onClick?: () => void
  }

  const ProfileDropdownLink = ({ to, name, onClick }: ProfileDropdownLink) => {
    const handleClick = () => {
      if (onClick) {
        onClick()
      }
      setShowDropdown(false)
    }

    if (to) {
      return (
        <Link to={to} className={styles.link} onClick={handleClick}>
          {name}
        </Link>
      )
    }

    return (
      <div className={styles.link} onClick={handleClick}>
        {name}
      </div>
    )
  }


  const onProfileClick = () => {
    navigate('/profile')
    dispatch(setAccountActiveButton('My profile'))
  }

  const onMyWalletsClick = () => {
    navigate('/profile')
    dispatch(setAccountActiveButton('My wallet'))
  }

  const onHistoryClick = () => {
    navigate('/profile')
    dispatch(setAccountActiveButton('History'))
  }

  return (
    <div className={styles.component}>
      {showDropdown && <img src={Tippy_up} alt="Tippy_up" className={styles.tippy} />}
      <Avatar className={clsx(stylesCommon.userIcon,
        isMobile && stylesCommon.userIconMobile,
        )} style={{ cursor: 'pointer' }} onClick={() => setShowDropdown(!showDropdown)} />
      <div
        className={clsx(
          "dropdown-menu" + (showDropdown ? " show" : ""),
          styles.menu
        )}
      >
        {getPersonalInfoData?.data?.login && playerId && <PlayerContainer loginName={getPersonalInfoData?.data?.login} playerId={playerId} />}
        <div className={styles.linksContainer}>
          <ProfileDropdownLink onClick={onProfileClick} name={t('My profile')} />
          <ProfileDropdownLink onClick={onMyWalletsClick} name={t('My wallets')} />
          <ProfileDropdownLink onClick={onHistoryClick} name={t('History')} />
          <ProfileDropdownLink onClick={handleLogout} name={t('Logout')} />
        </div>
      </div>
    </div>
  )
}
