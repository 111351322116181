// isAuthenticatedSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';
import {DISABLE_MISC_LOGS} from "../../../common/constants"


type IsAuthenticatedSliceResponse = {
  success: boolean;
  data: boolean; // Уточните тип данных
  messages: string | null;
};

type IsAuthenticatedSliceError = {
  error: any;
  message: string;
};

interface IsAuthenticatedSliceState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: boolean; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: IsAuthenticatedSliceState = {
  success: false,
  loading: false,
  error: null,
  data: false,
  messages: null,
};


// Типизация для fetchIsAuthenticated
type TokenParam = string;


export const fetchIsAuthenticated = createAsyncThunk<
  IsAuthenticatedSliceResponse,
  TokenParam,
  { rejectValue: IsAuthenticatedSliceError }
>(
  'IsAuthenticatedSlice/fetchIsAuthenticated',
  async (token, { rejectWithValue }) => {
    try {
      !DISABLE_MISC_LOGS && console.log('Attempting to fetchIsAuthenticated:', `${config.serverUrl}/site/is-authenticated`, token);

      const response = await axios.get<IsAuthenticatedSliceResponse>(
        `${config.serverUrl}/site/is-authenticated`,
        {
          params: {
            token,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      !DISABLE_MISC_LOGS && console.log('Attempting to fetchIsAuthenticated response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error fetchIsAuthenticated:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to get IsAuthenticated .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);

export const webSocketGetIsAuthenticated = (token: string, onMessage?: (isAuthenticated: boolean) => void) => {
  return (dispatch: any) => {
    const ws = new WebSocket(config.serverWebsocketUrl);
    !DISABLE_MISC_LOGS && console.log('Connecting to WebSocket server:', config.serverWebsocketUrl);

    ws.onopen = () => {
      !DISABLE_MISC_LOGS && console.log('Connected to WebSocket server for getIsAuthenticated');
      ws.send(JSON.stringify({ type: 'getIsAuthenticated', token }));
    };

    ws.onmessage = (event) => {
      !DISABLE_MISC_LOGS && console.log('WebSocket message received:', event.data);
      try {
        const data = JSON.parse(event.data);

        if (data.type === 'getIsAuthenticated') {
          const isAuthenticated = data.resultData?.data;

          dispatch(ActionAuthenticatedSetSuccess(isAuthenticated));
          !DISABLE_MISC_LOGS && console.log('ActionAuthenticatedSetSuccess isAuthenticated', isAuthenticated);

          // Вызываем коллбек, если он передан
          if (onMessage) {
            onMessage(isAuthenticated);
          }
        } else if (data.type === 'error') {
          dispatch(ActionAuthenticatedSetFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
        dispatch(ActionAuthenticatedSetFailure('Error parsing WebSocket message'));
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};



const IsAuthenticatedSlice = createSlice({
  name: 'Profile/IsAuthenticatedSlice',
  initialState,
  reducers: {
    resetStateAuthenticated: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
    ActionAuthenticatedSetSuccess: (state, action: PayloadAction<boolean>) => {
      state.loading = false;
      state.success = true;
      state.data = action.payload;
      state.messages = null;
    },
    ActionSetAuthenticated: (state) => {
      state.loading = false;
      state.success = true;
      state.data = true;
      state.messages = null;
    },
    ActionSetNotAuthenticated: (state) => {
      state.loading = false;
      state.success = true;
      state.data = false;
      state.messages = null;
    },
    //Экшен для обработки ошибки при получении данных через WebSocket
    ActionAuthenticatedSetFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.messages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIsAuthenticated.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchIsAuthenticated.fulfilled, (state, action: PayloadAction<IsAuthenticatedSliceResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchIsAuthenticated.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateAuthenticated, ActionAuthenticatedSetSuccess, ActionAuthenticatedSetFailure, ActionSetAuthenticated, ActionSetNotAuthenticated } = IsAuthenticatedSlice.actions;

export const IsAuthenticatedSliceReducer = IsAuthenticatedSlice.reducer;



// ---------
// Selectors
// ---------
export const selectIsAuthenticatedSliceLoading = (state: RootState) => state.IsAuthenticatedSliceReducer.loading;
export const selectIsAuthenticatedSliceSuccess = (state: RootState) => state.IsAuthenticatedSliceReducer.success;
export const selectIsAuthenticatedSliceError = (state: RootState) => state.IsAuthenticatedSliceReducer.error;
export const selectIsAuthenticatedSliceMessages = (state: RootState) => state.IsAuthenticatedSliceReducer.messages;
export const selectIsAuthenticatedSliceData = (state: RootState) => state.IsAuthenticatedSliceReducer.data;
export const selectIsAuthenticated = (state: RootState): boolean => state.IsAuthenticatedSliceReducer.data === true;


export const selectIsAuthenticatedSliceErrorString = (state: RootState): string | null => {
  const error = state.IsAuthenticatedSliceReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectIsAuthenticatedSliceErrorArray = (state: RootState): string[] | null => {
  const error = state.IsAuthenticatedSliceReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
