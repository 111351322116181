// sendEmailConfirmationSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type SendEmailConfirmationResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type SendEmailConfirmationError = {
  error: any;
  message: string;
};

interface SendEmailConfirmationState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: any[]; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: SendEmailConfirmationState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchSendEmailConfirmation
export interface SendEmailConfirmationParams {
  token: string;
}

export const fetchSendEmailConfirmation = createAsyncThunk<
  SendEmailConfirmationResponse,
  SendEmailConfirmationParams,
  { rejectValue: SendEmailConfirmationError }
>(
  'SendEmailConfirmation/fetchSendEmailConfirmation',
  async ({ token }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchSendEmailConfirmation:', `${config.serverUrl}/player/send-email-confirmation`, { token });

      const response = await axios.post<SendEmailConfirmationResponse>(
        `${config.serverUrl}/player/send-email-confirmation`,
        {},
        {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            },
        }
      );
      console.log('Attempting to fetchSendEmailConfirmation response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error Send Email Confirmation:', error);

      if (axios.isAxiosError(error)) {
        if (!error.response) {
          return rejectWithValue({
            error: [ error.code ||  'Network error'],
            message: 'Network error. Please check your connection.',
          });
        }
        return rejectWithValue({
          error: error.response.data.errors || { error: error.response.data.message },
          message: error.response.data.message || error.response.data.message || 'Failed to Send Email Confirmation.',
        });
      }

      return rejectWithValue({
        error: [ 'Unknown error' ],
        message: 'An unknown error occurred.',
      });
    }
  }
);



const SendEmailConfirmationSlice = createSlice({
  name: 'SendEmailConfirmation',
  initialState,
  reducers: {
    resetStateSendEmailConfirmation: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSendEmailConfirmation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchSendEmailConfirmation.fulfilled, (state, action: PayloadAction<SendEmailConfirmationResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchSendEmailConfirmation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateSendEmailConfirmation } = SendEmailConfirmationSlice.actions;

export const SendEmailConfirmationReducer = SendEmailConfirmationSlice.reducer;



// ---------
// Selectors
// ---------
export const selectSendEmailConfirmationLoading = (state: RootState) => state.SendEmailConfirmationReducer.loading;
export const selectSendEmailConfirmationSuccess = (state: RootState) => state.SendEmailConfirmationReducer.success;
export const selectSendEmailConfirmationError = (state: RootState) => state.SendEmailConfirmationReducer.error;
export const selectSendEmailConfirmationMessages = (state: RootState) => state.SendEmailConfirmationReducer.messages;
export const selectSendEmailConfirmationData = (state: RootState) => state.SendEmailConfirmationReducer.data;

export const selectSendEmailConfirmationErrorString = (state: RootState): string | null => {
  const error = state.SendEmailConfirmationReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectSendEmailConfirmationMessagesString = (state: RootState): string | null => {
  const messages = state.SendEmailConfirmationReducer.messages;

  if (Array.isArray(messages)) {
    return messages.join('\n');
  }

  if (typeof messages === 'object' && messages !== null) {
    return Object.entries(messages)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return messages;
};

export const selectSendEmailConfirmationErrorArray = (state: RootState): string[] | null => {
  const error = state.SendEmailConfirmationReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
