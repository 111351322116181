import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../../store"
import {User_02Icon} from "../../../assets/images/icons/withColorChange/User_02Icon"
import {Lock01Icon} from "../../../assets/images/icons/withColorChange/Lock01Icon"
import {ShieldTickIcon} from "../../../assets/images/icons/withColorChange/ShieldTickIcon"
import {Settings04Icon} from "../../../assets/images/icons/withColorChange/Settings04Icon"
import {SpacingWidth01Icon} from "../../../assets/images/icons/withColorChange/SpacingWidth01Icon"
import React from "react"
import {UserIconProps} from "../../../common/types"

export type ProfileButtonId = 'Profile' | 'Security' | 'Verification' | 'Preferences' | 'Limits'

// button type
type AccountButtonData = {
  id: ProfileButtonId
  label: string
  img?: string
  icon?: React.ComponentType<UserIconProps>
  imgAlt?: string
}

export type MenuState = {
  activeButton: ProfileButtonId  // ID of active button
  buttonData: Array<AccountButtonData> // Button data
};

const initialState: MenuState = {
  activeButton: 'Profile', // initial state of active button
  buttonData: [
    {id: 'Profile', label: 'Profile', icon: User_02Icon},
    {id: 'Security', label: 'Security', icon: Lock01Icon},
    {id: 'Verification', label: 'Verification', icon: ShieldTickIcon},
    {id: 'Preferences', label: 'Preferences', icon: Settings04Icon},
    {id: 'Limits', label: 'Limits', icon: SpacingWidth01Icon},
  ],
}

const menuProfileSlice = createSlice({
  name: 'menuAccount',
  initialState,
  reducers: {
    setProfileActiveButton: (state, action: PayloadAction<ProfileButtonId>) => {
      state.activeButton = action.payload // setting active button
    },
  },
})

export const menuProfileReducer = menuProfileSlice.reducer;
export const { setProfileActiveButton } = menuProfileSlice.actions;

export const selectActiveProfileButton = (state: RootState) => state.menuProfileReducer.activeButton
export const selectProfileButtonData = (state: RootState) => state.menuProfileReducer.buttonData;


