import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from "react-redux"
import {selectToken} from "../../../../../redux/slices/auth/loginSlice"

import {useGetSessionInfoQuery} from "../../../../../api/api"
import {useAppSelector} from "../../../../../hooks"
import {interfaceSelector} from "../../../../../redux/slices/interfaceSlice"
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"
import {SessionsData} from "src/api/types"
import {SortableTableCell} from '../../common/SortableTableCell'
import {DEFAULT_PAGINATION_VALUE, SIBLING_COUNT, TRANSACTIONS_PAGINATION_VALUES} from "../../../../../common/constants"
import {Pagination} from "../../../../../common/Pagination"
import {Link} from "react-router-dom"
import {extractUniqueOptions, formatDate, getComparator} from '../../common/utils'
import {useGetCurrencySelectOptions} from "../../common/useGetCurrencySelectOptions"
import {STATUS_OPTIONS} from "./SessionsInfoConstants"
import tablesCommonStyles from "../../common/TablesCommonStyles.module.scss"
import stylesCommonTables from "../../common/TablesCommonStyles.module.scss"
import styles from './SessionsInfo.module.scss'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import {BrightCopyToClipboard} from "../../../../../common/BrightCopyToClipboard/BrightCopyToClipboard"
import {TitleAndFilterButton} from "../../common/TitleAndFilterButton/TitleAndFilterButton"
import {FiltersForTable} from "../../common/FiltersForTable/FiltersForTable"
import {useFilters} from "../../../../../common/hooks/useFilters"
import {useLocalPagination} from 'src/common/hooks/useLocalPagination'
import {useTranslation} from "react-i18next";

const initialFilters = {
  sessionIdFilter: '',
  gameFilter: '',
  gameTypeFilter: '',
  betFilter: '',
  winFilter: '',
  jackpotFilter: '',
  fsFilter: '',
  currencyFilter: '',
  startFilter: '',
  endFilter: '',
  statusFilter: ''
}

export const SessionsInfo: React.FC = () => {
  const {
    filters,
    draftFilters,
    handleDraftFilterChange,
    handleApplyFilters,
    handleClearFilters
  } = useFilters<typeof initialFilters>(initialFilters)

  // Sort state
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const [orderBy, setOrderBy] = useState<keyof SessionsData>('created_at')
  const [filtersShown, setFiltersShown] = useState(false)

  const {selectedLanguage} = useAppSelector(interfaceSelector)
  const token = useSelector(selectToken)
  const {t} = useTranslation()

  const {currencySelectOptions} = useGetCurrencySelectOptions()

  const {data: initialSessionInfo} = useGetSessionInfoQuery({
    token: token ? token : '',
    languageId: selectedLanguage.id,
    page: 1,
    pageSize: 1, // Request only one record to get total_count
  })

  const totalCount = initialSessionInfo?.pagination?.total_count

  const { data: allSessionData } = useGetSessionInfoQuery({
    token: token ? token : '',
    languageId: selectedLanguage.id,
    page: 1,
    pageSize: totalCount ?? DEFAULT_PAGINATION_VALUE, // Request all records at once
  }, {
    pollingInterval: 30000,
    skip: !totalCount, // The query is executed only after total_count has been loaded
  })

  // Filter and sort data
  const filteredData = useMemo(() => {
    if (!allSessionData?.data) return []

    return allSessionData.data
      .filter((data: SessionsData) => {
        const matchesSessionId = !filters.sessionIdFilter || data.uid.toString().includes(filters.sessionIdFilter)
        const matchesGame = !filters.gameFilter || data.game.name.includes(filters.gameFilter)
        const matchesGameType = !filters.gameTypeFilter || data.gameType.name.includes(filters.gameTypeFilter)
        const matchesBet = !filters.betFilter || data.bet_real.toString().includes(filters.betFilter)
        const matchesWin = !filters.winFilter || data.win_real.toString().includes(filters.winFilter)
        const matchesJackpot = !filters.jackpotFilter || data.jackpot_total.toString().includes(filters.jackpotFilter)
        const matchesCurrency = !filters.currencyFilter || data.currency.code.includes(filters.currencyFilter)
        const matchesStart = !filters.startFilter || formatDate(data.created_at).includes(filters.startFilter)
        const matchesEnd = !filters.endFilter || formatDate(data.end_datetime).includes(filters.endFilter)
        const matchesStatus = !filters.statusFilter || data.status.includes(filters.statusFilter)

        return (
          matchesSessionId &&
          matchesGame &&
          matchesGameType &&
          matchesBet &&
          matchesWin &&
          matchesJackpot &&
          matchesCurrency &&
          matchesStart &&
          matchesEnd &&
          matchesStatus
        )
      })
      .sort((a, b) => {
        if (orderBy === 'currency') {
          return order === 'asc'
            ? a.currency.code.localeCompare(b.currency.code)
            : b.currency.code.localeCompare(a.currency.code)
        }
        return getComparator<SessionsData, keyof SessionsData>(order, orderBy)(a, b)
      });
  }, [allSessionData, filters, order, orderBy])

  const {
    paginatedData,
    currentPage,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage
  } = useLocalPagination({
    data: filteredData,
    defaultPageSize: DEFAULT_PAGINATION_VALUE
  });

   useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [filters]);

  const gameTypeOptions = allSessionData
    ? extractUniqueOptions(allSessionData.data, 'gameType.name')
    : []

  const gameOptions = allSessionData
    ? extractUniqueOptions(allSessionData.data, 'game.name')
    : []



  const handleRequestSort = (property: keyof SessionsData) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  type Headers = {
    label: string
    orderBy?: keyof SessionsData
    filterKey?: string
    selectOptions?: { value: string, label: string }[]
    sortable?: boolean
  };

  const headers: Headers[] = [
    {label: 'Session ID', orderBy: 'uid', filterKey: 'sessionIdFilter', sortable: true},
    {
      label: 'Game',
      orderBy: 'game',
      filterKey: 'gameFilter',
      selectOptions: gameOptions,
      sortable: true
    },
    {
      label: 'Game type',
      orderBy: 'gameType',
      filterKey: 'gameTypeFilter',
      selectOptions: gameTypeOptions,
      sortable: true
    },
    {label: 'Total bet', orderBy: 'bet_real', filterKey: 'betFilter', sortable: true},
    {label: 'Total win', orderBy: 'win_real', filterKey: 'winFilter', sortable: true},
    {label: 'Total jackpot', orderBy: 'jackpot_total', filterKey: 'jackpotFilter', sortable: true},
    {label: 'Total FS', filterKey: 'fsFilter'},
    {
      label: 'Currency',
      orderBy: 'currency',
      filterKey: 'currencyFilter',
      selectOptions: currencySelectOptions,
      sortable: true
    },
    {label: 'Start', orderBy: 'created_at', filterKey: 'startFilter', sortable: true},
    {label: 'End', orderBy: 'end_datetime', filterKey: 'endFilter', sortable: true},
    {
      label: 'Status',
      orderBy: 'status',
      filterKey: 'statusFilter',
      selectOptions: STATUS_OPTIONS,
      sortable: true
    },
    {
      label: 'Info',
      sortable: false
    },
  ]

  const cells = (data: SessionsData) => [
    <Box className={tablesCommonStyles.uid}>
      {data.uid}
      <BrightCopyToClipboard text={data.uid} variant={'history'}/>
    </Box>,
    data.game.name,
    data.gameType.name,
    data.bet_real,
    data.win_real,
    data.jackpot_total,
    'N/A',
    data.currency.code,
    formatDate(data.created_at),
    formatDate(data.end_datetime),
    data.status,
    <TableCell key={`link-${data.id}`}><Link to={`/transactions/${data.id}`} className={styles.transactionsLink}>View
      transactions</Link></TableCell>
  ]

  return (
    <div>
      <div className={stylesCommon.profileRoundedTabNoPadding}>
        <TitleAndFilterButton title={t('Game activity')} filterShown={filtersShown} onFilterShown={setFiltersShown}/>
        {filtersShown && (
          <FiltersForTable
            filters={filters} // Using draft filters
            onFilterChange={(key, value) => handleDraftFilterChange(key as keyof typeof filters, value)} // Updating draft filters only
            filterOptions={{
              gameFilter: gameOptions,
              gameTypeFilter: gameTypeOptions,
              currencyFilter: currencySelectOptions,
              statusFilter: STATUS_OPTIONS
            }}
            filterLabels={{
              sessionIdFilter: "Session ID",
              gameFilter: "Game",
              gameTypeFilter: "Game Type",
              betFilter: "Bet Amount",
              winFilter: "Win Amount",
              jackpotFilter: "Jackpot",
              fsFilter: "Free Spins",
              currencyFilter: "Currency",
              startFilter: "Start Date",
              endFilter: "End Date",
              statusFilter: "Status"
            }}
            onApplyFilters={handleApplyFilters} // Apply button action
            draftFilters={draftFilters}
            onClearFilters={handleClearFilters}
          />
        )}
        <TableContainer>
          <Table sx={{ "& .MuiTableCell-root": { borderBottom: "none" } }}>
            <TableHead className={tablesCommonStyles.backgroundSurfaceElevation2} sx={{ borderBottom: "none" }}>
              <TableRow >
                {headers.map((header, index) =>
                  header.sortable ? (
                    <SortableTableCell
                      key={index}
                      label={header.label}
                      orderBy={header.orderBy || ''}
                      currentOrderBy={orderBy}
                      order={order}
                      onSort={() => header.orderBy && handleRequestSort(header.orderBy)}
                    />
                  ) : (
                    <TableCell key={index}>{header.label}</TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((element, index) => (
                <TableRow key={element.id}
                          className={index % 2 !== 0 ? tablesCommonStyles.backgroundSurfaceElevation2 : undefined}

                >
                  {cells(element).map((cell, index) => (
                    <TableCell key={`${element.id}-${index}`}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </div>
      {allSessionData?.pagination && (
        <div className={stylesCommonTables.paginationTableContainer}>
          <Pagination
            variant={'sessions'}
            onPageChange={(value) => {
              setCurrentPage(Number(value));
            }}
            totalCount={filteredData.length}
            currentPage={currentPage}
            pageSize={itemsPerPage}
            siblingCount={SIBLING_COUNT}
            selectSettings={{
              value: itemsPerPage.toString(),
              onChangeOption: (value: string) => {
                setItemsPerPage(Number(value)) // Change the number of items per page
                setCurrentPage(1) // Reset current page to 1
              },
              arr: TRANSACTIONS_PAGINATION_VALUES.map((val) => val.toString())
            }}
          />
        </div>

      )}
    </div>

  )
}
