import styles from './Promo.module.scss'
import trophy_2_1_1 from 'src/assets/images/trophy_2_1_1.png'
import {PromoInput} from "./PromoInput/PromoInput"
import {useTranslation} from "react-i18next";

export const Promo = () => {
  const {t} = useTranslation()
  return (
    <div className={styles.promo}>
       <div className={styles.imgTexts}>
         <img src={trophy_2_1_1} alt="trophy_2_1_1"/>
         <div className={styles.textBlock}>
           <div className={styles.promoHeader}>{t('Promo code')}</div>
           <div className={styles.promoDescription}>{t('Get your promo code via personal message or social networks')}</div>
         </div>
       </div>
      <PromoInput />
    </div>
  )
}