// userIpBlacklistSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';
import {DISABLE_MISC_LOGS} from "../../../common/constants"


type UserIpBlacklistResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type UserIpBlacklistError = {
  error: any;
  message: string;
};

interface UserIpBlacklistState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: any[]; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: UserIpBlacklistState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchUserIpBlacklist
interface UserIpBlacklistParams {
  ip: string;
}

export const fetchUserIpBlacklist = createAsyncThunk<
  UserIpBlacklistResponse,
  UserIpBlacklistParams,
  { rejectValue: UserIpBlacklistError }
>(
  'UserIpBlacklist/fetchUserIpBlacklist',
  async ({ ip }, { rejectWithValue }) => {
    try {
      !DISABLE_MISC_LOGS && console.log('Attempting to fetchUserIpBlacklist:', `${config.serverUrl}/util/user-ip-blacklist`, { ip });

      const response = await axios.post<UserIpBlacklistResponse>(
        `${config.serverUrl}/util/user-ip-blacklist`,
        { ip },
        { headers: { 'Content-Type': 'application/json' } }
      );
      !DISABLE_MISC_LOGS && console.log('Attempting to fetchUserIpBlacklist response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error fetch user ip blacklist:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to fetch user ip blacklist .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);



const UserIpBlacklistSlice = createSlice({
  name: 'UserIpBlacklist',
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserIpBlacklist.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchUserIpBlacklist.fulfilled, (state, action: PayloadAction<UserIpBlacklistResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchUserIpBlacklist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetState } = UserIpBlacklistSlice.actions;

export const UserIpBlacklistReducer = UserIpBlacklistSlice.reducer;



// ---------
// Selectors
// ---------
export const selectUserIpBlacklistLoading = (state: RootState) => state.UserIpBlacklistReducer.loading;
export const selectUserIpBlacklistSuccess = (state: RootState) => state.UserIpBlacklistReducer.success;
export const selectUserIpBlacklistError = (state: RootState) => state.UserIpBlacklistReducer.error;
export const selectUserIpBlacklistMessages = (state: RootState) => state.UserIpBlacklistReducer.messages;
export const selectUserIpBlacklistData = (state: RootState) => state.UserIpBlacklistReducer.data;
export const selectHasUserIpBlacklist = (state: RootState) => ( state.UserIpBlacklistReducer.data === true );

export const selectUserIpBlacklistErrorString = (state: RootState): string | null => {
  const error = state.UserIpBlacklistReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectUserIpBlacklistErrorArray = (state: RootState): string[] | null => {
  const error = state.UserIpBlacklistReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
