import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from "react-redux"
import {
  selectToken
} from "../../../../../redux/slices/auth/loginSlice"
import {useGetSessionTransactionsQuery} from "../../../../../api/api"
import {useAppSelector} from "../../../../../hooks"
import {interfaceSelector} from "../../../../../redux/slices/interfaceSlice"
import {Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"
import {GameTransaction} from "src/api/types"
import {SortableTableCell} from '../../common/SortableTableCell'
import {formatDate, getComparator} from "../../common/utils"
import {useNavigate, useParams} from "react-router-dom"
import {Pagination} from "../../../../../common/Pagination"
import {DEFAULT_PAGINATION_VALUE, SIBLING_COUNT, TRANSACTIONS_PAGINATION_VALUES} from "../../../../../common/constants"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import stylesCommonTables from "src/components/pages/Transactions/common/TablesCommonStyles.module.scss"
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import {useGetCurrencySelectOptions} from "../../common/useGetCurrencySelectOptions"
import {TYPE_OPTIONS} from "./SessionTransactionConstants"
import {STATUS_OPTIONS} from "../../common/TablesConstants"
import {BrightCopyToClipboard} from "../../../../../common/BrightCopyToClipboard/BrightCopyToClipboard"

export const SessionTransaction: React.FC = () => {
  const {selectedLanguage} = useAppSelector(interfaceSelector)
  const token = useSelector(selectToken)

  let {transactionId} = useParams<{ transactionId: string }>()
  const navigate = useNavigate()

  const {currencySelectOptions} = useGetCurrencySelectOptions()

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGINATION_VALUE)

  const {data} = useGetSessionTransactionsQuery({
      token: token ? token : '',
      languageId: selectedLanguage.id,
      id: Number(transactionId),
      page: currentPage,
      pageSize: itemsPerPage
    },
    {
      pollingInterval: 30000, // re-fetching every 30 seconds
    }
  )

  const [filters, setFilters] = useState({
    transactionIdFilter: '',
    typeFilter: '',
    realAmountFilter: '',
    currencyFilter: '',
    startFilter: '',
    statusFilter: ''
  })

  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const [orderBy, setOrderBy] = useState<keyof GameTransaction>('created_at')

  const handleRequestSort = (property: keyof GameTransaction) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    console.log('token', token)

  }, [token])


  const filteredData = useMemo(() => {
    if (!data?.data?.gameTransactions) return []

    return data.data.gameTransactions
      .filter((transaction: GameTransaction) => {
        const matchesSessionId = !filters.transactionIdFilter || transaction.id.toString().includes(filters.transactionIdFilter)
        const matchesGameType = !filters.typeFilter || transaction.type.includes(filters.typeFilter)
        const matchesRealAmount = !filters.realAmountFilter || transaction.transaction_real_amount.toString().includes(filters.realAmountFilter)
        const matchesCurrency = !filters.currencyFilter || transaction.currency.code.includes(filters.currencyFilter)
        const matchesStart = !filters.startFilter || formatDate(transaction.created_at).includes(filters.startFilter)
        const matchesStatus = !filters.statusFilter || transaction.status.includes(filters.statusFilter)
        return matchesSessionId && matchesGameType && matchesRealAmount && matchesCurrency && matchesStart && matchesStatus
      })
      .sort((a, b) => { // special situation for currency sorting
        if (orderBy === 'currency') {
          return order === 'asc'
            ? a.currency.code.localeCompare(b.currency.code)
            : b.currency.code.localeCompare(a.currency.code)
        }
        return getComparator<GameTransaction, keyof GameTransaction>(order, orderBy)(a, b)
      })
  }, [data, filters, order, orderBy])

  type Headers = {
    label: string;
    orderBy?: keyof GameTransaction;
    filterKey?: string;
    selectOptions?: { value: string; label: string }[];
    sortable?: boolean;
  }

  const headers: Headers[] = [
    {label: 'Transaction ID', orderBy: 'id', filterKey: 'transactionIdFilter', sortable: true},
    {
      label: 'Type',
      orderBy: 'type',
      filterKey: 'typeFilter',
      selectOptions: TYPE_OPTIONS,
      sortable: true
    },
    {label: 'Real amount', orderBy: 'transaction_real_amount', filterKey: 'realAmountFilter', sortable: true},
    {
      label: 'Currency',
      orderBy: 'currency',
      filterKey: 'currencyFilter',
      selectOptions: currencySelectOptions,
      sortable: true
    },
    {label: 'Created at', orderBy: 'created_at', filterKey: 'startFilter', sortable: true},
    {
      label: 'Status',
      orderBy: 'status',
      filterKey: 'statusFilter',
      selectOptions: STATUS_OPTIONS,
      sortable: true
    },
  ]

  const cells = (transaction: GameTransaction) => {
    return [
      <Box className={stylesCommonTables.uid}>
        {transaction.id}
        <BrightCopyToClipboard text={transaction.id.toString()} variant={'history'}/>
      </Box>,
      transaction.type,
      transaction.transaction_real_amount,
      transaction.currency.code,
      formatDate(transaction.created_at),
      transaction.status,
    ]

  }

  if (!data?.data) return <div>No transactions available</div>

  return (
    <Box>
      <div className={stylesCommon.name}>
        <IconButton onClick={() => navigate(-1)} aria-label="back">
          <ArrowBackIcon/>
        </IconButton>
        <h3>Game Activity</h3>
      </div>
      <div> <b>Transaction ID:</b> {data?.data.uid}</div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) =>
                header.sortable ? (
                  <SortableTableCell
                    key={index}
                    label={header.label}
                    orderBy={header.orderBy || ''}
                    currentOrderBy={orderBy}
                    order={order}
                    onSort={() => header.orderBy && handleRequestSort(header.orderBy)}
                  />
                ) : (
                  <TableCell key={index}>{header.label}</TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((element) => (
              <TableRow key={element.id}>
                {cells(element).map((cell) => (
                  <TableCell key={element.id}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data?.pagination && (
        <div className={stylesCommonTables.paginationTableContainer}>
          <Pagination
            variant={'transactions'}
            onPageChange={(value) => setCurrentPage(Number(value))}
            totalCount={data.pagination.total_count}
            currentPage={currentPage}
            pageSize={itemsPerPage}
            siblingCount={SIBLING_COUNT}
            selectSettings={{
              value: itemsPerPage.toString(),
              onChangeOption: (value: string) => {
                setItemsPerPage(Number(value)) // Change the number of items per page
                setCurrentPage(1) // Reset current page to 1
              },
              arr: TRANSACTIONS_PAGINATION_VALUES.map((val) => val.toString())
            }}
          />
        </div>

      )}
    </Box>
  )
}
