// checkQueueSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type CheckQueueResponse = {
    success: boolean;
    data: boolean; // Уточните тип данных
    messages: string | null;
};

type CheckQueueError = {
    error: any;
    message: string;
};

interface CheckQueueState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: boolean; // Уточните тип массива, если известно
    messages: string | null;
}


const initialState: CheckQueueState = {
    success: false,
    loading: false,
    error: null,
    data: false,
    messages: null,
};


// Типизация для fetchCheckQueue
export type FetchCheckQueueParams = {
    token: string;
    params?: {
        language?: number; // Параметр language является необязательным
    };
}


export const fetchCheckQueue = createAsyncThunk<
    CheckQueueResponse,
    FetchCheckQueueParams,
    { rejectValue: CheckQueueError }
>(
    'CheckQueue/fetchCheckQueue',
    async ({ token, params = {} }, { rejectWithValue }) => {
        try {
            console.log('Attempting to fetchCheckQueue:', `${config.serverUrl}/popup/check-queue`, { token, params });

            const response = await axios.get<CheckQueueResponse>(
                `${config.serverUrl}/popup/check-queue`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    params
                }
            );
            console.log('Attempting to fetchCheckQueue response.data:', response.data);

            return response.data;
        } catch (error) {
            console.error('Error fetchCheckQueue:', error);

            if (axios.isAxiosError(error) && error.response) {
                return rejectWithValue({
                    error: error.response.data,
                    message: error.response.data.message || 'Failed to fetchCheckQueue .',
                });
            }

            return rejectWithValue({
                error: null,
                message: 'An unknown error occurred.',
            });
        }
    }
);

export const connectWebSocketCheckQueue = (token: string, params = {}) => {
    return (dispatch: any) => {
        const ws = new WebSocket(config.serverWebsocketUrl);
        ws.onopen = () => {
            ws.send(JSON.stringify({ type: 'getCheckQueue', params, token }));
        };

        ws.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
            try {
                const data = JSON.parse(event.data);

                if (data.type === 'getCheckQueue') {
                    const result = data.result;

                    dispatch(webSocketCheckQueueSuccess(result));
                } else if (data.type === 'error') {
                    dispatch(webSocketCheckQueueFailure(data.message));
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
                dispatch(webSocketCheckQueueFailure('Error parsing WebSocket message'));
            }
        };

        ws.onclose = () => {
            console.log('Disconnected from WebSocket server');
        };

        return () => {
            ws.close();
        };
    };
};


const CheckQueue = createSlice({
    name: 'Popup/CheckQueue',
    initialState,
    reducers: {
        resetStateCheckQueue: (state) => {
            state.success = initialState.success;
            state.loading = initialState.loading;
            state.error = initialState.error;
            state.data = initialState.data;
            state.messages = initialState.messages;
        },
        //Экшен для обработки ошибки при получении данных через WebSocket
        webSocketCheckQueueSuccess: (state, action: PayloadAction<CheckQueueResponse>) => {
            state.loading = false;
            state.success = true;
            state.data = action.payload.data;
            state.messages = null;
        },
        //Экшен для обработки ошибки при получении данных через WebSocket
        webSocketCheckQueueFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
            state.messages = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCheckQueue.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.messages = null;
                state.success = false;
            })
            .addCase(fetchCheckQueue.fulfilled, (state, action: PayloadAction<CheckQueueResponse>) => {
                state.loading = false;
                state.success = action.payload.success;
                state.data = action.payload.data;
                state.messages = action.payload.messages;
            })
            .addCase(fetchCheckQueue.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error || null;
                state.messages = action.payload?.message || 'Unknown error occurred.';
                state.success = false;
            });
    },
});

export const { resetStateCheckQueue, webSocketCheckQueueSuccess, webSocketCheckQueueFailure } = CheckQueue.actions;

export const CheckQueueReducer = CheckQueue.reducer;



// ---------
// Selectors
// ---------
export const selectCheckQueueLoading = (state: RootState) => state.CheckQueueReducer.loading;
export const selectCheckQueueSuccess = (state: RootState) => state.CheckQueueReducer.success;
export const selectCheckQueueError = (state: RootState) => state.CheckQueueReducer.error;
export const selectCheckQueueMessages = (state: RootState) => state.CheckQueueReducer.messages;
export const selectCheckQueueData = (state: RootState) => state.CheckQueueReducer.data;



export const selectCheckQueueErrorString = (state: RootState): string | null => {
    const error = state.CheckQueueReducer.error;

    if (Array.isArray(error)) {
        return error.join('\n');
    }

    if (typeof error === 'object' && error !== null) {
        return Object.entries(error)
            .map(([key, value]) => `${key}: ${value}`)
            .join('\n');
    }

    return error;
};

export const selectCheckQueueErrorArray = (state: RootState): string[] | null => {
    const error = state.CheckQueueReducer.error;
    let errorsArray: string[] = [];

    if (Array.isArray(error)) {
        errorsArray = error.map((err) => `${err}`);
    } else if (typeof error === 'object' && error !== null) {
        errorsArray = Object.entries(error)
            .flatMap(([key, value]) =>
                Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
            );
    } else if (error) {
        errorsArray = [error];
    }

    return errorsArray.length > 0 ? errorsArray : null;
};
