import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from "../../../../hooks"
import { useLocation, useNavigate } from 'react-router-dom'
import TextInput from '../formComponents/TextInput'
import DataInput from '../formComponents/DataInput/DataInput'
import PasswordInput from '../formComponents/PasswordInput'
import SelectInput from '../formComponents/SelectInput/SelectInput'
import CheckboxInput from '../formComponents/CheckboxInput'
import Alert from '../../../layouts/ui/alert/Alert'
import styles from './Signup.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import config from 'src/config'
import depositSignupStyles from 'src/common/styles/depositSignup.module.scss'

import {
  fetchSendCodeConfirmation,
  selectSendCodeConfirmationSuccess,
  selectSendCodeConfirmationLoading,
  selectSendCodeConfirmationError,
  selectSendCodeConfirmationData,
  selectSendCodeConfirmationErrorArray,
  selectSendCodeConfirmationErrorString,
  selectSendCodeConfirmationMessages,
  resetStateSendCodeConfirmation,
  FetchSendCodeConfirmationParam
} from '../../../../redux/slices/auth/sendCodeConfirmationSlice'

import {
  RegistrationFormElementsType,
  RegistrationFormElementType,
} from '../../../../redux/slices/auth/getRegistrationFormSlice'

import {
  fetchRegisterValidationFirstScreen,
  resetStateRegisterValidationFirstScreen,
  selectRegisterValidationFirstScreenError,
  selectRegisterValidationFirstScreenSuccess
} from '../../../../redux/slices/auth/registerValidationFirstScreenSlice'

import {
  selectIsAuthenticated
} from "../../../../redux/slices/auth/isAuthenticatedSlice"

import {
  selectToken
} from "../../../../redux/slices/auth/loginSlice"

import { SubmitButton } from "../formComponents/SubmitButton/SubmitButton"
import { BrightCheckbox } from "../../../../common/BrightCheckbox/BrightCheckbox"
import mail_01_passive from 'src/assets/images/icons/mail_01_passive.svg'
import mail_01 from 'src/assets/images/icons/mail_01.svg'
import { SelectChangeEvent } from '@mui/material'
import { interfaceSelector } from "../../../../redux/slices/interfaceSlice"
import { ScrollButton } from "../../../../common/ScrollButton/ScrollButton"
import {
  BrightCarouselDetachedControl,
  CarouselHandle
} from "../../../../common/BrightCarousel/BrightCarouselDetachedControl"
import clsx from "clsx"
import { carouselGradient } from "../../../../common/styles/styleConstants"
import { prepareFormStateForServer, getBonusimage, Currencies, FormSettings } from "./Signup"

// Интерфейсы для состояний формы и ошибок клиента
export interface FormStateConfirmation {
  [key: string]: string | boolean;
}

interface ClientErrors {
  required: { [key: string]: string };
}

interface Country {
  country_id: string;
  title_ru: string;
  title_ua: string;
  title_be: string;
  title_en: string;
  title_es: string;
  title_pt: string;
  title_de: string;
  title_fr: string;
  title_it: string;
  title_pl: string;
  title_ja: string;
  title_lt: string;
  title_lv: string;
  title_cz: string;
  iso: string;
}

interface Bonus {
  id: string;
  name: string;
  image_path: string | null;
  vertical_image_path: string | null;
  icon_path: string | null;
}

interface SignupConfirmationFormProps {
  onConfirm:  (formState: FormStateConfirmation) => void;
  toggleBonusStatus:  (bonusId: string) => void;
  bonuses: Bonus[];
  checkedBonus: string | null;
  currencies: Currencies[];
  formSettings: FormSettings;
  formElements: RegistrationFormElementsType;
  initialCurrencyId?: string;
}


const SignupConfirmationForm: React.FC<SignupConfirmationFormProps> = ({ onConfirm, toggleBonusStatus, bonuses, checkedBonus, currencies, formSettings, formElements, initialCurrencyId }) => {
  // const dispatch = useDispatch();
  const dispatch = useAppDispatch()

  // Рефы
  const formRef = useRef<HTMLFormElement>(null)

  // Результаты запроса на генерацию кода подтверждения
  const codeConfirmation = useSelector(selectSendCodeConfirmationData)
  const codeConfirmationCreatedSuccess = useSelector(selectSendCodeConfirmationSuccess)
  const codeConfirmationMessages = useSelector(selectSendCodeConfirmationMessages)
  const codeConfirmationLoading = useSelector(selectSendCodeConfirmationLoading)
  const codeConfirmationError = useSelector(selectSendCodeConfirmationError)
  const codeConfirmationErrorString = useSelector(selectSendCodeConfirmationErrorString)
  const codeConfirmationErrorArray = useSelector(selectSendCodeConfirmationErrorArray)


  // Валидация формы первого экрана
  const registerValidationFirstScreenSuccess = useSelector(selectRegisterValidationFirstScreenSuccess)
  const registerValidationFirstScreenError = useSelector(selectRegisterValidationFirstScreenError)
  // const registerValidationFirstScreenLoading = useSelector(selectRegisterValidationFirstScreenLoading);

  // Вспомогательные локальные стэйты
  const [formState, setFormState] = useState<FormStateConfirmation>({})
  // const [initialState, setInitialState] = useState<FormStateConfirmation>({})
  const [clientErrors, setClientErrors] = useState<ClientErrors>({ required: {} })
  
  const [showErrors, setShowErrors] = useState(false) //это чтобы алерт с ошибками не появлялся после закрытия
  const [showConfirmationErrors, setShowConfirmationErrors] = useState(false) // это только ошибки конфирма
  const [firstScreenServerValidationErrors, setFirstScreenServerValidationErrors] = useState({
    email: null,
    password: null,
    currency: null
  })

  const [secondScreenServerValidationErrors, setSecondScreenServerValidationErrors] = useState<Record<string, string>>({})

  // Array of refs for each slider
  const carouselRef = useRef<CarouselHandle | null>(null)

  // Инициализация начальных значений формы
  useEffect(() => {
    if (!formElements || !currencies) {
      return;
    }
  
    const initialFormValues: FormStateConfirmation = {};
  
    // Ищем ID валюты из параметров или используем EUR
    let selectedCurrencyId: string | null = null;

    if (initialCurrencyId) {
      selectedCurrencyId = initialCurrencyId;
    } else {
      Object.entries(currencies).forEach((key, index) => {
        let c = key[1].toString();
        if (c === "EUR") {
          selectedCurrencyId = key[0];
        }
      });
    }

    // Если валюта не найдена, берем первую
    if (!selectedCurrencyId && Object.keys(currencies).length > 0) {
      selectedCurrencyId = Object.keys(currencies)[0];
    }

    formElements.forEach((item: RegistrationFormElementType) => {
      if (item.flag_displayed !== "YES") return;
  
      if (item.code === "currency") {
        initialFormValues["currency"] = selectedCurrencyId || '';
      } else {
        // Сохраняем существующие значения, если они есть
        initialFormValues[item.code] = formState[item.code] || "";
      }
    });
  
    // Обновляем только пустые поля
    const updatedFormState = {
      ...formState,
      ...Object.fromEntries(
        Object.entries(initialFormValues).filter(([key, value]) => !formState[key])
      )
    };
    
    setFormState(updatedFormState);
  }, [formElements, currencies, initialCurrencyId]);
  
  

  useEffect(() => {
    dispatch(resetStateSendCodeConfirmation());
  }, [])


  // After request server validation first screen response
  // Результат запроса на валидацию полей первого экрана
  useEffect(() => {
    if (registerValidationFirstScreenError) {
      let errors = {
        email: null,
        password: null,
        currency: null
      }

      if (registerValidationFirstScreenError.email) {
        errors.email = registerValidationFirstScreenError.email
      }

      if (registerValidationFirstScreenError.new_password) {
        errors.password = registerValidationFirstScreenError.new_password
      }

      if (registerValidationFirstScreenError.id_currency) {
        errors.currency = registerValidationFirstScreenError.id_currency
      }

      setFirstScreenServerValidationErrors(errors)
    }
    if (registerValidationFirstScreenSuccess) {
      if (clientValidation()) {
        const confirmationData: FetchSendCodeConfirmationParam = {
          email: formState.email as string,
          type: 'auth'
        }
        dispatch(fetchSendCodeConfirmation(confirmationData))
        dispatch(resetStateRegisterValidationFirstScreen()) // Сбрасываем состояние после отправки
      }
    }
  }, [registerValidationFirstScreenSuccess, registerValidationFirstScreenError])

  useEffect(() => {
    if (codeConfirmationError) {
      console.log('codeConfirmationError', codeConfirmationError);
      console.log('codeConfirmationErrorArray', codeConfirmationErrorArray);
      setShowConfirmationErrors(true) // раз ошибки есть то разблокируем показ окна алерта
    } else if (codeConfirmationCreatedSuccess) {
      onConfirm(formState)
      clearErrorsStepOne() // очистим ошибки первого шага
      dispatch(resetStateSendCodeConfirmation());
    }

    // return () => {
    //   clearErrorsStepOne();
    //   // dispatch(resetStateSendCodeConfirmation());
    // };
  }, [codeConfirmationCreatedSuccess, codeConfirmationError]);


  // Хелпер для очистки ошибок первого шага
  const clearErrorsStepOne = () => {
    dispatch(resetStateRegisterValidationFirstScreen())
    setFirstScreenServerValidationErrors({
      email: null,
      password: null,
      currency: null,
    })
  }

  // Обработка клика на кнопку Continue на первом экране
  const handleButtonContinueClick = () => {
    if (clientValidation()) {
      const preparedFormState = prepareFormStateForServer(formState)
      dispatch(fetchRegisterValidationFirstScreen(preparedFormState))
    } else {
      console.error('Validation failed')
    }
  }


  // Обработка формы первого экрана
  const handleConfirmationSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

  }

  // Функция валидации полей формы
  const clientValidation = (): boolean => {
    const formElements = formRef.current?.elements
    const newErrors: ClientErrors = { required: {} }
    let formIsValid = true

    if (formElements) {
      Array.from(formElements).forEach(field => {
        const input = field as HTMLInputElement
        // Определяем, является ли поле пустым
        const isFieldEmpty =
          input.type === 'checkbox'
            ? !input.checked
            : typeof input.value === 'string' && input.value.trim() === ''

        if (input.required && isFieldEmpty) {
          formIsValid = false
          const errorMessage = input.getAttribute('data-error-required') || 'This field is required.'
          newErrors.required[input.name] = errorMessage
        }
      })
    }

    setClientErrors(newErrors)
    return formIsValid
  }

  const handleInputChange = (
    code: string, e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | SelectChangeEvent<string>
  ) => {

    // Используем `checked` для чекбоксов, а `value` для остальных полей
    const target = e.target as (HTMLInputElement | HTMLSelectElement)

    const value: string | boolean =
      target && target.type === 'checkbox' && 'checked' in target
        ? (target as HTMLInputElement).checked
        : String(target.value)

    console.log(code, value)
    if (code === 'bonus') {
      console.log('Selected bonus:', value)
    }
    const updatedFormState = {
      ...formState,
      [code]: value,  // Используем `code` как ключ, чтобы обновить соответствующее поле
    }
    // console.log(updatedFormState);
    setFormState(updatedFormState)

    // Проверяем, изменились ли данные по сравнению с начальным состоянием
    // setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))

    // Если поле заполнено (или отмечено), удаляем его из ошибок валидации
    setClientErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }

      // Проверка для строки перед использованием `trim()`
      const target = e.target as HTMLInputElement | HTMLSelectElement // Type casting
      const isFieldFilled =
        target.type === 'checkbox'
          ? value
          : typeof value === 'string' && value.trim() !== ''

      if (isFieldFilled) {
        delete updatedErrors['required'][code]
      } else {
        const target = e.target as HTMLInputElement | HTMLSelectElement // Type casting
        const errorMessage = target.getAttribute('data-error-required') || 'This field is required.'
        updatedErrors['required'][code] = errorMessage
      }

      return updatedErrors
    })
  }

  // Закрытие алерта
  const handleConfirmationErrorClose = () => {
    setShowConfirmationErrors(false) // Скрываем ошибки при нажатии на крестик
  }

  const { isMobile } = useAppSelector(interfaceSelector)

  // Инициализация начальных значений формы
  useEffect(() => {
    if (!formElements || !currencies) {
      return;
    }
  
    const initialFormValues: FormStateConfirmation = {};
  
    // Ищем ID валюты из параметров или используем EUR
    let selectedCurrencyId: string | null = null;

    if (initialCurrencyId) {
      selectedCurrencyId = initialCurrencyId;
    } else {
      Object.entries(currencies).forEach((key, index) => {
        let c = key[1].toString();
        if (c === "EUR") {
          selectedCurrencyId = key[0];
        }
      });
    }

    // Если валюта не найдена, берем первую
    if (!selectedCurrencyId && Object.keys(currencies).length > 0) {
      selectedCurrencyId = Object.keys(currencies)[0];
    }

    formElements.forEach((item: RegistrationFormElementType) => {
      if (item.flag_displayed !== "YES") return;
  
      if (item.code === "currency") {
        initialFormValues["currency"] = selectedCurrencyId || '';
      } else {
        // Сохраняем существующие значения, если они есть
        initialFormValues[item.code] = formState[item.code] || "";
      }
    });
  
    // Обновляем только пустые поля
    const updatedFormState = {
      ...formState,
      ...Object.fromEntries(
        Object.entries(initialFormValues).filter(([key, value]) => !formState[key])
      )
    };
    
    setFormState(updatedFormState);
  }, [formElements, currencies, initialCurrencyId]);

  // Рендеринг полей формы в зависимости от типа
  const renderFormField = (item: RegistrationFormElementType, isRegistration?: boolean) => {

    const isAutoComplete = isRegistration ? "off" : "on"

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | SelectChangeEvent<string>) =>
      handleInputChange(item.code, e)

    let serverValidationErrors = null

    if (item.code === "email" || item.code === "password" || item.code === "currency") {
      serverValidationErrors = firstScreenServerValidationErrors[item.code]
    }


    const commonProps = {
      id: item.code,
      name: item.code,
      value: String(formState[item.code] || ''),
      onChange: handleChange, // Обработчик для input и select
      required: item.flag_required === "YES",
      placeholder: item.name,
      error: clientErrors.required[item.code] || serverValidationErrors,
    }

    switch (item.code) {
      case 'currency':
        const currencyOptions = currencies

        return <SelectInput {...commonProps} options={currencyOptions
          ? Object.entries(currencyOptions).map(([id, code]) => ({
            id,
            name: String(code)
          }))
          : []
        } variant={'currency'} />

      case 'country':
        const countries: Country[] = formSettings.countries

        return (
          <>
            <SelectInput {...commonProps} options={countries.map((country: Country) => ({
              id: String(country.country_id),
              name: country.title_en
            }))}
              variant={'country'}
            />
          </>

        )

      case 'terms':
        return (
          <CheckboxInput
            {...commonProps}
            checked={!!formState[item.code]} // Булевое значение для контролируемого чекбокса
            hint="I agree to the Terms & Conditions and Privacy Policy"
          />
        )

      case 'birth':
        return <DataInput {...commonProps} />

      case 'password':
        return (
          <>
            <PasswordInput {...commonProps} id="new_password" name="new_password"
              placeholder="Enter new password" autoComplete={isAutoComplete} />
            {(
              formSettings?.is_password_confirmation_required
              &&
              <PasswordInput {...commonProps} id="confirm_password" name="confirm_password"
                placeholder="Confirm password" autoComplete={isAutoComplete} />
            )}

          </>
        )

      case 'email':
        return (
          <TextInput {...commonProps}
            activeIcon={mail_01}
            passiveIcon={mail_01_passive}
            autoComplete={isAutoComplete}
          />
        )

      default:
        return <TextInput {...commonProps} />
    }
  }


  type SubscriptionBlockProps = {
    subscription: boolean
    handleInputChange: (field: string, event: React.ChangeEvent<HTMLInputElement>) => void
  };

  const bonusImgDir = config.fileUrls.bonusFolder


  const SubscriptionBlock = ({ handleInputChange, subscription }: SubscriptionBlockProps) => {
    return (
      <div key="subscription-checkbox" className={styles.subscriptionBlock}>


        {
          isMobile && bonuses &&
          <div
            id="block-bonuslist"
            className={depositSignupStyles.carousel}
          >

            <div className={depositSignupStyles.carouselControls}>
              <ScrollButton onClick={() => carouselRef.current?.scroll('left')} direction={'left'}
                variant={'arrow'} />
              <ScrollButton onClick={() => carouselRef.current?.scroll('right')} direction={'right'}
                variant={'arrow'} />
            </div>
            <BrightCarouselDetachedControl
              ref={carouselRef}
              array={bonuses.map((bonus: Bonus, index: number) => (
                <div
                  key={bonus.id}
                  className={clsx(depositSignupStyles.bonusCard, checkedBonus == bonus.id && depositSignupStyles.bonusCardActive)}
                  onClick={() => toggleBonusStatus(bonus.id)}
                  style={{
                    backgroundImage: bonus?.image_path
                      ? `${carouselGradient}, url(${getBonusimage(bonus)})`
                      : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                  }}
                >
                  <div className={depositSignupStyles.bonusHeader}>
                    {bonus?.name}
                    index: {index + 1}
                  </div>
                  {/*<div className={styles.bonusDescription}>
                        {bonus?.bonusLangs[0].description.slice(0, 40)}{bonus?.bonusLangs[0].description.length > 40 && '...'}
                      </div>*/}
                </div>
              ))}
              className={depositSignupStyles.carousel}
            />
          </div>
        }


        {formSettings?.flag_subscription !== 'YES' && (
          <div>
            <div className="form-check">
              <BrightCheckbox
                className="form-check-input"
                id="subscriptionCheck"
                name="subscription"
                checked={subscription}
                onChange={(e) => handleInputChange('subscription', e)}
              />
              <label htmlFor="subscriptionCheck" className={stylesCommon.checkboxHint}>
                I want to receive news and updates
              </label>
            </div>
          </div>
        )}
      </div>
    )
  }

  // -------------------------------
  // Вывод представлений
  // -------------------------------
  return (
    <>
      {/* Блок с формой */}
      <form onSubmit={handleConfirmationSubmit} ref={formRef}>
        <div className={styles.signupFormInputs}>
          {formElements && formElements.map((item: RegistrationFormElementType, index: number) => {
            if (item.flag_displayed !== "YES") return null

            if (item.code === "email" || item.code === "password" || item.code === "currency" || item.code === "terms") {
              return (
                <div key={item.id || index} className={

                  (item.code === "email" || item.code === "password")
                    ? styles.smallMarginBottom
                    : styles.bigMarginBottom
                }>
                  {/*<React.Fragment>*/}
                  {item.code === "terms" && <SubscriptionBlock subscription={!!formState.subscription}
                    handleInputChange={handleInputChange} />} {/*render subscription-checkbox before terms*/}
                  {renderFormField(item, true)}
                  {/*</React.Fragment>*/}
                </div>
              )
            }
          })}
        </div>

        <div key="gender-radio">
          {formSettings.flag_gender === "YES" && (
            <div className="mb-3">
              <label className="form-label"><strong>Gender</strong></label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="genderMale"
                  value="male"
                />
                <label className="form-check-label" htmlFor="genderMale">
                  Male
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="genderFemale"
                  value="female"
                />
                <label className="form-check-label" htmlFor="genderFemale">
                  Female
                </label>
              </div>
            </div>
          )}


        </div>

        {/* Блок с кнопкой и ссылкой */}
        <SubmitButton onClick={handleButtonContinueClick} isLoading={codeConfirmationLoading}
          text={'Continue'} />
      </form>

      {codeConfirmationError && (
        <Alert onClose={handleConfirmationErrorClose} type="error">
          
          {codeConfirmationError.map((error: any, index: any) => (
            <p key={index}>{error}</p>
          ))}
        </Alert>
      )}


      {codeConfirmationCreatedSuccess && (
        <Alert><strong>{codeConfirmationMessages ? codeConfirmationMessages : 'Success!'}</strong></Alert>
      )}
    </>
  );



}

export default SignupConfirmationForm
