import React, { ChangeEvent } from 'react';
import styles from './TextInput.module.scss';
import clsx from 'clsx'
import './TextInputBootstrap.scss' /*non module class to style bootstrap error position*/
import { useTranslation } from "react-i18next"

interface TextInputProps {
  id: string;
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  label?: string;
  placeholder: string;
  error?: string;
  hint?: string;
  currency_code?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  name,
  value,
  onChange,
  required = false,
  label,
  placeholder,
  error,
  hint,
  currency_code
}) => {

  const { t } = useTranslation()

  return (
    <div className={styles.textInput}>

      {label &&
        (<label htmlFor={id} className="form-label">
          <div className={styles.title}>
            {label}
          </div>
        </label>)
      }

      <input
        type="text"
        id={id}
        name={name}
        className={clsx(`form-control ${error ? 'is-invalid' : ''}`, styles.input)}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={`${placeholder}...`}
        data-error-required={`${placeholder} ${t('is required')}`}
      />
      {error && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
      {hint && (
        <div className={styles.hint}>
          {hint}
        </div>
      )}
      <div className={styles.currency}>
        {currency_code}
      </div>
    </div>)
};

export default TextInput;

// todo: need to add currency name to the end of the placeholder
