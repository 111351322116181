import React from 'react'
import styles from "./CarouselTitleIcon.module.scss"
import clsx from "clsx"
import {useAppSelector} from "../../hooks"
import {interfaceSelector} from "../../redux/slices/interfaceSlice"
import {useNavigate} from "react-router-dom"

type Props = {
   logoPath: string | null
   title: string
   className?: string
   slugForMobile?: string
}

export const CarouselTitleIcon = ({logoPath, title, className, slugForMobile}: Props) => {

  const {isMobile} = useAppSelector(interfaceSelector)
  const navigate = useNavigate();

  const onSlugMobileClick = () => {
     if (slugForMobile) {
       navigate(`/game-group/${slugForMobile}`)
     }
  }
  
  return (
    <div className={clsx(clsx(styles.titleContainer,
      slugForMobile && styles.titleContainerClickable ,className))}>
      {
        logoPath &&
          <div className={styles.iconContainer}>
              <img src={logoPath}
                   alt="carousel_icon" className={styles.winners_icon}
              />
          </div>

      }
      <h5 onClick={isMobile ? onSlugMobileClick : undefined}>{title}</h5>
    </div>
  )
}

