import styles from './Tabbar.module.scss'
import Trophy from 'src/assets/images/Tabbar/Trophy.png'
import Trophy_sport from 'src/assets/images/Tabbar/Trophy_sport.png'
import Trophy_slot from 'src/assets/images/Tabbar/Trophy_slot.png'
import Trophy_bonus from 'src/assets/images/Tabbar/Trophy_bonus.png'
import Deposit from 'src/assets/images/Tabbar/Deposit.png'
import TabbarElement, {TabbarElementProps} from "./TabbarElement/TabbarElement"
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {
  interfaceSelector,
  setIsChildrenMenuOpened,
  setIsMobileMenuOpened,
  setSelectedMenu
} from 'src/redux/slices/interfaceSlice'
import {useState} from "react"
import clsx from 'clsx'

export const Tabbar = () => {

  const dispatch = useAppDispatch();
  const {isMobileMenuOpened} = useAppSelector(interfaceSelector)
  const [isTabsVisible, setIsTabsVisible] = useState(true)

  const closeSelectedMenuWithChildren = () => {
    dispatch(setSelectedMenu(null))
    dispatch(setIsChildrenMenuOpened(false))
  }

  const buttons: Array<TabbarElementProps> = [
    {
      icon: Trophy,
      label: 'Menu',
      onClick: () => {
        closeSelectedMenuWithChildren()
        dispatch(setIsMobileMenuOpened(!isMobileMenuOpened))
      }
    },
    {
      icon: Trophy_sport,
      label: 'Sport',
    },
    {
      icon: Trophy_slot,
      label: 'Slots',
    },
    {
      icon: Trophy_bonus,
      label: 'Bonuses',
    },
    {
      icon: Deposit,
      label: 'Deposit',
    }
  ]
  
  const onIndicatorClick = () => {
    closeSelectedMenuWithChildren()
    dispatch(setIsMobileMenuOpened(false))
    setIsTabsVisible(!isTabsVisible)
  }


  return (
    <div className={clsx(styles.tabbar, !isTabsVisible && styles.tabbarNoBackground)}>
      {isTabsVisible && <div className={styles.tabs}>
        {buttons.map((button, index) => (
         <TabbarElement key={index} label={button.label} icon={button.icon} onClick={button.onClick}/>
        ))}
       </div> }
      <div className={styles.homeIndicatorContainer} onClick={onIndicatorClick}>
         <div className={styles.homeIndicator}/>
      </div>
    </div>
  )
}