import {Link} from "react-router-dom"
import styles from "./ProfileShowAllLink.module.scss"
import React from "react"
import {useTranslation} from "react-i18next";

type Props = {
  to: string
}

export const ProfileShowAllLink = ({to}: Props) => {
  const {t} = useTranslation()
  return (
    <Link to={to} className={styles.showAll}>
        {t('Show all')}
    </Link>
  )
}