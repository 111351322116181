// sendCodeConfirmationSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type SendCodeConfirmationSliceResponse = {
  success: boolean;
  data: boolean; // Уточните тип данных
  messages: string | null;
};

type SendCodeConfirmationSliceError = {
  error: any;
  message: string;
};

interface SendCodeConfirmationSliceState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: boolean; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: SendCodeConfirmationSliceState = {
  success: false,
  loading: false,
  error: null,
  data: false,
  messages: null,
};

// Типизация параметров для fetchSendCodeConfirmation
// Типизация параметров для fetchSendCodeConfirmation
export type FetchSendCodeConfirmationParam = { 
  email: string;
  type: string;
};


export const fetchSendCodeConfirmation = createAsyncThunk<
  SendCodeConfirmationSliceResponse,
  FetchSendCodeConfirmationParam,
  { rejectValue: SendCodeConfirmationSliceError }
>(
  'SendCodeConfirmationSlice/fetchSendCodeConfirmation',
  async (userData, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchSendCodeConfirmation:', { userData });

      const response = await axios.post<SendCodeConfirmationSliceResponse>(
        `${config.serverUrl}/util/send-code-confirmation`,
        { userData },
        { headers: { 'Content-Type': 'application/json' } }
      );

      console.log(`fetchSendCodeConfirmation success: ${response.status}`, response.data);
      return response.data;
    } catch (error) {
      console.error('Error in fetchSendCodeConfirmation:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data.errors || [ error.response.data.message ],
          message: error.response.data.message || 'Failed to send code confirmation.',
        });
      }

      return rejectWithValue({
        error: ['An unknown error occurred during registration.'],
        message: 'An unknown error occurred during registration.',
      });
    }
  }
);



const SendCodeConfirmationSlice = createSlice({
  name: 'Profile/SendCodeConfirmationSlice',
  initialState,
  reducers: {
    resetStateSendCodeConfirmation: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSendCodeConfirmation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchSendCodeConfirmation.fulfilled, (state, action: PayloadAction<SendCodeConfirmationSliceResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchSendCodeConfirmation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || action.payload?.message || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateSendCodeConfirmation } = SendCodeConfirmationSlice.actions;
export const SendCodeConfirmationReducer = SendCodeConfirmationSlice.reducer;


// ---------
// Selectors
// ---------
export const selectSendCodeConfirmationLoading = (state: RootState) => state.SendCodeConfirmationReducer.loading;
export const selectSendCodeConfirmationSuccess = (state: RootState) => state.SendCodeConfirmationReducer.success;
export const selectSendCodeConfirmationError = (state: RootState) => state.SendCodeConfirmationReducer.error;
export const selectSendCodeConfirmationMessages = (state: RootState) => state.SendCodeConfirmationReducer.messages;
export const selectSendCodeConfirmationData = (state: RootState) => state.SendCodeConfirmationReducer.data;
// export const selectSendCodeConfirmation = (state: RootState): boolean => { return state.SendCodeConfirmationReducer.data === true};


export const selectSendCodeConfirmationErrorString = (state: RootState): string | null => {
  const error = state.SendCodeConfirmationReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectSendCodeConfirmationErrorArray = (state: RootState): string[] | null => {
  const error = state.SendCodeConfirmationReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
