import React, {ReactNode, useEffect} from 'react'
import styles from "./MainLayout.module.scss"
import {ChildrenMenu} from "../ChildrenMenu/ChildrenMenu"
import MyProgressModal from "src/components/elems/MyProgressModal"
import Navigation from "src/components/layouts/Navigation/Navigation"
import Sidebar from "../Sidebar/Sidebar"
import Footer from "../Footer/Footer"
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {interfaceSelector, setCurrentScreenWidth, setIsMobileMode} from "../../../redux/slices/interfaceSlice"
import {getNumberFromPx, isMobileScreen} from "../../../common/helpers"
import {useI18nLanguage} from "../../../common/i18n/useI18nLanguage"
import {useScreenWidth} from "../../../common/hooks/useScreenWidth"
import {Container} from "@mui/material"
import {useLocation} from "react-router-dom"

type MainLayoutProps = {
  children: ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({children}) => {
  const {sidebarHeight} = useAppSelector(interfaceSelector)
  const rootStyles = getComputedStyle(document.body)
  const contentGridPadding = getNumberFromPx(rootStyles.getPropertyValue('--content-grid-padding'))

  useI18nLanguage() // connect to i18n

  const screenWidth = useScreenWidth()  // detect screen width

  const dispatch = useAppDispatch()

  useEffect(() => {
     dispatch(setCurrentScreenWidth(screenWidth)) // set it only at this component, to avoid memory leak
     if (screenWidth < 1199) {dispatch(setIsMobileMode(true))}
     else dispatch(setIsMobileMode(false))
  }, [screenWidth])

  const isMobile = isMobileScreen(screenWidth)

  const {isMobileMenuOpened, childrenMenu } = useAppSelector(interfaceSelector)

  const location = useLocation();
  console.log(location.pathname)

  return (
    <Container className={styles.mainLayout} disableGutters maxWidth={false}>
      {/*moved to higher lvl for mobile*/}
      {isMobile && isMobileMenuOpened && <div>
        {!childrenMenu.isChildrenMenuOpened && <Sidebar/>}
        <ChildrenMenu/>
      </div> }
      {(!isMobile || (isMobile && location.pathname === '/')) && <Navigation />}
      <div className={styles.gridContainer}>
        {!isMobile && <div className={styles.sidebarGrid}>
            <Sidebar/>
            <ChildrenMenu/>
        </div> }
        <div className={styles.contentGrid}>
          <MyProgressModal/>
          <div style={{
            minHeight: `${sidebarHeight - contentGridPadding}px`,
          }}>{children}</div>
          <Footer/>
        </div>
      </div>
    </Container>
  )
}

export default MainLayout
