import styles from './ContentBlock.module.scss';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import stylesCommon from 'src/common/styles/commonStyles.module.scss';
import { ButtonStyled } from '../../../common/ButtonStyled/ButtonStyled';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query/react'
import { SerializedError } from '@reduxjs/toolkit';
import config from '../../../config'

type ContentBlockData = {
  data: {
    flag_full_area_clickable: 'YES' | 'NO';
    link_href: string;
    flag_show_title: 'YES' | 'NO';
    title: string;
    body: string;
    link_label: string;
    image_path: string;
  };
};

type Props = {
  data: ContentBlockData | null | undefined;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  isMobile: boolean;
};

export const ContentBlock = ({ data, isLoading, error, isMobile }: Props) => {
  if (error) {
    return <div />;
  }

  if (isLoading) {
    return <div>ContentBlock loading ...</div>;
  }

  if (!data || !data.data) {
    return null;
  }

  const Wrapper = data.data.flag_full_area_clickable === 'YES' ? Link : 'div';
  const hasImage = data.data.image_path && data.data.image_path.trim() !== '';

  return (
    <Box className={clsx(styles.contentBlockContainer, !isMobile ? stylesCommon.group : stylesCommon.groupMobile)}>
      <Wrapper
        to={data.data.link_href}
        className={clsx(styles.contentWrapper, {
          [styles.linkWrapper]: data.data.flag_full_area_clickable === 'YES',
          [styles.fullWidth]: !hasImage
        })}
      >
        <div className={styles.contentText}>
          {data.data.flag_show_title === 'YES' && <div className={styles.title}>{data.data.title}</div>}
          <div
            className={styles.body}
            dangerouslySetInnerHTML={{ __html: data.data.body }}
          />
          {data.data.flag_full_area_clickable !== 'YES' && (
            <ButtonStyled to={data.data.link_href} variant={'glass'} paddingVariant={'14px 18px'}>
              {data.data.link_label}
            </ButtonStyled>
          )}
        </div>
        {!isMobile && hasImage && (
          <div className={styles.contentImage}>
            <img src={`${config.fileUrls.rootFolder}${data.data.image_path}`} alt={data.data.title}/>
          </div>
        )}
      </Wrapper>
    </Box>
  );
};

