// listResponsibleGamingsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type ListResponsibleGamingsSliceResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type ListResponsibleGamingsSliceError = {
  error: any;
  message: string;
};

interface ListResponsibleGamingsSliceState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: any[]; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: ListResponsibleGamingsSliceState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchListResponsibleGamings
interface ListResponsibleGamingsSliceParams {
  token: string;
  params?: {};
}

export const fetchListResponsibleGamings = createAsyncThunk<
  ListResponsibleGamingsSliceResponse,
  ListResponsibleGamingsSliceParams,
  { rejectValue: ListResponsibleGamingsSliceError }
>(
  'ListResponsibleGamingsSlice/fetchListResponsibleGamings',
  async ({ token, params = {} }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchListResponsibleGamings:', `${config.serverUrl}/player/list-responsible-gamings`, { token, params });

      const response = await axios.get<ListResponsibleGamingsSliceResponse>(
        `${config.serverUrl}/player/list-responsible-gamings`,
        {
          params: {
            ...params, // Ваши дополнительные параметры
            token,     // Токен авторизации как часть query-параметров
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Attempting to fetchListResponsibleGamings response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error get list Responsible Gamings:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to get ListResponsibleGamingsSlice .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);



const ListResponsibleGamingsSlice = createSlice({
  name: 'Profile/ListResponsibleGamingsSlice',
  initialState,
  reducers: {
    resetStateListResponsibleGamings: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListResponsibleGamings.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchListResponsibleGamings.fulfilled, (state, action: PayloadAction<ListResponsibleGamingsSliceResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchListResponsibleGamings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateListResponsibleGamings } = ListResponsibleGamingsSlice.actions;

export const ListResponsibleGamingsReducer = ListResponsibleGamingsSlice.reducer;



// ---------
// Selectors
// ---------
export const selectListResponsibleGamingsLoading = (state: RootState) => state.ListResponsibleGamingsReducer.loading;
export const selectListResponsibleGamingsSuccess = (state: RootState) => state.ListResponsibleGamingsReducer.success;
export const selectListResponsibleGamingsError = (state: RootState) => state.ListResponsibleGamingsReducer.error;
export const selectListResponsibleGamingsMessages = (state: RootState) => state.ListResponsibleGamingsReducer.messages;
export const selectListResponsibleGamingsData = (state: RootState) => state.ListResponsibleGamingsReducer.data;


export const selectListResponsibleGamingsErrorString = (state: RootState): string | null => {
  const error = state.ListResponsibleGamingsReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectListResponsibleGamingsErrorArray = (state: RootState): string[] | null => {
  const error = state.ListResponsibleGamingsReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
