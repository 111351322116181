import React, {FC} from 'react'
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import stylesAccordion from 'src/common/styles/accordions.module.scss'
import clsx from 'clsx'

type RootProps = {
  summary: string
  details: React.ReactNode
  isExpandMoreIconHidden?: boolean
  variant?: 'termsFaq' | 'singleTerm'
};

type WrapperProps = {
  variant?: 'termsFaq' | 'singleTerm'
  children: React.ReactNode
}

const Root: FC<RootProps> = ({summary, details, isExpandMoreIconHidden, variant}) => {

  const isMobileStyles = variant === 'termsFaq' || variant === 'singleTerm'

  return <Accordion className={clsx(
    stylesAccordion.accordionRoot,
    variant === 'termsFaq' && stylesAccordion.accordionRootTermsUpdated,
    variant === 'singleTerm' && stylesAccordion.accordionRootSingleTerm,
    (variant === 'termsFaq' || variant === 'singleTerm') && stylesAccordion.mobileAccordionRoot, /* for expended style*/
  )} disableGutters>
    <AccordionSummary
      {...(!isExpandMoreIconHidden && {
        expandIcon: <ExpandMoreIcon sx={{color: '#fff'}}/>
      })}
      className={clsx(stylesAccordion.accordionSummary,
        (variant === 'termsFaq' || variant === 'singleTerm') && stylesAccordion.accordionSummaryTermsUpdated,
        variant === 'singleTerm' && stylesAccordion.accordionSummarySingleTerm,
      )}
    >
      {summary}
    </AccordionSummary>
    <AccordionDetails className={clsx(stylesAccordion.accordionDetails,
      {
        [stylesAccordion.accordionDetailsTermsUpdated]: variant === 'termsFaq' || variant === 'singleTerm',
        [stylesAccordion.accordionDetailsSingleTerm]: variant === 'singleTerm',
      },
      (variant === 'termsFaq' || variant === 'singleTerm') && stylesAccordion.accordionDetailsTermsUpdated)

    }
    >
      {details}
    </AccordionDetails>
  </Accordion>
}

const Wrapper: FC<WrapperProps> = ({children, variant}) => (
  <div className={clsx(stylesAccordion.footerAccordions, {
    [stylesAccordion.accordionTermsSingle]: variant === 'singleTerm'
  })}>{children}</div>
)

export const BrightAccordion = {
  Root,
  Wrapper,
}