import CoinsPaid from 'src/assets/images/icons/bonusDepositMethods/CoinsPaid.svg'
import crypto from 'src/assets/images/icons/bonusDepositMethods/crypto.svg'
import binancePay from 'src/assets/images/icons/bonusDepositMethods/binancePay.svg'
import pix from 'src/assets/images/icons/bonusDepositMethods/pix.svg'
import styles from './DepositMethods.module.scss'

export const DepositMethods = () => {
  return (
    <div className={styles.depositMethods}>
      <img src={CoinsPaid} alt=""/>
      <img src={crypto} alt=""/>
      <img src={binancePay} alt=""/>
      <img src={pix} alt=""/>
    </div>
  )
}