import React, {useRef} from 'react'
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styles from './Footer.module.scss'
import stylesAccordion from 'src/common/styles/accordions.module.scss'
import {useGetFooterMenuQuery} from "../../../api/api"
import {useAppSelector} from "../../../hooks"
import {interfaceSelector} from "../../../redux/slices/interfaceSlice"
import social_icons_mono_telegram from 'src/assets/images/icons/social_icons_mono_telegram.svg'
import social_icons_mono_instagram from 'src/assets/images/icons/social_icons_mono_instagram.svg'
import social_icons_mono_x from 'src/assets/images/icons/social_icons_mono_x.svg'
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {SiteLogo} from "../../../common/SiteLogo/SiteLogo"

const Footer = () => {
  const {selectedLanguage} = useAppSelector(interfaceSelector)
  const {data, isSuccess, isLoading} = useGetFooterMenuQuery({language: selectedLanguage.id})
  const {t} = useTranslation()

  const accordionRefs = useRef<HTMLDivElement[]>([])

  const handleAccordionChange = (isExpanded: boolean, index: number) => {
    if (isExpanded && accordionRefs.current[index]) {
      accordionRefs.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const NameOfCompany = '[Name of the company]'
  const RegNo = '160979'
  const LicenseNo = '8048/JAZ2022-062'
  const CompanyRegNo = 'HE435884'
  const CasinoName = '[Casino name]'
  const Year = '2024'

  const rootStyles = getComputedStyle(document.body)
  const dividerBackgroundColor = rootStyles.getPropertyValue('--background-surface-subtle-primary')

  if (!data || !isSuccess) return <></>
  if (isLoading) return <div>Loading Footer...</div>


  return (
    <div className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.data}>
          <div className={styles.logoContainer}>
            <SiteLogo ignoreAdaptive={true}/>
          </div>
          <p className={styles.text}>
            {NameOfCompany} reg.no {RegNo}. License no. {LicenseNo}.
            All payment procedures are operated by {NameOfCompany}, reg.no.
            {CompanyRegNo}.
          </p>
        </div>

        <div className={stylesAccordion.footerAccordions}>
          {data.data.map((item) => (
            <Accordion
              key={item.id}
              className={stylesAccordion.accordionRoot}
              disableGutters
              ref={(el) => {
                if (el) accordionRefs.current[item.id] = el
              }}
              onChange={(_, isExpanded) => handleAccordionChange(isExpanded, item.id)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: '#fff'}}/>} className={stylesAccordion.accordionSummary}>
                <div>{item.title}</div>
              </AccordionSummary>
              <AccordionDetails className={stylesAccordion.accordionDetails}>
                {item.children && item.children.map((child) => (
                  <Link key={child.id} to={child.link_href} className={stylesAccordion.link}>
                    {child.title}
                  </Link>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      {/* done with style there, because module styles not works for divider*/}
      <div style={{
        height: '1px',
        backgroundColor: dividerBackgroundColor,
      }}/>
      <div className={styles.bottomContainer}>
        <div className={styles.bottomText}>{CasinoName} {Year}. {t('All rights reserved')}</div>
        <div className={styles.socialIcons}>
          <img src={social_icons_mono_telegram} alt="social_icons_mono_telegram"/>
          <img src={social_icons_mono_instagram} alt="social_icons_mono_instagram"/>
          <img src={social_icons_mono_x} alt="social_icons_mono_x"/>
        </div>
      </div>
    </div>
  )
}

export default Footer
