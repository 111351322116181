import {useEffect, useMemo} from 'react'
import i18n from './i18n'
import {interfaceSelector} from "../../redux/slices/interfaceSlice"
import {useGetConstructTextBlocksQuery, useGetLanguagesQuery} from "../../api/api"
import {useAppSelector} from "../../hooks"

export const useI18nLanguage = () => {
  const {selectedLanguage} = useAppSelector(interfaceSelector)
  const {data: languages, isLoading} = useGetLanguagesQuery()

  const {data: languageData} = useGetConstructTextBlocksQuery({
    language: selectedLanguage.id,
  })

  const languageMap = useMemo(() => {
    if (!languages) return {}; // If language are undefined, return an empty object

    return Object.fromEntries(
      Object.entries(languages).map(([id, lang]) => [Number(id), lang.iso_code])
    );
  }, [languages]);

  useEffect(() => {

    if (isLoading) {
      return;
    } // do nothing if languages is fetching from api

    const languageCode = languageMap[selectedLanguage.id]
    // const languageCode = languageMap[selectedLanguage.id] || 'en'

    if (languageCode) {

      if (languageData?.data && languageData.data?.length) {
        // If translations have arrived, we add them
        i18n.addResources(languageCode, 'translation', languageData.data)
      } else {
        // for english language, if translate array is blank we clear the translations
        i18n.removeResourceBundle(languageCode, 'translation');
      }

      i18n.changeLanguage(languageCode) // Change the current language
    } else {
      i18n.removeResourceBundle(languageCode, 'translation');

      console.error('Unknown language ID:', selectedLanguage.id) // Log an error for unknown language ID
    }
  }, [languageData, selectedLanguage.id, languageMap, isLoading])

  // Add resources after reload to restore translations from API
  useEffect(() => {
    if (languageData?.data) {
      i18n.addResourceBundle(i18n.language, "translation", languageData.data, true, true);
    }
  }, [languageData, i18n.language]);
}