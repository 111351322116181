import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from "../../../../hooks"
import QueuePopupBody from "./QueuePopupBody"

import { selectIsAuthenticated } from "../../../../redux/slices/auth/isAuthenticatedSlice"

import { selectToken } from "../../../../redux/slices/auth/loginSlice"

import {
    fetchChangeQueue,
    selectChangeQueueLoading,
    selectChangeQueueData,
    selectChangeQueueMessages,
    selectChangeQueueError,
    selectChangeQueueSuccess,

} from '../../../../redux/slices/popup/changeQueueSlice'

import {
    fetchCheckQueue,
    connectWebSocketCheckQueue,
    selectCheckQueueLoading,
    selectCheckQueueData,
    selectCheckQueueMessages,
    selectCheckQueueError,
    selectCheckQueueSuccess,

} from '../../../../redux/slices/popup/checkQueueSlice'


import Modal from "../../../layouts/ui/modal/Modal"

import {
    interfaceSelector,
    setIsShowQueuePopup
} from "../../../../redux/slices/interfaceSlice"

const QueuePopup = () => {

    const dispatch = useAppDispatch()

    const { isShowQueuePopup } = useAppSelector(interfaceSelector)
    const [isInactive, setIsInactive] = useState(false);

    const token = useSelector(selectToken)

    // Change Queue Selectors
    const changeQueueLoading = useSelector(selectChangeQueueLoading);
    const changeQueueData = useSelector(selectChangeQueueData);
    const changeQueueMessages = useSelector(selectChangeQueueMessages);
    const changeQueueError = useSelector(selectChangeQueueError);
    const changeQueueSuccess = useSelector(selectChangeQueueSuccess);

    // Check Queue Selectors
    const checkQueueLoading = useSelector(selectCheckQueueLoading);
    const checkQueueData = useSelector(selectCheckQueueData);
    const checkQueueMessages = useSelector(selectCheckQueueMessages);
    const checkQueueError = useSelector(selectCheckQueueError);
    const checkQueueSuccess = useSelector(selectCheckQueueSuccess);

    useEffect(() => {
        if (token) {
            dispatch(fetchCheckQueue({ token }));
            const checkQueueWebSocket = dispatch(connectWebSocketCheckQueue(token));

            return () => {
                checkQueueWebSocket();
            };
        }
    }, [dispatch]);



    useEffect(() => {
        console.log('QueuePopup checkQueueData', checkQueueData, checkQueueSuccess)

        if (checkQueueError) {
            console.error('QueuePopup checkQueueError', checkQueueError)
        }

        if (checkQueueSuccess && checkQueueData?.popup) {
            // console.log('checkQueueData?.popup', checkQueueData?.popup);

            if(checkQueueData.popup.flag_active === 'YES'){
                dispatch(setIsShowQueuePopup(true));

                const params = {
                    id: checkQueueData.id,
                };

                dispatch(fetchChangeQueue({ token, params }));
            }
        }

    }, [dispatch, checkQueueError, checkQueueSuccess, checkQueueData]);

    useEffect(() => {
        console.log('QueuePopup changeQueueError changeQueueSuccess', changeQueueError, changeQueueSuccess);
    }, [changeQueueError, changeQueueSuccess]);

    const handleClosePopup = () => {
        setIsInactive(false);
        if (isShowQueuePopup) dispatch(setIsShowQueuePopup(false));
    };

    return (
        <>
            {
                isShowQueuePopup && <Modal
                    show={isShowQueuePopup}
                    onClose={handleClosePopup}
                    body={<QueuePopupBody data={checkQueueData.popup} onClose={handleClosePopup} />}
                    showHeader={false}
                    width="343px" // Указываем ширину
                    height="343px"
                />
            }
        </>
    );
};

export default QueuePopup;
