import {ButtonStyled} from "../ButtonStyled/ButtonStyled"
import clsx from "clsx"
import styles from "./NavigationButtonsGroup.module.scss"
import React from "react"
import {NavigationButtonData} from "../types"
import {
  NavigationButtonsContainer,
  NavigationButtonsVariant
} from "../NavigationButtonsContainer/NavigationButtonsContainer"
import {ButtonStyledProfile} from "src/components/pages/Account/ButtonStyledProfile/ButtonStyledProfile"
import {AccountButtonId} from "../../redux/slices/menus/menuAccountSlice"
import {ProfileButtonId} from "../../redux/slices/menus/menuProfileSlice"
import {useTranslation} from "react-i18next"
import {WalletTabs} from "../../components/pages/Account/OtherTabs/MyWallet"
import {HistoryTabs} from "../../components/pages/Account/OtherTabs/History"

export type BonusesPageId = 'Activated' | 'All bonuses' | 'History'

type Props = {
  buttonData: Array<NavigationButtonData>
  activeButton: AccountButtonId | number | WalletTabs | HistoryTabs | BonusesPageId
  handleButtonClick: (id: AccountButtonId | number | ProfileButtonId | WalletTabs | HistoryTabs ) => void
  variant: NavigationButtonsVariant
}

type buttonContentProps = {
  button: NavigationButtonData
  isIconActive?: boolean
}


const ButtonContent = ({button, isIconActive}: buttonContentProps) => {
  const Icon = button.icon; // Если есть icon, это SVG-компонент
  const {t} = useTranslation()

  return (
    <div className={styles.buttonContent}>
      {t(button.label)}{" "}
      {Icon ? (
        <div className={styles.iconContainer}>
          <Icon
            {...(isIconActive && { stroke: "#F6F6F6", strokeOpacity: "1" })}
            className={button.label.length > 0 ? styles.imgWithMargin : ""}
          />
        </div>

      ) : (
        <img
          src={button.img}
          alt={button.imgAlt}
          className={button.label.length > 0 ? styles.imgWithMargin : ""}
        />
      )}
    </div>
  )
}

export const NavigationButtonsGroup = ({buttonData, activeButton, handleButtonClick, variant}: Props) => {
  return (
    <NavigationButtonsContainer variant={variant}>
      <div className={clsx(styles.buttonGroup, {
        [styles.buttonGroupGap]: variant === 'profile' || variant === 'dropdown',
      })} role="group" aria-label="Basic example">
        {buttonData.map((button) => {
          if (variant === "profileMain") {
            return (
              <ButtonStyledProfile
                key={button.id}
                variant={activeButton === button.id ? "selected" : "passive"}
                onClick={() => handleButtonClick(button.id)}
                className={clsx(
                  styles.navigationButton,
                  activeButton === button.id ? styles.activeButton : ""
                )}
              >
                <ButtonContent button={button} isIconActive={activeButton === button.id}/>
              </ButtonStyledProfile>
            )
          } else {
            return (
              <ButtonStyled
                key={button.id}
                variant={activeButton === button.id ? "primary" : "glass"}
                onClick={() => handleButtonClick(button.id)}
                className={clsx(
                  variant === "dropdown" ? styles.buttonDropdown : styles.buttonProfile,
                  styles.navigationButton
                )}
              >
                <ButtonContent button={button}/>
              </ButtonStyled>
            )
          }
        })}
      </div>
    </NavigationButtonsContainer>
  )
}