import React from 'react'
import {useEffect, ReactNode} from 'react'
import {useLocation} from 'react-router-dom'
import {useGetSeoTagsQuery} from "../api/api"

type Props = {
  children: ReactNode
}

export const SeoUpdater = ({children}: Props) => {
  const location = useLocation()
  const {data: seoTags} = useGetSeoTagsQuery({url: location.pathname, id_domain: 1})

  useEffect(() => {
    if (location.pathname.startsWith('/page/') || location.pathname === '/profile') {
      return;
    } // Special situation: logic inside Page.tsx will work, instead of code below
    // And second for /profile

    const updateMetaTag = (name: string, content: string | null) => {
      let tag = document.querySelector(`meta[name="${name}"]`);
      if (!tag) {
        if (content) {
          tag = document.createElement('meta');
          tag.setAttribute('name', name);
          document.head.appendChild(tag);
        }
      }
      if (tag && content) {
        tag.setAttribute('content', content);
      } else if (tag) {
        // Remove meta tag if content is missing
        document.head.removeChild(tag);
      }
    };

    if (seoTags && seoTags.data) {
      // Set meta tags with page data
      document.title = seoTags.data.title || 'Default Title';
      updateMetaTag('description', seoTags.data.description || 'Default description');
      updateMetaTag('keywords', seoTags.data.keywords || 'Default keywords');
    } else {
      // Set default values if there is no data
      // document.title = 'React App';
      updateMetaTag('description', 'Web site created using create-react-app');
      updateMetaTag('keywords', null);
    }
  }, [seoTags]);

  return <>{children}</>
}
