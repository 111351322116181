import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from "../../../../hooks"
import Alert from '../../../layouts/ui/alert/Alert'

import {
  interfaceSelector,
} from "../../../../redux/slices/interfaceSlice"

import {
  fetchSendPhoneConfirmation,
  resetStateSendPhoneConfirmation,
  selectSendPhoneConfirmationLoading,
  selectSendPhoneConfirmationSuccess,
  selectSendPhoneConfirmationError,
  selectSendPhoneConfirmationMessages,
  selectSendPhoneConfirmationData,
  selectSendPhoneConfirmationErrorArray
} from '../../../../redux/slices/player/sendPhoneConfirmationSlice'

import {
  fetchConfirmPhone,
  resetStateConfirmPhone,
  selectConfirmPhoneErrorArray,
  selectConfirmPhoneLoading,
  selectConfirmPhoneSuccess,
  selectConfirmPhoneError,
  selectConfirmPhoneMessages,
  selectConfirmPhoneData
} from '../../../../redux/slices/site/confirmPhone';



import { fetchGetPersonalInfo } from 'src/redux/actions/player/getPersonalInfoActions'
import {
  selectGetPersonalInfo,
  selectGetPersonalInfoError,
  selectGetPersonalInfoLoading,
  selectGetPersonalInfoSuccess
} from 'src/redux/selectors/player/getPersonalInfoSelectors'

// import {
//   fetchProjectSettings,
//   selectProjectSettingsData,
//   selectProjectSettingsSuccess
// } from 'src/redux/slices/getProjectSettingsSlice'

import {
  selectIsAuthenticated
} from "../../../../redux/slices/auth/isAuthenticatedSlice"
import {
  selectToken
} from "../../../../redux/slices/auth/loginSlice"
import styles from './SecurityPhoneVerification.module.scss'
import clsx from 'clsx'
import { ButtonStyled } from "../../../../common/ButtonStyled/ButtonStyled"
import { BrightInput } from "../../../../common/BrightInput/BrightInput"
import {useTranslation} from "react-i18next";


// interface SecurityPhoneVerification {
//     [key: string]: any; // Ключи — строки, значения могут быть разными
// }

interface SecurityPhoneVerification {
  phone_number: string,
  sms_code: string,
}

type FormState = SecurityPhoneVerification

interface ClientErrors {
  required: {
    [key: string]: {
      hasError: boolean;
      message: string;
    };
  };
}

interface ServerValidationErrors {
  phone_number: null | string,
  sms_code: null | string
}


// interface PersonalInfo {
//   phone: string;
// }

type PersonalInfoCombined = { id: number; subtype: string; code: string; name: string; flag_displayed: string; value?: string }[];

interface SecurityPhoneVerificationProps {
  onSuccess: () => void;
}

const SecurityPhoneVerification: React.FC<SecurityPhoneVerificationProps> = ({ onSuccess }) => {
  const dispatch = useAppDispatch()

  const { isShowPhoneNumberVerificationModal } = useAppSelector(interfaceSelector)

  const getPersonalInfo: PersonalInfoCombined = useSelector(selectGetPersonalInfo)
  const getPersonalInfoLoading = useSelector(selectGetPersonalInfoLoading)
  const getPersonalInfoError = useSelector(selectGetPersonalInfoError)
  const getPersonalInfoSuccess = useSelector(selectGetPersonalInfoSuccess)

  const sendPhoneConfirmationLoading = useSelector(selectSendPhoneConfirmationLoading);
  const sendPhoneConfirmationSuccess = useSelector(selectSendPhoneConfirmationSuccess);
  const sendPhoneConfirmationError = useSelector(selectSendPhoneConfirmationError);
  const sendPhoneConfirmationMessages = useSelector(selectSendPhoneConfirmationMessages);
  const sendPhoneConfirmationData = useSelector(selectSendPhoneConfirmationData);
  const sendPhoneConfirmationErrorArray = useSelector(selectSendPhoneConfirmationErrorArray);

  const confirmPhoneLoading = useSelector(selectConfirmPhoneLoading);
  const confirmPhoneSuccess = useSelector(selectConfirmPhoneSuccess);
  const confirmPhoneError = useSelector(selectConfirmPhoneError);
  const confirmPhoneMessages = useSelector(selectConfirmPhoneMessages);
  const confirmPhoneData = useSelector(selectConfirmPhoneData);
  const confirmPhoneErrorArray = useSelector(selectConfirmPhoneErrorArray);


  // const projectSettingsLoading = useSelector(selectProjectSettingsLoading)
  // const projectSettingsSuccess = useSelector(selectProjectSettingsSuccess)
  // const projectSettingsError = useSelector(selectProjectSettingsError)
  // const projectSettingsData = useSelector(selectProjectSettingsData)
  // const projectSettingsErrorArray = useSelector(selectProjectSettingsErrorArray)


  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)
  const {t} = useTranslation()

  const [isUpdatesSuccess, setIsUpdatesSuccess] = useState<boolean>(false)
  const [showErrors, setShowErrors] = useState(false) //это чтобы алерт с ошибками
  const [fields, setFields] = useState<string[]>([])
  const [expirationTimestamp, setExpirationTimestamp] = useState<number | null>();
  const [timeLeft, setTimeLeft] = useState<number>(0);

  const [screenView, setScreenView] = useState<'phone' | 'phone&sms_code'>('phone');

  const initClientErrors: ClientErrors = {
    required: {
      phone_number: {
        hasError: false,
        message: 'This field is required.'
      },
      sms_code: {
        hasError: false,
        message: 'This field is required.'
      }
    }
  }
  const [clientErrors, setClientErrors] = useState<ClientErrors>(initClientErrors);

  const initServerValidationErrors: ServerValidationErrors = {
    phone_number: null,
    sms_code: null,
  }
  const [serverValidationErrors, setServerValidationErrors] = useState<ServerValidationErrors>(initServerValidationErrors)

  const initialState: FormState = {
    phone_number: '',
    sms_code: '',
  }
  const [formState, setFormState] = useState<FormState>(initialState)

  const [phone, setPhone] = useState<null | string>(null)

  // Начальные значения из API
  const [isEditing, setIsEditing] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)

  // Сбрасываем стейт при открытии и закрытии модалки
  useEffect(() => {
    console.log('Current formState 1', formState)
    resetState();
  }, [isShowPhoneNumberVerificationModal])

  useEffect(() => {
    if (isAuthenticated && token) {
      console.log('Current formState 1-2', formState)
      dispatch(fetchGetPersonalInfo(token))
    }
  }, [dispatch, isAuthenticated, token])

  useEffect(() => {
    console.log('Current formState 2', formState)
    console.log('SecurityPhoneVerification getPersonalInfo', getPersonalInfo)

    if (screenView === 'phone') {
      setFields([
        'phone_number',
      ])
    } else {
      setFields([
        'phone_number',
        'sms_code',
      ])
    }

    if (getPersonalInfoSuccess) {
      const phoneInfo = getPersonalInfo.find(item => item.code === 'phone');
      
      if(phoneInfo?.value && phoneInfo.value.length > 0){
        setPhone(phoneInfo.value);

        setFormState({
          phone_number: phoneInfo.value,
          sms_code: '',
        });
        
      }else{
        setPhone(null);
      }
      

      

    }


  }, [token, getPersonalInfo, getPersonalInfoSuccess, screenView])

  useEffect(() => {
    console.log('Current formState 3', formState)
    if(phone !== null){
      // Если телефон уже есть, то сразу отправляем запрос на код
      if(token){
        setScreenView("phone&sms_code")
        dispatch(fetchSendPhoneConfirmation({ token: token, phone: formState.phone_number }));
      }else{
        setScreenView("phone")
      }
    }
  }, [dispatch, phone])
  


  useEffect(() => {
    console.log('Current formState 4', formState)
    setExpirationTimestamp(null)
    if (sendPhoneConfirmationSuccess && sendPhoneConfirmationData) {
      setScreenView('phone&sms_code');
      console.log('Current formState sendPhoneConfirmationData.phone', sendPhoneConfirmationData.phone)
      setFormState({
        phone_number: sendPhoneConfirmationData.phone,
        sms_code: '',
      });
      
      const expirationTimestamp = sendPhoneConfirmationData.sms_code_expiration;
      setExpirationTimestamp(expirationTimestamp)
      // Math.floor((expirationTimestamp * 1000 - Date.now()) / 1000)
    }
    if (sendPhoneConfirmationError) {
      if(sendPhoneConfirmationError.phone_number || sendPhoneConfirmationError.sms_code){
        setServerValidationErrors({
          phone_number: sendPhoneConfirmationError.phone_number || null,
          sms_code: sendPhoneConfirmationError.sms_code || null,
        })
      }else{
        // Если ошибки есть и они не связаны с инпутами, то отображаем общие ошибки внизу
        setShowErrors(true)
      }
      
    }

    if (confirmPhoneError) {
      setServerValidationErrors({
        phone_number: confirmPhoneError.phone_number || null,
        sms_code: confirmPhoneError.sms_code || null,
      })
    }

  }, [sendPhoneConfirmationSuccess, sendPhoneConfirmationError, confirmPhoneError, sendPhoneConfirmationData])

  useEffect(() => {
    console.log('Current formState 5', formState)
    if (expirationTimestamp) {
      const interval = setInterval(() => {
        const newTimeLeft = calculateTimeLeft(expirationTimestamp);
        setTimeLeft(newTimeLeft);

        if (newTimeLeft === 0) {
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setTimeLeft(0)
    }
  }, [expirationTimestamp])

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  
  useEffect(() => {
    console.log('Current formState 6', formState)
    if (confirmPhoneSuccess) {
      timerRef.current = setTimeout(() => {
        onSuccess();
      }, 3000);
    }
  }, [confirmPhoneSuccess, onSuccess]);

   useEffect(() => {
    console.log('Current formState 7', formState)
  }, [formState]);

  useEffect(() => {
    console.log('Current formState 8', formState)
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      resetState();
    };
  }, []);


  const resetState = () => {
    setExpirationTimestamp(null);
    setTimeLeft(0);
    setFormState(initialState);
    // setShowErrors(false)
    setClientErrors(initClientErrors);
    setServerValidationErrors(initServerValidationErrors)
    dispatch(resetStateSendPhoneConfirmation());
    dispatch(resetStateConfirmPhone());
    setScreenView('phone');
  }

  // In seconds
  const calculateTimeLeft = (expTimestamp: number) => Math.max(Math.floor((expTimestamp * 1000 - Date.now()) / 1000), 0);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const clientValidation = (): boolean => {
    const formElements = formRef.current?.elements
    let formIsValid = true

    const isFieldRequired = (fieldName: string) => {
      return clientErrors.required.hasOwnProperty(fieldName)
    };

    if (formElements) {
      Array.from(formElements).forEach(field => {
        const input = field as HTMLInputElement
        const inputName = input.name;
        const inputValue = input.value.trim();

        if (!inputValue && isFieldRequired(inputName)) {
          formIsValid = false

          setClientErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors }

            updatedErrors.required[inputName].hasError = true

            return updatedErrors
          })
        }
      })
    }


    return formIsValid
  }

  const handleInputChange = (code: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let value = e.target.value
    // console.log(code, value)
    const updatedFormState = {
      ...formState,
      [code]: value,
    }

    setFormState(updatedFormState)

    // Проверяем, изменились ли данные по сравнению с начальным состоянием
    setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))

    // Если поле заполнено (или отмечено), удаляем его из ошибок валидации
    setClientErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }

      // Проверка для строки перед использованием `trim()`
      const isFieldFilled = e.target.type === 'checkbox' ? value : (typeof value === 'string' && value.trim() !== '')

      if (isFieldFilled) {
        updatedErrors.required[code].hasError = false
      } else {
        updatedErrors.required[code].hasError = true
      }

      return updatedErrors
    })
  }

  const handleErrorClose = () => {
    setShowErrors(false) // Скрываем ошибки при нажатии на крестик
  }

  const onConfirm = () => {
    // setScreenView('phone&sms_code');
    if (clientValidation()) {
      const sms_code = formState.sms_code
      token && dispatch(fetchConfirmPhone({ token, sms_code }))
    } else {
      console.error('Confirm phone: Validation failed')
    }
  }

  const onSendCode = () => {
    if (clientValidation()) {
      token && dispatch(fetchSendPhoneConfirmation({ token: token, phone: formState.phone_number }))
    } else {
      console.error('Send code: Validation failed')
    }
  }

  const onResendCode = () => {
    // if (clientValidation()) {
    if (token) {
      // setExpirationTimestamp(null);
      // setTimeLeft(0);
      dispatch(fetchSendPhoneConfirmation({ token: token, phone: formState.phone_number  }));
      resetState();
    }
    // } else {
    //   console.error('Resend code: Validation failed')
    // }
  }

  // const handleButtonClickClear = () => {
  //   clearStates()
  //   clearValidationStates()
  //   setFormState(initialState)
  // }

  // const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()

  //   if (!token) {
  //     console.error('Token is missing')
  //     return // Прекращаем выполнение, если токен отсутствует
  //   }

  //   if (clientValidation()) {
  //     // dispatch(fetchUpdatePassword({token, params: formState})) // Передаем токен как строку
  //   } else {
  //     console.error('Validation failed')
  //   }
  // }


  const formatPlaceholder = (fieldName: string): string => {
    return fieldName
      .replace(/_/g, ' ') // Заменяем `_` на пробел
      .replace(/\b\w/g, (char) => char.toUpperCase()) // Делаем первую букву каждого слова заглавной
  }

  if (confirmPhoneSuccess) {
    return (
      <div className={clsx(styles.security)}>
        <div className={styles.title}>Success!</div>
        <p>{t('Phone confirmed successfully')}</p>
      </div>
    )
  }

  return (
    <div className={clsx(styles.security)}>
      <div className={styles.title}>{t('Phone confirmation')}</div>
      {screenView === 'phone' && <p>{t('Please, enter the phone number to resive confirmation SMS code')}</p>}
      {screenView === 'phone&sms_code' && <p>{t('Please, enter the SMS code to confirm your phone number')}</p>}
      <form
        // onSubmit={handleSubmit} 
        ref={formRef} className={styles.form}>
        {
          (fields as Array<keyof SecurityPhoneVerification>).map((nameField, indexField) => (
            <div key={indexField}>
              <BrightInput
                id={nameField}
                name={nameField}
                value={formState[nameField] || ''}
                disabled={nameField === 'phone_number' && screenView === 'phone&sms_code'}
                onChange={(e) => handleInputChange(nameField, e)}
                label={formatPlaceholder(nameField)}
                error={
                  (clientErrors.required[nameField].hasError ? clientErrors.required[nameField].message : null) ||
                  (serverValidationErrors[nameField])
                }
                variation={'profile'}
                className={styles.input}
                type={'text'}
              />
            </div>

          ))
        }

        {
          !sendPhoneConfirmationLoading &&
          sendPhoneConfirmationSuccess &&
          <div className='mb-4'>
            <p>{sendPhoneConfirmationMessages && t(sendPhoneConfirmationMessages[0])}</p>
            {timeLeft > 0 ? (
              <div>{t('You may request new code in')}: {formatTime(timeLeft)}</div>
            ) : (
              <div>{t('The code has expired')}</div>
            )}
          </div>
        }

        {screenView === 'phone' &&
          <>
            <ButtonStyled
              className="mt-4"
              variant='accent'
              isWidth100={true}
              onClick={() => onSendCode()}
              type="button"
            // disabled={!isEditing || updatePasswordLoading}
            >
              {sendPhoneConfirmationLoading ? t('Loading...') : t('Send code')}
            </ButtonStyled>
          </>
        }

        {screenView === 'phone&sms_code' &&
          <>
            <ButtonStyled
              isWidth100={true}
              onClick={() => onConfirm()}
              type="button"
            // disabled={!isEditing || updatePasswordLoading}
            >
              {confirmPhoneLoading ? t('Loading...') : t('Confirm')}
            </ButtonStyled>


            <ButtonStyled
              className="mt-4"
              variant='accent'
              isWidth100={true}
              onClick={() => onResendCode()}
              type="button"
              disabled={timeLeft > 0}
            >
              {sendPhoneConfirmationLoading ? t('Loading...') : t('Resend code')}
            </ButtonStyled>
          </>
        }



      </form>

      {showErrors && sendPhoneConfirmationErrorArray && sendPhoneConfirmationErrorArray.length > 0 && (
        <Alert onClose={handleErrorClose} type="error">
          {sendPhoneConfirmationErrorArray.map((error, index) => {
            return (<p key={index}>{error}</p>)
          })}
        </Alert>
      )}

      {showErrors && confirmPhoneErrorArray && confirmPhoneErrorArray.length > 0 && (
        <Alert onClose={handleErrorClose} type="error">
          {confirmPhoneErrorArray.map((error, index) => {
            return (<p key={index}>{error}</p>)
          })}
        </Alert>
      )}
    </div >


  )
}

export default SecurityPhoneVerification
