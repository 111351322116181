import React from 'react'
import styles from './BonusesGroup.module.scss'
import trophy_2_1 from 'src/assets/images/bonuses/trophy_2_1.png'
import treasure_chest_1 from 'src/assets/images/bonuses/treasure_chest_1.png'
import trophy_2_1_2 from 'src/assets/images/bonuses/trophy_2_1_2.png'
import clsx from 'clsx'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import {useTranslation} from "react-i18next";

const bonusesData = [
  { id: 1, img: trophy_2_1, title: 'Tournaments' },
  { id: 2, img: treasure_chest_1, title: 'Bonuses' },
  { id: 3, img: trophy_2_1_2, title: 'Vip club' },
]


export const BonusesGroup = () => {
  const {t} = useTranslation()
  return (
    <div className={clsx(styles.bonuses, stylesCommon.group)}>
      {bonusesData.map(({ id, img, title }) => (
        <div key={id} className={styles.item}>
          <img src={img} alt={t(title)} />
          <div className={styles.title}>{t(title)}</div>
        </div>
      ))}
    </div>
  )
}
