import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import GameList from '../../elems/GameList/GameList'
import {
  selectToken
} from "../../../redux/slices/auth/loginSlice"
import { GameItem } from "../../../common/types"
import styles from "./GameGroup.module.scss"
import { ScrollButton } from "../../../common/ScrollButton/ScrollButton"
import { useGetGameListQuery, useGetGameGroupContentBlockQuery } from "../../../api/api"
import { Pagination } from "../../../common/Pagination"
import { GAME_GROUP_GAMES_PER_PAGE, PAGE_PAGINATION_VALUES, SIBLING_COUNT } from "../../../common/constants"
import { BrightBreadcrumbs } from "../../../common/BrightBreadcrumbs/BrightBreadcrumbs"
import { addFavoriteGame } from "../../../redux/slices/addFavoriteGame"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { interfaceSelector } from "../../../redux/slices/interfaceSlice"
import { MobileStatus } from "../../../common/MobileStatus/MobileStatus"
import {ContentBlock} from "../../elems/ContentBlock/ContentBlock"

export const GameGroup: React.FC = () => {
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(GAME_GROUP_GAMES_PER_PAGE)
  const [groupId, setGroupId] = useState<null | number>(null)

  let { gameGroupSlug } = useParams<{ gameGroupSlug: string }>()

  const token = useSelector(selectToken)
  const { isMobile, selectedLanguage } = useAppSelector(interfaceSelector)

  const navigate = useNavigate()

  const dispatch = useAppDispatch()


  const { data: gameListData, isLoading, isError, isSuccess, refetch } = useGetGameListQuery(
    {
      token: token ? token : '',
      gameGroupSlug: gameGroupSlug ? gameGroupSlug : '',
      page: currentPage,
      pageSize: itemsPerPage,
    }
  )

  const {
    data: gameGroupContentBlockData,
    isLoading: isLoadingGroupContentBlock,
    isError: isErrorGroupContentBlock,
    isSuccess: isSuccessGroupContentBlock,
    error: errorGroupContentBlock,
    refetch: refetchGroupContentBlock
  } = useGetGameGroupContentBlockQuery(
    {
      group_id: groupId,
      language: selectedLanguage.id,
    },

    { skip: !groupId } // Skip the query if groupId is null or undefined
  );


  const groupName = gameListData && gameListData.data?.length > 0
    ? gameListData.data[0]?.group_name
    : '';

  console.log('groupName', groupName)

  useEffect(() => {
    if (isSuccess) {
      console.log('gameListData', gameListData)
      const groupId = gameListData && gameListData.data?.length > 0
        ? gameListData.data[0]?.group_id
        : null;
      groupId && setGroupId(groupId);
    } else {
      setGroupId(null)
    }

  }, [isSuccess, gameListData])

  const handleStarClick = async (gameId: number) => {
    if (token) {
      await dispatch(addFavoriteGame({ gameId, token }))
      refetch()
    }
  }

  const handlePlayClick = (game: GameItem) => {
    navigate(`/games/${game.slug}`)
  }

  const handleDemoClick = (game: GameItem) => {
    navigate(`/games/${game.slug}?mode=demo`)
  }

  const breadcrumbItems = [
    { label: 'Home page', path: '/', isLink: true },
    { label: 'Games', path: '/games', isLink: true },
    { label: gameGroupSlug || '', isLink: false }
  ]

  if (isLoading) return <div>Loading...</div>
  if (!gameListData) return <></>
  if (isError) return <div>GameGroups error...</div>

  return (
    <>
      {isMobile && <MobileStatus variant={'gameGroup'} title={groupName} />}
      <div className={styles.gameGroupContainer}>
        {!isMobile && <BrightBreadcrumbs items={breadcrumbItems} />}
        <div className={styles.gameGroup}>
          <div className={styles.nameContainer}>
            {!isMobile && <ScrollButton onClick={() => {
              navigate(-1)
            }} direction={"left"} />}
            <div className={styles.categoryName}>{gameGroupSlug}</div>
          </div>
          <GameList
            gameList={gameListData.data}
            onStarClick={handleStarClick}
            onDemoClick={handleDemoClick}
            onPlayClick={handlePlayClick}
          />
          {!(gameListData.pagination.total_count < itemsPerPage) &&
            <div className={styles.paginationContainer}>
              <Pagination
                variant="games"
                onPageChange={(value) => setCurrentPage(Number(value))}
                totalCount={gameListData.pagination.total_count}
                currentPage={currentPage}
                pageSize={itemsPerPage}
                siblingCount={SIBLING_COUNT}
                selectSettings={{
                  value: itemsPerPage.toString(),
                  onChangeOption: (value: string) => {
                    setItemsPerPage(Number(value))
                    setCurrentPage(1)
                  },
                  arr: PAGE_PAGINATION_VALUES.map((val) => val.toString()),
                }}
                isSelectorOfPagesHidden={true}
              />
            </div>
          }
        </div>
      </div>

      {gameGroupContentBlockData && gameGroupContentBlockData.data?.flag_active === "YES" &&(
        <div>
          <ContentBlock data={gameGroupContentBlockData} isLoading={isLoading} error={errorGroupContentBlock} isMobile={isMobile} />
        </div>
      )}

    </>
  )
}

/*todo: add portal functionality & SnackBar if game already in favorites */

