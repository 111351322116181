import styles from './AccountFavouriteGame.module.scss'
import {FavoriteGame} from "../../../../../../api/types"
import config from "../../../../../../config"
import React from "react"
import star_fill from 'src/assets/images/icons/star_fill.svg'
import arrow_right from 'src/assets/images/icons/arrow_right.svg'
import {ButtonStyled} from "../../../../../../common/ButtonStyled/ButtonStyled"

type Props = { 
  game: FavoriteGame
  
}

export const AccountFavouriteGame = ({game}: Props) => {
  return (
    <div className={styles.accountFavouriteGame}>
      <img src={`${config.fileUrls.game}${game.game.image_path_album}`} alt={game.game.name} className={styles.gameImg}/>
      <div className={styles.rightContainer}>
        <div className={styles.gameInfo}>
          <img src={star_fill} alt="star_fill"/>
          <div className={styles.gameName}>{game.game.name}</div>
          <div className={styles.provider}>{game.game.provider_name}</div>
        </div>
        <ButtonStyled variant={'glass'} className={styles.button} to={`/games/${game.game.slug}`}>
          <img src={arrow_right} alt="arrow_right" className={styles.arrowRight}/>
        </ButtonStyled>
      </div>
    </div>
  )
}

// todo: add provider_name