import styles from './DepositBanner.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import clsx from 'clsx'
import React, { useState } from "react"
import {BannerLeft} from "./BannerLeft/BannerLeft"
import {BannerRight} from "./BannerRight/BannerRight"
import {DepositRightSidebar} from "./DepositRightSidebar/DepositRightSidebar"
import {useAppSelector} from "../../../hooks"
import {interfaceSelector} from "../../../redux/slices/interfaceSlice"
import {isMobileScreen} from "../../../common/helpers"
import {selectIsAuthenticated} from "../../../redux/slices/auth/isAuthenticatedSlice"
import {DepositBannerBonus} from "../../../api/types"
import { useNavigate } from 'react-router-dom'

interface PlayData {
  currencyId: string;
  amount: string;
  bonusId?: string | null;
}

export const DepositBanner = () => {
  const navigate = useNavigate()
  const [selectedBonus, setSelectedBonus] = useState<DepositBannerBonus | null>(null)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const {currentScreenWidth} = useAppSelector(interfaceSelector)
  const isMobile = isMobileScreen(currentScreenWidth)

  const handleBonusSelect = (bonus: DepositBannerBonus) => {
    setSelectedBonus(bonus)
  }

  const handlePlay = (data: PlayData) => {
    if (!isAuthenticated) {
      const params = new URLSearchParams()
      params.append('currencyId', data.currencyId)
      params.append('amount', data.amount)
      if (selectedBonus?.id) {
        params.append('bonusId', selectedBonus.id.toString())
      }
      navigate(`/auth/signup?${params.toString()}`)
    } else {
      // Здесь логика для аутентифицированного пользователя
      console.log('Play data:', {
        ...data,
        bonusId: selectedBonus?.id || null
      })
    }
  }

  return (
    <div className={clsx(styles.depositBanner, !isMobile ? stylesCommon.group : stylesCommon.groupMobileMobileMargin24)}>
      <div className={styles.innerContainer}>
        <BannerLeft onSelectedBonus={handleBonusSelect} />
        <BannerRight onPlay={handlePlay} />
      </div>
      {!isMobile || isAuthenticated ? <DepositRightSidebar /> : null}
    </div>
  )
}