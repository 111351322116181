// getInfoSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type GetInfoSliceResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type GetInfoSliceError = {
  error: any;
  message: string;
};

interface GetInfoSliceState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: any[]; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: GetInfoSliceState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchGetInfo
interface GetInfoSliceParams {
  token: string;
  params?: {};
}

export const fetchGetInfo = createAsyncThunk<
  GetInfoSliceResponse,
  GetInfoSliceParams,
  { rejectValue: GetInfoSliceError }
>(
  'GetInfoSlice/fetchGetInfo',
  async ({ token }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchGetInfo:', `${config.serverUrl}/player/get-info`, { token });

      const response = await axios.get<GetInfoSliceResponse>(
        `${config.serverUrl}/player/get-info`,
        {
          params: {},
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        }
      );
      console.log('Attempting to fetchGetInfo response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error Get Info:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to Get Info .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);



const GetInfoSlice = createSlice({
  name: 'Profile/GetInfoSlice',
  initialState,
  reducers: {
    resetStateGetInfo: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchGetInfo.fulfilled, (state, action: PayloadAction<GetInfoSliceResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchGetInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateGetInfo } = GetInfoSlice.actions;

export const GetInfoReducer = GetInfoSlice.reducer;



// ---------
// Selectors
// ---------
export const selectGetInfoLoading = (state: RootState) => state.GetInfoReducer.loading;
export const selectGetInfoSuccess = (state: RootState) => state.GetInfoReducer.success;
export const selectGetInfoError = (state: RootState) => state.GetInfoReducer.error;
export const selectGetInfoMessages = (state: RootState) => state.GetInfoReducer.messages;
export const selectGetInfoData = (state: RootState) => state.GetInfoReducer.data;

export const selectGetInfoDataMainBalance = (state: RootState) => state.GetInfoReducer.data?.main_balance;

export const selectGetInfoErrorString = (state: RootState): string | null => {
  const error = state.GetInfoReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectGetInfoErrorArray = (state: RootState): string[] | null => {
  const error = state.GetInfoReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
