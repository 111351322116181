import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from "../../../hooks"
import {useTranslation} from "react-i18next"

import {
    closeModal as closeModalBonus,
    openModal as openModalBonus,
    selectIsShowModal as selectIsShowModalBonus
} from '../../../redux/slices/modals/modalBonusInfoSlice'

import {
    selectBonusList,
    selectBonusListError,
    selectBonusListLoading
} from 'src/redux/selectors/bonus/bonusListSelectors'

import {
    BalanceMainCurrency,
    selectBalanceMainCurrency,
    selectListBalancesSliceData,
    selectListBalancesSliceError,
    selectListBalancesSliceErrorArray,
    selectListBalancesSliceLoading,
    selectListBalancesSliceSuccess
} from "src/redux/slices/Account/listBalancesSlice"

import {selectIsAuthenticated} from "../../../redux/slices/auth/isAuthenticatedSlice"
import {selectToken} from "../../../redux/slices/auth/loginSlice"
import ModalAddBonus, {BonusFreespinsItem, OnBonus} from "../../layouts/modals/ModalAddBonus"
import {MyBonusesElement} from "../Account/Main/MyBonuses/MyBonusesElement/MyBonusesElement"
import styles from './Bonuses.module.scss'
import {BonusesPageId, NavigationButtonsGroup} from "../../../common/NavigationButtonsGroup/NavigationButtonsGroup"
import {NavigationButtonData} from "../../../common/types"
import {useGetActivatedBonusQuery, useGetBonusListQuery} from "../../../api/api"
import {ActivatedBonus, Bonus} from "../../../api/types"


const GameGroup = () => {
    // const dispatch = useDispatch()
    const dispatch = useAppDispatch()
    const {t} = useTranslation()

    const bonusList = useSelector(selectBonusList) as Bonus[] | null

    const loading = useSelector(selectBonusListLoading) as boolean
    const error = useSelector(selectBonusListError) as string | null
    const isAuthenticated = useSelector(selectIsAuthenticated) as boolean
    const token = useSelector(selectToken) as string | null

    console.log('token:', token)

    const listBalancesSliceData = useSelector(selectListBalancesSliceData)
    const listBalancesSliceError = useSelector(selectListBalancesSliceError)
    const listBalancesSliceSuccess = useSelector(selectListBalancesSliceSuccess)
    const listBalancesSliceLoading = useSelector(selectListBalancesSliceLoading)
    const listBalancesSliceErrorArray = useSelector(selectListBalancesSliceErrorArray)
    const balanceMainCurrency = useSelector(selectBalanceMainCurrency)

    const isShowModalBonus = useSelector(selectIsShowModalBonus)

    // const [showModalBonusInfo, setShowModalBonusInfo] = useState<boolean>(false)
    const [onBonus, setOnBonus] = useState<OnBonus | null>(null)
    const [mainCurrency, setMainCurrency] = useState<null | BalanceMainCurrency>(null)
    // const [spinCount, setSpinCount] = useState<number | string>("-");
    // const [wagerMultiplier, setWagerMultiplier] = useState<number | string>("-");

    /*const ids = ['Activated', 'ALL bonuses', 'History'];
    const buttonData = ids.map(id => ({ id, label: id }));*/

    const {data: BonusData} = useGetBonusListQuery(
        {token: token || ''},
        {
            skip: !isAuthenticated,
        }
    )

    const {data: ActivationBonusData} = useGetActivatedBonusQuery(
        {token: token || ''},
        {
            skip: !isAuthenticated,
        }
    )

    const buttonData = [
        {id: 'Activated', label: 'Activated'},
        {id: 'All bonuses', label: 'All bonuses'},
        {id: 'History', label: 'History'},
    ] as unknown as NavigationButtonData[]

    useEffect(() => {
        if (listBalancesSliceSuccess && balanceMainCurrency) {
            setMainCurrency({
                id: balanceMainCurrency.id,
                code: balanceMainCurrency.code,
            })
        }
    }, [token, listBalancesSliceSuccess, balanceMainCurrency, listBalancesSliceData])

    const [accountActiveButton, setAccountActiveButton] = useState<BonusesPageId>('Activated')

    // console.log('accountActiveButton:', accountActiveButton)

    console.log('bonusList:', bonusList)


    // useEffect(() => {
    //   if (balanceMainCurrency) {
    //     // TODO откуда заполнять закомментированные данные!
    //     const mainCurrencyId = balanceMainCurrency.id;
    //     const mainCurrencyCode = balanceMainCurrency.code;

    //     if (onBonus && onBonus.bonusFreespins.length > 0) {
    //       const freespins = onBonus.bonusFreespins.find(
    //         (item) => item.currency.id == mainCurrencyId
    //       );

    //       setSpinCount(
    //         (() => {
    //           if (freespins) {
    //             return `${freespins.spin_count}`;
    //           }
    //           return t("-");
    //         })()
    //       );
    //       setWagerMultiplier(
    //         (() => {
    //           if (freespins) {
    //             if (freespins.wager_multiplier) {
    //               return `X${freespins.wager_multiplier}`;
    //             }
    //           }
    //           return t("-");
    //         })()
    //       )

    //     }


    //   }


    // }, [dispatch, balanceMainCurrency, onBonus]);


    const getSpinCount = (bonus: Bonus) => {
        if (balanceMainCurrency) {
            const mainCurrencyId = balanceMainCurrency.id
            const mainCurrencyCode = balanceMainCurrency.code

            if (bonus && bonus.bonusFreespins.length > 0) {
                const freespins = bonus.bonusFreespins.find(
                    (item) => item.currency.id == mainCurrencyId
                )

                if (freespins) {
                    return `${freespins.spin_count}`
                }
            }
        }
        return t("-")
    }

    const getWagerMultiplier = (bonus: Bonus) => {
        if (balanceMainCurrency) {
            const mainCurrencyId = balanceMainCurrency.id
            const mainCurrencyCode = balanceMainCurrency.code

            if (bonus && bonus.bonusFreespins.length > 0) {
                const freespins = bonus.bonusFreespins.find(
                    (item) => item.currency.id == mainCurrencyId
                )

                if (freespins) {
                    if (freespins.wager_multiplier) {
                        return `X${freespins.wager_multiplier}`
                    }
                }
            }
        }
        return t("-")
    }


    const onClickMoreInfo = (bonus: Bonus | ActivatedBonus) => {
        const b = 'bonus' in bonus ? bonus.bonus : bonus // check if it is nested or not

        setOnBonus({
            id: b.id,
            bonusEventDeposits: b.bonusEventDeposits || [],
            bonusAccruals: b.bonusAccruals || [],
            wagering_type: b.wagering_type,
            bonusWagerings: b.bonusWagerings || [],
            bonusFreespins: b.bonusFreespins || [],
            bonusWageringToward: b.bonusWageringToward || [],
        })
        dispatch(openModalBonus())
    }

    const onClickGetBonus = async (e: React.MouseEvent<HTMLButtonElement>, bonus: Bonus) => {
        e.preventDefault()
        console.log(`onClickGetBonus`, bonus)
    }

    const handleButtonClick = (id: string | number) => {
        setAccountActiveButton(id as BonusesPageId)
    }

    console.log('accountActiveButton:', accountActiveButton)

    return (
        <div className={styles.bonuses}>
            <div className={styles.title}>Bonuses</div>
            <div className={styles.navigationContainer}>
                <NavigationButtonsGroup buttonData={buttonData} activeButton={accountActiveButton}
                                        handleButtonClick={handleButtonClick} variant={'profile'}/>
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <div className={styles.bonusElements}>
                    {accountActiveButton === 'Activated' ? (
                        ActivationBonusData &&
                        ActivationBonusData.data.map((bonus, i: number) => (
                            <div key={i}>
                                <MyBonusesElement
                                    bonusHeader={bonus.bonus.bonusLangs[0]?.name}
                                    bonusText={bonus.bonus.bonusLangs[0]?.description}
                                    variant="bonusPageActivated"
                                    onLearnMore={() => void onClickMoreInfo(bonus.bonus)}
                                    bonusImg={bonus.bonus.image_path || ''}
                                    isActive={bonus.status === 'active'}
                                />
                            </div>
                        ))
                    ) : (
                        BonusData &&
                        BonusData.data.map((bonus, i: number) => (
                            <div key={i}>
                                <MyBonusesElement
                                    bonusHeader={bonus.bonusLangs[0].name}
                                    bonusText={bonus.bonusLangs[0].description}
                                    variant="bonusPage"
                                    onLearnMore={() => void onClickMoreInfo(bonus)}
                                    bonusImg={bonus.image_path ? bonus.image_path : ''}
                                    isActive={bonus.status === 'active'}
                                />
                            </div>
                        ))
                    )}
                </div>
            )}


            <ModalAddBonus
                isShowModal={isShowModalBonus}
                onCloseModal={() => dispatch(closeModalBonus())}
                onBonus={onBonus}
            />
        </div>

    )
}

export default GameGroup
