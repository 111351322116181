// deleteResponsibleGamingSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type DeleteResponsibleGamingResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type DeleteResponsibleGamingError = {
  error: any;
  message: string;
};

interface DeleteResponsibleGamingState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: any[]; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: DeleteResponsibleGamingState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchDeleteResponsibleGaming
export interface DeleteResponsibleGamingParams {
  token: string;
  type_limit: string;
}

export const fetchDeleteResponsibleGaming = createAsyncThunk<
  DeleteResponsibleGamingResponse,
  DeleteResponsibleGamingParams,
  { rejectValue: DeleteResponsibleGamingError }
>(
  'DeleteResponsibleGaming/fetchDeleteResponsibleGaming',
  async ({ token, type_limit }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchDeleteResponsibleGaming:', `${config.serverUrl}/player/delete-responsible-gaming`, { token, type_limit });

      const response = await axios.post<DeleteResponsibleGamingResponse>(
        `${config.serverUrl}/player/delete-responsible-gaming`,
        { type_limit },
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Attempting to fetchDeleteResponsibleGaming response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error delete responsible gaming:', error);

      if (axios.isAxiosError(error)) {
        if (!error.response) {
          return rejectWithValue({
            error: [error.code || 'Network error'],
            message: 'Network error. Please check your connection.',
          });
        }
        return rejectWithValue({
          error: error.response.data.errors || error.response.data,
          message: error.response.data.message || error.response.data.message || 'Failed to delete responsible gaming.',
        });
      }

      return rejectWithValue({
        error: ['Unknown error'],
        message: 'An unknown error occurred.',
      });
    }
  }
);



const DeleteResponsibleGamingSlice = createSlice({
  name: 'DeleteResponsibleGaming',
  initialState,
  reducers: {
    resetStateDeleteResponsibleGaming: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeleteResponsibleGaming.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchDeleteResponsibleGaming.fulfilled, (state, action: PayloadAction<DeleteResponsibleGamingResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchDeleteResponsibleGaming.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateDeleteResponsibleGaming } = DeleteResponsibleGamingSlice.actions;

export const DeleteResponsibleGamingReducer = DeleteResponsibleGamingSlice.reducer;



// ---------
// Selectors
// ---------
export const selectDeleteResponsibleGamingLoading = (state: RootState) => state.DeleteResponsibleGamingReducer.loading;
export const selectDeleteResponsibleGamingSuccess = (state: RootState) => state.DeleteResponsibleGamingReducer.success;
export const selectDeleteResponsibleGamingError = (state: RootState) => state.DeleteResponsibleGamingReducer.error;
export const selectDeleteResponsibleGamingMessages = (state: RootState) => state.DeleteResponsibleGamingReducer.messages;
export const selectDeleteResponsibleGamingData = (state: RootState) => state.DeleteResponsibleGamingReducer.data;

export const selectDeleteResponsibleGamingErrorString = (state: RootState): string | null => {
  const error = state.DeleteResponsibleGamingReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectDeleteResponsibleGamingMessagesString = (state: RootState): string | null => {
  const messages = state.DeleteResponsibleGamingReducer.messages;

  if (Array.isArray(messages)) {
    return messages.join('\n');
  }

  if (typeof messages === 'object' && messages !== null) {
    return Object.entries(messages)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return messages;
};

export const selectDeleteResponsibleGamingErrorArray = (state: RootState): string[] | null => {
  const error = state.DeleteResponsibleGamingReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
