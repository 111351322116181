// modalBonusInfoSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface ModalBonusInfoState {
  isShowModal: boolean;
  currencyId?: number | null;
}

const initialState: ModalBonusInfoState = {
  isShowModal: false,
  currencyId: 2,
};

const modalBonusInfoSlice = createSlice({
  name: 'modalBonusInfoSlice',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isShowModal = true;
    },
    closeModal: (state) => {
      state.isShowModal = false;
      state.currencyId = null;
    },
    setCurrencyId: (state, action: PayloadAction<number | null>) => {
      state.currencyId = action.payload;
    },
    setModalBonusInfoState: (state, action: PayloadAction<{isShowModal: boolean; currencyId: number | null }>) => {
      state.isShowModal = action.payload.isShowModal;
      state.currencyId = action.payload.currencyId;
    },
  },
});

export const { openModal, closeModal, setCurrencyId, setModalBonusInfoState } = modalBonusInfoSlice.actions;
export const ModalBonusInfoReducer = modalBonusInfoSlice.reducer;
// export default modalBonusInfoSlice.reducer;

export const selectIsShowModal = (state: RootState) => state.ModalBonusInfoReducer.isShowModal;
export const selectCurrencyIdModal = (state: RootState) => state.ModalBonusInfoReducer.currencyId;

