import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import styles from './AccountFavouriteGames.module.scss'
import clsx from 'clsx'
import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {selectListFavoriteGames} from "src/redux/selectors/player/listFavoriteGamesSelectors"
import {
  selectIsAuthenticated
} from "../../../../../redux/slices/auth/isAuthenticatedSlice"
import {
  selectToken
} from "../../../../../redux/slices/auth/loginSlice"
import {fetchToListFavoriteGames} from "src/redux/actions/player/listFavoriteGamesActions"
import {FavoriteGame} from "src/api/types"
import {AccountFavouriteGame} from "./AccountFavouriteGame/AccountFavouriteGame"
import {ProfileShowAllLink} from "../../common/ProfileShowAllLink/ProfileShowAllLink"
import {ButtonStyled} from "../../../../../common/ButtonStyled/ButtonStyled"
import {useTranslation} from "react-i18next";

export const AccountFavouriteGames = () => {
  const dispatch = useDispatch()
  const listFavoriteGames = useSelector(selectListFavoriteGames) as FavoriteGame[]
  const listWithoutFirstThree = listFavoriteGames.slice(0, 3);
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)
  const {t} = useTranslation()

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch<any>(fetchToListFavoriteGames(token))
    }
  }, [dispatch, isAuthenticated, token])

  return (
    <div className={clsx(stylesCommon.accountElement, styles.accountFavouriteGames)}>
      <div className={styles.headerContainer}>
        <div className={stylesCommon.header}>{t('Favourite games')}</div>
        <ProfileShowAllLink to={'/favorite-games'} />
      </div>
      <div className={styles.games}>
        {
          listWithoutFirstThree.length === 0
            ? <div className={styles.noGamesContainer}>
              <div className={styles.noGamesText}>{t('You don’t have favourite games so far')}</div>
              <ButtonStyled paddingVariant={'14px 18px'} height={'48px'} variant={'accent'} to={'/games'}>{t('Choose games')}</ButtonStyled>
            </div>
            : <>{
              listWithoutFirstThree.map((game) => (
                <AccountFavouriteGame key={game.player_game_favorite_id} game={game} />
              ) )
            }</>
          }
      </div>
    </div>
  )
}