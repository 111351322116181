import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from "../../../../hooks"
import Alert from '../../../layouts/ui/alert/Alert'

import {
  fetchSendEmailConfirmation,
  resetStateSendEmailConfirmation,
  selectSendEmailConfirmationLoading,
  selectSendEmailConfirmationSuccess,
  selectSendEmailConfirmationError,
  selectSendEmailConfirmationMessages,
  selectSendEmailConfirmationData,
  selectSendEmailConfirmationErrorArray
} from '../../../../redux/slices/player/sendEmailConfirmationSlice'

import {
  fetchConfirmEmail,
  resetStateConfirmEmail,
  selectConfirmEmailErrorArray,
  selectConfirmEmailLoading,
  selectConfirmEmailSuccess,
  selectConfirmEmailError,
  selectConfirmEmailMessages,
  selectConfirmEmailData
} from '../../../../redux/slices/site/confirmEmail';



import { fetchGetPersonalInfo } from 'src/redux/actions/player/getPersonalInfoActions'
import {
  selectGetPersonalInfo,
  selectGetPersonalInfoError,
  selectGetPersonalInfoLoading,
  selectGetPersonalInfoSuccess
} from 'src/redux/selectors/player/getPersonalInfoSelectors'

// import {
//   fetchProjectSettings,
//   selectProjectSettingsData,
//   selectProjectSettingsSuccess
// } from 'src/redux/slices/getProjectSettingsSlice'

import {
  selectIsAuthenticated
} from "../../../../redux/slices/auth/isAuthenticatedSlice"
import {
  selectToken
} from "../../../../redux/slices/auth/loginSlice"
import styles from './SecurityEmailVerification.module.scss'
import clsx from 'clsx'
import { ButtonStyled } from "../../../../common/ButtonStyled/ButtonStyled"
import { BrightInput } from "../../../../common/BrightInput/BrightInput"
import { setIsShowPasswordModal, setPasswordSuccessModalShow } from "../../../../redux/slices/interfaceSlice"
import {useTranslation} from "react-i18next";


// interface SecurityEmailVerification {
//     [key: string]: any; // Ключи — строки, значения могут быть разными
// }

interface SecurityEmailVerification {
  email: string,
  token: string,
}

type FormState = SecurityEmailVerification

interface ClientErrors {
  required: {
    [key: string]: {
      hasError: boolean;
      message: string;
    };
  };
}

interface ServerValidationErrors {
  email: null | string,
  token: null | string
}


type PersonalInfoCombined = { id: number; subtype: string; code: string; name: string; flag_displayed: string; value?: string }[];

interface SecurityEmailVerificationProps {
  onSuccess: () => void;
}

const SecurityEmailVerification: React.FC<SecurityEmailVerificationProps> = ({ onSuccess }) => {
  const dispatch = useAppDispatch()

  const getPersonalInfo: PersonalInfoCombined = useSelector(selectGetPersonalInfo)
  const getPersonalInfoLoading = useSelector(selectGetPersonalInfoLoading)
  const getPersonalInfoError = useSelector(selectGetPersonalInfoError)
  const getPersonalInfoSuccess = useSelector(selectGetPersonalInfoSuccess)

  const sendEmailConfirmationLoading = useSelector(selectSendEmailConfirmationLoading);
  const sendEmailConfirmationSuccess = useSelector(selectSendEmailConfirmationSuccess);
  const sendEmailConfirmationError = useSelector(selectSendEmailConfirmationError);
  const sendEmailConfirmationMessages = useSelector(selectSendEmailConfirmationMessages);
  const sendEmailConfirmationData = useSelector(selectSendEmailConfirmationData);
  const sendEmailConfirmationErrorArray = useSelector(selectSendEmailConfirmationErrorArray);

  const confirmEmailLoading = useSelector(selectConfirmEmailLoading);
  const confirmEmailSuccess = useSelector(selectConfirmEmailSuccess);
  const confirmEmailError = useSelector(selectConfirmEmailError);
  const confirmEmailMessages = useSelector(selectConfirmEmailMessages);
  const confirmEmailData = useSelector(selectConfirmEmailData);
  const confirmEmailErrorArray = useSelector(selectConfirmEmailErrorArray);


  // const projectSettingsLoading = useSelector(selectProjectSettingsLoading)
  // const projectSettingsSuccess = useSelector(selectProjectSettingsSuccess)
  // const projectSettingsError = useSelector(selectProjectSettingsError)
  // const projectSettingsData = useSelector(selectProjectSettingsData)
  // const projectSettingsErrorArray = useSelector(selectProjectSettingsErrorArray)


  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)
  const {t} = useTranslation()

  const [isUpdatesSuccess, setIsUpdatesSuccess] = useState<boolean>(false)
  const [showErrors, setShowErrors] = useState(false) //это чтобы алерт с ошибками
  const [fields, setFields] = useState<string[]>([])
  const [expirationTimestamp, setExpirationTimestamp] = useState<number | null>();
  const [timeLeft, setTimeLeft] = useState<number>(0);

  const [screenView, setScreenView] = useState<'email' | 'email&token'>('email');

  const initClientErrors: ClientErrors = {
    required: {
      email: {
        hasError: false,
        message: 'This field is required.'
      },
      token: {
        hasError: false,
        message: 'This field is required.'
      }
    }
  }
  const [clientErrors, setClientErrors] = useState<ClientErrors>(initClientErrors);

  const initServerValidationErrors: ServerValidationErrors = {
    email: null,
    token: null,
  }
  const [serverValidationErrors, setServerValidationErrors] = useState<ServerValidationErrors>(initServerValidationErrors)

  const initialState: FormState = {
    email: '',
    token: '',
  }
  const [formState, setFormState] = useState<FormState>(initialState)

  // Начальные значения из API
  const [isEditing, setIsEditing] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    resetState();

  }, [dispatch])

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(fetchGetPersonalInfo(token))
    }
  }, [dispatch, isAuthenticated, token])

  useEffect(() => {
    console.log('SecurityEmailVerification getPersonalInfo', getPersonalInfo)

    if (screenView === 'email') {
      setFields([
        'email',
      ])
    } else {
      setFields([
        'email',
        'token',
      ])
    }

    if (getPersonalInfoSuccess) {
      const emailInfo = getPersonalInfo.find(item => item.code === 'email');

      setFormState({
        email: emailInfo?.value || '',
        token: '',
      });

      // Если телефон уже есть, то сразу отправляем запрос на код
      if(emailInfo && token){
        setScreenView("email&token")
        dispatch(fetchSendEmailConfirmation({ token }));
      }

    }


  }, [dispatch, token, getPersonalInfo, getPersonalInfoSuccess, screenView])

  useEffect(() => {
    setExpirationTimestamp(null)

    if (sendEmailConfirmationSuccess) {
      setScreenView('email&token');
      const expirationTimestamp = sendEmailConfirmationData.token_expiration;
      setExpirationTimestamp(expirationTimestamp)
      // Math.floor((expirationTimestamp * 1000 - Date.now()) / 1000)
    }
    if (sendEmailConfirmationError) {
      if(sendEmailConfirmationError.email || sendEmailConfirmationError.token){
        setServerValidationErrors({
          email: sendEmailConfirmationError.email || null,
          token: sendEmailConfirmationError.token || null,
        })
      }else{
        // Если ошибки есть и они не связаны с инпутами, то отображаем общие ошибки внизу
        setShowErrors(true)
      }
      
    }

    if (confirmEmailError) {
      setServerValidationErrors({
        email: confirmEmailError.email || null,
        token: confirmEmailError.token || null,
      })
    }

  }, [dispatch, sendEmailConfirmationSuccess, sendEmailConfirmationError, confirmEmailError, sendEmailConfirmationData])

  useEffect(() => {
    if (expirationTimestamp) {
      const interval = setInterval(() => {
        const newTimeLeft = calculateTimeLeft(expirationTimestamp);
        setTimeLeft(newTimeLeft);

        if (newTimeLeft === 0) {
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setTimeLeft(0)
    }
  }, [expirationTimestamp])

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  
  useEffect(() => {
    if (confirmEmailSuccess) {
      timerRef.current = setTimeout(() => {
        onSuccess();
      }, 3000);
    }
  }, [confirmEmailSuccess, onSuccess]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      resetState();
    };
  }, [dispatch]);


  const resetState = () => {
    setExpirationTimestamp(null);
    setTimeLeft(0);
    // setShowErrors(false)
    setClientErrors(initClientErrors);
    setServerValidationErrors(initServerValidationErrors)
    dispatch(resetStateSendEmailConfirmation());
    dispatch(resetStateConfirmEmail());
    setScreenView('email');
  }

  // In seconds
  const calculateTimeLeft = (expTimestamp: number) => Math.max(Math.floor((expTimestamp * 1000 - Date.now()) / 1000), 0);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const clientValidation = (): boolean => {
    const formElements = formRef.current?.elements
    let formIsValid = true

    const isFieldRequired = (fieldName: string) => {
      return clientErrors.required.hasOwnProperty(fieldName)
    };

    if (formElements) {
      Array.from(formElements).forEach(field => {
        const input = field as HTMLInputElement
        const inputName = input.name;
        const inputValue = input.value.trim();

        if (!inputValue && isFieldRequired(inputName)) {
          formIsValid = false

          setClientErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors }

            updatedErrors.required[inputName].hasError = true

            return updatedErrors
          })
        }
      })
    }


    return formIsValid
  }

  const handleInputChange = (code: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let value = e.target.value
    // console.log(code, value)
    const updatedFormState = {
      ...formState,
      [code]: value,
    }

    setFormState(updatedFormState)

    // Проверяем, изменились ли данные по сравнению с начальным состоянием
    setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))

    // Если поле заполнено (или отмечено), удаляем его из ошибок валидации
    setClientErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }

      // Проверка для строки перед использованием `trim()`
      const isFieldFilled = e.target.type === 'checkbox' ? value : (typeof value === 'string' && value.trim() !== '')

      if (isFieldFilled) {
        updatedErrors.required[code].hasError = false
      } else {
        updatedErrors.required[code].hasError = true
      }

      return updatedErrors
    })
  }

  const handleErrorClose = () => {
    setShowErrors(false) // Скрываем ошибки при нажатии на крестик
  }

  const onConfirm = () => {
    // setScreenView('email&token');
    if (clientValidation()) {
      const token = formState.token
      console.error('Confirm email: token', token)
      token && dispatch(fetchConfirmEmail({ token }))
    } else {
      console.error('Confirm email: Validation failed')
    }
  }

  const onSendCode = () => {
    if (clientValidation()) {
      token && dispatch(fetchSendEmailConfirmation({ token }))
    } else {
      console.error('Send code: Validation failed')
    }
  }

  const onResendCode = () => {
    // if (clientValidation()) {
    if (token) {
      // setExpirationTimestamp(null);
      // setTimeLeft(0);
      dispatch(fetchSendEmailConfirmation({ token }));
      resetState();
    }
    // } else {
    //   console.error('Resend code: Validation failed')
    // }
  }

  // const handleButtonClickClear = () => {
  //   clearStates()
  //   clearValidationStates()
  //   setFormState(initialState)
  // }

  // const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()

  //   if (!token) {
  //     console.error('Token is missing')
  //     return // Прекращаем выполнение, если токен отсутствует
  //   }

  //   if (clientValidation()) {
  //     // dispatch(fetchUpdatePassword({token, params: formState})) // Передаем токен как строку
  //   } else {
  //     console.error('Validation failed')
  //   }
  // }


  const formatPlaceholder = (fieldName: string): string => {
    return fieldName
      .replace(/_/g, ' ') // Заменяем `_` на пробел
      .replace(/\b\w/g, (char) => char.toUpperCase()) // Делаем первую букву каждого слова заглавной
  }

  if (confirmEmailSuccess) {
    return (
      <div className={clsx(styles.security)}>
        <div className={styles.title}>Success!</div>
        <p>{t('Email confirmed successfully')}</p>
      </div>
    )
  }

  return (
    <div className={clsx(styles.security)}>
      <div className={styles.title}>{t('Email confirmation')}</div>
      <p>{t('Please, enter the token to confirm your email')}</p>
      <form
        // onSubmit={handleSubmit} 
        ref={formRef} className={styles.form}>
        {
          (fields as Array<keyof SecurityEmailVerification>).map((nameField, indexField) => (
            <div key={indexField}>
              <BrightInput
                id={nameField}
                name={nameField}
                value={formState[nameField] || ''}
                disabled={nameField === 'email' && formState.email.length > 0}
                onChange={(e) => handleInputChange(nameField, e)}
                label={formatPlaceholder(nameField)}
                error={
                  (clientErrors.required[nameField].hasError ? clientErrors.required[nameField].message : null) ||
                  (serverValidationErrors[nameField])
                }
                variation={'profile'}
                className={styles.input}
                type={'text'}
              />
            </div>

          ))
        }

        {
          !sendEmailConfirmationLoading &&
          sendEmailConfirmationSuccess &&
          <div className='mb-4'>
            <p>{t(sendEmailConfirmationMessages[0])}</p>
            {timeLeft > 0 ? (
              <div>{t('You may request new code in')}: {formatTime(timeLeft)}</div>
            ) : (
              <div>{t('The code has expired')}</div>
            )}
          </div>
        }

        {screenView === 'email' &&
          <>
            <ButtonStyled
              className="mt-4"
              variant='accent'
              isWidth100={true}
              onClick={() => onSendCode()}
              type="button"
            // disabled={!isEditing || updatePasswordLoading}
            >
              {sendEmailConfirmationLoading ? t('Loading...') : t('Send code')}
            </ButtonStyled>
          </>
        }

        {screenView === 'email&token' &&
          <>
            <ButtonStyled
              isWidth100={true}
              onClick={() => onConfirm()}
              type="button"
            // disabled={!isEditing || updatePasswordLoading}
            >
              {confirmEmailLoading ? t('Loading...') : t('Confirm')}
            </ButtonStyled>


            <ButtonStyled
              className="mt-4"
              variant='accent'
              isWidth100={true}
              onClick={() => onResendCode()}
              type="button"
              disabled={timeLeft > 0}
            >
              {sendEmailConfirmationLoading ? t('Loading...') : t('Resend code')}
            </ButtonStyled>
          </>
        }



      </form>

      {showErrors && sendEmailConfirmationErrorArray && sendEmailConfirmationErrorArray.length > 0 && (
        <Alert onClose={handleErrorClose} type="error">
          {sendEmailConfirmationErrorArray.map((error, index) => {
            return (<p key={index}>{error}</p>)
          })}
        </Alert>
      )}

      {showErrors && confirmEmailErrorArray && confirmEmailErrorArray.length > 0 && (
        <Alert onClose={handleErrorClose} type="error">
          {confirmEmailErrorArray.map((error, index) => {
            return (<p key={index}>{error}</p>)
          })}
        </Alert>
      )}
    </div >


  )
}

export default SecurityEmailVerification
