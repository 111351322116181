import React from "react";
import {UserIconProps} from "../../../../common/types"

export const User_02Icon = ({ stroke = "white", strokeOpacity = '0.6', className }: UserIconProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="user-02">
      <g id="Icon">
        <path
          d="M8.10032 10C5.98694 10 4.10752 11.0204 2.91099 12.604C2.65346 12.9448 2.5247 13.1152 2.52891 13.3455C2.53216 13.5235 2.6439 13.7479 2.7839 13.8578C2.96512 14 3.21624 14 3.71848 14H12.4822C12.9844 14 13.2355 14 13.4167 13.8578C13.5568 13.7479 13.6685 13.5235 13.6717 13.3455C13.676 13.1152 13.5472 12.9448 13.2897 12.604C12.0931 11.0204 10.2137 10 8.10032 10Z"
          stroke={stroke}
          strokeOpacity={strokeOpacity}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.10032 8C9.75718 8 11.1003 6.65685 11.1003 5C11.1003 3.34315 9.75718 2 8.10032 2C6.44347 2 5.10032 3.34315 5.10032 5C5.10032 6.65685 6.44347 8 8.10032 8Z"
          stroke={stroke}
          strokeOpacity={strokeOpacity}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
