// interfaceSlice.ts
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {MenuItemWithoutChildren} from "../../api/types"
import { RootState } from '../store';

type successModalType = {
  show: boolean
  variant: 'password' | 'profile'
}

type successPopup = {
  show: boolean
  text: string
}

export type authTabsType = 'login' | 'signup' | 'password_recovery'

type InitialState = {
  childrenMenu: {
    isChildrenMenuOpened: boolean
    menuPosition: number
    selectedMenu: MenuItemWithoutChildren[] | null
  }
  selectedLanguage: {
    title: string
    iso_code: string
    id: number
  }
  sidebarHeight: number
  isListBalancesDropdownShown: boolean
  isBalanceLoadingAfterSwitch: boolean
  termTopHeight: number
  faqPageHeaderContainerHeight: number
  isShowPasswordModal: boolean
  isShowPhoneNumberVerificationModal: boolean
  isShowEmailVerificationModal: boolean
  isShowPopup: boolean
  successPopup: successPopup
  isShowQueuePopup: boolean
  authActiveTab: authTabsType
  successModal: successModalType
  currentScreenWidth: number
  isMobile: boolean
  isMobileMenuOpened: boolean
    showModalAddWallet: boolean
}

const initialState: InitialState = {
    selectedLanguage: JSON.parse(localStorage.getItem('selectedLanguage') || '{"title": "English", "iso_code": "en", "id": 1}'),
    childrenMenu: {
        isChildrenMenuOpened: false,
        menuPosition: 0,
        selectedMenu: null,
    },
    sidebarHeight: 0,
    isListBalancesDropdownShown: false,
    isBalanceLoadingAfterSwitch: false, // this is for MoneyBlock & ListBalancesDropdown
    termTopHeight: 0,
    faqPageHeaderContainerHeight: 0,
    isShowPasswordModal: false,
    isShowPhoneNumberVerificationModal: false,
    isShowEmailVerificationModal: false,
    isShowPopup: false,
    successPopup: {
        show: false,
        text: ''
    },
    isShowQueuePopup: false,
    authActiveTab: 'login',
    successModal: {
        show: false,
        variant: 'password'
    },
    currentScreenWidth: 0, // for adaptive
    isMobile: false,
    isMobileMenuOpened: false, // for mobile menu
    showModalAddWallet: false
}

const interfaceSlice = createSlice({
    name: 'interface',
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.selectedLanguage = action.payload
            localStorage.setItem('selectedLanguage', JSON.stringify(action.payload))
        },
        setIsChildrenMenuOpened: (state, action) => {
            state.childrenMenu.isChildrenMenuOpened = action.payload
        },
        setChildrenMenuPosition: (state, action) => {
            state.childrenMenu.menuPosition = action.payload
        },
        setSelectedMenu: (state, action) => {
            state.childrenMenu.selectedMenu = action.payload
        },
        clearChildrenMenuData: (state) => {
            state.childrenMenu.isChildrenMenuOpened = false
            state.childrenMenu.menuPosition = 0
            state.childrenMenu.selectedMenu = null
        },
        setSidebarHeight: (state, action) => {
            state.sidebarHeight = action.payload
        },
        setListBalancesDropdownShown: (state, action: PayloadAction<boolean>) => {
            state.isListBalancesDropdownShown = action.payload
        },
        setBalanceLoadingAfterSwitch: (state, action) => {
            state.isBalanceLoadingAfterSwitch = action.payload
        },
        setTermTopHeight: (state, action) => {
            state.termTopHeight = action.payload
        },
        setFaqPageHeaderContainerHeight: (state, action) => {
            state.faqPageHeaderContainerHeight = action.payload
        },
        setIsShowPasswordModal: (state, action) => {
            state.isShowPasswordModal = action.payload
        },
        setIsShowPhoneNumberVerificationModal: (state, action) => {
            state.isShowPhoneNumberVerificationModal = action.payload
        },
        setIsShowEmailVerificationModal: (state, action) => {
            state.isShowEmailVerificationModal = action.payload
        },
        setIsShowPopup: (state, action) => {
            state.isShowPopup = action.payload
        },
        setSuccessPopup: (state, action: PayloadAction<successPopup>) => {
            state.successPopup.show = action.payload.show
            state.successPopup.text = action.payload.text
        },
        setIsShowQueuePopup: (state, action) => {
            state.isShowQueuePopup = action.payload
        },
        setPasswordSuccessModalShow: (state, action: PayloadAction<successModalType>) => {
            state.successModal.show = action.payload.show
            state.successModal.variant = action.payload.variant
        },
        setCurrentScreenWidth: (state, action) => {
            state.currentScreenWidth = action.payload
        },
        setIsMobileMode: (state, action) => {
            state.isMobile = action.payload
        },
        setIsMobileMenuOpened: (state, action) => {
            state.isMobileMenuOpened = action.payload
        },
        setAuthActiveTab: (state, action) => {
            state.authActiveTab = action.payload
        },
        setShowModalAddWallet: (state, action) => {
            state.showModalAddWallet = action.payload
        }
    },
    selectors: {
        interfaceSelector: state => state,
        childrenMenuSelector: state => state.childrenMenu
    }
})

export const {
    setLanguage,
    setIsChildrenMenuOpened,
    setChildrenMenuPosition,
    setSelectedMenu,
    clearChildrenMenuData,
    setSidebarHeight,
    setListBalancesDropdownShown,
    setBalanceLoadingAfterSwitch,
    setTermTopHeight,
    setFaqPageHeaderContainerHeight,
    setIsShowPasswordModal,
    setIsShowPopup,
    setSuccessPopup,
    setIsShowQueuePopup,
    setIsShowPhoneNumberVerificationModal,
    setIsShowEmailVerificationModal,
    setPasswordSuccessModalShow,
    setCurrentScreenWidth,
    setIsMobileMode,
    setIsMobileMenuOpened,
    setAuthActiveTab,
    setShowModalAddWallet
} = interfaceSlice.actions

export const {interfaceSelector, childrenMenuSelector} = interfaceSlice.selectors

export const selectIsListBalancesDropdownShown = (state: RootState) => state.interface.isListBalancesDropdownShown
export const selectIsBalanceLoadingAfterSwitch = (state: RootState) => state.interface.isBalanceLoadingAfterSwitch

export const InterfaceReducer = interfaceSlice.reducer

