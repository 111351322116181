import React, {useEffect, useState} from 'react'
import axios from 'axios'
import config from '../../../config'
import {useTranslation} from "react-i18next"
import styles from './BlacklistPage.module.scss'
import stopImg from 'src/assets/images/stop_img.png'

interface ErrorMessage {
    errorMessage: string;
}

interface ErrorResponse {
    error: string;
}

export const BlacklistPage: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);
    const {t} = useTranslation()

    useEffect(() => {
        document.title = "Access denied";
    }, []);


    return (
        <div className={styles.component}>
            <div className={styles.imgWrap}>
                <img src={stopImg} className={styles.mainImg} alt="clubs_poker_chip"/>
            </div>
            <div className={styles.textContainer}>
                <div className={styles.topTitle}>{t('Not available in your region')}</div>
                <div className={styles.text}>{t('Don`t have an access?')}</div>
                <div className={styles.textSmall}>{t('Just turn on a VPN and reload the page.')}</div>
            </div>
        </div>
    );
}
