import React from 'react';
import styles from "./SuccessPopup.module.scss";
import { useAppDispatch } from "../../../../hooks"
import config from '../../../../config'
import { useTranslation } from "react-i18next";
import checkmark from "src/assets/images/checkmark_1.png"

interface SuccessProps {
    message?: string
}

const Success: React.FC<SuccessProps> = ({ message }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    return (
        <>
            <div className={styles.popupContainer}>
                <img src={checkmark} alt="success" className={styles.checkedImg} />
                <div className={styles.textSuccess}>
                    {message}
                </div>
            </div>
            <div className={styles.popupBackground}></div>
        </>
    );
};

export default Success;
