//myProgressActions.js
import axios from 'axios';
import config from '../../../config.js';
import {DISABLE_MISC_LOGS} from "../../../common/constants";

export const FETCH_MYPROGRESS_REQUEST             = 'FETCH_MYPROGRESS_REQUEST';
export const FETCH_MYPROGRESS_SUCCESS             = 'FETCH_MYPROGRESS_SUCCESS';
export const FETCH_MYPROGRESS_FAILURE             = 'FETCH_MYPROGRESS_FAILURE';
export const SET_SHOW_MODAL_MYPROGRESS            = 'SET_SHOW_MODAL_MYPROGRESS';
export const SET_POSITION_STYLES_MODAL_MYPROGRESS = 'SET_POSITION_STYLES_MODAL_MYPROGRESS';

// Экшены для получения данных
export const fetchMyProgressRequest = () => ({
  type: FETCH_MYPROGRESS_REQUEST,
});

export const fetchMyProgressSuccess = (data) => ({
  type: FETCH_MYPROGRESS_SUCCESS,
  payload: data,
});

export const fetchMyProgressFailure = (error) => ({
  type: FETCH_MYPROGRESS_FAILURE,
  payload: error,
});

// // param (bool) isShow
// export const setShowModalMyProgress = (isShow) => ({
//   type: SET_SHOW_MODAL_MYPROGRESS,
//   payload: isShow,
// });

export const setShowModalMyProgress = (isShow) => (dispatch) => {
    dispatch({
      type: SET_SHOW_MODAL_MYPROGRESS,
      payload: isShow, // true для показа, false для скрытия
    });
  
    if (!isShow) {
      dispatch({
        type: SET_POSITION_STYLES_MODAL_MYPROGRESS,
        payload: {}, // Сбрасываем стили при закрытии
      });
    }
  };

export const setPositionStylesModalMyProgress = (params) => ({
  type: SET_POSITION_STYLES_MODAL_MYPROGRESS,
  payload: params,
});

export const fetchMyProgress = (token) => async (dispatch) => {
  dispatch(fetchMyProgressRequest());
  try {
    const response = await axios.get(`${config.apiBaseUrl}/player/my-progress`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
    
    dispatch(fetchMyProgressSuccess(response.data));
  } catch (error) {
    dispatch(fetchMyProgressFailure(error.message));
  }
};


export const connectWebSocketMyProgress = (token, language = null) => {
  return (dispatch) => {
    const ws = new WebSocket(config.serverWebsocketUrl);
    !DISABLE_MISC_LOGS && console.log('config.serverWebsocketUrl: ' + config.serverWebsocketUrl);


    ws.onopen = () => {
      !DISABLE_MISC_LOGS && console.log('Connected to WebSocket server getMyProgress');
      ws.send(JSON.stringify({ type: 'getMyProgress', token }));
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received getMyProgress:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log('Parsed event.data:', data.myProgress.data);

        if (data.type === 'getMyProgress') {
          const myProgressData = data.myProgress.data;
          
          // Преобразуем данные в массив
          const myProgressArray = Array.isArray(myProgressData) ? myProgressData : [myProgressData];

          console.log('MyProgress received (array):', myProgressArray);
          dispatch(fetchMyProgressSuccess(myProgressArray));
        } else if (data.type === 'error') {
          dispatch(fetchMyProgressFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error.message);
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
