// FinancialProcessesNavigation
import { useSelector } from 'react-redux'
import { useAppDispatch } from "../../../hooks"
import clsx from 'clsx'
import {
    NavigationButtonsContainer
} from "src/common/NavigationButtonsContainer/NavigationButtonsContainer"
import { ButtonStyled } from "../../../common/ButtonStyled/ButtonStyled"
import {
    setActiveButton,
    selectActiveButton,
    selectButtonData,
    ButtonData
} from "../../../redux/slices/menuFinancialProcessesSlice"
import styles from './FinancialProcessesNavigation.module.scss'
import {useTranslation} from "react-i18next";

export const FinancialProcessesNavigation = () => {
    const dispatch = useAppDispatch()
    const activeButton = useSelector(selectActiveButton)
    const buttonData = useSelector(selectButtonData)
    const {t} = useTranslation()
    const handleButtonClick = (id: number) => {
        console.log('FinancialProcessesNavigation id',id)
        dispatch(setActiveButton(id))// Устанавливаем активную кнопку
    }

    return (
        <NavigationButtonsContainer>
            <div className={`${styles.buttonGroup}`} role="group" aria-label="Basic example">
                {buttonData.map((button: ButtonData) => (
                    <ButtonStyled
                        key={button.id}
                        variant={activeButton === button.id ? 'primary' : 'glass'}
                        onClick={() => handleButtonClick(button.id)}
                        className={clsx(styles.button, styles.navigationButton)}
                    >
                        <div className={styles.buttonContent}>
                            {t(button.label)} <img src={button.img} alt={button.imgAlt}
                                className={button.label.length > 0 ? styles.imgWithMargin : ''} /> {/* if button has label, then add margin to img */}
                        </div>
                    </ButtonStyled>
                ))}
            </div>
        </NavigationButtonsContainer>
    )
}