import React from "react";
import { UserIconProps } from "../../../../common/types";

export const ShieldTickIcon = ({ stroke = "white", strokeOpacity = "0.6", className }: UserIconProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="shield-tick">
      <path
        id="Icon"
        d="M6.49984 7.66691L7.83317 9.00024L10.8332 6.00024M13.8332 8.00024C13.8332 11.2725 10.2639 13.6525 8.96516 14.4102C8.81757 14.4963 8.74377 14.5393 8.63962 14.5617C8.5588 14.579 8.44088 14.579 8.36005 14.5617C8.2559 14.5393 8.1821 14.4963 8.03451 14.4102C6.73581 13.6525 3.1665 11.2725 3.1665 8.00024V4.81197C3.1665 4.27897 3.1665 4.01246 3.25368 3.78338C3.33069 3.581 3.45583 3.40042 3.61828 3.25726C3.80217 3.0952 4.0517 3.00162 4.55077 2.81447L8.12531 1.47402C8.2639 1.42205 8.3332 1.39606 8.40449 1.38576C8.46773 1.37662 8.53195 1.37662 8.59518 1.38576C8.66647 1.39606 8.73577 1.42205 8.87437 1.47402L12.4489 2.81447C12.948 3.00162 13.1975 3.0952 13.3814 3.25726C13.5438 3.40042 13.669 3.581 13.746 3.78338C13.8332 4.01246 13.8332 4.27897 13.8332 4.81197V8.00024Z"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
