import {ButtonId} from "../../../../common/types"
import {NavigationButtonsGroup} from "../../../../common/NavigationButtonsGroup/NavigationButtonsGroup"
import React from "react"
import Documents from "src/components/pages/Documents/Documents"
import {useAppDispatch, useAppSelector} from "../../../../hooks"
import {
  ProfileButtonId,
  selectActiveProfileButton,
  selectProfileButtonData,
  setProfileActiveButton
} from "../../../../redux/slices/menus/menuProfileSlice"
import Personal from "../../PersonalInformation/Personal/Personal"
import {Security} from "../../PersonalInformation/Security/Security"
import {Limits} from "../../ResponsibleGamings/Limits/Limits"
import {interfaceSelector, setPasswordSuccessModalShow} from "../../../../redux/slices/interfaceSlice"
import {SecuritySuccess} from "../../PersonalInformation/SecurityPasswordSuccess/SecuritySuccess"
import Modal from "../../../layouts/ui/modal/Modal"

export const MyProfile = () => {

  const profileActiveButton = useAppSelector(selectActiveProfileButton)
  const buttonData = useAppSelector(selectProfileButtonData)

  const dispatch = useAppDispatch()



  const handleButtonClick = (id: ButtonId) => {
    console.log('id in handleButtonClick', id)
    dispatch(setProfileActiveButton(id as ProfileButtonId)) // Set active button
  }

  return (
    <div>
      <NavigationButtonsGroup buttonData={buttonData} activeButton={profileActiveButton}
                              handleButtonClick={handleButtonClick} variant={'profileMain'}/>
      {profileActiveButton === 'Profile' && <Personal />}
      {profileActiveButton === 'Security' && <Security/>}
      {profileActiveButton === 'Verification' && <Documents />}
      {profileActiveButton === 'Limits' && <Limits />}
    </div>
  )
}