import React from 'react'
import styles from './RegistrationBanner.module.scss'
import crow_dynamic_color from 'src/assets/images/crow_dynamic_color.png'
import {ButtonStyled} from "../../../common/ButtonStyled/ButtonStyled"
import clsx from 'clsx'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import {useAppSelector} from "../../../hooks"
import {interfaceSelector} from "../../../redux/slices/interfaceSlice"

export const RegistrationBanner = () => {
  const {isMobile} = useAppSelector(interfaceSelector)

  return (
    <div className={clsx(styles.component, isMobile ? stylesCommon.groupMobile : stylesCommon.group)}>
      <div className={styles.firstContainer}>
        <div className={styles.registrationContainer}>
          <div className={styles.imageContainer}>
            <img src={crow_dynamic_color} alt="crow_dynamic_color"/>
          </div>
          <div className={styles.registrationText}>Registration</div>
        </div>
        <div className={styles.jackpotText}>It’s time to register and win your Jackpot!</div>
      </div>
      <ButtonStyled to="/auth/signup" variant={'primary'} height={isMobile ? '40px' : '48px'}>Play now</ButtonStyled>
    </div>
  )
}