// getAvailableLimitsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


export type AvailableLimitsNames = 'deposit' | 'bet' | 'loss' | 'self'

type AvailableLimitsSliceResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type AvailableLimitsSliceError = {
  error: any;
  message: string;
};

interface AvailableLimitsSliceState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: any[]; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: AvailableLimitsSliceState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchAvailableLimits
interface AvailableLimitsSliceParams {
  token: string;
  params?: {};
}

export const fetchAvailableLimits = createAsyncThunk<
  AvailableLimitsSliceResponse,
  AvailableLimitsSliceParams,
  { rejectValue: AvailableLimitsSliceError }
>(
  'AvailableLimitsSlice/fetchAvailableLimits',
  async ({ token, params = {} }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchAvailableLimits:', `${config.serverUrl}/player/get-available-limits`, { token, params });

      const response = await axios.get<AvailableLimitsSliceResponse>(
        `${config.serverUrl}/player/get-available-limits`,
        {
          params: {
            ...params, // Ваши дополнительные параметры
            token,     // Токен авторизации как часть query-параметров
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Attempting to fetchAvailableLimits response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error get Available Limits:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to get Available Limits .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);



const AvailableLimitsSlice = createSlice({
  name: 'Profile/AvailableLimitsSlice',
  initialState,
  reducers: {
    resetStateAvailableLimits: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableLimits.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchAvailableLimits.fulfilled, (state, action: PayloadAction<AvailableLimitsSliceResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchAvailableLimits.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateAvailableLimits } = AvailableLimitsSlice.actions;

export const AvailableLimitsReducer = AvailableLimitsSlice.reducer;



// ---------
// Selectors
// ---------
export const selectAvailableLimitsLoading = (state: RootState) => state.AvailableLimitsReducer.loading;
export const selectAvailableLimitsSuccess = (state: RootState) => state.AvailableLimitsReducer.success;
export const selectAvailableLimitsError = (state: RootState) => state.AvailableLimitsReducer.error;
export const selectAvailableLimitsMessages = (state: RootState) => state.AvailableLimitsReducer.messages;
export const selectAvailableLimitsData = (state: RootState) => state.AvailableLimitsReducer.data;


export const selectAvailableLimitsErrorString = (state: RootState): string | null => {
  const error = state.AvailableLimitsReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectAvailableLimitsErrorArray = (state: RootState): string[] | null => {
  const error = state.AvailableLimitsReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
