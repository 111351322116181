import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {
    selectIsAuthenticated
} from "../../../../redux/slices/auth/isAuthenticatedSlice"
import {
    selectToken
} from "../../../../redux/slices/auth/loginSlice"
import Login from '../Login/Login'
import PasswordRecovery from '../PasswordRecovery/PasswordRecovery'
import Signup from '../Signup/Signup'
import {RootState} from '../../../../redux/store'
import styles from './AuthPage.module.scss'
import {interfaceSelector, setAuthActiveTab, authTabsType} from "../../../../redux/slices/interfaceSlice"
import {useAppDispatch, useAppSelector} from "../../../../hooks"

export type authMenuTabsType = 'login' | 'signup'

export interface SignupParams {
  currencyId?: string;
  bonusId?: string;
}

const AuthPage: React.FC = () => {
    // const [activeTab, setActiveTab] = useState<'login' | 'signup'>('login');
    const isAuthenticated = useSelector<RootState, boolean>(selectIsAuthenticated); // По умолчанию активен логин
    const navigate = useNavigate();
    const { param } = useParams();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch()
    const { authActiveTab } = useAppSelector(interfaceSelector)

    const signupParams: SignupParams = {
        currencyId: searchParams.get('currencyId') || undefined,
        bonusId: searchParams.get('bonusId') || undefined
    }

    // Редирект на главную, если пользователь аутентифицирован
    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        } else {
            if (param) {
                switch (param) {
                    case 'login':
                        dispatch(setAuthActiveTab('login'));
                        break;
                    case 'signup':
                        dispatch(setAuthActiveTab('signup'));
                        break;
                    default:
                        navigate('/404');
                        break;
                }
            }
        }
    }, [isAuthenticated, navigate, param, dispatch]);

    const handleButtonActiveTabClick = (tab: authMenuTabsType) => {
        dispatch(setAuthActiveTab(tab));
    };

    return (
        
        <div className={styles.authPage}>
            {authActiveTab === 'login' && <Login handleButtonActiveTabClick={handleButtonActiveTabClick} />}
            {authActiveTab === 'signup' && <Signup handleButtonActiveTabClick={handleButtonActiveTabClick} signupParams={signupParams} />}
            {authActiveTab === 'password_recovery' && <PasswordRecovery/>}
        </div>
    );
};

export default AuthPage;
