import React from 'react'
import GameWidget from "../elems/GameWidget/GameWidget"
import {ContentBlock} from "../elems/ContentBlock/ContentBlock"
import {Winners} from "../elems/Winners/Winners"
import GameGroups from "../elems/GameGroups/GameGroups"
import {CarouselBlock} from "../elems/CarouselBlock/CarouselBlock"
import {RegistrationBanner} from "../elems/RegistrationBanner/RegistrationBanner"
import {useSelector} from "react-redux"
import {selectIsAuthenticated} from "../../redux/slices/auth/isAuthenticatedSlice"
import { useGetContentBlockQuery } from '../../api/api';
import {Jackpot} from "../elems/Jackpot/Jackpot"
import {DepositBanner} from "../elems/DepositBanner/DepositBanner"
import { useAppSelector } from '../../hooks';
import { interfaceSelector } from '../../redux/slices/interfaceSlice';
import { isMobileScreen } from '../../common/helpers';

export const CommonBlocks = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const { selectedLanguage, currentScreenWidth } = useAppSelector(interfaceSelector);
  const { data, isLoading, error } = useGetContentBlockQuery({ language: selectedLanguage.id, id: 1 });
  const isMobile = isMobileScreen(currentScreenWidth);

  return (
    <>
      <DepositBanner />
      <GameGroups />
      {/*<Providers/>*/}
      <Winners />
      <GameWidget />
      <CarouselBlock />
      {isAuthenticated && <Jackpot /> }
      <ContentBlock data={data} isLoading={isLoading} error={error} isMobile={isMobile} />
      {!isAuthenticated && <RegistrationBanner />}
    </>
  )
}