import React, { useRef, useEffect, useState } from 'react';
import TextInput from './TextInput/TextInput'
import { ButtonStyled } from "../../../../common/ButtonStyled/ButtonStyled"
import styles from './PlayerInfo.module.scss'
import { Balance, PaymentMethod } from '../Withdrawal'
import { useTranslation } from "react-i18next"
import {useSelector} from "react-redux"
import {selectActiveButton} from "../../../../redux/slices/menuFinancialProcessesSlice"

interface PlayerInfoProps {
    handleFormSubmit: (e: React.FormEvent<HTMLFormElement>, fieldsToSave: any) => void;
    selectedCurrency: Balance | null;
    selectedPaymentMethod: PaymentMethod | null;

}

const PlayerInfo: React.FC<PlayerInfoProps> = ({
    handleFormSubmit,
    selectedCurrency,
    selectedPaymentMethod
}) => {
    const formRef = useRef<HTMLFormElement>(null);
    const [formState, setFormState] = useState<{ [key: string]: any }>({})
    const [clientErrors, setClientErrors] = useState<{ required: { [key: string]: string } }>({ required: {} })
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const { t } = useTranslation()

    useEffect(() => {
        setClientErrors({ required: {} })
    }, [])

    useEffect(() => {
        console.log('selectedPaymentMethod', selectedPaymentMethod)
        if (selectedCurrency && selectedPaymentMethod) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [selectedCurrency, selectedPaymentMethod])

    const activeButton = useSelector(selectActiveButton)


    const clientValidation = () => {
        const formElements = formRef.current!.elements
        const newErrors = { required: {} as { [key: string]: string } }
        let formIsValid = true

        Array.from(formElements).forEach(field => {
            const inputField = field as HTMLInputElement
            const isFieldEmpty = inputField.type === 'checkbox' ? !inputField.checked : !inputField.value.trim()
            if (inputField.required && isFieldEmpty) {
                formIsValid = false
                const errorMessage = inputField.getAttribute('data-error-required') || t('This field is required.')
                newErrors.required[inputField.name] = errorMessage
            }
        })
        setClientErrors(newErrors)
        return formIsValid
    }

    const updateClientErrors = (name: string, value: string, e: React.ChangeEvent<HTMLInputElement>) => {
        setClientErrors(prevErrors => {
            const updatedErrors = { ...prevErrors }
            if (value.trim() !== '') {
                delete updatedErrors.required[name]
            } else {
                const errorMessage = e.target.getAttribute('data-error-required') || t('This field is required.')
                updatedErrors.required[name] = errorMessage
            }
            return updatedErrors
        })
    }

    const handleInputChange = (name: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
        setFormState(prevState => ({ ...prevState, [name]: value }))
        updateClientErrors(name, value.toString(), e)
    }

    const handleButtonWithdrawClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        // e.preventDefault();
        clientValidation() // нужно для того, чтобі сробатывал кастомный стиль валидации
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        handleFormSubmit(e, formState)
    }

    // -- Rendering --

    return (
        <form onSubmit={onSubmit} ref={formRef} className={styles.form}>

            <TextInput
                id="amount"
                name="amount"
                value={formState["amount"] || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange("amount", e)}
                required={true}
                placeholder="amount"
                error={clientErrors.required["amount"]}
            />

            <TextInput
                id="comment"
                name="comment"
                value={formState["comment"] || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange("comment", e)}
                placeholder="comment"
                error={clientErrors.required["comment"]}
            />

            {selectedPaymentMethod && selectedPaymentMethod?.fields.map((field, index) => (
                field.flag_display === "YES" &&
                <TextInput
                    id={field.name_tech}
                    name={field.name_tech}
                    value={formState[field.name_tech] || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(field.name_tech, e)}
                    required={field.flag_required === "YES"}
                    // label={field.name_tech}
                    placeholder={field.name}
                    error={clientErrors.required[field.name_tech]}
                />
            ))}

            <ButtonStyled
                disabled={isDisabled}
                type="submit"
                variant="primary"
                onClick={(e) => handleButtonWithdrawClick(e)}
                className={`btn ${styles.button} ${isDisabled ? 'btn-secondary' : ''}`}
            >
                Withdraw
            </ButtonStyled>


            {activeButton == 1 && <div className={styles.promoContainer}>
                <div className={styles.promo}>{t('I have promocode')}</div>
            </div> }

        </form>
    );
};

export default PlayerInfo;
