// setDepositRequest
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';
import { RootState } from '../store';


type SetDepositRequestResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type SetDepositRequestError = {
  error: any;
  message: string;
};

interface SetDepositRequestState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: any[]; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: SetDepositRequestState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchSetDepositRequest
export interface SetDepositRequestParams {
    token: string;
    params: {
      amount: string,
      id_payment_paysystem: string,
      id_payment_method: string,
      id_payment_contract?: string,
      id_currency: number | "",
      ip: string,
      comment: string,
      finance_details: string,
      id_bonus?: string | number;
    };
}

export const fetchSetDepositRequest = createAsyncThunk<
  SetDepositRequestResponse,
  SetDepositRequestParams,
  { rejectValue: SetDepositRequestError }
>(
  'setDepositRequest/fetchSetDepositRequest',
  async ({ token, params }, { rejectWithValue }) => {
    try {
      console.log('Attempting to set deposit request`:', `${config.serverUrl}/payment/set-deposit-request`, { token, params });

      const response = await axios.post(
        `${config.serverUrl}/payment/set-deposit-request`,
        { token, params },
        { headers: { 'Content-Type': 'application/json' } }
      );

      const data = response.data;
      console.log('fetchSetDepositRequest data (after API response):', data);

      if (response.status === 200 && data.success) {
        console.log('Password updated successfully:', data);
        return { success: data.success, data: data.data, messages: data.messages };
      } else {
        console.error('fetchSetDepositRequest error:', data.errors || data.message);
        return rejectWithValue({
          error: data.errors || null,
          message: data.message || 'Error set deposit request`.',
        });
      }
    } catch (error: any) {
      console.error('fetchSetDepositRequest error catch:', error);

      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 404) {
          console.error('fetchSetDepositRequest - API endpoint not found:', error.response);
          return rejectWithValue({
            error: null,
            message: 'API endpoint not found.',
          });
        } else if (error.response.status === 400) {
          console.error('fetchSetDepositRequest - Validation error:', error.response.data.errors);
          return rejectWithValue({
            error: error.response.data?.errors,
            message: error.response.data.messages || 'Validation error.',
          });
        } else if (error.response.status === 403) {
          // Для ошибки 403 выводим точное сообщение
          console.error('fetchSetDepositRequest - Forbidden:', error.response.data.errors);
          return rejectWithValue({
            error: error.response.data?.errors || 'Deposit request` is incorrect',
            message: error.response.data.messages || 'Access denied.',
          });
        } else {
          console.error('fetchSetDepositRequest - Server error:', error.response.data);
          return rejectWithValue({
            error: error.response.data?.errors || 'Unknown error',
            message: error.response.data?.messages || 'Server error.',
          });
        }
      } else {
        console.error('fetchSetDepositRequest - Network error:', error.message);
        return rejectWithValue({
          error: null,
          message: 'Network error: Unable to connect to the server.',
        });
      }
    }
  }
);



const setDepositRequestSlice = createSlice({
  name: 'setDepositRequest',
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSetDepositRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchSetDepositRequest.fulfilled, (state, action: PayloadAction<SetDepositRequestResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchSetDepositRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetState } = setDepositRequestSlice.actions;

export const setDepositRequestReducer = setDepositRequestSlice.reducer;



// ---------
// Selectors
// ---------
export const selectSetDepositRequestLoading = (state: RootState) => state.setDepositRequestReducer.loading;
export const selectSetDepositRequestSuccess = (state: RootState) => state.setDepositRequestReducer.success;
export const selectSetDepositRequestError = (state: RootState) => state.setDepositRequestReducer.error;
export const selectSetDepositRequestMessages = (state: RootState) => state.setDepositRequestReducer.messages;
export const selectSetDepositRequestData = (state: RootState) => state.setDepositRequestReducer.data;

export const selectSetDepositRequestErrorString = (state: RootState): string | null => {
  const error = state.setDepositRequestReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectSetDepositRequestErrorArray = (state: RootState): string[] | null => {
  const error = state.setDepositRequestReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
