import React, {useRef, useState, forwardRef, useEffect} from 'react'
import styles from './FileUpload.module.scss'
import {ButtonStyled} from "../../../../common/ButtonStyled/ButtonStyled"
import {formatFilename} from "../../../../common/helpers"

type Props = {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  clear: boolean
}

export const FileUpload = forwardRef<HTMLInputElement, Props>(({ handleFileChange, clear }, ref) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [fileName, setFileName] = useState<string>('')

  // const handleButtonClick = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click() // open file dialog
  //   }
  // }

    useEffect(() => {
      if(clear){
        setFileName('')
      }
      
    }, [clear])

  const handleButtonClick = () => {
    if (ref && (ref as React.MutableRefObject<HTMLInputElement>).current) {
      (ref as React.MutableRefObject<HTMLInputElement>).current.click(); // open file dialog
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setFileName(file.name)
      handleFileChange(event)
    }
  }

  return (
    <div className={styles.fileUpload}>
      <input
        type="file"
        ref={ref}
        onChange={handleFileSelect}
        className={styles.hiddenFileInput} // hidden default input
      />
      <ButtonStyled onClick={handleButtonClick} variant="primary">
        Choose file
      </ButtonStyled>
      {fileName && <div className={styles.fileName}>{formatFilename(fileName)}</div>}
    </div>
  )
})