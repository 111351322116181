import React from 'react'
import styles from './PlayerContainer.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import {Avatar} from "../Icons/User01Icon/Avatar"
import {BrightCopyToClipboard} from "../BrightCopyToClipboard/BrightCopyToClipboard"
import {useTranslation} from "react-i18next";

type Props = {
  loginName: string
  playerId: string
}

export const PlayerContainer = ({loginName, playerId}: Props) => {
  const {t} = useTranslation()
  return (
    <div className={styles.playerContainer}>
      <Avatar className={stylesCommon.userIcon}/>
      <div className={styles.playerData}>
        <div className={styles.name}>{loginName}</div>
        <div className={styles.playerContainer}>
          <div className={styles.player}>{t('Player')} ID</div>
          <div className={styles.playerID}>{playerId}</div>
          {/*<CopyToClipboard text={playerId ? playerId : ''}>
            <img src={copy_06} alt="copy_06" className={styles.copy}/>
          </CopyToClipboard>*/}
          <BrightCopyToClipboard text={playerId ? playerId : ''} />
        </div>
      </div>
    </div>
  )
}