import styles from './DepositRightSidebarElement.module.scss'
import {DepositSidebarElement} from '../DepositRightSidebar'
import star_01_white_fill from 'src/assets/images/icons/star_01_white_fill.svg'

export const DepositRightSidebarElement = ({text, img, amount, type}: DepositSidebarElement) => {
  return (
    <div className={styles.depositRightSidebarElement}>
      <img src={img} alt={text} className={styles.image}/>
      <div className={styles.rightPart}>
        {
          type === 'star'
            ? <>{Array.from({ length: Number(amount) }).map((_, index) => (
                <img key={index} src={star_01_white_fill} alt={`star-${index}`} className={styles.star}/>
              ))}</>
            :  <div className={styles.amount}>
              {amount}
            </div>

        }

        <div className={styles.text}>
          {text}
        </div>
      </div>

    </div>
  )
}