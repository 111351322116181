import american_samoa from 'src/assets/images/flags/american_samoa.png'
import afghanistan from 'src/assets/images/flags/afghanistan.png'
import andorra from 'src/assets/images/flags/andorra.png'
import styles from "../components/elems/LanguageDropdown/LanguageDropdown.module.scss"

/*Better to do that selector like CurrencySelector, but because of flag names did so.*/
export const flagSelectorOld = (country_code: string) => {
  switch (country_code) {
    case '23':
      return <img src={american_samoa} alt={country_code}/>
    case '26':
      return <img src={andorra} alt={country_code}/>
    case '30':
      return <img src={afghanistan} alt={country_code}/>
    default:
      return <div>{country_code}</div>
  }
}

type FlagSelectorProps = {
  iso_code: string
  size?: 32
}

export const FlagSelector = ({iso_code, size}: FlagSelectorProps) => {
  return (
    <img
      src={`/images/icons/flags/${iso_code}.svg`}
      style={{ width: `${size}px`, height: `${size}px` }}
      className={styles.flag}
      alt={`${iso_code} flag`}
    />
  )
}
