import styles from "./PaymentsImageAndOtherBanks.module.scss"
import React from "react"
import {PaymentMethod} from "../../PlayerDeposit"
import config from "../../../../../config"

type Props = {
  method: PaymentMethod
}

export const PaymentsImageAndOtherBanks = ({method}: Props) => {
  const imgDir = config.fileUrls.payment

  return (
    <div className={styles.component}>
      {method.image_path ? (
        <>
          <img
            src={`${imgDir}${method.image_path}`}
            alt={method.pay_system_name}
            className={styles.paymentMethodImg}
          />
        </>
      ) : (
        <div className={styles.otherBanks}>Other</div>
      )}
    </div>
  )
}