import bonus_image from 'src/assets/images/bonus_image.jpg'
import styles from './MyBonusesElement.module.scss'
import {ButtonStyled} from "../../../../../../common/ButtonStyled/ButtonStyled"
import divider_vertical from 'src/assets/images/icons/divider_vertical.svg'
import clsx from 'clsx'
import config from "../../../../../../config"

type Countdown = {
  days: number
  hours: number
  minutes: number
}

type Props = {
   bonusHeader: string
   bonusText: string
   isActive?: boolean
   countdown?: Countdown
   variant?: 'bonusPage' | 'bonusPageActivated'
   onLearnMore?: () => void
   bonusImg?: string
}

export const MyBonusesElement = ({bonusHeader, bonusText, isActive, countdown, variant, onLearnMore, bonusImg}: Props) => {

  const bonusCommon = (variant === 'bonusPage' || variant === 'bonusPageActivated')

  return (
    <div className={clsx(styles.myBonusesElement, bonusCommon && styles.myBonusesElementPage)}>
      <div className={styles.bonusImgContainer}>
        <img src={
            !bonusImg
                ? bonus_image
                : `${config.fileUrls.bonusFolder}${bonusImg}`
        } alt="" className={styles.bonusImage}/>
        {isActive && <div className={styles.active}>Active</div> }
        {countdown && <div className={styles.countdown}>
            <span>{countdown.days} d</span>
            <img src={divider_vertical} alt="divider" className={styles.divider}/>
            <span>{countdown.hours} h</span>
            <img src={divider_vertical} alt="divider" className={styles.divider}/>
            <span>{countdown.minutes} m</span>
        </div>}
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.bonusHeader}>{bonusHeader}</div>
        <div className={styles.textButtonsContainer}>
          <div className={clsx(styles.bonusText, bonusCommon && styles.bonusTextBonusPage)}>{bonusText}</div>
          <div className={styles.buttons}>
            <ButtonStyled variant={'glass'} height={'40px'} onClick={onLearnMore}>Learn more</ButtonStyled>
            <ButtonStyled variant={'accent'} height={'40px'}>{variant === 'bonusPageActivated' ? 'Cancel' : 'Activate'}</ButtonStyled>
          </div>
        </div>
      </div>
    </div>
  )
}