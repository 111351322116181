import styles from './BalanceElement.module.scss'
import {CurrencySelector} from "../../../../../../common/CurrencySelectors"
import React from "react"

type Props = {
  variant: 'Real' | 'Bonus'
  amount: string
  currency: string
}

export const BalanceElement = ({amount, variant, currency}: Props) => {
  return (
    <div className={styles.balanceElement}>
      <div className={styles.variant}>{variant}</div>
      <div className={styles.amount}><CurrencySelector currencyCode={currency} size={18}/> {amount}</div>
    </div>
  )
}