import React, {forwardRef, useImperativeHandle} from 'react'
import {Box} from '@mui/material'
import {useCarouselScroll} from './useCarouselScroll'
import styles from './BrightCarousel.module.scss'
import clsx from 'clsx'

export type CarouselHandle = {
  scroll: (direction: 'left' | 'right') => void 
} 

type Props = {
  array: React.ReactNode[]
  className?: string
  selectedIndex?: number
}

// Changed from the BrightCarousel component to a forwardRef component, allowing the parent to gain access to the `scroll` method
export const BrightCarouselDetachedControl = forwardRef<CarouselHandle, Props>(({array, className, selectedIndex}, ref) => {
  const isSingleMode = selectedIndex !== undefined
  const {scrollRef, scroll} = useCarouselScroll()

  // This hook provides  the `scroll` method for scrolling the content
  // it replaces the internal scroll management with an externally controlled mechanism
  useImperativeHandle(ref, () => ({
    scroll: (direction: 'left' | 'right') => {
      scroll(direction)
    },
  }))

  return (
    // Rendering the scrollable container, same as original, but without the buttons (external control now)
    <Box ref={scrollRef} display="flex" overflow="auto" whiteSpace="nowrap" className={clsx(styles.scrollContainer, className)}>
      {array.map((item, index) => (
        <div key={index} style={{ display: isSingleMode && index !== selectedIndex ? 'none' : 'block' }}>
          {item}
        </div>
      ))}
    </Box>
  )
})

