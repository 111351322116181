import { useState, useMemo } from 'react';

type UseLocalPaginationProps<T> = {
  data: T[];
  defaultPageSize: number;
};

export function useLocalPagination<T>({ data, defaultPageSize }: UseLocalPaginationProps<T>) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultPageSize);

  const paginatedData = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return data.slice(start, end);
  }, [data, currentPage, itemsPerPage]);

  return {
    paginatedData,
    currentPage,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage
  };
}