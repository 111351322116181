import React from "react";
import { UserIconProps } from "../../../../common/types";

export const SpacingWidth01Icon = ({ stroke = "white", strokeOpacity = "0.6", className }: UserIconProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="spacing-width-01">
      <path
        id="Icon"
        d="M4.3999 8H12.3999M4.3999 8L5.73324 6M4.3999 8L5.73324 10M12.3999 8L11.0666 6M12.3999 8L11.0666 10M14.3999 14V2M2.3999 14V2"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
