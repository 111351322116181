import {createApi, fetchBaseQuery, FetchBaseQueryError, FetchArgs, BaseQueryFn} from '@reduxjs/toolkit/query/react'
import {
    ActivatedBonusResponse,
    ApiResponseSlug, BonusResponse,
    ContentBlockResponse,
    FaqApiResponse,
    GameWidgetsResponse,
    GetCarouselResponse, GetConstructTextBlocksResponse, GetDepositBannerResponse,
    GetFooterMenuResponse,
    GetGameGroupsResponse, GetGameInfoResponse, GetGameListResponse,
    GetListBalancesResponse,
    GetPersonalInfoResponse,
    GetSessionInfoResponse,
    GetSessionTransactionsResponse,
    LanguageResponse,
    MenuResponse,
    PageListResponse,
    SeoRedirectsResponse,
    SeoTagsResponse,
    SwitchAccountResponse, TermsApiResponse, TransactionHistoryResponse,
    WinnersResponse,
    GameGroupContentBlockResponse
} from "./types"
import config from "../config"
import {selectIp} from '../redux/slices/ipSlice'


import {RootState} from '../redux/store'
import {Direction} from "../redux/slices/transactionsSlice" // Подключаем тип состояния из вашего хранилища

const customProxyBaseQuery: BaseQueryFn<
    string | FetchArgs, // Тип для args
    unknown,           // Успешный результат
    FetchBaseQueryError // Ошибка
> = async (args, api, extraOptions) => {
    const state = api.getState() as RootState // Типизируем state
    const ip = selectIp(state) // Берём IP из состояния (замените на свой селектор)

    // Список URL-ов, для которых добавляем IP
    const endpointsWithIp = [
        '/game/list',
        // '/game/get-info',
        // '/player/list-balances',
    ]

    let modifiedArgs = args // По умолчанию аргументы остаются неизменными

    // Проверка, является ли args объектом FetchArgs
    if (typeof args === 'object' && 'url' in args) {
        const shouldAddIp = endpointsWithIp.some((url) => args.url.includes(url))

        if (shouldAddIp) {
            modifiedArgs = {
                ...args,
                params: {...(args.params || {}), ip}, // Добавляем IP в параметры
            }
        }
    }

    // Выполняем запрос через базовый fetchBaseQuery
    return fetchBaseQuery({baseUrl: `${config.serverUrl}/`})(modifiedArgs, api, extraOptions)
}


export const api = createApi({
    reducerPath: 'api',
    // baseQuery: fetchBaseQuery({ baseUrl: `${config.apiBaseUrl}/` }),
    baseQuery: fetchBaseQuery({baseUrl: `${config.apiBaseUrl}/`}),
    endpoints: (builder) => ({
        getFaq: builder.query<FaqApiResponse, number>({
            query: (languageId) => `faq/list?language=${languageId}`,
        }),
        getPageBySlug: builder.query<ApiResponseSlug, { slug: string, language: number }>({
            query: ({slug, language}) => ({
                url: `page/one`,
                params: {
                    slug,
                    language,
                },
            }),
        }),
        getMenu: builder.query<MenuResponse, number>({
            query: (languageId) => `construct/menu?language=${languageId}`,
        }),
        getLanguages: builder.query<LanguageResponse, void>({
            query: () => `util/langs`,
            keepUnusedDataFor: 600, // 10 minutes for cashing
        }),
        getSeoTags: builder.query<SeoTagsResponse, { url: string; id_domain: number }>({
            query: ({url, id_domain}) => ({
                url: `seo/tags`,
                params: {
                    id_domain,
                    url,
                },
            }),
        }),
        getPageList: builder.query<PageListResponse, { language: number; page: number; pageSize: number }>({
            query: ({language, page, pageSize}) => `page/list?language=${language}&page=${page}&pageSize=${pageSize}`,
        }),
        getGameWidgets: builder.query<GameWidgetsResponse, { language: number }>({
            query: ({language}) => `/construct/game-widgets?language=${language}&device=desktop`,
        }), // this works only without token, for token need to use web socket
        getContentBlock: builder.query<ContentBlockResponse, { language: number, id: number }>({
            query: ({language, id}) => `/construct/content-block?id=${id}&language=${language}`,
        }),
        getSeoRedirects: builder.query<SeoRedirectsResponse, { url: string, id_domain: number }>({
            query: ({url, id_domain}) => ({
                url: `seo/redirect`,
                params: {url, id_domain}
            }),
        }),
        switchAccount: builder.mutation<SwitchAccountResponse, { token: string, currencyId: number }>({
            query: ({currencyId, token}) => ({
                url: 'player/switch-account',
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    currencyId: currencyId,
                },
            }),
        }), // not works because of CORS
        getWinners: builder.query<WinnersResponse, { language: number, id: number }>({
            query: ({language, id}) => ({
                url: '/construct/winner',
                params: {language, id},
            }),
        }),
        getCarousel: builder.query<GetCarouselResponse, { languageId: number, id: number }>({
            query: ({languageId, id}) => {
                return {
                    url: `/construct/carousel`,
                    params: {
                        language: languageId,
                        id
                    },
                }
            },
        }),
        getGameGroups: builder.query<GetGameGroupsResponse, { language: number }>({
            query: ({language}) => ({
                url: '/game/get-groups',
                params: {language, device: 'desktop'},
            }),
        }),
        getFooterMenu: builder.query<GetFooterMenuResponse, { language: number }>({
            query: ({language}) => ({
                url: '/construct/footer-menu',
                params: {language},
            }),
        }),
        getConstructTextBlocks: builder.query<GetConstructTextBlocksResponse, { language: number }>({
            query: ({language}) => ({
                url: '/construct/text-blocks',
                params: {language},
            }),
            keepUnusedDataFor: 600, // 10 minutes for cashing
        }),
        getGameInfo: builder.query<GetConstructTextBlocksResponse, { language: number }>({
            query: ({language}) => ({
                url: '/construct/text-blocks',
                params: {language},
            }),
        }),
        getConstructTerms: builder.query<TermsApiResponse, number>({
            query: (languageId) => `construct/terms?language=${languageId}`,
        }),
        getDepositBanner: builder.query<GetDepositBannerResponse, {
            language: number,
            id: number,
        }>({
            query: ({language, id}) => {
                return {
                    url: `/construct/deposit-banner`,
                    params: {
                        language,
                        id,
                    },
                }
            },
        }),
    }),
})

export const apiProxy = createApi({
    reducerPath: 'apiProxy',
    // baseQuery: fetchBaseQuery({ baseUrl: `${config.serverUrl}/` }),
    baseQuery: customProxyBaseQuery,
    endpoints: (builder) => ({
        getSessionTransactions: builder.query<GetSessionTransactionsResponse, {
            token: string;
            id: number;
            languageId: number,
            page: number,
            pageSize: number
        }>({
            query: ({token, languageId, id, page, pageSize}) => {
                return {
                    url: `/game/get-session-transactions`,
                    headers: {
                        Authorization: `${token}`,
                    },
                    params: {
                        id,
                        language: languageId,
                        page,
                        pageSize,
                    },
                }
            },
        }),
        getSessionInfo: builder.query<GetSessionInfoResponse, {
            token: string;
            languageId: number,
            page: number,
            pageSize: number
        }>({
            query: ({token, languageId, page, pageSize}) => {
                return {
                    url: `/game/get-sessions-info`,
                    headers: {
                        Authorization: `${token}`,
                    },
                    params: {
                        language: languageId,
                        page,
                        pageSize,
                    },
                }
            },
        }),
        getPersonalInfo: builder.query<GetPersonalInfoResponse, { token: string }>({
            query: ({token}) => ({
                url: `/player/get-personal-info`,
                method: 'GET',
                headers: {
                    Authorization: `${token}`,
                },
            }),
        }),
        getListBalances: builder.query<GetListBalancesResponse, { token: string }>({
            query: ({token}) => {
                return {
                    url: '/player/list-balances',
                    method: 'GET',
                    headers: {
                        Authorization: `${token}`,
                    },
                    params: {
                        group: 'type',
                    },
                }
            },
        }),
        getGameInfo: builder.query<GetGameInfoResponse, { token: string, slug: string, mode?: string }>({
            query: ({token, slug, mode}) => {
                return {
                    url: '/game/get-info',
                    method: 'GET',
                    headers: {
                        Authorization: `${token}`,
                    },
                    params: {
                        slug,
                        mode
                    },
                }
            },
        }),
        getGameList: builder.query<GetGameListResponse, {
            token?: string,
            gameGroupSlug: string,
            page?: number;
            pageSize?: number
        }>({
            query: ({token, gameGroupSlug, page, pageSize}) => {
                return {
                    url: '/game/list',
                    method: 'GET',
                    params: {
                        token,
                        gameGroupSlug: gameGroupSlug,
                        page,
                        pageSize
                    },
                }
            },
        }),
        getTransactionHistory: builder.query<TransactionHistoryResponse, {
            token: string;
            direction: Direction,
            page: number,
            pageSize: number
        }>({
            query: ({token, direction, page, pageSize}) => {
                return {
                    url: `/payment/get-transaction-history`,
                    headers: {
                        Authorization: `${token}`,
                    },
                    params: {
                        direction,
                        page,
                        pageSize,
                    },
                }
            },
        }),
        // get content block for game group
        getGameGroupContentBlock: builder.query<GameGroupContentBlockResponse, {
            group_id: number | null,
            language: number,
        }>({
            query: ({group_id, language}) => {
                if (group_id === null) {

                    return {
                        url: '',
                        method: 'GET',
                    };
                }

                return {
                    url: `/game/get-content-block`,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    params: {
                        group_id,
                        language,
                    },
                }
            },
        }),
        getBonusList: builder.query<BonusResponse, {
            token: string;
        }>({
            query: ({token,}) => ({
                url: `/bonus/list`,
                headers: {
                    Authorization: `${token}`,
                },
            }),
        }),
        getActivatedBonus: builder.query<ActivatedBonusResponse, { token: string }>({
            query: ({token}) => ({
                url: '/player/get-activated-bonuses',
                headers: {
                    Authorization: `${token}`,
                },
            }),
        }),
    }),

})


export const {
    useGetPageBySlugQuery,
    useGetMenuQuery,
    useGetLanguagesQuery,
    useGetFaqQuery,
    useGetSeoTagsQuery,
    useGetSeoRedirectsQuery,
    useGetPageListQuery,
    useGetGameWidgetsQuery,
    useGetContentBlockQuery,
    useSwitchAccountMutation,
    useGetWinnersQuery,
    useGetCarouselQuery,
    useGetGameGroupsQuery,
    useGetFooterMenuQuery,
    useGetConstructTextBlocksQuery,
    useGetConstructTermsQuery,
    useGetDepositBannerQuery,
} = api

export const {
    useGetSessionTransactionsQuery,
    useGetSessionInfoQuery,
    useGetPersonalInfoQuery,
    useGetListBalancesQuery,
    useGetGameInfoQuery,
    useGetGameListQuery,
    useGetTransactionHistoryQuery,
    useGetGameGroupContentBlockQuery,
    useGetBonusListQuery,
    useGetActivatedBonusQuery,
} = apiProxy