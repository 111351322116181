import Site_logo from 'src/assets/images/icons/Democasino.svg'
import {Link} from "react-router-dom"
import React from "react"
import styles from './SiteLogo.module.scss'
import clsx from "clsx"

type Props = {
  ignoreAdaptive?: boolean
  variant?: '143x20'
}

export const SiteLogo = ({ignoreAdaptive = false, variant}: Props) => {
  return (
    <Link to="/">
      <img
        src={Site_logo} alt="site_logo"
        className={clsx(
          variant === '143x20' ? styles.siteLogo132x20 : styles.siteLogo,
          {[styles.adaptive]: !ignoreAdaptive},
        )}
      />
    </Link>
  )
}