import colorBlock_treasure_chest_1 from 'src/assets/images/colorBlock_treasure_chest_1.png'
import colorBlock_trophy_2_1_2 from 'src/assets/images/colorBlock_trophy_2_1_2.png'
import styles from './ExperienceLoyaltyElement.module.scss'
import {useTranslation} from "react-i18next";


export type ExperienceLoyaltyElementProps = {
  variant: 'Experience' | 'Loyalty'
  lvl: string
  points: string
}

export const ExperienceLoyaltyElement = ({variant, lvl, points}: ExperienceLoyaltyElementProps) => {
    const { t } = useTranslation()
  return (
    <div className={styles.experienceLoyaltyElement}>
      <img src={
        variant === 'Experience'
          ? colorBlock_treasure_chest_1
          : colorBlock_trophy_2_1_2
      } className={styles.img} alt=""/>
      <div className={styles.lvl}>{t(`${variant} level`)} <div>{lvl}</div></div>
      <div className={styles.points}>{t(`${variant} points`)} <div>{points}</div></div>
    </div>
  )
}