import React from 'react'
import styles from './SubmitButton.module.scss'
import {ButtonStyled} from "../../../../../common/ButtonStyled/ButtonStyled"

type SubmitButtonProps = {
  onClick?: () => void
  isLoading?: boolean
  text: string
  className?: string
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
                                                     onClick,
                                                     isLoading,
                                                     text,
                                                     className = '',
                                                   }) => {
  return (
    <ButtonStyled
      type="submit"
      onClick={onClick}
      className={`${styles.loginButton} ${className}`}
      paddingVariant="14px 18px"
      height="48px" 
    >
      {isLoading ? 'loading...' : text}
    </ButtonStyled>
  )
}