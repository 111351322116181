import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from "../../../../hooks"
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import TextInput from '../formComponents/TextInput'
import DataInput from '../formComponents/DataInput/DataInput'
import PasswordInput from '../formComponents/PasswordInput'
import SelectInput from '../formComponents/SelectInput/SelectInput'
import CheckboxInput from '../formComponents/CheckboxInput'
import Alert from '../../../layouts/ui/alert/Alert'
import styles from './Signup.module.scss'
import stylesAuthCommon from "../AuthCommon.module.scss"
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import signup_background from 'src/assets/images/signup_background.jpg'
import Grid from '@mui/material/Grid2'
import bonus_chip from 'src/assets/images/bonus_chip.png'
import bonus_gift from 'src/assets/images/bonus_gift.png'
import config from 'src/config'
import login_background from 'src/assets/images/login_background.jpg'
import { deviceType, browser, os } from '../../../../helpers/deviceDetectorHelper'
import depositSignupStyles from 'src/common/styles/depositSignup.module.scss'
import { authTabsType } from '../../../../redux/slices/interfaceSlice'
import { SignupParams } from '../AuthPage/AuthPage'

import {
  fetchAuthForm,
  selectAuthFormSuccess,
  selectAuthFormLoading,
  selectAuthFormError,
  selectAuthFormErrorArray,
  selectAuthFormData,
  selectAuthFormMessages

} from '../../../../redux/slices/auth/authFormSlice'

import {
  clearErrorsLogin,
  fetchLogin,
  selectLoginSuccess,
  selectLoginLoading,
  selectLoginError,
  selectLoginErrorArray,
  selectLoginData

} from '../../../../redux/slices/auth/loginSlice'

import {
  fetchGetRegistrationForm,
  selectGetRegistrationFormData,
  selectGetRegistrationFormLoading,
  selectGetRegistrationFormSuccess,
  selectGetRegistrationFormError,
  selectGetRegistrationFormMessages,
  selectGetRegistrationFormErrorString,
  selectGetRegistrationFormElements,
  RegistrationFormElementsType
} from '../../../../redux/slices/auth/getRegistrationFormSlice'

import {
  selectRegisterValidationFirstScreenData,
  fetchRegisterValidationFirstScreen,
  resetStateRegisterValidationFirstScreen,
  selectRegisterValidationFirstScreenError,
  selectRegisterValidationFirstScreenSuccess
} from '../../../../redux/slices/auth/registerValidationFirstScreenSlice'

import {
  fetchRegister,
  resetStateRegister,
  selectRegisterSliceLoading,
  selectRegisterSliceSuccess,
  selectRegisterSliceError,
  selectRegisterSliceMessages,
  selectRegisterSliceData,
  selectRegisterSliceErrorArray,
  FetchRegisterParam

} from '../../../../redux/slices/auth/registerSlice'

import {
  selectIsAuthenticated
} from "../../../../redux/slices/auth/isAuthenticatedSlice"

import {
  selectToken
} from "../../../../redux/slices/auth/loginSlice"

import { NavigationButtonsContainer } from "../../../../common/NavigationButtonsContainer/NavigationButtonsContainer"
import { LoginNavigationButtons } from "../formComponents/LoginNavigationButtons/LoginNavigationButtons"
import { SocialAuth } from "../formComponents/SocialAuth/SocialAuth"
import { SubmitButton } from "../formComponents/SubmitButton/SubmitButton"
import { BrightCheckbox } from "../../../../common/BrightCheckbox/BrightCheckbox"
import mail_01_passive from 'src/assets/images/icons/mail_01_passive.svg'
import mail_01 from 'src/assets/images/icons/mail_01.svg'
import { SelectChangeEvent } from '@mui/material'
import { getNumberFromPx } from "../../../../common/helpers"
import { MobileStatus } from "../../../../common/MobileStatus/MobileStatus"
import { interfaceSelector } from "../../../../redux/slices/interfaceSlice"
import { ScrollButton } from "../../../../common/ScrollButton/ScrollButton"
import {
  BrightCarouselDetachedControl,
  CarouselHandle
} from "../../../../common/BrightCarousel/BrightCarouselDetachedControl"
import clsx from "clsx"
import { carouselGradient } from "../../../../common/styles/styleConstants"
import SignupConfirmationForm, { FormStateConfirmation } from "./SignupConfirmationForm"
import SignupRegistrationForm from "./SignupRegistrationForm"


// Интерфейсы для состояний формы и ошибок клиента
interface FormState {
  [key: string]: string | boolean;
}

interface ClientErrors {
  required: { [key: string]: string };
}

interface RegistrationFormElement {
  id: string;
  id_form: string;
  code: string;
  name: string;
  flag_displayed: string;
  flag_required: string;
  flag_displayed_readonly: string;
  flag_required_readonly: string;
  pos: string;
}

interface Country {
  country_id: string;
  title_ru: string;
  title_ua: string;
  title_be: string;
  title_en: string;
  title_es: string;
  title_pt: string;
  title_de: string;
  title_fr: string;
  title_it: string;
  title_pl: string;
  title_ja: string;
  title_lt: string;
  title_lv: string;
  title_cz: string;
  iso: string;
}

export interface Currencies {
  [key: string]: string; // Ключ — строка (ID валюты), значение — строка (код валюты)
}

export interface FormSettings {
  countries: Country[];
  is_password_confirmation_required: boolean;
  flag_subscription: 'YES' | 'NO';
  flag_gender: 'YES' | 'NO';
}

interface Bonus {
  id: string;
  name: string;
  image_path: string | null;
  vertical_image_path: string | null;
  icon_path: string | null;
}

interface CheckedBonus {
  id: string | null;
}

interface Authform {
  bonuses: Bonus[];
}


interface SignupProps {
  handleButtonActiveTabClick: (tab: 'login' | 'signup') => void;
  backgroundColor?: string;
  signupParams?: SignupParams;
}

export interface DataFirstStepState {
  [key: string]: string;
}

type LoginParams = {
  ip: string,
  email: string,
  password: string,
  device: string,
  browser: string,
  os: string,
}

const rootStyles = getComputedStyle(document.body)
const loginRegisterRightPart = '496px'

// Сервер при регистрации ожидает такие имена, а для вівода формі приходят другие
// Поэтому требуется перед отправкой на сервер поменять название
export const prepereParamNameToServer = (name: string) => {
  switch (name) {
    case 'country':
      return 'id_country'
    case 'currency':
      return 'id_currency'
    case 'birth':
      return 'datebirth'
    case 'password':
      return 'new_password'
    // Добавьте другие преобразования по мере необходимости
    default:
      return name // Если нет необходимости в изменении, возвращаем имя как есть
  }
}

export const prepareFormStateForServer = (formState: Record<string, any>) => {
  const preparedFormState: Record<string, any> = {} // Новый объект для преобразованных данных

  // Проходим по каждому элементу formState
  Object.entries(formState).forEach(([key, value]) => {
    const preparedName = prepereParamNameToServer(key) // Переименовываем ключ, если нужно
    preparedFormState[preparedName] = value // Добавляем преобразованный ключ и его значение в новый объект
  })

  return preparedFormState
}

export const getBonusimage = (bonus: Bonus) => {
  console.log('getBonusimage', `${config.fileUrls.bonusFolder}${bonus.image_path}`)
  return bonus && `${config.fileUrls.bonusFolder}${bonus.image_path}`
}



const Signup: React.FC<SignupProps> = ({ handleButtonActiveTabClick, backgroundColor = '#b2b2b2', signupParams }) => {
  // const dispatch = useDispatch();
  const dispatch = useAppDispatch()

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

  const authform = useSelector(selectAuthFormData) // bonuses data
  const authformSuccess = useSelector(selectAuthFormSuccess)
  const authformMessages = useSelector(selectAuthFormMessages)
  const authformLoading = useSelector(selectAuthFormLoading)
  const authformError = useSelector(selectAuthFormError)
  const authformErrorArray = useSelector(selectAuthFormErrorArray)

  const loginError = useSelector(selectLoginError)
  const loginErrorArray = useSelector(selectLoginErrorArray)
  const loginSuccess = useSelector(selectLoginSuccess)

  // Результаты запроса на получение настройки формы
  const getRegistrationFormSettings = useSelector(selectGetRegistrationFormData)
  const getRegistrationFormSettingsLoading = useSelector(selectGetRegistrationFormLoading)
  const getRegistrationFormSettingsError = useSelector(selectGetRegistrationFormError)
  const getRegistrationFormElements = useSelector(selectGetRegistrationFormElements)


  const { isMobile } = useAppSelector(interfaceSelector)

  // Рефы
  const formRef = useRef<HTMLFormElement>(null)

  // Навигация
  const navigate = useNavigate()
  const location = useLocation()

  const [view, setView] = useState<'stepOne' | 'stepTwo'>('stepOne')
  const [ip, setIp] = useState<string>('')
  const [checkedBonus, setCheckedBonus] = useState<string | null>(null) // хранит id бонуса или null
  const [formState, setFormState] = useState<FormState>({})
  const [initialState, setInitialState] = useState<FormState>({})
  const [dataFirstStep, setDataFirstStep] = useState<DataFirstStepState>({})
  const [showLoginErrors, setShowLoginErrors] = useState(false)
  const [lastToggleTime, setLastToggleTime] = useState<number>(0);

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json')
        setIp(response.data.ip)
      } catch (error) {
        console.error('Error fetching IP:', error)
      }
    }

    fetchIp()
  }, [])

  // Первоначальная инициализация формы
  useEffect(() => {
    dispatch(fetchAuthForm({ ip }))
    console.log('component useEffect 1')
  }, [dispatch])



  // Загрузка настроек формы при смене ID
  useEffect(() => {
    if (isAuthenticated && token) {
      navigate('/')
    } else {
      dispatch(fetchGetRegistrationForm({ id: '1' }))
    }
  }, [dispatch, isAuthenticated, token, navigate])

  // Данные формы при инициализации.
  useEffect(() => {
    const initialFormValues: FormState = {}

    if (getRegistrationFormSettingsError || getRegistrationFormSettings.length === 0) {
      return
    }

    getRegistrationFormSettings?.settingRegistrationFormElements.forEach((item: RegistrationFormElement) => {
      if (item.flag_displayed !== "YES") return
      if (item.code === 'currency') {
        const defaultCurrencyId = getRegistrationFormSettings.defaultCurrency.id
        initialFormValues['currency'] = defaultCurrencyId
      } else {
        initialFormValues[item.code] = ""
      }
    })


    setFormState(initialFormValues)
    setInitialState(initialFormValues)
  }, [getRegistrationFormSettings, getRegistrationFormSettingsError])


  useEffect(() => {
    if (loginError) {
      setShowLoginErrors(true)
      console.error('Ошибка логина:', loginError)
    }
    if (loginSuccess) {
      navigate('/profile')
    }

  }, [loginError, loginSuccess])

    // Установка начального бонуса
    useEffect(() => {
      console.log('[BONUS_DEBUG] Setting initial bonus. signupParams:', signupParams);
      console.log('[BONUS_DEBUG] authformSuccess:', authformSuccess);
      console.log('[BONUS_DEBUG] authform:', authform);
      
      if (signupParams?.bonusId && authformSuccess && authform) {
        const bonusExists = authform.bonuses.some((bonus: Bonus) => bonus.id == signupParams.bonusId);
        console.log('[BONUS_DEBUG] bonusExists:', bonusExists);
        
        if (bonusExists) {
          console.log('[BONUS_DEBUG] Setting checkedBonus to:', signupParams.bonusId);
          setCheckedBonus(signupParams.bonusId);
        }
      }
    }, [authformSuccess, authform, signupParams?.bonusId]);

    // Отслеживание изменений checkedBonus
    useEffect(() => {
      console.log('[BONUS_DEBUG] checkedBonus changed to:', checkedBonus);
      console.log('[BONUS_DEBUG] authform?.bonuses:', authform?.bonuses);
    }, [checkedBonus, authform?.bonuses]);

  const toggleBonusStatus = (bonusId: string) => {
    const now = Date.now();
    if (now - lastToggleTime < 300) return; // Защита от частых вызовов (300ms)
    
    console.log('[BONUS_DEBUG] toggleBonusStatus called with:', bonusId);
    console.log('[BONUS_DEBUG] current checkedBonus:', checkedBonus);
    
    // Если бонус уже выбран, не меняем его
    if (checkedBonus == bonusId) {
      return;
    }
    
    setCheckedBonus(bonusId);
    setLastToggleTime(now);
  }


  const onConfirm = (formState: FormStateConfirmation) => {
    setDataFirstStep({
      ...formState,
      ip: ip,
      ...(checkedBonus !== null && { id_bonus: checkedBonus }) 
    })
    setView('stepTwo')

  }


  // If registration is success
  const onRegistr = () => {
    console.log('Регистрация успешна:')
    // Определите параметры логина
    const loginParams = {
      ip: ip,
      email: dataFirstStep.email,
      password: dataFirstStep.password,
      device: deviceType(),
      browser: browser(),
      os: os(),
    }

    console.info('onRegistr', loginParams)
    dispatch(fetchLogin(loginParams))
  }

  const handleLoginErrorClose = () => {
    setShowLoginErrors(false) // Скрываем ошибки при нажатии на крестик
  }

  const renderBonusButtons = (): JSX.Element => {
    if (!authformSuccess || !authform) return <></>

    return (
      <>
        {authform.bonuses.map((bonus: Bonus, index: number) => {
          const iconPath = bonus.icon_path
            ? `${config.fileUrls.bonusFolder}${bonus.icon_path}`
            : (index + 1) % 2 === 0
              ? bonus_gift
              : bonus_chip

          return (
            <button
              key={bonus.id}
              onClick={() => toggleBonusStatus(bonus.id)}
              className={`${stylesAuthCommon.btnInBlock} ${checkedBonus == bonus.id ? stylesAuthCommon.active : ''}`}
            >
              <img src={iconPath} alt="bonus" />
            </button>
          )
        })}
      </>
    )
  }

  const renderLeftBar = (): JSX.Element => {
    if (authformSuccess && authform) {
      const imgUrl = config.fileServerBaseUrl
      let backgroundImage = ''
      let title = authform.title

      // Если выбран бонус и у него есть изображение
      let selectedBonus: Bonus | undefined

      if (checkedBonus !== null) {
        selectedBonus = authform.bonuses.find((bonus: Bonus) => bonus.id == checkedBonus);
        if (selectedBonus && selectedBonus.image_path) {
          backgroundImage = getBonusimage(selectedBonus)
        } else {
          // если у бонуса нет картинки
          backgroundImage = ''
        }
        if (selectedBonus && selectedBonus.name) {
          title = selectedBonus.name
        }
      }

      // Определение фонового изображения в зависимости от разрешения экрана (если бонус не выбран)
      if (!backgroundImage) {
        backgroundImage = `${imgUrl}${authform.image_path_desktop}`
        if (window.matchMedia("(min-width: 1200px)").matches) {
          backgroundImage = `${imgUrl}${authform.image_path_large_desktop}`
        } else if (window.matchMedia("(min-width: 768px) and (max-width: 1199px)").matches) {
          backgroundImage = `${imgUrl}${authform.image_path_tablet}`
        } else if (window.matchMedia("(max-width: 767px)").matches) {
          backgroundImage = `${imgUrl}${authform.image_path_mobile}`
        }
      }

      const selectedBonusImagePath = selectedBonus ? (selectedBonus?.vertical_image_path ? `${config.fileUrls.bonusFolder}${selectedBonus?.vertical_image_path}` : login_background) : signup_background


      return (
        <Grid size={1} width={'100%'}
        >
          {!isMobile && <div className={stylesAuthCommon.leftContent}
            style={{
              "--background": `url(${selectedBonusImagePath})`, // backgroundImage
            } as React.CSSProperties}>


            {
              selectedBonus &&
              <div className={styles.extraBonusTitle}>Welcome Bonus Offer</div>

            }
            <div className={stylesAuthCommon.text}>{title}</div>

          </div>}
        </Grid>
      )
    } else {
      return (
        <Grid size={1} width={'100%'}
          style={{
            backgroundColor: backgroundColor,
            backgroundSize: 'cover',
            borderRadius: '0.5rem'
          }}
        >
          <div className={stylesAuthCommon.leftContent}>
            <h2 className="text-white">Welcome!</h2>
            <p className="text-white">
              Quick registration and login. Join us!
            </p>
          </div>
        </Grid>
      )
    }
  }


const form = () => {
    switch (view) {
      case 'stepOne':
        return <SignupConfirmationForm
          onConfirm={onConfirm}
          toggleBonusStatus={toggleBonusStatus}
          bonuses={authform?.bonuses}
          checkedBonus={checkedBonus}
          currencies={authform?.currencies}
          formSettings={getRegistrationFormSettings}
          formElements={getRegistrationFormElements}
          initialCurrencyId={signupParams?.currencyId}
        />

      case 'stepTwo':
        return <SignupRegistrationForm
          onRegistr={onRegistr}
          dataFirstStep={dataFirstStep}
          currencies={authform?.currencies}
          formSettings={getRegistrationFormSettings}
          formElements={getRegistrationFormElements}
        />
    }
  }


  // -------------------------------
  // Вывод представлений
  // -------------------------------

  // Рендеринг представления страницы
  if (getRegistrationFormSettingsLoading) {
    return <p>loading...</p>
  }

  if (getRegistrationFormSettingsError) {
    return (
      <div className="alert alert-danger mt-2" role="alert">
        {getRegistrationFormSettingsError || 'An unexpected error occurred.'}
      </div>
    )
  }

  if (!getRegistrationFormSettings || getRegistrationFormSettings.length === 0) {
    return <p>no data...</p>
  }

  if (getRegistrationFormSettings?.flag_active !== 'YES') {
    return <p>This form is inactive...</p>
  }

  return (
    <>
      {isMobile && <MobileStatus />}
      <div className={styles.outsideButtonsBlock} id="button-close-registration">
        {renderBonusButtons()}

      </div>
      <div className={styles.signup}>
        <div className={styles.gridContainer}>
          {/* Left block - 1/3 width */}
          {renderLeftBar()}

          {/* Right block - 2/3 width */}
          <Grid width={'100%'}>
            <div className={stylesAuthCommon.rightContent}>

              {/* Блок с кнопками Логин и Регистрация */}
              <NavigationButtonsContainer>
                <LoginNavigationButtons
                  onLoginClick={() => handleButtonActiveTabClick('login')}
                  onRegisterClick={() => handleButtonActiveTabClick('signup')}
                  activeButton={'register'}
                />
              </NavigationButtonsContainer>


              {/* Блок с формой */}
              {authform && getRegistrationFormSettings && form()}

              {showLoginErrors && loginErrorArray && loginErrorArray.length > 0 && (
                <Alert onClose={handleLoginErrorClose} type="error">
                  {loginErrorArray.map((error, index) => {
                    return (<p key={index}>{error}</p>)
                    // Проверяем, существует ли ошибка в локальном состоянии
                    // if (!secondScreenServerValidationErrors.hasOwnProperty(index)) {
                    //     return (<p key={index}>{error}</p>);
                    // }
                    // return null; // Не выводим ошибку, если она есть в локальном состоянии
                  })}
                </Alert>
              )}

              {/* Блок с соцсетями */}
              <SocialAuth variant={'sign up'} />

            </div>
          </Grid>
        </div>
        {/* тут будет внутренний контент */}
      </div>
    </>
  )

}

export default Signup
