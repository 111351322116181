import React, {useEffect, useState} from 'react'
import {clsx} from 'clsx'
import {v4} from 'uuid'

import styles from './Pagination.module.scss'
import {DOTS, usePagination} from './usePagination'
import {FormControl, MenuItem, Select} from '@mui/material'
import {ScrollButton} from "../ScrollButton/ScrollButton"

export const Pagination = (props: Props) => {
  const {onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className, variant, isSelectorOfPagesHidden} = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1]

  const [isChevronLeftDisabled, setIsChevronLeftDisabled] = useState(false)
  const [isChevronRightDisabled, setIsChevronRightDisabled] = useState(false)
  const [paginationIsBlocked, setPaginationIsBlocked] = useState(false)

  useEffect(() => {
    setIsChevronLeftDisabled(currentPage == 1)

    if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
      setPaginationIsBlocked(true)
    } else {
      setPaginationIsBlocked(false)
    }

    setIsChevronRightDisabled(currentPage === lastPage)
  }, [currentPage, paginationRange, lastPage])

  const isSelected = (pageNumber: number) => {
    return pageNumber === currentPage
  }

  return (
    <div className={clsx(styles.paginationContainer, className)}>
      <ScrollButton
        onClick={!isChevronLeftDisabled && !paginationIsBlocked ? onPrevious : () => {
      }}
        direction={"left"}
        className={clsx(
          isChevronLeftDisabled && styles.disabled,
          styles.chevronLeft
        )}
        variant={'arrow'}
      />

      {paginationRange &&
        paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <div key={v4()} className={clsx(styles.paginationItem, styles.dots)}>
                ...
              </div>
            )
          }

          return (
            <div
              key={v4()}
              className={clsx(styles.paginationItem, {
                [styles.selected]: isSelected(+pageNumber),
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </div>
          )
        })}
      <ScrollButton
        onClick={!isChevronRightDisabled && !paginationIsBlocked ? onNext : () => {
        }}
        direction={"right"}
        className={clsx({
          [styles.disabled]: isChevronRightDisabled || paginationIsBlocked,
        }, styles.chevronRight)}
        variant={'arrow'}
      />
      {!isSelectorOfPagesHidden && <div className={styles.selectBlock}>
        <span className={styles.showLabel}>Show</span>
        <FormControl variant="outlined" className={styles.selectContainer} size="small">
          <Select
            value={props.selectSettings.value}
            onChange={(e) => props.selectSettings.onChangeOption(e.target.value)}
            variant={'outlined'}
            className={styles.select}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: 'black',
                  color: 'white',
                },
              },
            }}
          >

            {props.selectSettings.arr.map((option) => (
              <MenuItem key={option} value={option} className={styles.selectItem} sx={{
                color: 'white',
                '&.Mui-selected': {
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                },
                '&.Mui-selected:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.3)',
                },
              }}>
                {option}
              </MenuItem>
            ))}

          </Select>
        </FormControl>
        <span className={styles.totalLabel}>{variant} per page</span>
      </div> }
    </div>
  )
}

type Props = {
  variant: 'transactions' | 'articles' | 'sessions' | 'games'
  onPageChange: (value: number | string) => void
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
  className?: string
  selectSettings: {
    value: string
    onChangeOption: (value: string) => void
    arr: Array<string>
  }
  isSelectorOfPagesHidden?: boolean
}
