import {ButtonId, NavigationButtonData} from "../../../../common/types"
import React, {useEffect, useState} from "react"
import {NavigationButtonsGroup} from "../../../../common/NavigationButtonsGroup/NavigationButtonsGroup"
import {Wallet01Icon} from "../../../../assets/images/icons/withColorChange/Wallet01Icon"
import {CreditDeposit01Icon} from "../../../../assets/images/icons/withColorChange/CreditDeposit01Icon"
import {CreditWithdraw01Icon} from "../../../../assets/images/icons/withColorChange/CreditWithdraw01Icon"
import {CoinsExchange01Icon} from "../../../../assets/images/icons/withColorChange/CoinsExchange01Icon"
import {CurrencyBuycrypto01Icon} from "../../../../assets/images/icons/withColorChange/CurrencyBuycrypto01Icon"
import Accounts from "../../Accounts/Accounts"
import {openModal} from "../../../../redux/slices/modals/modalPlayerDepositSlice"
import {useAppDispatch} from "../../../../hooks"
import {setActiveButton} from "../../../../redux/slices/menuFinancialProcessesSlice"

export type WalletTabs = 'Wallets' | 'Deposit' | 'Withdraw' | 'Exchange' | 'Buy Crypto'
type WalletButtonData = Omit<NavigationButtonData, 'id'> & { id: WalletTabs }

export const MyWallet = () => {
  const [activeWalletButton, setActiveWalletButton] = useState<WalletTabs>('Wallets')
  const dispatch = useAppDispatch()

  useEffect(() => {
    document.title = `Account - My Wallet - ${activeWalletButton}`

  }, [activeWalletButton])

  const onDepositClick = () => {
    dispatch(openModal());
    dispatch(setActiveButton(1))
  }

  const onWithdrawClick = () => {
    dispatch(openModal());
    dispatch(setActiveButton(2))
  }

  const buttonData: Array<WalletButtonData> = [
    {id: 'Wallets', label: 'Wallets', icon: Wallet01Icon},
    {id: 'Deposit', label: 'Deposit', icon: CreditDeposit01Icon, onClick: onDepositClick},
    {id: 'Withdraw', label: 'Withdraw', icon: CreditWithdraw01Icon, onClick: onWithdrawClick},
    {id: 'Exchange', label: 'Exchange', icon: CoinsExchange01Icon},
    {id: 'Buy Crypto', label: 'Buy Crypto', icon: CurrencyBuycrypto01Icon},
  ]

  const handleButtonClick = (id: ButtonId) => {
    // If the button with this ID has onClick, execute it
    const onClick = buttonData.find((btn) => btn.id === id)?.onClick;
    if (onClick) {
      onClick();
      return;
    }

    // If there is no onClick, just change the active tab
    setActiveWalletButton(id as WalletTabs);
  }

  return (
    <div>
      <NavigationButtonsGroup buttonData={buttonData} activeButton={activeWalletButton}
                              handleButtonClick={handleButtonClick} variant={'profileMain'}/>
      {activeWalletButton === 'Wallets' && <Accounts /> }
    </div>

  )
}