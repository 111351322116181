import React, {forwardRef, MouseEvent} from 'react'
import config from '../../../../config.js'
import styles from './CurrencyAndPayment.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import {Balance, PaymentMethod} from '../PlayerDeposit'
import clsx from 'clsx'
import {ButtonStyled} from "../../../../common/ButtonStyled/ButtonStyled"
// import credit_deposit_01 from 'src/assets/images/icons/buttons/credit_deposit_01.svg'
// import coins_exchange_01 from 'src/assets/images/icons/buttons/coins_exchange_01.svg'
// import credit_withdraw_01 from 'src/assets/images/icons/buttons/credit_withdraw_01.svg'
// import currency_buycrypto_01 from 'src/assets/images/icons/buttons/currency_buycrypto_01.svg'
import {CurrencySelector} from "../../../../common/CurrencySelectors"
// import {
//   NavigationButtonsContainer
// } from "src/common/NavigationButtonsContainer/NavigationButtonsContainer"
// import {
//   setActiveButton,
//   selectActiveButton,
//   selectButtonData,
//   ButtonData
// } from "../../../../redux/slices/menuFinancialProcessesSlice"
import {FinancialProcessesNavigation} from "../../../elems/FinancialProcessesNavigation/FinancialProcessesNavigation"
import {PaymentsImageAndOtherBanks} from "../common/PaymentsImageAndOtherBanks/PaymentsImageAndOtherBanks"
import {useSelector} from "react-redux"
import {selectActiveButton} from "../../../../redux/slices/menuFinancialProcessesSlice"
import {useTranslation} from "react-i18next"
import {PromoCode} from "../../../../common/PromoCode/PromoCode"

// Определяем интерфейсы для пропсов и других типов
interface Currency {
  currency_id: number;
  currency_code: string;
  amount: number;
}

interface CurrencyAndPaymentFormProps {
  playerBalances: Currency[];
  getPaymentMethods: PaymentMethod[];
  playerBalancesLoading: boolean;
  playerBalancesErrorString: string | null;
  getPaymentMethodsLoading: boolean;
  getPaymentMethodsErrorString: string | null;
  selectedCurrency: string | null | Balance;
  selectedPaymentMethod?: string | null | PaymentMethod;
  handleCurrencyChange: (currencyId: number) => void;
  handlePaymentMethodChange: (paymentMethodId: string) => void;
  handleButtonContinueClick: (e: MouseEvent<HTMLButtonElement>) => void;
  isButtonContinueClick: boolean;
}

const imgDir = config.fileUrls.payment
const defaultImg = "https://img.freepik.com/premium-vector/content-delivery-network-concept-proxy-anonymous-vpn-vector-design-cloud-processing-computing_135661-1393.jpg?semt=ais_hybrid"

const handleCurrencyMore = (e: MouseEvent<HTMLButtonElement>) => {
  // Ваша логика обработки
}

const CurrencyAndPaymentForm = forwardRef<HTMLDivElement, CurrencyAndPaymentFormProps>(({
                                                                                          playerBalances,
                                                                                          getPaymentMethods,
                                                                                          playerBalancesLoading,
                                                                                          playerBalancesErrorString,
                                                                                          getPaymentMethodsLoading,
                                                                                          getPaymentMethodsErrorString,
                                                                                          selectedCurrency,
                                                                                          selectedPaymentMethod,
                                                                                          handleCurrencyChange,
                                                                                          handlePaymentMethodChange,
                                                                                          handleButtonContinueClick,
                                                                                          isButtonContinueClick
                                                                                        }, ref) => {

  // const handleButtonClick = (id: number) => {
  //   console.log(id)
  //   dispatch(setActiveButton(id))// Устанавливаем активную кнопку
  // }

  const activeButton = useSelector(selectActiveButton)
  const {t} = useTranslation()
  return (
    <div ref={ref} className={styles.currencyAndPayments}>

      {/* Навигация по компонентам */}
      <FinancialProcessesNavigation/>

      <div className={styles.currencyContainer}>
        <div className={styles.currencyElement}>
          <div className={styles.currencyText}>{t('Currency for deposit')}</div>
          {selectedCurrency && typeof selectedCurrency !== 'string' && (
            <div className={styles.balance}>
              <div className={styles.name}>{t('Balance')}:</div>
              <div className={styles.amount}>{selectedCurrency.amount} {selectedCurrency.currency_code}</div>
            </div>
          )}
          <div className={`${styles.buttonGroup}`} role="group" aria-label="Currency">
            {playerBalances.map((balance) => (
              <button
                key={balance.currency_id}
                type="button"
                onClick={() => handleCurrencyChange(balance.currency_id)}
                className={clsx(stylesCommon.currencyButton,
                  typeof selectedCurrency !== 'string' &&
                  selectedCurrency?.currency_id === balance.currency_id &&
                  styles.currencyButtonSelected)
                }
              >
                <div className={stylesCommon.currencyCode}>
                  <CurrencySelector currencyCode={balance.currency_code}/>
                  {balance.currency_code}
                </div>
              </button>
            ))}
          </div>
          {/*<ButtonStyled variant={'glass'} onClick={(e) => handleCurrencyMore(e)}
                        className={clsx(styles.button, styles.moreButton)}>More </ButtonStyled>*/} {/* hidden */}
        </div>

        <div className={styles.currencyElement}>
          <div className={styles.currencyText}>
            {t('Payment methods')}
          </div>
          {selectedPaymentMethod !== 'string' && selectedPaymentMethod && (
            <div className={styles.balance}>
              <div className={styles.amount}>
                {t('min deposit')}: {(selectedPaymentMethod as PaymentMethod).min_deposit} | {t('max deposit')}: {(selectedPaymentMethod as PaymentMethod).max_deposit}
              </div>

            </div>

          )}
          <div className={styles.paymentButtonsGroup} role="group" aria-label="Payment method selection">
            {getPaymentMethodsLoading ? (
              <div>Loading...</div>
            ) : getPaymentMethodsErrorString ? (
              <div>{getPaymentMethodsErrorString}</div>
            ) : (
              <div role="group" aria-label="Payment method selection">
                <div className={styles.currencyButtonsGroup}>
                  {getPaymentMethods.length > 0 && getPaymentMethods.map((method) => (
                    <button
                      key={method.id}
                      type="button"
                      onClick={() => handlePaymentMethodChange(method.id)}
                      className={clsx(styles.currencyButton,
                        (selectedPaymentMethod !== 'string' && (selectedPaymentMethod as PaymentMethod)?.id === method.id) && styles.currencyButtonSelected
                      )}
                    >
                      <PaymentsImageAndOtherBanks method={method}/>
                    </button>
                  ))}
                </div>

              </div>
            )}
          </div>
          {getPaymentMethods.length > 0 ? (
            <>
              {/*<ButtonStyled variant={'glass'} onClick={(e) => handleCurrencyMore(e)}
                            className={clsx(styles.button, styles.moreButton)}>More </ButtonStyled>*/} {/* hidden */}
            </>
          ) : (
            <p>No data...</p>
          )}
        </div>
      </div>


      {isButtonContinueClick && (!selectedCurrency || !selectedPaymentMethod) && (
        <div className="alert alert-danger mt-2" role="alert">
          <p>{t('Please select currency and payment method!')}</p>
        </div>
      )}

      <ButtonStyled variant={'primary'} onClick={(e) => handleButtonContinueClick(e)}
                    className={styles.button}>{t('Continue')}</ButtonStyled>
      {activeButton == 1 &&
        <PromoCode />
      }
    </div>
  )
})

export default CurrencyAndPaymentForm
