import styles from './LimitsElement.module.scss'
import check from "src/assets/images/icons/check.svg"
import check_green from "src/assets/images/icons/check_green.svg"
import shield_2 from 'src/assets/images/shield_2.png'
import { ButtonStyled } from "../../../../../common/ButtonStyled/ButtonStyled"
import React, { useEffect, useState, ReactNode } from 'react'
import { ModeState, ModeTypes } from '../Limits'
import {useTranslation} from "react-i18next";

export type LimitsElementType = {
    title: string
    description: string
    data: { name: string; value: string }[];
    buttonText: string
    mode: ModeTypes
    isAvailableSetLimit: boolean
    onClickBotton: () => void
    onClickMenuItemAdd: () => void
    onClickMenuItemEdit: () => void
    onClickMenuItemDelete: () => void
    children?: ReactNode
}


export const LimitsElement = ({ title, description, data, buttonText, mode, isAvailableSetLimit, onClickBotton, onClickMenuItemAdd, onClickMenuItemEdit, onClickMenuItemDelete, children }: LimitsElementType) => {
    const [openMenu, setOpenMenu] = useState(false);

    const renderButton = () => {
        if (mode as ModeTypes === 'set') {
            return (<ButtonStyled disabled={!isAvailableSetLimit} variant={'accent'} onClick={onClickBotton} className={styles.button}>{t(buttonText)}</ButtonStyled>)
        } else {
            return (<ButtonStyled disabled={isAvailableSetLimit} variant={'accent'} onClick={onClickBotton} className={styles.button}>{t(buttonText)}</ButtonStyled>)
        }
    }

    const {t} = useTranslation()

    return (
        <div className={styles.limitsElement}>
            <div>
                <div className={styles.imagesBlock}>
                    <div>
                        <img src={shield_2} alt="" />
                    </div>

                    <div className={styles.menuBox}>
                        <div className={styles.menuButtonBox}>
                            <button className={styles.menuButton} onClick={() => setOpenMenu(!openMenu)}>⋮</button>
                        </div>
                        {openMenu && (
                            <div className={styles.menuDropdown}>

                                <div className={styles.menuDropdownItem}>
                                    <p>{t('Add')}</p>
                                    <button onClick={() => onClickMenuItemAdd()} className="btn p-0 border-0 bg-transparent"><img src={mode === ('set' as ModeTypes) ? check_green : check} alt="" /></button>
                                </div>

                                <div className={styles.menuDropdownItem}>
                                    <p>{t('Edit')}</p>
                                    <button onClick={() => onClickMenuItemEdit()} className="btn p-0 border-0 bg-transparent"><img src={mode === ('update' as ModeTypes) ? check_green : check} alt="" /></button>
                                </div>
                                <div className={styles.menuDropdownItem}>
                                    <p>{t('Delete')}</p>
                                    <button onClick={() => onClickMenuItemDelete()} className="btn p-0 border-0 bg-transparent"><img src={mode === ('delete' as ModeTypes) ? check_green : check} alt="" /></button>
                                </div>

                            </div>
                        )}

                    </div>

                </div>



                <div className={styles.title}>{t(title)}</div>
                <div className={styles.description}>{t(description)}</div>
                <div className={styles.data}>
                    {data.map((element, index) => (
                        <div key={index} className={styles.dataRaw}>
                            <p>{t(element.name)}</p>
                            <p>{t(element.value)}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div>{children}</div>
            {renderButton()}

        </div>
    )
}