// updateResponsibleGamingSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type UpdateResponsibleGamingResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type UpdateResponsibleGamingError = {
  error: any;
  message: string;
};

interface UpdateResponsibleGamingState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: any[]; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: UpdateResponsibleGamingState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchUpdateResponsibleGaming
export interface UpdateResponsibleGamingParams {
  token: string;
  type_limit: string;
  duration_days: number;
  amount?: number
}

export const fetchUpdateResponsibleGaming = createAsyncThunk<
  UpdateResponsibleGamingResponse,
  UpdateResponsibleGamingParams,
  { rejectValue: UpdateResponsibleGamingError }
>(
  'UpdateResponsibleGaming/fetchUpdateResponsibleGaming',
  async ({ token, type_limit, duration_days, amount }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchUpdateResponsibleGaming:', `${config.serverUrl}/player/update-responsible-gaming`, { token, type_limit, duration_days, amount });

      const response = await axios.post<UpdateResponsibleGamingResponse>(
        `${config.serverUrl}/player/update-responsible-gaming`,
        { type_limit, duration_days, amount },
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Attempting to fetchUpdateResponsibleGaming response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error update responsible gaming:', error);

      if (axios.isAxiosError(error)) {
        if (!error.response) {
          return rejectWithValue({
            error: [ error.code ||  'Network error'],
            message: 'Network error. Please check your connection.',
          });
        }
        return rejectWithValue({
          error: error.response.data.errors || error.response.data,
          message: error.response.data.message || error.response.data.message || 'Failed to update responsible gaming.',
        });
      }

      return rejectWithValue({
        error: [ 'Unknown error' ],
        message: 'An unknown error occurred.',
      });
    }
  }
);



const UpdateResponsibleGamingSlice = createSlice({
  name: 'UpdateResponsibleGaming',
  initialState,
  reducers: {
    resetStateUpdateResponsibleGaming: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpdateResponsibleGaming.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchUpdateResponsibleGaming.fulfilled, (state, action: PayloadAction<UpdateResponsibleGamingResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchUpdateResponsibleGaming.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateUpdateResponsibleGaming } = UpdateResponsibleGamingSlice.actions;

export const UpdateResponsibleGamingReducer = UpdateResponsibleGamingSlice.reducer;



// ---------
// Selectors
// ---------
export const selectUpdateResponsibleGamingLoading = (state: RootState) => state.UpdateResponsibleGamingReducer.loading;
export const selectUpdateResponsibleGamingSuccess = (state: RootState) => state.UpdateResponsibleGamingReducer.success;
export const selectUpdateResponsibleGamingError = (state: RootState) => state.UpdateResponsibleGamingReducer.error;
export const selectUpdateResponsibleGamingMessages = (state: RootState) => state.UpdateResponsibleGamingReducer.messages;
export const selectUpdateResponsibleGamingData = (state: RootState) => state.UpdateResponsibleGamingReducer.data;

export const selectUpdateResponsibleGamingErrorString = (state: RootState): string | null => {
  const error = state.UpdateResponsibleGamingReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectUpdateResponsibleGamingMessagesString = (state: RootState): string | null => {
  const messages = state.UpdateResponsibleGamingReducer.messages;

  if (Array.isArray(messages)) {
    return messages.join('\n');
  }

  if (typeof messages === 'object' && messages !== null) {
    return Object.entries(messages)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return messages;
};

export const selectUpdateResponsibleGamingErrorArray = (state: RootState): string[] | null => {
  const error = state.UpdateResponsibleGamingReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
