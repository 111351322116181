import styles from "./BannerLeft.module.scss"
import {ScrollButton} from "../../../../common/ScrollButton/ScrollButton"
import {BrightCarouselDetachedControl} from "../../../../common/BrightCarousel/BrightCarouselDetachedControl"
import {DepositBannerBonus} from "../../../../api/types"
import React, {useState, useEffect} from "react"
import {useGetDepositBannerQuery} from "../../../../api/api"
import signup_background from 'src/assets/images/signup_background.jpg'
import config from "src/config"
import {useTranslation} from "react-i18next"

interface BannerLeftProps {
  onSelectedBonus: (bonus: DepositBannerBonus) => void;
}

export const BannerLeft: React.FC<BannerLeftProps> = ({ onSelectedBonus }) => {
  const {data} = useGetDepositBannerQuery({language: 1, id: 1})

  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if (data?.data.bonuses[selectedIndex]) {
      onSelectedBonus(data.data.bonuses[selectedIndex])
    }
  }, [selectedIndex, data, onSelectedBonus])

  const handlePrev = () => {
    setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev)) // Don't go into the minus
  }

  const handleNext = () => {
    setSelectedIndex((prev) => (prev < (data?.data.bonuses.length ?? 1) - 1 ? prev + 1 : prev)) // Do not go beyond the array boundaries
  }

  const currentBonusImage = data?.data.bonuses[selectedIndex].image_path
    ? `${config.fileUrls.bonusFolder}${data?.data.bonuses[selectedIndex].image_path}`
    : signup_background

  const {t} = useTranslation()

  return (
    <div className={styles.leftPart} style={{
      backgroundImage: `url(${currentBonusImage})`,
      backgroundPosition: "top",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}>
      <div className={styles.title}>{data?.data.title}</div>
      <div className={styles.carouselContainer}>
        <ScrollButton onClick={handlePrev} direction={'left'} variant={'arrow'} className={styles.leftControl}/>
        <div className={styles.carousel}>
          {
            data && <BrightCarouselDetachedControl
                  array={data?.data.bonuses.map((bonus: DepositBannerBonus, index: number) => (
                    <div key={index} className={styles.bonusTitle}>
                      {bonus.name}
                    </div>
                  ))}
                  selectedIndex={selectedIndex}
              />

          }
        </div>
        <ScrollButton onClick={handleNext} direction={'right'} variant={'arrow'} className={styles.rightControl}/>
      </div>

      <div className={styles.bottomText}>{t('Play more - get more!')}</div>
    </div>
  )
}