// menuFinancialProcessesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import credit_deposit_01 from 'src/assets/images/icons/buttons/credit_deposit_01.svg'
import coins_exchange_01 from 'src/assets/images/icons/buttons/coins_exchange_01.svg'
import credit_withdraw_01 from 'src/assets/images/icons/buttons/credit_withdraw_01.svg'
import currency_buycrypto_01 from 'src/assets/images/icons/buttons/currency_buycrypto_01.svg'

// Тип кнопки
export type ButtonData = {
  id: number;
  label: string;
  img: string;
  imgAlt: string;
};

// Тип состояния
export type MenuState = {
  activeButton: number; // ID активной кнопки
  buttonData: Array<ButtonData>; // Данные кнопок
};

// Начальное состояние
const initialState: MenuState = {
  activeButton: 1, // Начальное состояние активной кнопки
  buttonData: [
    { id: 1, label: 'Deposit', img: credit_deposit_01, imgAlt: 'credit_deposit_01' },
    { id: 2, label: 'Withdraw', img: credit_withdraw_01, imgAlt: 'credit_withdraw_01' },
    { id: 3, label: '', img: coins_exchange_01, imgAlt: 'coins_exchange_01' },
    { id: 4, label: '', img: currency_buycrypto_01, imgAlt: 'currency_buycrypto_01' },
  ],
};

const menuFinancialProcessesSlice = createSlice({
  name: 'menuFinancialProcessesReducer',
  initialState,
  reducers: {
    setActiveButton: (state, action: PayloadAction<number>) => {
      state.activeButton = action.payload; // Устанавливаем активную кнопку
    },
    updateButtonData: (state, action: PayloadAction<Array<ButtonData>>) => {
      state.buttonData = action.payload; // Обновляем данные кнопок
    },
  },
});

// Селекторы
export const selectActiveButton = (state: RootState) => state.menuFinancialProcessesReducer.activeButton; // Селектор для activeButton
export const selectButtonData = (state: RootState) => state.menuFinancialProcessesReducer.buttonData; // Селектор для buttonData

// Экспортируем действия и редюсер
export const { setActiveButton, updateButtonData } = menuFinancialProcessesSlice.actions;
const menuFinancialProcessesReducer = menuFinancialProcessesSlice.reducer;
export default menuFinancialProcessesReducer;
