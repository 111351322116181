import {GettingStarted} from "./GettingStarted/GettingStarted"
import {ExperienceLoyalty} from "./ExperienceLoyalty/ExperienceLoyalty"
import {Balance} from "./Balance/Balance"
import {AccountFavouriteGames} from "./AccountFavouriteGames/AccountFavouriteGames"
import {VerifyProfile} from "./VerifyProfile/VerifyProfile"
import {MyBonuses} from "./MyBonuses/MyBonuses"
import React from "react"

export const Main = () => {
  return (
    <>
      <GettingStarted/>
      <ExperienceLoyalty/>
      <Balance />
      <AccountFavouriteGames />
      <VerifyProfile />
      <MyBonuses />
    </>
  )
}