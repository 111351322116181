import React from 'react'
import styles from './QuestionBlock.module.scss'
import mail_01 from 'src/assets/images/icons/mail_01.svg'
import telegram from 'src/assets/images/icons/telegram.svg'
import message_chat_square from 'src/assets/images/icons/message_chat_square.svg'
import {useTranslation} from "react-i18next"

export const QuestionBlock = () => {
  const {t} = useTranslation()
  return (
    <div className={styles.questionBlock}>
      <div className={styles.textContainer}>
        <div className={styles.title}>
            {t('Any questions?')}
        </div>
        <div className={styles.subtitle}>
            {t('Contact our support')}
        </div>
      </div>
      <div className={styles.imageContainer}>
        <div className={styles.imageElement}>
          <img src={mail_01} alt="mail_01"/>
        </div>
        <div className={styles.imageElement}><img src={telegram} alt="telegram"/></div>
        <div className={styles.imageElement}><img src={message_chat_square} alt="message_chat_square"/></div>
      </div>

    </div>
  )
}