import { AppBar } from "@mui/material";
import { styled } from "@mui/system";
import { isMobileScreen } from "./helpers";
import {useAppSelector} from "../hooks"
import {interfaceSelector} from "../redux/slices/interfaceSlice"

export const AppBarStyled = styled(AppBar)(() => {

  const {isMobile} = useAppSelector(interfaceSelector)

  return {
    backgroundColor: "var(--background-surface-elevation-1)",
    height: isMobile ? "var(--navbar-height-mobile)" : "var(--navbar-height)",
    padding: isMobile ? "0 16px" : "0 40px",
    color: "var(--text-base-secondary)",
    justifyContent: "center",
  }
})


