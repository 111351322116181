// changeQueueSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type ChangeQueueResponse = {
  success: boolean;
  data: boolean; // Уточните тип данных
  messages: string | null;
};

type ChangeQueueError = {
  error: any;
  message: string;
};

interface ChangeQueueState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: boolean; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: ChangeQueueState = {
  success: false,
  loading: false,
  error: null,
  data: false,
  messages: null,
};



// Типизация для fetchChangeQueue
// export type fetchChangeQueueParams = {
//   id: number;
// }

export type FetchChangeQueueParams = {
    token: string;
    params: {
        id: number;
    };
}

export const fetchChangeQueue = createAsyncThunk<
  ChangeQueueResponse,
  FetchChangeQueueParams,
  { rejectValue: ChangeQueueError }
>(
  'ChangeQueue/fetchChangeQueue',
  async ({ token, params = {} }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchChangeQueue:', `${config.serverUrl}/popup/change-queue`, params);

      const response = await axios.get<ChangeQueueResponse>(
        `${config.serverUrl}/popup/change-queue`,
        {
          params,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        }
      );
      console.log('Attempting to fetchChangeQueue response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error fetchChangeQueue:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to fetchChangeQueue .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);


const ChangeQueue = createSlice({
  name: 'Popup/ChangeQueue',
  initialState,
  reducers: {
    resetStateChangeQueue: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChangeQueue.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchChangeQueue.fulfilled, (state, action: PayloadAction<ChangeQueueResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchChangeQueue.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateChangeQueue } = ChangeQueue.actions;

export const ChangeQueueReducer = ChangeQueue.reducer;



// ---------
// Selectors
// ---------
export const selectChangeQueueLoading = (state: RootState) => state.ChangeQueueReducer.loading;
export const selectChangeQueueSuccess = (state: RootState) => state.ChangeQueueReducer.success;
export const selectChangeQueueError = (state: RootState) => state.ChangeQueueReducer.error;
export const selectChangeQueueMessages = (state: RootState) => state.ChangeQueueReducer.messages;
export const selectChangeQueueData = (state: RootState) => state.ChangeQueueReducer.data;



export const selectChangeQueueErrorString = (state: RootState): string | null => {
  const error = state.ChangeQueueReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectChangeQueueErrorArray = (state: RootState): string[] | null => {
  const error = state.ChangeQueueReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
