import React, {useEffect, useRef, useState} from 'react'
import {useGetConstructTermsQuery} from '../../../api/api'
import {TermsItem} from '../../../api/types'
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {interfaceSelector, setTermTopHeight} from "../../../redux/slices/interfaceSlice"
import styles from './Terms.module.scss'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import clsx from 'clsx'
import {FaqTermsTemplate} from "../../../common/FaqTermsTemplate/FaqTermsTemplate"
import {BrightBreadcrumbs} from "../../../common/BrightBreadcrumbs/BrightBreadcrumbs"
import {formatDate} from "../Transactions/common/utils"
import {MobileStatus} from "../../../common/MobileStatus/MobileStatus"
import {useTranslation} from "react-i18next"
import {BrightAccordion} from 'src/common/BrightAccordion/BrightAccordion'

export const Terms = () => {
  const {selectedLanguage} = useAppSelector(interfaceSelector) // Current selected language
  const {data, error, isLoading} = useGetConstructTermsQuery(selectedLanguage.id) // Fetch Terms based on language
  const [selectedTerm, setSelectedTerm] = useState<TermsItem | null>(null) // Track selected Term

  const {isMobile} = useAppSelector(interfaceSelector)
  const {t} = useTranslation()

  const terms = data?.data || [] // Extract Terms data or set empty array

  // Find the most recent updated_at
  const latestTerm = terms.length > 0
    ? terms.reduce((latest, item) => (item.updated_at > latest.updated_at ? item : latest))
    : null

  const formattedDate = latestTerm ? formatDate(latestTerm.updated_at, "dd.mm.yyyy") : "N/A"

  const breadcrumbItems = [
    {label: 'Home page', path: '/', isLink: true},
    {label: 'Terms & Conditions', isLink: false}
  ]

  const constantText = 'In order to prevent potential misunderstandings arising from the translation of our original text, it is imperative to note that the casino terms and conditions are exclusively provided in English across all language versions. Should you require assistance with the translation and interpretation of these terms and conditions into your language, please do not hesitate to reach out to our live chat support or write an email to support@spaceduckers.net. Our dedicated support agents will be available to assist you in understanding the terms and conditions thoroughly.'

  useEffect(() => {
    if (terms.length > 0 && !selectedTerm) {
      setSelectedTerm(terms[0]) // Set the first Term as selected
    }
  }, [terms, selectedTerm])

  const dispatch = useAppDispatch()

  // Measure Height
  const elementRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!isLoading && elementRef.current) {
      dispatch(setTermTopHeight(elementRef.current.clientHeight)) // setting element height
    }
  }, [isLoading])

  if (isLoading) return <p>Loading Terms, please wait...</p>
  if (error) return <p>Error fetching Terms: {error.toString()}</p>

  return (
    <>
      {isMobile && <MobileStatus variant={'game'} title={t('Terms & Conditions')}/>}

      <div className={clsx(styles.termsContainer, stylesCommon.pageContainer)}>

        {!isMobile ? (
          <div className={styles.termsTop} ref={elementRef}>
            <div className={styles.termsLeft}>
              <BrightBreadcrumbs items={breadcrumbItems}/>
              <div className={styles.updated}>Last updated: {formattedDate}</div>
            </div>
            <div className={styles.termsText}>{constantText}</div>
          </div>
        ) : (
          <BrightAccordion.Wrapper variant={'singleTerm'}>
            <BrightAccordion.Root
              summary={`Last updated: ${formattedDate}`}
              details={<div className={styles.termsText}>{constantText}</div>}
              variant={'singleTerm'}
            />
          </BrightAccordion.Wrapper>
        )}
        <FaqTermsTemplate items={terms} selectedItem={selectedTerm}
                          setSelectedItem={(item) => setSelectedTerm(item as TermsItem)} variant={'terms'}/>
      </div>
    </>
  )
}
