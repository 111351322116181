import React from "react";
import { UserIconProps } from "../../../../common/types";

export const CreditWithdraw01Icon = ({
                                stroke = "white",
                                strokeOpacity = "0.6",
                                className,
                              }: UserIconProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.0335 11.3327H3.96683M3.96683 4.66602C3.22009 4.66602 2.84672 4.66602 2.56151 4.81134C2.31063 4.93917 2.10665 5.14315 1.97882 5.39403C1.8335 5.67924 1.8335 6.05261 1.8335 6.79935L1.8335 11.866C1.8335 12.6128 1.8335 12.9861 1.97882 13.2713C2.10665 13.5222 2.31063 13.7262 2.56151 13.854C2.84672 13.9993 3.22009 13.9993 3.96683 13.9993L13.0335 13.9993C13.7802 13.9993 14.1536 13.9993 14.4388 13.854C14.6897 13.7262 14.8937 13.5222 15.0215 13.2713C15.1668 12.9861 15.1668 12.6128 15.1668 11.866V6.79935C15.1668 6.05261 15.1668 5.67924 15.0215 5.39403C14.8937 5.14315 14.6897 4.93917 14.4388 4.81134C14.1536 4.66602 13.7802 4.66602 13.0335 4.66602"
      stroke={stroke}
      strokeOpacity={strokeOpacity}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_4207_76314)">
      <path
        d="M5.77783 5.05425H6.83339V8.52092C6.83339 8.52412 6.83339 8.52741 6.83338 8.53078C6.83336 8.57605 6.83332 8.63582 6.8377 8.68935C6.84287 8.75269 6.85658 8.84983 6.90908 8.95286C6.97566 9.08353 7.08189 9.18976 7.21256 9.25634C7.31559 9.30884 7.41273 9.32255 7.47607 9.32772C7.52959 9.33209 7.58936 9.33206 7.63463 9.33203C7.63801 9.33203 7.6413 9.33203 7.6445 9.33203H9.35561C9.35881 9.33203 9.3621 9.33203 9.36547 9.33203C9.41075 9.33206 9.47051 9.33209 9.52404 9.32772C9.58738 9.32255 9.68452 9.30884 9.78755 9.25634C9.91822 9.18976 10.0245 9.08353 10.091 8.95286C10.1435 8.84983 10.1572 8.75269 10.1624 8.68935C10.1668 8.63582 10.1667 8.57605 10.1667 8.53077C10.1667 8.52741 10.1667 8.52412 10.1667 8.52092V5.05425H11.2223C11.4245 5.05425 11.6068 4.93243 11.6842 4.74559C11.7616 4.55876 11.7188 4.3437 11.5758 4.2007L8.85361 1.47848C8.65835 1.28322 8.34176 1.28322 8.1465 1.47848L5.42428 4.2007C5.28128 4.3437 5.2385 4.55876 5.31589 4.74559C5.39328 4.93243 5.5756 5.05425 5.77783 5.05425Z"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4207_76314">
        <rect
          width="9.33333"
          height="9.33333"
          fill="white"
          transform="translate(3.8335 0.666016)"
        />
      </clipPath>
    </defs>
  </svg>
);
