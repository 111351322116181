import React from "react";
import { UserIconProps } from "../../../../common/types";

export const CreditDeposit01Icon = ({
                               stroke = "white",
                               strokeOpacity = "0.6",
                               className,
                             }: UserIconProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_4207_102771)">
      <path
        d="M13.3338 11.3327H4.26712M4.26712 4.66602C3.52039 4.66602 3.14702 4.66602 2.8618 4.81134C2.61092 4.93917 2.40695 5.14315 2.27911 5.39403C2.13379 5.67924 2.13379 6.05261 2.13379 6.79935L2.13379 11.866C2.13379 12.6128 2.13379 12.9861 2.27911 13.2713C2.40694 13.5222 2.61092 13.7262 2.8618 13.854C3.14702 13.9993 3.52039 13.9993 4.26712 13.9993L13.3338 13.9993C14.0805 13.9993 14.4539 13.9993 14.7391 13.854C14.99 13.7262 15.194 13.5222 15.3218 13.2713C15.4671 12.9861 15.4671 12.6128 15.4671 11.866V6.79935C15.4671 6.05261 15.4671 5.67924 15.3218 5.39403C15.194 5.14315 14.99 4.93917 14.7391 4.81134C14.4539 4.66602 14.0805 4.66602 13.3338 4.66602"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip1_4207_102771)">
        <path
          d="M11.8761 6.46336C12.0191 6.32036 12.0619 6.1053 11.9845 5.91847C11.9071 5.73163 11.7248 5.60981 11.5226 5.60981H10.467V2.14314C10.467 2.13994 10.467 2.13666 10.467 2.13329C10.467 2.08802 10.4671 2.02824 10.4627 1.97472C10.4575 1.91137 10.4438 1.81424 10.3913 1.7112C10.3247 1.58054 10.2185 1.4743 10.0878 1.40772C9.98481 1.35522 9.88767 1.34152 9.82433 1.33634C9.77081 1.33197 9.71103 1.332 9.66576 1.33203C9.66239 1.33203 9.6591 1.33203 9.6559 1.33203H7.94479C7.94159 1.33203 7.9383 1.33203 7.93493 1.33203C7.88966 1.332 7.82989 1.33197 7.77636 1.33634C7.71302 1.34152 7.61588 1.35522 7.51285 1.40772C7.38218 1.4743 7.27595 1.58054 7.20937 1.7112C7.15687 1.81424 7.14317 1.91137 7.13799 1.97472C7.13362 2.02824 7.13365 2.08801 7.13368 2.13328C7.13368 2.13665 7.13368 2.13994 7.13368 2.14314V5.60981H6.07812C5.87589 5.60981 5.69358 5.73163 5.61619 5.91847C5.53879 6.1053 5.58157 6.32036 5.72457 6.46336L8.44679 9.18558C8.64206 9.38085 8.95864 9.38085 9.1539 9.18558L11.8761 6.46336Z"
          stroke={stroke}
          strokeOpacity={strokeOpacity}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4207_102771">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.800293)"
        />
      </clipPath>
      <clipPath id="clip1_4207_102771">
        <rect
          width="9.33333"
          height="9.33333"
          fill="white"
          transform="translate(4.13379 0.666016)"
        />
      </clipPath>
    </defs>
  </svg>
);
